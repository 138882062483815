.projects-body {
	&__items {
		margin-top: 2.1rem;
		margin-bottom: 6.2rem;
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 6.8rem;
			@media (max-width: $sw-sm-max) {
				margin-bottom: 5rem;
			}
		}
		&-title {
			h2 {
				font-size: 2.4rem;
				font-weight: 800;
				text-transform: capitalize;
				@media (max-width: $sw-sm-max) {
					font-size: 2rem;
					line-height: normal;
				}
				@media (max-width: $sw-xx-max) {
					font-size: 1.4rem;
				}
			}
		}
		&-body {
			display: flex;
			@media (max-width: $sw-sm-max) {
				flex-direction: column-reverse;
				align-items: center;
			}
		}
		&-desc {
			max-width: 63rem;
			padding-top: 0.7rem;
			padding-left: 1.8rem;
			@media (max-width: $sw-xx-max) {
				padding-left: 0;
			}
			p {
				font-size: 1.6rem;
				font-weight: 500;
				line-height: 2.6rem;
				letter-spacing: -0.2px;
				margin-bottom: 1.5rem;
				@media (max-width: $sw-sm-max) {
					font-size: 1.3rem;
				}
				
				
			}
			a {
				text-decoration: none;
				font-size: 1.6rem;
				font-weight: 900;
				color: $cerulean;
				display: flex;
				align-items: center;
				@media (max-width: $sw-xx-max) {
					font-size: 1.4rem;
				}
				&:hover {
					.icon-arrow {
						transform: translateX(0.6rem);
					}
				}
				.icon-arrow {
					margin-left: 4px;
					fill: $cerulean;
					transition: all 0.3s ease;
					flex-shrink: 0;
				}
			}
		}
		&-img {
			width: 20.1rem;
			height: 15rem;
			flex-shrink: 0;
			margin-left: 7.5rem;
			margin-top: 0.7rem;
			@media (max-width: $sw-sm-max) {
				margin-left: 0;
				margin-bottom: 1rem;
				width: 30rem;
			}
			@media (max-width: $sw-xx-max) {
				width: 100%;
			}
			&-inner {
				height: 100%;
			}
		}
	}
	&__disclaimer {
		p {
			font-weight: 500;
			font-style: italic;
			font-size: 1.6rem;
			letter-spacing: -0.1px;
		}
	}
	&__report {
		margin-top: 5.6rem;
		margin-bottom: 2.7rem;
		display: flex;
		@media (max-width: $sw-sm-max) {
			flex-direction: column;
			margin-top: 3rem;
			align-items: center;
		}
		&-question {
			max-width: 320px;
			flex-shrink: 0;
			@media (max-width: $sw-md-max) {
				max-width: 200px;
			}
			@media (max-width: $sw-xx-max) {
				max-width: 100%;
				text-align: center;
			}
			span {
				color: #47D597;
				font-size: 2.8rem;
				font-weight: 800;
				line-height: 4.4rem;
				@media (max-width: $sw-md-max) {
					font-size: 1.6rem;
					line-height: 2rem;
				}
			}
		}
		&-form {
			margin-left: 1.8rem;
			margin-top: 0.4rem;
			@media (max-width: $sw-md-max) {
				margin-left: 0;
			}
			@media (max-width: $sw-sm-max) {
				margin-top: 20px;
			}
			.news-inner__aside-form {
				padding-top: 0;
				background: none;
				width: 32.7rem;
				@media (max-width: $sw-sm-max) {
					padding-left: 0;
				}
				.form-box_submit button {
					background-color: #47D597;
			}
			}
			.form-box__content--inputs {
				padding-top: 0;
			}
		}
		&-list {
			/* padding-top: 3.6rem;
			padding-bottom: 2rem;
			padding-left: 1.2rem;
			padding-right: 1.4rem; */
			background-color: #DDDDDD;
			margin-left: 3rem;
			    padding: 2.6rem;
			@media (max-width: $sw-sm-max) {
				width: 100%;
				margin-left: 0;
			}
			span {
				font-weight: 400;
				font-size: 1.6rem;
			}
			ul {
				list-style-type: inherit;
				padding-top: 2.5rem;
				padding-left: 1.6rem;
				li {
					font-size: 1.6rem;
					&:not(:last-child){
						margin-bottom: 0.8rem;
					}
				}
			}
		}
	}
}