.cookies-box{
	z-index: 3;
	right: 3.2rem;
	width: 24.6rem;
	bottom: 3.2rem;
	position: fixed;
	padding-top: 2.9rem;
	padding-left: 3.2rem;
	padding-right: 3.2rem;
	padding-bottom: 3.2rem;
	background-color: $white;
	transition: all 0.5s ease;
	box-shadow: 0 2rem 3rem 0 rgba(15, 82, 109, .24);
	@media ( max-width: $sw-xs-max) {
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		padding-top: 2.8rem;
		padding-left: 2.4rem;
		padding-right: 11rem;
		padding-bottom: 3rem;
	}
	&.deactivate{
		@media ( min-width: $sw-sm-min) {
			opacity: 0;
			transform: translateX(150%);
		}
		@media ( max-width: $sw-xs-max) {
			opacity: 0;
			transform: translateY(120%);
		}
	}
}

.cookies-box__title{
	font-weight: 700;
	font-size: 1.6rem;
	line-height: 1.8rem;
	margin-bottom: 1.1rem;
}

.cookies-box__description{
	font-size: 1.4rem;
	line-height: 1.9rem;
}

.cookies-box__action{
	font-size: 1.6rem;
	margin-top: 1.9rem;
	@media ( max-width: $sw-xs-max) {
		top: 1.3rem;
		right: 2.4rem;
		position: absolute;
	}
}