.partner {
    &__section {
        padding-top: 8rem;
        height: 100%;
        background-color: #f5f5f5;
        @media (max-width: $sw-lg-max) {
            padding-top: 11rem;
        }
        @media (max-width: $sw-xs-max) {
            padding-top: 3rem;
        }
        .section-navigation {
            margin-bottom: 3.5rem;
        }
        .partner-about-container {
            @media (max-width: $sw-xs-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__info-wrap {
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: flex-start;
        padding: 4.1rem 0 6.8rem 0;
        margin-bottom: 7.9rem;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
            padding: 4rem;
        }
        @media (max-width: $sw-xs-max) {
            padding: 4rem 2.5rem;
        }
    }
    &__logo {
        width: 15.4rem;
        height: 15.4rem;
        flex-shrink: 0;
        margin-left: 8.5rem;
        margin-right: 10rem;
        @media (max-width: $sw-md-max) {
            margin-left: 6rem;
            margin-right: 6rem;
        }
        @media (max-width: $sw-sm-max) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__info {
        margin-top: 3.4rem;
    }
    &__name {
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }
    &__descr {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
    &__link {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin: 4.2rem 6.5rem 0 10rem;
        font-weight: 500;
        transition: opacity .3s ease;
        opacity: 1;
        @media (max-width: $sw-md-max) {
            margin: 3rem 5rem 0 5rem;
        }
        @media (max-width: $sw-sm-max) {
            margin: 3rem 0 0 0;
        }
        &:hover {
            @media (min-width: $sw-md-min) {
                opacity: 0.8;
            }
        }
    }
    &__about {
        &-wrap {
            // border: 1px solid blue;
            display: flex;
            align-items: flex-start;
            margin-bottom: 16rem;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
                margin-bottom: 10rem;
            }
        }
        &-content {
            // border: 1px solid red;
            padding-left: 8.5rem;
            padding-right: 10rem;
            width: calc(100% - 15.4rem);
            @media (max-width: $sw-md-max) {
                padding-left: 6rem;
                padding-right: 6rem;
            }
            @media (max-width: $sw-sm-max) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        &-partners {
            // border: 1px solid blue;
            width: 15.4rem;
            flex-shrink: 0;
            @media (max-width: $sw-sm-max) {
                width: 100%;
            }
            @media (max-width: $sw-xs-max) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            .platforms__item {
                padding-left: 0;
                @media (max-width: $sw-sm-max) {
                    margin-right: 1rem;
                }
            }
            .platform-box {
                @media (max-width: $sw-sm-max) {
                    width: 20.8rem;
                    height: 20.8rem;
                }
                &__case {
                    @media (max-width: $sw-sm-max) {
                        transform: translateX(0px);
                        background-color: transparent;
                        &-btn {
                            color: #000;
                            svg {
                                fill: #000;
                            }
                        }
                    }
                }
                &__view picture img {
                    @media (max-width: $sw-sm-max) {
                        bottom: 2.5rem;
                    }
                }
            }
            .slick-dots {
                @media (max-width: $sw-sm-max) {
                    justify-content: center;
                }
            }
        }
        &-title {
            text-transform: uppercase;
            margin-bottom: 1.8rem;
            @media (max-width: $sw-xs-max) {
                font-size: 3.2rem;
                line-height: 3.4rem;
                margin-bottom: 3.5rem;
            }
        }
    }
    &__content {
        &-block {
            // border: 1px solid red;
            margin-bottom: 6rem;
            line-height: 4rem;
            @media (max-width: $sw-xs-max) {
                margin-bottom: 8rem;
            }
            & h1, h2, h3, h4 {
                margin-bottom: 4rem;
                font-weight: 700;
                @media (max-width: $sw-xs-max) {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    line-height: 4rem;
                    margin-bottom: 2rem;
                }
            }
            & p {
                font-size: 2rem;
                line-height: 2.8rem;
                margin-bottom: 4rem;
                @media (max-width: $sw-xs-max) {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            }
        }
    }
    &__carousel {
        height: 35rem;
        // border: 1px solid green;
        overflow: hidden;
        @media (max-width: $sw-xs-max) {
            height: 24rem;
        }
        &-item {
            width: 58rem;
            height: 35rem !important;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $sw-xs-max) {
                width: 100%;
                height: 24rem !important;
            }
        }
        &-wrap {
            width: 58rem;
            position: relative;
            // border: 1px solid red;
            margin-bottom: 4rem;
            @media (max-width: $sw-sm-max) {
                margin: 0 auto 4rem auto;
            }
            @media (max-width: $sw-xs-max) {
                width: 100%;
            }
        }
        &-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.1rem;
            .partner__carousel-action {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 1;
                transition: opacity .3s ease;
                @media (max-width: $sw-xs-max) {
                    display: none;
                }
                &.slick-disabled {
                    opacity: 0.5;
                }
                &.action-next {
                    right: -3.5rem;
                }
                &.action-prev {
                    left: -3.5rem;
                }
            }
        }
    }
}

.section-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3rem;
    @media (max-width: $sw-xs-max) {
        justify-content: space-between;
    }
    .link {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1.6rem;
        .icon-arrow {
            @media (max-width: $sw-xs-max) {
                fill: #000;
            }
        }
        & + .link {
            margin-left: 3rem;
            position: relative;
            @media (max-width: $sw-xs-max) {
                margin-left: 0;
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -1.5rem;
                top: 50%;
                transform: translateY(-50%);
                background-color: #787878;
                width: 1px;
                height: 1.2rem;
                cursor: default;
                pointer-events: none;
                @media (max-width: $sw-xs-max) {
                    display: none;
                }
            }
        }
    }
}