.section-expect{
	margin-top: 11.1rem;
	margin-bottom: 11.1rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 10.5rem;
		margin-bottom: 10.5rem;
	}
	.section-title{
		.section-title__main{
			h5{
				font-weight: 500;
				font-size: 2.4rem;
				line-height: 3rem;
				margin-top: 2.8rem;
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					margin-top: 3.2rem;
				}
			}
		}
	}
	@media ( max-width: $sw-sm-max) {
		.section-img{
			display: none;
		}
	}
	
}

.expect__content{
	margin-top: 14.1rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 7.2rem;
	}
}

.expect__items{
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.expect__item{
	width: 25%;
	padding-right: 2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 33.33333%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 50%;
	}
	@media ( max-width: $sw-xx-max) {
		width: 100%;
		padding-right: 0;
	}

	@media ( min-width: $sw-md-min) {
		&:nth-child(n+5){
			margin-top: 5.1rem;
		}
	}
	@media ( min-width: $sw-sm-min) and ( max-width: $sw-sm-max) {
		&:nth-child(n+4){
			margin-top: 5.1rem;
		}
	}
	@media ( min-width: $sw-xs-min) and ( max-width: $sw-xs-max) {
		&:nth-child(n+3){
			margin-top: 5.1rem;
		}
	}
	@media ( max-width: $sw-xx-max) {
		&:nth-child(n+2){
			margin-top: 5.1rem;
		}
	}
}

.expect-box{

}

.expect-box__view{
	height: 9.8rem;
	svg{
		width: 1em;
	    height: 1em;
	    vertical-align: top;
	    display: inline-block;
		font-size: 7rem;
	}
	img{
		width: 7rem;
    	height: auto;
	}
}

.expect-box__name{
	font-weight: 900;
	font-size: 2rem;
	min-height: 6.2rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-xx-max) {
		min-height: auto;
	}
}

.expect-box__caption{
	font-size: 2rem;
	margin-top: .8rem;
	line-height: 2.8rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 1.3rem;
	}
}