.section-news-list{
	padding-top: 7.9rem;
	padding-bottom: 7.9rem;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 6.2rem;
		padding-bottom: 3.6rem;
	}
}

.news-list__content{
	overflow: hidden;
	margin-top: 3.4rem;
}

.news-list__items{
	display: flex;
	align-items: center;
	margin-left: -1.6rem;
	.news-box{
		width: 33.33333%;
		padding-left: 1.6rem;
	}
}