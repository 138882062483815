.lead-partners__section {
    background-color: $wildSand;
    .partner-boxes__content {
       align-items: flex-start;
    }  
    .partner-boxes__aside {
        margin-bottom: 0;
        margin-top: 5.7rem;
    }
    .partner-boxes__title {
        width: 100%;
        margin-bottom: 1.8rem;
        text-transform: uppercase;
    }
    .partner-boxes__aside-content {
        height: auto;
         & + .partner-boxes__aside-content {
             margin-top: 1.6rem;
         }
    }
}

.partner-boxes__block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}

.platform-box_lg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 6rem;
    @media (max-width: $sw-xs-max) {
        width: 32.5rem;
    }
    &-wrap {
        width: 32.5rem;
        height: 32.5rem;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        @media (max-width: $sw-md-max) {
            width: 30.5rem;
            height: 30.5rem;
        }
        @media (max-width: $sw-sm-max) {
            width: 32.5rem;
            height: 32.5rem;
        }
        @media (max-width: $sw-xs-max) {
            margin-right: 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
        @media (max-width: $sw-xx-max) {
            height: 30rem;
        }
    }
    
    &-partner {
        position: relative;
        z-index: 2;
        
    }
    &.with_img {
        &:after{
             content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,.5);
        }
        &-partner {
            img {
                filter: grayscale(100%);
            }
        }
    }
}