@charset "UTF-8";
/*$link-hover-color:      darken($link-color, 15%);*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "TTNorms", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
  text-align: left;
  background-color: #f3f3f3; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #eb50a3;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: #1c1c1c;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "TTNorms", sans-serif;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small,
.speaker-position,
.partner-boxes__wrapper .platform-box__type,
.reg-aside__order-period {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 480px) {
    .container {
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      max-width: 73.8rem; } }
  @media (min-width: 992px) {
    .container {
      max-width: 96.2rem; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 103.8rem; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 103.8rem; } }

.container-fluid {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.5rem;
  margin-left: -1.5rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

@media (min-width: 480px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xs-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xs-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xs-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xs-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xs-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xs-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xs-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xs-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xs-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xs-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xs-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xs-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 25; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .order-xs-13 {
    order: 13; }
  .order-xs-14 {
    order: 14; }
  .order-xs-15 {
    order: 15; }
  .order-xs-16 {
    order: 16; }
  .order-xs-17 {
    order: 17; }
  .order-xs-18 {
    order: 18; }
  .order-xs-19 {
    order: 19; }
  .order-xs-20 {
    order: 20; }
  .order-xs-21 {
    order: 21; }
  .order-xs-22 {
    order: 22; }
  .order-xs-23 {
    order: 23; }
  .order-xs-24 {
    order: 24; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 4.16667%; }
  .offset-xs-2 {
    margin-left: 8.33333%; }
  .offset-xs-3 {
    margin-left: 12.5%; }
  .offset-xs-4 {
    margin-left: 16.66667%; }
  .offset-xs-5 {
    margin-left: 20.83333%; }
  .offset-xs-6 {
    margin-left: 25%; }
  .offset-xs-7 {
    margin-left: 29.16667%; }
  .offset-xs-8 {
    margin-left: 33.33333%; }
  .offset-xs-9 {
    margin-left: 37.5%; }
  .offset-xs-10 {
    margin-left: 41.66667%; }
  .offset-xs-11 {
    margin-left: 45.83333%; }
  .offset-xs-12 {
    margin-left: 50%; }
  .offset-xs-13 {
    margin-left: 54.16667%; }
  .offset-xs-14 {
    margin-left: 58.33333%; }
  .offset-xs-15 {
    margin-left: 62.5%; }
  .offset-xs-16 {
    margin-left: 66.66667%; }
  .offset-xs-17 {
    margin-left: 70.83333%; }
  .offset-xs-18 {
    margin-left: 75%; }
  .offset-xs-19 {
    margin-left: 79.16667%; }
  .offset-xs-20 {
    margin-left: 83.33333%; }
  .offset-xs-21 {
    margin-left: 87.5%; }
  .offset-xs-22 {
    margin-left: 91.66667%; }
  .offset-xs-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #eb50a3;
  text-decoration: underline; }
  .btn-link:hover {
    color: #1c1c1c;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "TTNorms", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "TTNorms", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 480px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

@media (min-width: 480px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 480px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

@media (min-width: 480px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-n1 {
    margin: -0.25rem !important; }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important; }
  .m-xs-n2 {
    margin: -0.5rem !important; }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important; }
  .m-xs-n3 {
    margin: -1rem !important; }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important; }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important; }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important; }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important; }
  .m-xs-n4 {
    margin: -1.5rem !important; }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important; }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important; }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important; }
  .m-xs-n5 {
    margin: -3rem !important; }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important; }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important; }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important; }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #000000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.h-reset-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none; }

.h-reset h1, .h-reset h2, .h-reset h3, .h-reset h4, .h-reset h5, .h-reset h6 {
  margin: 0; }

.m-reset {
  margin: 0; }

html {
  height: 100%;
  font-size: 10px; }

body {
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff; }

img {
  height: auto;
  max-width: 100%; }

.h-object-fit {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: block; }
  .h-object-fit > img,
  .h-object-fit > video {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    object-position: 50% 50%;
    object-fit: cover;
    max-height: none;
    max-width: none;
    height: 100%; }
    .no-object-fit .h-object-fit > img,
    .no-objectfit .h-object-fit > img, .no-object-fit
    .h-object-fit > video,
    .no-objectfit
    .h-object-fit > video {
      display: none; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Thin.eot");
  src: url("../fonts/TTNorms-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Thin.woff") format("woff"), url("../fonts/TTNorms-Thin.ttf") format("truetype"), url("../fonts/TTNorms-Thin.svg#fontname") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-ExtraLight.eot");
  src: url("../fonts/TTNorms-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraLight.woff") format("woff"), url("../fonts/TTNorms-ExtraLight.ttf") format("truetype"), url("../fonts/TTNorms-ExtraLight.svg#fontname") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Light.eot");
  src: url("../fonts/TTNorms-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Light.woff") format("woff"), url("../fonts/TTNorms-Light.ttf") format("truetype"), url("../fonts/TTNorms-Light.svg#fontname") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Regular.eot");
  src: url("../fonts/TTNorms-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Regular.woff") format("woff"), url("../fonts/TTNorms-Regular.ttf") format("truetype"), url("../fonts/TTNorms-Regular.svg#fontname") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Medium.eot");
  src: url("../fonts/TTNorms-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Medium.woff") format("woff"), url("../fonts/TTNorms-Medium.ttf") format("truetype"), url("../fonts/TTNorms-Medium.svg#fontname") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Heavy.eot");
  src: url("../fonts/TTNorms-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Heavy.woff") format("woff"), url("../fonts/TTNorms-Heavy.ttf") format("truetype"), url("../fonts/TTNorms-Heavy.svg#fontname") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Bold.eot");
  src: url("../fonts/TTNorms-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Bold.woff") format("woff"), url("../fonts/TTNorms-Bold.ttf") format("truetype"), url("../fonts/TTNorms-Bold.svg#fontname") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-ExtraBold.eot");
  src: url("../fonts/TTNorms-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraBold.woff") format("woff"), url("../fonts/TTNorms-ExtraBold.ttf") format("truetype"), url("../fonts/TTNorms-ExtraBold.svg#fontname") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Black.eot");
  src: url("../fonts/TTNorms-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Black.woff") format("woff"), url("../fonts/TTNorms-Black.ttf") format("truetype"), url("../fonts/TTNorms-Black.svg#fontname") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-ThinItalic.eot");
  src: url("../fonts/TTNorms-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ThinItalic.woff") format("woff"), url("../fonts/TTNorms-ThinItalic.ttf") format("truetype"), url("../fonts/TTNorms-ThinItalic.svg#fontname") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-ExtraLightItalic.eot");
  src: url("../fonts/TTNorms-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraLightItalic.woff") format("woff"), url("../fonts/TTNorms-ExtraLightItalic.ttf") format("truetype"), url("../fonts/TTNorms-ExtraLightItalic.svg#fontname") format("svg");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-LightItalic.eot");
  src: url("../fonts/TTNorms-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-LightItalic.woff") format("woff"), url("../fonts/TTNorms-LightItalic.ttf") format("truetype"), url("../fonts/TTNorms-LightItalic.svg#fontname") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-Italic.eot");
  src: url("../fonts/TTNorms-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-Italic.woff") format("woff"), url("../fonts/TTNorms-Italic.ttf") format("truetype"), url("../fonts/TTNorms-Italic.svg#fontname") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-MediumItalic.eot");
  src: url("../fonts/TTNorms-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-MediumItalic.woff") format("woff"), url("../fonts/TTNorms-MediumItalic.ttf") format("truetype"), url("../fonts/TTNorms-MediumItalic.svg#fontname") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-HeavyItalic.eot");
  src: url("../fonts/TTNorms-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-HeavyItalic.woff") format("woff"), url("../fonts/TTNorms-HeavyItalic.ttf") format("truetype"), url("../fonts/TTNorms-HeavyItalic.svg#fontname") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-BoldItalic.eot");
  src: url("../fonts/TTNorms-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-BoldItalic.woff") format("woff"), url("../fonts/TTNorms-BoldItalic.ttf") format("truetype"), url("../fonts/TTNorms-BoldItalic.svg#fontname") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-ExtraBoldItalic.eot");
  src: url("../fonts/TTNorms-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-ExtraBoldItalic.woff") format("woff"), url("../fonts/TTNorms-ExtraBoldItalic.ttf") format("truetype"), url("../fonts/TTNorms-ExtraBoldItalic.svg#fontname") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: "TTNorms";
  src: url("../fonts/TTNorms-BlackItalic.eot");
  src: url("../fonts/TTNorms-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/TTNorms-BlackItalic.woff") format("woff"), url("../fonts/TTNorms-BlackItalic.ttf") format("truetype"), url("../fonts/TTNorms-BlackItalic.svg#fontname") format("svg");
  font-weight: 900;
  font-style: italic; }

@media (max-width: 767px) {
  .container {
    padding-left: 2.4rem;
    padding-right: 2.4rem; } }

.p-wrapp {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  flex-direction: column; }

.nav-list-box {
  top: 0;
  width: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  left: 22.8rem;
  position: fixed;
  overflow: hidden;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: #ffffff; }
  @media (max-width: 1439px) {
    .nav-list-box {
      left: 29.2rem;
      padding-top: 8.4rem;
      padding-bottom: 8.4rem; } }
  @media (max-width: 767px) {
    .nav-list-box {
      left: 0;
      width: 0;
      z-index: 6;
      opacity: 0;
      top: 7.2rem;
      visibility: hidden;
      padding-top: 3.1rem; }
      .nav-submenu-active .nav-list-box {
        opacity: 1;
        visibility: visible; }
      .nav-submenu-deactive .nav-list-box {
        opacity: 1;
        visibility: visible; } }
  .nav-submenu-active .nav-list-box {
    overflow-y: auto; }

.nav-box {
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: 22.8rem;
  position: fixed;
  overflow: hidden;
  background-color: #ffffff; }
  @media (max-width: 1439px) {
    .nav-box {
      bottom: auto;
      width: 29.2rem; } }
  @media (max-width: 767px) {
    .nav-box {
      width: 100%; } }

.content-box {
  padding-left: 22.8rem; }
  @media (max-width: 1439px) {
    .content-box {
      padding-top: 8.4rem; } }
  @media (max-width: 1439px) {
    .content-box {
      padding: 0; } }

.tab-pane {
  display: none; }
  .tab-pane.active {
    display: block; }

main {
  flex-grow: 1; }
  @media (max-width: 767px) {
    main {
      padding-top: 7.2rem; } }
  @media (max-width: 767px) {
    main.conference {
      padding-top: 5.6rem; } }

img {
  max-width: 100%;
  height: auto;
  width: auto; }

hr {
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
  border-top: 1px solid #f3f3f3; }

a {
  outline: none;
  color: #eb50a3;
  transition: all 0.3s ease; }
  a.no-decoration, a.pagination__wrap__item, .pagination__wrap a, a.wp-pagenavi__item, .wp-pagenavi a, a.all-photoes__item {
    text-decoration: none; }
    a.no-decoration:hover, a.pagination__wrap__item:hover, .pagination__wrap a:hover, a.wp-pagenavi__item:hover, .wp-pagenavi a:hover, a.all-photoes__item:hover {
      text-decoration: none; }
    a.no-decoration:focus, a.pagination__wrap__item:focus, .pagination__wrap a:focus, a.wp-pagenavi__item:focus, .wp-pagenavi a:focus, a.all-photoes__item:focus {
      text-decoration: none; }
    a.no-decoration:active, a.pagination__wrap__item:active, .pagination__wrap a:active, a.wp-pagenavi__item:active, .wp-pagenavi a:active, a.all-photoes__item:active {
      text-decoration: none; }
  a.tag {
    display: block;
    color: #787878;
    font-size: 1.6rem;
    padding-top: .7rem;
    padding-left: 1rem;
    line-height: 1.6rem;
    padding-right: 1rem;
    padding-bottom: .9rem;
    border-radius: 2.7rem;
    background-color: #E2E2E2; }
    @media (min-width: 992px) {
      a.tag:hover {
        color: #000000; } }
  a:hover {
    text-decoration: underline;
    outline: none; }
  a:focus {
    text-decoration: underline;
    outline: none; }
  a:active {
    text-decoration: underline;
    outline: none; }

a.link_black, span.link_black, button.link_black {
  font-weight: 900; }

a.link_medium, span.link_medium, button.link_medium {
  font-weight: 500; }

a.link_blue, span.link_blue, button.link_blue {
  color: #009BD7; }
  a.link_blue svg, span.link_blue svg, button.link_blue svg {
    fill: #009BD7; }
  @media (min-width: 992px) {
    a.link_blue:hover, span.link_blue:hover, button.link_blue:hover {
      opacity: .8;
      color: #009BD7; } }

a.link_white, span.link_white, button.link_white {
  color: #ffffff; }
  a.link_white svg, span.link_white svg, button.link_white svg {
    fill: #ffffff; }
  @media (min-width: 992px) {
    a.link_white:hover, span.link_white:hover, button.link_white:hover {
      opacity: .8;
      color: #ffffff; } }

a.link_dark, span.link_dark, button.link_dark {
  color: #000000; }
  @media (min-width: 992px) {
    a.link_dark:hover, span.link_dark:hover, button.link_dark:hover {
      opacity: .8;
      color: #000000; } }

a.link_orange, span.link_orange, button.link_orange {
  color: #FC6621; }
  a.link_orange svg, span.link_orange svg, button.link_orange svg {
    fill: #FC6621; }
  @media (min-width: 992px) {
    a.link_orange:hover, span.link_orange:hover, button.link_orange:hover {
      opacity: .8;
      color: #FC6621; } }

a.link_green, span.link_green, button.link_green {
  color: #98CB4E; }
  a.link_green svg, span.link_green svg, button.link_green svg {
    fill: #98CB4E; }
  @media (min-width: 992px) {
    a.link_green:hover, span.link_green:hover, button.link_green:hover {
      opacity: .8;
      color: #98CB4E; } }

a.link_line, span.link_line, button.link_line {
  overflow: hidden;
  position: relative;
  display: inline-flex; }
  a.link_line:after, span.link_line:after, button.link_line:after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    display: block;
    position: absolute;
    background-color: #ffffff;
    transition: all 0.3s ease;
    transform: translateX(-110%); }
  @media (min-width: 992px) {
    a.link_line:hover, span.link_line:hover, button.link_line:hover {
      opacity: 1; }
      a.link_line:hover:after, span.link_line:hover:after, button.link_line:hover:after {
        transform: translateX(0); } }

a.link_arrow, span.link_arrow, button.link_arrow {
  font-weight: 900;
  font-size: 1.6rem;
  align-items: center;
  line-height: 1.6rem;
  display: inline-flex; }
  a.link_arrow span + svg, span.link_arrow span + svg, button.link_arrow span + svg {
    margin-left: .8rem; }
  a.link_arrow svg, span.link_arrow svg, button.link_arrow svg {
    flex-shrink: 0;
    font-size: 1.8rem;
    transition: all 0.3s ease; }
    a.link_arrow svg + span, span.link_arrow svg + span, button.link_arrow svg + span {
      margin-left: .8rem; }
  @media (min-width: 992px) {
    a.link_arrow:hover, span.link_arrow:hover, button.link_arrow:hover {
      opacity: 1; }
      a.link_arrow:hover span + svg, span.link_arrow:hover span + svg, button.link_arrow:hover span + svg {
        transform: translateX(0.6rem); }
      a.link_arrow:hover svg + span, span.link_arrow:hover svg + span, button.link_arrow:hover svg + span {
        transform: translateX(0.6rem); } }
  a.link_arrow.small, a.link_arrow.speaker-position, .partner-boxes__wrapper a.link_arrow.platform-box__type, a.link_arrow.reg-aside__order-period, span.link_arrow.small, span.link_arrow.speaker-position, .partner-boxes__wrapper span.link_arrow.platform-box__type, span.link_arrow.reg-aside__order-period, button.link_arrow.small, button.link_arrow.speaker-position, .partner-boxes__wrapper button.link_arrow.platform-box__type, button.link_arrow.reg-aside__order-period {
    font-weight: 400;
    font-size: 1.4rem; }
    a.link_arrow.small span + svg, a.link_arrow.speaker-position span + svg, .partner-boxes__wrapper a.link_arrow.platform-box__type span + svg, a.link_arrow.reg-aside__order-period span + svg, span.link_arrow.small span + svg, span.link_arrow.speaker-position span + svg, .partner-boxes__wrapper span.link_arrow.platform-box__type span + svg, span.link_arrow.reg-aside__order-period span + svg, button.link_arrow.small span + svg, button.link_arrow.speaker-position span + svg, .partner-boxes__wrapper button.link_arrow.platform-box__type span + svg, button.link_arrow.reg-aside__order-period span + svg {
      margin-left: 1.1rem; }
    a.link_arrow.small svg, a.link_arrow.speaker-position svg, .partner-boxes__wrapper a.link_arrow.platform-box__type svg, a.link_arrow.reg-aside__order-period svg, span.link_arrow.small svg, span.link_arrow.speaker-position svg, .partner-boxes__wrapper span.link_arrow.platform-box__type svg, span.link_arrow.reg-aside__order-period svg, button.link_arrow.small svg, button.link_arrow.speaker-position svg, .partner-boxes__wrapper button.link_arrow.platform-box__type svg, button.link_arrow.reg-aside__order-period svg {
      font-size: 1.1rem; }
    @media (min-width: 992px) {
      a.link_arrow.small:hover span + svg, a.link_arrow.speaker-position:hover span + svg, .partner-boxes__wrapper a.link_arrow.platform-box__type:hover span + svg, a.link_arrow.reg-aside__order-period:hover span + svg, span.link_arrow.small:hover span + svg, span.link_arrow.speaker-position:hover span + svg, .partner-boxes__wrapper span.link_arrow.platform-box__type:hover span + svg, span.link_arrow.reg-aside__order-period:hover span + svg, button.link_arrow.small:hover span + svg, button.link_arrow.speaker-position:hover span + svg, .partner-boxes__wrapper button.link_arrow.platform-box__type:hover span + svg, button.link_arrow.reg-aside__order-period:hover span + svg {
        transform: translateX(0.3rem); }
      a.link_arrow.small:hover svg + span, a.link_arrow.speaker-position:hover svg + span, .partner-boxes__wrapper a.link_arrow.platform-box__type:hover svg + span, a.link_arrow.reg-aside__order-period:hover svg + span, span.link_arrow.small:hover svg + span, span.link_arrow.speaker-position:hover svg + span, .partner-boxes__wrapper span.link_arrow.platform-box__type:hover svg + span, span.link_arrow.reg-aside__order-period:hover svg + span, button.link_arrow.small:hover svg + span, button.link_arrow.speaker-position:hover svg + span, .partner-boxes__wrapper button.link_arrow.platform-box__type:hover svg + span, button.link_arrow.reg-aside__order-period:hover svg + span {
        transform: translateX(0.3rem); } }

a.link_arrow-back, span.link_arrow-back, button.link_arrow-back {
  font-weight: 900;
  font-size: 1.6rem;
  align-items: center;
  line-height: 1.6rem;
  display: inline-flex; }
  a.link_arrow-back svg, span.link_arrow-back svg, button.link_arrow-back svg {
    transition: all 0.3s ease;
    transform: translateX(0) rotate(180deg); }
    a.link_arrow-back svg + span, span.link_arrow-back svg + span, button.link_arrow-back svg + span {
      margin-left: .8rem; }
  @media (min-width: 992px) {
    a.link_arrow-back:hover, span.link_arrow-back:hover, button.link_arrow-back:hover {
      opacity: 1; }
      a.link_arrow-back:hover svg, span.link_arrow-back:hover svg, button.link_arrow-back:hover svg {
        transform: translateX(-0.6rem) rotate(180deg); } }

a.link_arrow-down, span.link_arrow-down, button.link_arrow-down {
  font-weight: 900;
  font-size: 1.6rem;
  align-items: center;
  line-height: 1.6rem;
  display: inline-flex; }
  a.link_arrow-down span + svg, span.link_arrow-down span + svg, button.link_arrow-down span + svg {
    margin-left: .8rem; }
  a.link_arrow-down svg, span.link_arrow-down svg, button.link_arrow-down svg {
    flex-shrink: 0;
    font-size: 1.8rem;
    margin-top: -.2rem;
    transform: rotateZ(90deg);
    transition: all 0.3s ease; }
    a.link_arrow-down svg + span, span.link_arrow-down svg + span, button.link_arrow-down svg + span {
      margin-left: .8rem; }
  @media (min-width: 992px) {
    a.link_arrow-down:hover, span.link_arrow-down:hover, button.link_arrow-down:hover {
      opacity: 1; } }

a.link_icon-descript, span.link_icon-descript, button.link_icon-descript {
  color: #000000;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.6rem;
  align-items: center;
  display: inline-flex;
  text-transform: uppercase; }
  a.link_icon-descript i, span.link_icon-descript i, button.link_icon-descript i {
    width: 3.4rem;
    height: 3.4rem;
    display: block;
    position: relative;
    margin-right: .8rem;
    transition: all 0.3s ease; }
    a.link_icon-descript i svg, span.link_icon-descript i svg, button.link_icon-descript i svg {
      top: 50%;
      left: 50%;
      fill: #000000;
      stroke: #000000;
      font-size: 3.4rem;
      position: absolute;
      transition: all 0.3s ease;
      transform: translate(-50%, -50%); }
  @media (min-width: 992px) {
    a.link_icon-descript:hover, span.link_icon-descript:hover, button.link_icon-descript:hover {
      opacity: 1; }
      a.link_icon-descript:hover i svg, span.link_icon-descript:hover i svg, button.link_icon-descript:hover i svg {
        font-size: 3.8rem; } }
  a.link_icon-descript.link_blue, span.link_icon-descript.link_blue, button.link_icon-descript.link_blue {
    color: #009BDF; }
    a.link_icon-descript.link_blue i svg, span.link_icon-descript.link_blue i svg, button.link_icon-descript.link_blue i svg {
      fill: #009BDF;
      stroke: #009BDF; }
  a.link_icon-descript-big, span.link_icon-descript-big, button.link_icon-descript-big {
    color: #ffffff; }
    a.link_icon-descript-big i, span.link_icon-descript-big i, button.link_icon-descript-big i {
      width: 4.8rem;
      height: 4.8rem;
      margin-right: 1.6rem; }
      a.link_icon-descript-big i svg, span.link_icon-descript-big i svg, button.link_icon-descript-big i svg {
        stroke: none;
        font-size: 4.8rem; }
    @media (min-width: 992px) {
      a.link_icon-descript-big:hover i svg, span.link_icon-descript-big:hover i svg, button.link_icon-descript-big:hover i svg {
        font-size: 5.2rem; } }

a.link_arrow-filed, span.link_arrow-filed, button.link_arrow-filed {
  font-weight: 900;
  font-size: 1.6rem;
  align-items: center;
  line-height: 1.6rem;
  display: inline-flex; }
  a.link_arrow-filed span, span.link_arrow-filed span, button.link_arrow-filed span {
    white-space: nowrap; }
    a.link_arrow-filed span + i, span.link_arrow-filed span + i, button.link_arrow-filed span + i {
      margin-left: 1.8rem; }
  a.link_arrow-filed i, span.link_arrow-filed i, button.link_arrow-filed i {
    width: 5.2rem;
    height: 5.2rem;
    flex-shrink: 0;
    position: relative;
    margin-left: 1.8rem; }
    a.link_arrow-filed i:after, span.link_arrow-filed i:after, button.link_arrow-filed i:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      background-color: #ffffff; }
    a.link_arrow-filed i svg, span.link_arrow-filed i svg, button.link_arrow-filed i svg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      fill: #009BD7;
      font-size: 1.8rem;
      position: absolute; }
    a.link_arrow-filed i + span, span.link_arrow-filed i + span, button.link_arrow-filed i + span {
      margin-left: 1.8rem; }
  @media (min-width: 992px) {
    a.link_arrow-filed:hover, span.link_arrow-filed:hover, button.link_arrow-filed:hover {
      opacity: 1; } }

a.link_add, span.link_add, button.link_add {
  color: #000000;
  font-weight: 900;
  font-size: 1.6rem;
  position: relative;
  line-height: 1.6rem;
  padding-right: 2.2rem;
  text-transform: uppercase; }
  a.link_add:after, span.link_add:after, button.link_add:after {
    top: .1rem;
    content: '';
    right: .7rem;
    width: .2rem;
    height: 1.6rem;
    display: block;
    position: absolute;
    background-color: #000000; }
  a.link_add:before, span.link_add:before, button.link_add:before {
    right: 0;
    top: .8rem;
    content: '';
    width: 1.6rem;
    height: .2rem;
    display: block;
    position: absolute;
    background-color: #000000; }

body {
  font-variant-ligatures: none; }
  body.scroll-disabled {
    max-height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100vw;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }

.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: top;
  display: inline-block; }

ul:not(.h-reset-list):not(.slick-dots):not(.conference-box__list):not(.filters__list):not(.dropdown-side__list):not(.select-options):not(.reg-price__aside-list):not(.price__block-list):not(.news__sublist):not(.news__sublist-list):not(.text-list__item) {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.45rem;
  font-size: 2rem;
  line-height: 2.8rem;
  /* margin-top: 2.2rem;
	margin-bottom: 2.8rem; */ }
  ul:not(.h-reset-list):not(.slick-dots):not(.conference-box__list):not(.filters__list):not(.dropdown-side__list):not(.select-options):not(.reg-price__aside-list):not(.price__block-list):not(.news__sublist):not(.news__sublist-list):not(.text-list__item) li {
    position: relative;
    padding-left: 4.2rem;
    margin-bottom: .6rem;
    padding-bottom: 2rem; }
    ul:not(.h-reset-list):not(.slick-dots):not(.conference-box__list):not(.filters__list):not(.dropdown-side__list):not(.select-options):not(.reg-price__aside-list):not(.price__block-list):not(.news__sublist):not(.news__sublist-list):not(.text-list__item) li:after {
      /* top: .6rem;
			left: 2rem;
			width: 1rem;
			content: '';
			height: 1rem;
			display: block;
			position: absolute;
			transform: rotateZ(-45deg);
			border-right: 1px solid #ee6db2;
			border-bottom: 1px solid #ee6db2; */
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #47D597;
      position: absolute;
      top: 9px;
      left: 0; }

ol:not(.h-reset-list):not(.slick-dots) {
  margin: 0;
  padding: 0;
  list-style: none;
  /* margin-top: 2.2rem;
	margin-bottom: 2.8rem; */ }
  ol:not(.h-reset-list):not(.slick-dots) li {
    position: relative;
    padding-left: 4.2rem;
    margin-bottom: .6rem;
    font-size: 2rem;
    line-height: 2.8rem;
    counter-increment: step-counter; }
    ol:not(.h-reset-list):not(.slick-dots) li:after {
      top: 0;
      left: 2rem;
      display: block;
      color: #ee6db2;
      position: absolute;
      content: counter(step-counter) "."; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 900; }

h1 {
  margin: 0;
  font-size: 6.4rem;
  line-height: 6.8rem;
  margin-bottom: 1.6rem; }

h2 {
  font-size: 4.8rem;
  margin-top: .9rem;
  line-height: 5.2rem;
  margin-bottom: .9rem; }

h3 {
  margin-top: 1rem;
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-bottom: 2rem; }

h4 {
  font-size: 2.4rem;
  margin-top: 1.1rem;
  line-height: 2.8rem;
  margin-bottom: 1.1rem; }

h5 {
  font-size: 2rem;
  margin-top: 1.2rem;
  line-height: 2.2rem;
  margin-bottom: 1.2rem; }

h6 {
  font-size: 1.6rem;
  margin-top: 1.3rem;
  line-height: 1.8rem;
  margin-bottom: 1.3rem; }

p {
  margin-bottom: 2.8rem;
  font-size: 2rem;
  line-height: 2.8rem; }
  p.small, p.speaker-position, .partner-boxes__wrapper p.platform-box__type, p.reg-aside__order-period {
    font-size: 1.6rem;
    line-height: 2.2rem; }
  p.big, p.partner-aside__text, p.reg-failed__text, .reg-content__main p.text-block-title, .reg-content__confirm-info p {
    font-size: 2.4rem;
    line-height: 3rem; }
  p.text-main {
    font-weight: 500; }

blockquote {
  position: relative; }
  blockquote:after {
    top: 0;
    left: 0;
    right: 0;
    content: '';
    height: .2rem;
    display: block;
    position: absolute;
    background-color: #000; }
  blockquote p {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem; }
  blockquote span {
    display: block;
    font-size: 1.6rem;
    line-height: 2.2rem; }

.slick-track {
  display: flex;
  align-items: stretch; }
  .slick-track .slick-slide {
    float: none;
    height: auto;
    outline: none !important; }

.text-hint {
  margin-left: 10px;
  cursor: pointer; }
  .text-hint .icon-info {
    font-size: 2.4rem; }

.popover {
  font-size: 1.2rem; }

@media (max-width: 1439px) {
  .about-ui__content {
    padding-top: 7rem; } }

@media (max-width: 767px) {
  .about-ui__content {
    padding-top: 0; } }

.about-ui__content .conf-visit__section, .about-ui__content .cta__section, .about-ui__content .cta-update__section {
  margin-top: 5rem; }
  @media (max-width: 767px) {
    .about-ui__content .conf-visit__section, .about-ui__content .cta__section, .about-ui__content .cta-update__section {
      margin-top: 3.5rem; } }

.section-about-info {
  padding: 6.7rem 0 4.4rem 0;
  background-color: #F5F5F5; }
  .section-about-info h3 {
    margin-right: 6rem;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .section-about-info h3 {
        margin-right: 2rem; } }
    @media (max-width: 767px) {
      .section-about-info h3 {
        margin-right: 0; } }

.about-info__top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5rem; }
  @media (max-width: 767px) {
    .about-info__top {
      flex-wrap: wrap; } }

.about-info__image {
  width: 15.4rem;
  height: 15.4rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; }
  @media (max-width: 991px) {
    .about-info__image {
      width: 13rem;
      height: 13rem; } }
  .about-info__image img {
    width: auto;
    height: auto;
    max-width: 100%;
    border-radius: 50%; }

.about-info__inner {
  display: flex;
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  flex-wrap: wrap; }
  @media (max-width: 991px) {
    .about-info__inner {
      margin-left: -0.8rem;
      margin-right: -0.8rem; } }
  @media (max-width: 767px) {
    .about-info__inner {
      margin-left: 0;
      margin-right: 0; } }

.about-info__block {
  background-color: #fff;
  padding: 4.7rem 2.5rem;
  min-height: 39.2rem; }
  @media (max-width: 991px) {
    .about-info__block {
      padding: 2rem;
      min-height: 30rem; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .about-info__block {
      min-height: auto;
      width: 100%;
      margin: 0 auto; } }
  .about-info__block-wrap {
    width: calc(100% / 3);
    padding: 0 1.5rem;
    margin-bottom: 3rem; }
    @media (max-width: 991px) {
      .about-info__block-wrap {
        padding: 0 0.8rem;
        margin-bottom: 1.6rem; } }
    @media (max-width: 767px) {
      .about-info__block-wrap {
        padding: 0;
        width: 100%; } }
  .about-info__block__title {
    color: #FF0000;
    font-size: 4.8rem;
    margin-bottom: 3.4rem;
    font-weight: 700; }
    @media (max-width: 991px) {
      .about-info__block__title {
        font-size: 3.7rem;
        margin-bottom: 1.5rem; } }
  .about-info__block p {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8;
    color: #000000; }
    @media (max-width: 991px) {
      .about-info__block p {
        font-size: 1.5rem;
        line-height: 1.6; } }

.section-about-different {
  padding: 6.7rem 0 6.7rem 0; }
  @media (max-width: 1439px) {
    .section-about-different {
      padding: 6.7rem 0 6.7rem 0; } }
  @media (max-width: 1199px) {
    .section-about-different {
      padding: 6.7rem 0 6.7rem 0; } }
  @media (max-width: 991px) {
    .section-about-different {
      padding: 6.7rem 0 6.7rem 0; } }
  .section-about-different .section-title__main {
    margin-bottom: 7rem; }
    @media (max-width: 991px) {
      .section-about-different .section-title__main {
        margin-bottom: 5rem; } }
    .section-about-different .section-title__main h2 {
      color: #98CB4F;
      text-transform: uppercase; }

.about-different__inner {
  display: flex;
  align-items: flex-start;
  margin-left: -2rem;
  margin-right: -2rem;
  flex-wrap: wrap; }
  @media (max-width: 991px) {
    .about-different__inner {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (max-width: 767px) {
    .about-different__inner {
      margin-right: 0;
      margin-left: 0; } }

.about-different__block {
  background-color: #fff;
  padding-right: 0.35rem; }
  @media (max-width: 767px) {
    .about-different__block {
      padding-right: 0;
      width: 100%;
      margin: 0 auto; } }
  .about-different__block-wrap {
    width: calc(100% / 3);
    padding: 0 2rem;
    /* margin-bottom: 3rem; */ }
    @media (max-width: 991px) {
      .about-different__block-wrap {
        padding: 0 1rem;
        /* margin-bottom: 1.6rem; */ } }
    @media (max-width: 767px) {
      .about-different__block-wrap {
        padding: 0;
        width: 100%; } }
  .about-different__block__icon {
    margin-bottom: 1rem;
    min-height: 9.7rem;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .about-different__block__icon {
        min-height: 8rem; } }
    @media (max-width: 767px) {
      .about-different__block__icon {
        min-height: auto;
        width: 100%;
        text-align: center; } }
    .about-different__block__icon .icon {
      font-size: 8.4rem;
      color: #000000; }
      @media (max-width: 991px) {
        .about-different__block__icon .icon {
          font-size: 7.1rem; } }
      .about-different__block__icon .icon-diamond {
        font-size: 9.7rem; }
        @media (max-width: 991px) {
          .about-different__block__icon .icon-diamond {
            font-size: 8rem; } }
  .about-different__block p {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8;
    color: #000000; }
    @media (max-width: 991px) {
      .about-different__block p {
        font-size: 1.5rem;
        line-height: 1.6; } }

.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: top;
  display: inline-block; }

.icon {
  width: 1em;
  height: 1em;
  stroke-width: 0;
  fill: currentColor;
  stroke: currentColor;
  display: inline-block; }
  .icon.icon-quote-rus {
    height: 0.7111111111111111em; }
  .icon.icon-quote-eng {
    height: 0.6274509803921569em; }
  .icon-logo {
    height: 0.3950617283950617em; }

.icon-search {
  width: 0.9649122807017544em; }

.icon-check {
  width: 1.3em; }

.icon-chat {
  width: 1.144927536231884em; }

.icon-person {
  width: 0.9156193895870736em; }

.icon-chevron-sm {
  width: 1.8em; }

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  box-shadow: none;
  display: inline-block;
  outline: none !important;
  background-color: transparent; }

.btn {
  width: auto;
  color: #ffffff;
  height: 4.8rem;
  overflow: hidden;
  font-weight: 900;
  border-radius: 0;
  font-size: 1.6rem;
  padding: 0 2.4rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all .3s ease;
  background-color: #FC6621; }
  .btn:hover {
    color: #fff;
    text-decoration: none; }
  .btn:focus {
    color: #fff;
    text-decoration: none; }
  .btn:disabled {
    opacity: .3;
    pointer-events: none; }
  .btn_blue {
    background-color: #009BD7; }
  .btn_red {
    background: #9E0B0F; }
  .btn_black {
    background-color: black; }
  .btn_green {
    background-color: #73D19C; }
  .btn_braun {
    background-color: #231F20; }
    .btn_braun .icon-arrow {
      fill: #FC6621; }
  .btn_green {
    background-color: #73D19C; }
  .btn_xl {
    min-width: 24.1rem; }
    @media (max-width: 767px) {
      .btn_xl {
        min-width: 100%; } }
  .btn_sm {
    min-width: 15.6rem; }
  .btn_fl {
    width: 100%; }
  .btn_h-xl {
    height: 5.6rem; }
  .btn_close {
    margin: 0;
    padding: 0;
    width: 2.4rem;
    height: 2.4rem;
    position: relative;
    background-color: transparent; }
    .btn_close:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      width: 2rem;
      margin: auto;
      height: .2rem;
      display: block;
      position: absolute;
      background-color: #D8D8D8;
      transform: rotateZ(45deg);
      transition: all 0.3s ease; }
    .btn_close:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      width: 2rem;
      margin: auto;
      height: .2rem;
      display: block;
      position: absolute;
      background-color: #D8D8D8;
      transform: rotateZ(-45deg);
      transition: all 0.3s ease; }
    @media (min-width: 992px) {
      .btn_close:hover {
        transform: scale(1);
        background-color: transparent; }
        .btn_close:hover:after {
          background-color: #000000; }
        .btn_close:hover:before {
          background-color: #000000; } }
  .btn_submit:after {
    width: 0;
    top: .3rem;
    left: .3rem;
    bottom: .3rem;
    display: flex;
    color: #FC6621;
    overflow: hidden;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    content: attr(data-success); }
  .btn_submit.btn_blue:after {
    color: #009BD7; }
  .btn_arrow {
    padding: 0;
    width: 3rem;
    height: 3rem;
    position: relative;
    background-color: transparent; }
    .btn_arrow svg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      fill: #FC6621;
      font-size: 1.8rem;
      position: absolute;
      transform: rotateZ(90deg); }
    @media (min-width: 992px) {
      .btn_arrow:hover {
        transform: scale(1);
        transform: translateY(0.6rem); } }

.modal.fade .modal-dialog {
  opacity: 0;
  transform: translate(50%, 0); }

.modal.show .modal-dialog {
  opacity: 1;
  transform: translate(0, 0); }

.modal-backdrop.show {
  opacity: .9; }

.modal-dialog {
  margin: 0 auto;
  max-width: 68.4rem; }
  @media (max-width: 767px) {
    .modal-dialog {
      margin: 0 auto;
      max-width: 100%; } }
  .modal-dialog_sm {
    max-width: 52.9rem; }
    @media (max-width: 767px) {
      .modal-dialog_sm {
        margin: 0 auto;
        max-width: 100%; } }

.modal-content {
  border: none;
  border-radius: 0;
  min-height: 100vh;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .modal-content {
      min-height: 100vh; } }

.modal-close {
  z-index: 1;
  top: 2.8rem;
  right: 2.8rem;
  position: absolute; }
  @media (max-width: 479px) {
    .modal-close {
      top: 1.6rem;
      right: 1.6rem; } }

.modal-header {
  padding: 0;
  border: none;
  border-radius: 0;
  padding-top: 2.2rem;
  padding-left: 8.5rem;
  padding-right: 8.5rem;
  flex-direction: column;
  padding-bottom: 3.7rem; }
  @media (max-width: 767px) {
    .modal-header {
      padding-top: .8rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-bottom: 4.4rem; } }
  @media (max-width: 767px) {
    .modal-header .modal-title h3 {
      font-size: 2.4rem;
      line-height: 2.8rem; } }

.modal-caption {
  font-size: 1.6rem;
  padding-right: 32%;
  margin-top: 1.4rem;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .modal-caption {
      padding-right: 0;
      margin-top: 2rem; } }

.modal-body {
  padding: 0;
  padding-left: 8.5rem;
  padding-right: 8.5rem; }
  @media (max-width: 767px) {
    .modal-body {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.modal-footer {
  padding: 0;
  padding-left: 8.5rem;
  padding-right: 8.5rem; }
  @media (max-width: 767px) {
    .modal-footer {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.modal-description-box__header {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 767px) {
    .modal-description-box__header {
      flex-direction: column; } }

.modal-description-box__view {
  width: 15.4rem;
  flex-shrink: 0;
  height: 15.4rem;
  margin-right: 1.6rem; }
  @media (max-width: 767px) {
    .modal-description-box__view {
      margin-left: auto;
      margin-right: auto; } }
  .modal-description-box__view picture {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    border-radius: 50%; }

.modal-description-box__detail {
  padding-top: 3.3rem; }
  @media (max-width: 767px) {
    .modal-description-box__detail {
      width: 100%;
      display: flex;
      padding-top: .5rem;
      flex-direction: column; } }

.modal-description-box__name {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-bottom: .5rem; }
  @media (max-width: 767px) {
    .modal-description-box__name {
      order: 1;
      margin-bottom: 0;
      font-size: 2.4rem;
      margin-top: 1.1rem;
      line-height: 2.8rem; } }

.modal-description-box__position {
  font-size: 1.6rem;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .modal-description-box__position {
      order: 2;
      margin-top: 1.2rem; } }

.modal-description-box__social {
  margin-top: 1.1rem; }
  @media (max-width: 767px) {
    .modal-description-box__social {
      order: 0; } }
  .modal-description-box__social ul {
    display: flex;
    align-items: center; }
    .modal-description-box__social ul li:not(:last-child) {
      margin-right: .8rem; }
    .modal-description-box__social ul li a {
      display: block; }
      .modal-description-box__social ul li a svg {
        font-size: 2.4rem; }
        @media (max-width: 767px) {
          .modal-description-box__social ul li a svg {
            font-size: 4rem; } }
      @media (min-width: 992px) {
        .modal-description-box__social ul li a:hover {
          transform: translateY(-0.4rem); } }
      .modal-description-box__social ul li a.blue svg {
        fill: #009BDF; }

.modal-description-box__content {
  margin-top: 3.9rem; }
  @media (max-width: 767px) {
    .modal-description-box__content {
      margin-top: 3.7rem; } }

.modal-action .modal-close, .modal-action-sm .modal-close {
  right: 1.5rem;
  top: 1.5rem; }
  .modal-action .modal-close .btn_close:before, .modal-action .modal-close .btn_close:after, .modal-action-sm .modal-close .btn_close:before, .modal-action-sm .modal-close .btn_close:after {
    background-color: rgba(255, 255, 255, 0.2); }
  .modal-action .modal-close.background--light .btn_close:before, .modal-action .modal-close.background--light .btn_close:after, .modal-action-sm .modal-close.background--light .btn_close:before, .modal-action-sm .modal-close.background--light .btn_close:after {
    background-color: rgba(0, 0, 0, 0.2); }

.modal-action .modal-body, .modal-action-sm .modal-body {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }

.modal-action .modal-content, .modal-action-sm .modal-content {
  min-height: auto;
  height: auto;
  padding-top: 0;
  padding-bottom: 0; }
  .modal-action .modal-content p, .modal-action-sm .modal-content p {
    line-height: 1.26;
    font-weight: 500;
    color: #000000; }
  .modal-action .modal-content__inner .btn, .modal-action-sm .modal-content__inner .btn {
    min-width: 280px;
    font-size: 20px;
    text-transform: uppercase; }
    @media (max-width: 1199px) {
      .modal-action .modal-content__inner .btn, .modal-action-sm .modal-content__inner .btn {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .modal-action .modal-content__inner .btn, .modal-action-sm .modal-content__inner .btn {
        width: 100%;
        max-width: 280px;
        font-size: 16px;
        min-width: auto; } }

.modal-action .modal-content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }
  @media (max-width: 1199px) {
    .modal-action .modal-content {
      max-width: 450px; } }
  @media (max-width: 767px) {
    .modal-action .modal-content {
      max-width: 400px; } }
  .modal-action .modal-content p {
    font-size: 20px; }
    @media (max-width: 1199px) {
      .modal-action .modal-content p {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .modal-action .modal-content p {
        font-size: 15px; } }
  .modal-action .modal-content__img {
    width: 100%;
    height: 370px; }
    @media (max-width: 1199px) {
      .modal-action .modal-content__img {
        height: 280px; } }
    @media (max-width: 767px) {
      .modal-action .modal-content__img {
        height: 140px; } }
  .modal-action .modal-content__inner {
    padding: 40px 75px 50px 75px;
    background-color: #fff; }
    @media (max-width: 1199px) {
      .modal-action .modal-content__inner {
        padding: 30px 55px 40px 55px; } }
    @media (max-width: 767px) {
      .modal-action .modal-content__inner {
        padding: 20px; } }

.modal-action-sm .modal-dialog {
  max-width: 100%; }

.modal-action-sm .modal-content {
  position: absolute;
  right: 106px;
  bottom: 68px;
  width: 400px; }
  @media (max-width: 1199px) {
    .modal-action-sm .modal-content {
      right: 70px;
      bottom: 30px;
      width: 350px; } }
  @media (max-width: 767px) {
    .modal-action-sm .modal-content {
      position: relative;
      right: auto;
      bottom: auto;
      margin: 0 auto;
      width: 100%;
      max-width: 350px; } }
  .modal-action-sm .modal-content__img {
    width: 100%;
    height: 140px; }
  .modal-action-sm .modal-content__inner {
    padding: 24px 30px 30px 30px;
    background-color: #fff; }
    @media (max-width: 1199px) {
      .modal-action-sm .modal-content__inner {
        padding: 20px; } }
    .modal-action-sm .modal-content__inner p {
      font-size: 16px;
      margin-bottom: 15px; }
      @media (max-width: 1199px) {
        .modal-action-sm .modal-content__inner p {
          font-size: 15px; } }

.footer {
  padding-top: 6.6rem;
  padding-left: 22.8rem;
  padding-bottom: 11.5rem;
  background-color: #009BD7; }
  @media (max-width: 1439px) {
    .footer {
      padding-left: 0; } }
  @media (max-width: 767px) {
    .footer {
      padding-top: 3.7rem;
      padding-bottom: 6.6rem; } }
  .footer_conference {
    padding-top: 4.8rem;
    padding-bottom: 7.6rem;
    background-color: #F5F5F5; }
    @media (max-width: 767px) {
      .footer_conference {
        padding-top: 8.8rem;
        padding-bottom: 7.8rem; } }
    .footer_conference .footer__wrapper {
      flex-direction: column; }

.footer__wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .footer__wrapper {
      flex-direction: column; } }

.footer__subscribe {
  max-width: 30rem; }
  @media (max-width: 767px) {
    .footer__subscribe {
      max-width: 100%; } }

.footer__subscribe-title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  text-transform: uppercase; }

.footer__subscribe-action {
  margin-top: 3rem; }
  @media (max-width: 767px) {
    .footer__subscribe-action {
      margin-top: 1.9rem; } }

.footer__nav {
  font-size: 1.6rem;
  min-width: 32.7rem;
  padding-top: .8rem;
  line-height: 2.2rem; }
  @media (max-width: 991px) {
    .footer__nav {
      min-width: auto; } }
  @media (max-width: 767px) {
    .footer__nav {
      padding: 0;
      margin-top: 5.3rem; } }
  .footer__nav ul li:not(:last-child) {
    margin-bottom: .9rem; }
    @media (max-width: 767px) {
      .footer__nav ul li:not(:last-child) {
        margin-bottom: .5rem; } }
  .footer__nav ul li.copywriting {
    display: none; }
    @media (max-width: 767px) {
      .footer__nav ul li.copywriting {
        display: block;
        margin-top: 3.8rem;
        margin-bottom: 1.1rem; } }

.footer__social {
  display: none;
  margin-top: 7rem; }
  @media (max-width: 767px) {
    .footer__social {
      display: block; } }
  .footer__social ul {
    display: flex;
    align-items: center; }
    .footer__social ul li:not(:last-child) {
      margin-right: 1.3rem; }
    .footer__social ul li a {
      display: block; }
      .footer__social ul li a svg {
        fill: #ffffff;
        font-size: 4rem; }

.footer__wrapper-about-line {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .footer__wrapper-about-line {
      flex-direction: column; } }

.footer__reporter {
  width: 34%;
  font-size: 1.6rem;
  position: relative;
  line-height: 2.2rem;
  padding-right: 2.4rem; }
  @media (max-width: 991px) {
    .footer__reporter {
      width: 100%;
      padding-right: 0;
      min-height: 12rem;
      padding-top: 1rem;
      padding-left: 14.4rem; } }
  @media (max-width: 767px) {
    .footer__reporter {
      padding-top: 2rem;
      padding-left: 11.7rem; } }

.footer__reporter-pic {
  display: block;
  width: 15.4rem;
  height: 15.4rem;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1.2rem; }
  @media (max-width: 991px) {
    .footer__reporter-pic {
      top: 0;
      left: 0;
      margin: 0;
      width: 12rem;
      height: 12rem;
      position: absolute; } }
  @media (max-width: 767px) {
    .footer__reporter-pic {
      width: 9.3rem;
      height: 9.3rem; } }
  .footer__reporter-pic picture {
    width: 100%;
    height: 100%;
    display: block; }

.footer__reporter-name {
  line-height: 1.8rem;
  text-transform: uppercase; }

.footer__reporter-quote {
  margin-top: .4rem;
  padding-right: 38%; }
  @media (max-width: 767px) {
    .footer__reporter-quote {
      padding-right: 15%; } }

.footer__reporter-contacts {
  padding-top: .3rem;
  padding-left: .3rem; }
  @media (max-width: 767px) {
    .footer__reporter-contacts {
      padding-left: 0; } }

.footer__conference-additional {
  width: 66%;
  padding-top: 2.7rem; }
  @media (max-width: 991px) {
    .footer__conference-additional {
      width: 100%;
      padding-top: 0;
      margin-top: 4.6rem; } }
  @media (max-width: 767px) {
    .footer__conference-additional {
      margin-top: 6.6rem; } }

.footer__conference-options {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 767px) {
    .footer__conference-options {
      flex-direction: column; } }

.footer__conference-option {
  width: 50%;
  font-size: 1.6rem;
  position: relative;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .footer__conference-option {
      width: 100%;
      min-height: 7.2rem;
      padding-top: 0.8rem;
      padding-left: 11.7rem; }
      .footer__conference-option:not(:last-child) {
        margin-bottom: 5.5rem; } }

.footer__conference-option-icon {
  margin-bottom: 1.4rem; }
  @media (max-width: 767px) {
    .footer__conference-option-icon {
      top: 0;
      left: 0;
      margin-bottom: 0;
      position: absolute; } }
  .footer__conference-option-icon svg {
    font-size: 7.2rem; }

.footer__conference-option-action {
  margin-top: .5rem; }

.footer__conference-social {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 5.5rem; }

.footer__conference-social-title {
  margin-bottom: .6rem; }

.footer__conference-social-list ul {
  display: flex;
  align-items: center; }
  .footer__conference-social-list ul li:not(:last-child) {
    margin-right: .8rem; }
  .footer__conference-social-list ul li a {
    display: block;
    position: relative; }
    .footer__conference-social-list ul li a svg {
      font-size: 2.8rem; }
    @media (min-width: 992px) {
      .footer__conference-social-list ul li a:hover {
        transform: translateY(-0.4rem); } }

.footer__wrapper-nav-line {
  display: flex;
  font-size: 1.6rem;
  margin-top: 4.9rem;
  line-height: 2.2rem;
  align-items: flex-end; }
  @media (max-width: 767px) {
    .footer__wrapper-nav-line {
      margin-top: 8.7rem;
      flex-direction: column; } }

.footer__conference-nav {
  width: 34%; }
  @media (max-width: 767px) {
    .footer__conference-nav {
      width: 100%; } }
  .footer__conference-nav ul li:not(:last-child) {
    margin-bottom: .5rem; }

.footer__conference-manufacturer {
  width: 66%;
  color: #000000; }
  .footer__conference-manufacturer a {
    color: #000000;
    text-decoration: none; }
  @media (max-width: 767px) {
    .footer__conference-manufacturer {
      width: 100%;
      margin-top: 2.5rem; } }

@media (max-width: 1439px) {
  .nav-box {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15); } }

.nav-box:after {
  top: 0;
  right: 0;
  bottom: 0;
  content: '';
  width: .1rem;
  display: block;
  position: absolute;
  background-color: #E2E2E2; }
  @media (max-width: 1439px) {
    .nav-box:after {
      right: -.1rem; } }
  @media (max-width: 767px) {
    .nav-box:after {
      display: none; } }

@media (min-width: 1440px) {
  .nav-box.nav-box-simple .nav-box__content {
    padding-top: 0; } }

@media (min-width: 1440px) {
  .nav-box.nav-box-simple .nav-box__header {
    padding-left: 0;
    padding-right: 0; } }

.nav-box.nav-box-simple .nav-box__detail {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-top: 2.4rem;
  padding-bottom: 3.2rem; }
  @media (max-width: 1439px) {
    .nav-box.nav-box-simple .nav-box__detail {
      display: none; } }

@media (min-width: 1440px) {
  .nav-box.nav-box-simple .nav-box__brand {
    display: none; } }

@media (min-width: 1440px) {
  .nav-box.nav-box-simple .nav-box__body {
    padding-top: 4rem; } }

.nav-submenu-active .nav-box {
  box-shadow: none; }

@media (max-width: 1439px) {
  .nav-box_conference {
    width: auto; } }

@media (max-width: 767px) {
  .nav-box_conference {
    width: 100%; }
    .nav-box_conference:after {
      top: 5.5rem; } }

.nav-box_conference .nav-box__toggle {
  margin-right: 2.9rem; }
  @media (max-width: 767px) {
    .nav-box_conference .nav-box__toggle {
      order: 0; } }
  .nav-box_conference .nav-box__toggle button {
    color: #FC6621; }
  .nav-box_conference .nav-box__toggle .nav-box__toggle-view i {
    background-color: #FC6621; }

.nav-box_conference .nav-box__header {
  padding-left: 0;
  padding-right: 0; }
  @media (max-width: 1439px) {
    .nav-box_conference .nav-box__header {
      padding-left: 3.2rem;
      padding-right: 3.2rem; } }
  @media (max-width: 767px) {
    .nav-box_conference .nav-box__header {
      padding-right: 0;
      padding-left: 2.4rem; } }

.nav-box_conference .nav-box__body {
  padding-top: 3.2rem; }
  @media (max-width: 1439px) {
    .nav-box_conference .nav-box__body {
      padding-top: 6.4rem; } }

.nav-box_conference .nav-box__content {
  padding-top: 0; }
  @media (max-width: 1439px) {
    .nav-box_conference .nav-box__content {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem; } }
  @media (max-width: 767px) {
    .nav-box_conference .nav-box__content {
      padding-top: 0;
      padding-bottom: 0; } }

.nav-box_conference .nav-box__global nav ul li:not(:last-child) {
  margin-bottom: 2rem; }

.nav-box_conference .nav-box__global nav ul li a.lang.active {
  color: #FC6621; }

@media (max-width: 767px) {
  .nav-box__conference {
    display: none; } }

.nav-box__conference .conference-nav-box {
  padding-top: 3.7rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 4.1rem;
  background-color: #BABABA; }
  @media (max-width: 1439px) {
    .nav-box__conference .conference-nav-box {
      padding: 0;
      background-color: transparent; } }
  .nav-box__conference .conference-nav-box.red {
    background-color: #EE2737; }
    @media (max-width: 1439px) {
      .nav-box__conference .conference-nav-box.red {
        background-color: transparent; } }

.nav-box__conference .conference-nav-box__view {
  display: none;
  margin-right: .8rem; }
  @media (max-width: 1439px) {
    .nav-box__conference .conference-nav-box__view {
      display: block; } }

.nav-box__conference .conference-nav-box__title {
  margin-bottom: .7rem; }
  @media (max-width: 1439px) {
    .nav-box__conference .conference-nav-box__title {
      display: none; } }
  .nav-box__conference .conference-nav-box__title i {
    font-size: 2rem;
    line-height: 2.2rem; }
  .nav-box__conference .conference-nav-box__title b {
    margin-left: .7rem; }

.nav-box__conference .conference-nav-box__name {
  font-size: 2rem;
  line-height: 2.2rem; }
  @media (max-width: 1439px) {
    .nav-box__conference .conference-nav-box__name {
      display: flex;
      height: 3.6rem;
      max-width: 21rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      align-items: center; } }

.nav-box__conference .conference-nav-box__time {
  margin-top: .5rem; }
  @media (max-width: 1439px) {
    .nav-box__conference .conference-nav-box__time {
      display: none; } }

.nav-box__registration {
  display: none; }
  @media (max-width: 767px) {
    .nav-box__registration {
      display: block; } }

.nav-box__content {
  height: 100%;
  display: flex;
  padding-top: 3.5rem;
  padding-bottom: 3.2rem;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1439px) {
    .nav-box__content {
      padding-top: 1.8rem;
      padding-bottom: 1.8rem; } }
  @media (max-width: 767px) {
    .nav-box__content {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem; } }
  .nav-box__content.active {
    overflow: hidden;
    overflow-y: auto; }
    .nav-box__content.active .nav-box__footer {
      margin-bottom: 10px; }
    .nav-box__content.active .nav-box__news {
      margin-left: 10px; }
      @media (max-width: 767px) {
        .nav-box__content.active .nav-box__news {
          margin-left: 0; } }

.nav-box__header {
  flex-shrink: 0;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  @media (max-width: 1439px) {
    .nav-box__header {
      display: flex;
      align-items: center;
      padding-left: 3.2rem;
      padding-right: 3.2rem; } }
  @media (max-width: 767px) {
    .nav-box__header {
      padding-left: 2rem;
      padding-right: 2rem;
      justify-content: space-between; }
      .nav-box__header:after {
        content: '';
        display: block;
        left: 0;
        right: 0;
        width: auto;
        height: .1rem;
        bottom: 0;
        width: 100%;
        z-index: -1;
        position: absolute;
        background-color: #E2E2E2; } }

.nav-box__toggle {
  display: none;
  margin-right: 1rem; }
  @media (max-width: 1439px) {
    .nav-box__toggle {
      display: block; } }
  @media (max-width: 767px) {
    .nav-box__toggle {
      order: 1; } }
  .nav-box__toggle button {
    display: flex;
    color: #009BD7;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.4rem;
    align-items: stretch; }
  .nav-box__toggle .nav-box__toggle-view {
    width: 1.8rem;
    height: 1.4rem;
    position: relative;
    background-color: transparent; }
    .nav-box__toggle .nav-box__toggle-view i {
      left: 0;
      width: 1.8rem;
      height: .2rem;
      display: block;
      position: absolute;
      background-color: #009BD7; }
      .nav-box__toggle .nav-box__toggle-view i:nth-child(1) {
        top: 0rem; }
      .nav-box__toggle .nav-box__toggle-view i:nth-child(2) {
        top: .6rem; }
      .nav-box__toggle .nav-box__toggle-view i:nth-child(3) {
        top: 1.2rem; }
  .nav-box__toggle .nav-box__toggle-caption {
    width: 8.6rem;
    overflow: hidden;
    position: relative;
    margin-left: .8rem; }
    .nav-box__toggle .nav-box__toggle-caption span {
      left: 0;
      position: absolute;
      line-height: 1.4rem;
      text-transform: uppercase; }
      .nav-box__toggle .nav-box__toggle-caption span.open {
        top: 0; }
      .nav-box__toggle .nav-box__toggle-caption span.close {
        top: 100%;
        z-index: 1; }

.nav-box__body {
  height: 100%;
  display: flex;
  padding-top: 8.9rem;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1439px) {
    .nav-box__body {
      display: none; }
      .nav-mobile-active .nav-box__body {
        display: flex; }
      .nav-mobile-deactive .nav-box__body {
        display: flex; } }
  @media (max-width: 767px) {
    .nav-box__body {
      padding-top: 7.2rem;
      padding-bottom: 2rem; } }

.nav-box__main {
  height: 100%;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  @media (max-width: 767px) {
    .nav-box__main {
      padding-left: 5.4rem;
      padding-right: 5.4rem;
      max-height: 34rem;
      overflow: auto;
      overflow-x: hidden; } }
  @media (max-width: 360px) {
    .nav-box__main {
      padding-left: 3.2rem;
      padding-right: 3.2rem; } }
  .nav-box__main-news {
    display: flex;
    height: auto; }
    .nav-box__main-news .nav-box__global {
      transform: translateX(-230px);
      opacity: 0;
      visibility: hidden; }
      @media (max-width: 1439px) {
        .nav-box__main-news .nav-box__global {
          transform: translateX(-280px); } }
      @media (max-width: 767px) {
        .nav-box__main-news .nav-box__global {
          transform: translateX(-450px); } }
    .nav-box__main-news .nav-box__news {
      max-width: 245px;
      opacity: 1;
      visibility: visible;
      transform: translateX(-180px);
      /* max-width: 100%; */ }
      @media (max-width: 1439px) {
        .nav-box__main-news .nav-box__news {
          transform: translateX(-245px); } }
      @media (max-width: 767px) {
        .nav-box__main-news .nav-box__news {
          transform: translateX(-100%); } }
      .nav-box__main-news .nav-box__news .icon-arrow {
        transform: rotateZ(180deg); }
      .nav-box__main-news .nav-box__news .news__sublist, .nav-box__main-news .nav-box__news .news__sublist-list {
        list-style: none; }
    .nav-box__main-news.active .nav-box__global {
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
    .nav-box__main-news.active .nav-box__news {
      transform: translateX(50px);
      opacity: 0;
      visibility: hidden; }

.nav-box__global {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .4s ease-in-out; }

.nav-box__news {
  flex-shrink: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .4s ease-in-out;
  transform: translateX(50px); }
  .nav-box__news .nav-list-news {
    height: 470px;
    overflow: hidden;
    transition: all .6s ease-in-out; }
    @media (max-width: 1439px) {
      .nav-box__news .nav-list-news {
        height: 460px; } }
    @media (max-width: 767px) {
      .nav-box__news .nav-list-news {
        height: 420px; } }
    .nav-box__news .nav-list-news.active {
      height: auto;
      overflow: visible; }
  .nav-box__news .news__sublist {
    padding: 18px 0 0 14px; }
    .nav-box__news .news__sublist-title > span {
      font-size: 1.6rem;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 10px; }
    .nav-box__news .news__sublist-list {
      padding: 0 0 0 14px;
      max-height: 150px;
      overflow: auto; }
      .nav-box__news .news__sublist-list li {
        margin-bottom: 8px !important; }
        .nav-box__news .news__sublist-list li a {
          font-size: 1.4rem; }
        .nav-box__news .news__sublist-list li.active a {
          color: #009BDF; }
  .nav-box__news .show-more-btn {
    display: flex;
    align-items: center;
    margin-left: 28px;
    color: #828282;
    font-size: 14px;
    margin-top: 10px; }
    .nav-box__news .show-more-btn .icon-chevron-sm {
      font-size: 5px;
      fill: #828282;
      margin-left: 5px;
      margin-top: 2px;
      transform: rotate(0deg);
      transition: transform .3s ease; }
    .nav-box__news .show-more-btn.active .icon-chevron-sm {
      transform: rotate(-180deg); }
    .nav-box__news .show-more-btn.active .show-more-text {
      display: none; }
    .nav-box__news .show-more-btn.active .show-less-text {
      display: block; }
  .nav-box__news .show-less-text {
    display: none; }

.nav-box__footer {
  display: flex;
  flex-shrink: 0;
  /* margin-top: 6.4rem; */
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  flex-direction: column;
  transition: all 0.3s ease; }
  @media (min-width: 992px) {
    .nav-box__footer:hover {
      opacity: 1 !important; } }
  @media (max-width: 767px) {
    .nav-box__footer {
      margin-top: 5.4rem;
      padding-left: 5.4rem;
      padding-right: 5.4rem; } }
  @media (max-width: 360px) {
    .nav-box__footer {
      padding-left: 3.2rem;
      padding-right: 3.2rem; } }
  .nav-box__footer.footer-green .nav-box__conference-social-title {
    color: #72D19C; }
  .nav-box__footer.footer-green .nav-box__conference-social-list ul li a svg {
    fill: #009BD7; }

@media (max-width: 767px) {
  .nav-box__brand {
    order: 0; } }

.nav-box__brand a {
  padding-left: .4rem;
  padding-right: .4rem;
  display: inline-block;
  color: #009BDF; }
  .nav-box__brand a svg {
    font-size: 15rem; }
    @media (max-width: 1439px) {
      .nav-box__brand a svg {
        font-size: 12.2rem; } }

.nav-box__conference-social-title {
  color: #FC6621;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 1.2rem;
  text-transform: uppercase; }

.nav-box__conference-social-list ul {
  display: flex;
  align-items: center; }
  .nav-box__conference-social-list ul li:not(:last-child) {
    margin-right: .8rem; }
  .nav-box__conference-social-list ul li a {
    display: block; }
    .nav-box__conference-social-list ul li a svg {
      fill: #FC6621;
      font-size: 2.4rem; }
    @media (min-width: 992px) {
      .nav-box__conference-social-list ul li a:hover {
        transform: translateY(-0.4rem); } }

.nav-box__conference-copywriting {
  margin-top: 2.4rem; }

.nav-box__global nav ul li, .nav-box__news nav ul li {
  color: #231F20;
  font-size: 1.6rem;
  line-height: 1.6rem;
  transition: all 0.3s ease; }
  @media (max-width: 767px) {
    .nav-box__global nav ul li, .nav-box__news nav ul li {
      font-size: 2.4rem;
      line-height: 2.4rem; } }
  .nav-box__global nav ul li:not(:last-child), .nav-box__news nav ul li:not(:last-child) {
    margin-bottom: 2.2rem; }
    @media (max-width: 767px) {
      .nav-box__global nav ul li:not(:last-child), .nav-box__news nav ul li:not(:last-child) {
        margin-bottom: 2.3rem; } }
  .nav-box__global nav ul li a, .nav-box__news nav ul li a {
    display: flex;
    color: #231F20;
    align-items: center;
    text-decoration: none;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .nav-box__global nav ul li a, .nav-box__news nav ul li a {
        justify-content: flex-start; } }
    .nav-box__global nav ul li a svg, .nav-box__news nav ul li a svg {
      display: block;
      font-size: 1.6rem;
      transition: all 0.375s ease; }
      @media (max-width: 767px) {
        .nav-box__global nav ul li a svg, .nav-box__news nav ul li a svg {
          margin-top: .5rem;
          margin-left: 2.6rem; } }
    .nav-box__global nav ul li a.lang, .nav-box__news nav ul li a.lang {
      opacity: .3;
      font-weight: 500; }
      .nav-box__global nav ul li a.lang.active, .nav-box__news nav ul li a.lang.active {
        opacity: 1;
        color: #009BDF; }
    .nav-box__global nav ul li a:hover span, .nav-box__news nav ul li a:hover span {
      color: #231F20;
      opacity: .3; }
  @media (min-width: 992px) {
    .nav-box__global nav ul li:hover, .nav-box__news nav ul li:hover {
      opacity: 1 !important; } }
  .nav-submenu-active .nav-box__global nav ul li.nav-list a .icon-arrow, .nav-submenu-active .nav-box__news nav ul li.nav-list a .icon-arrow {
    transform: rotateZ(180deg); }

.nav-box__link-line {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .nav-box__link-line {
      justify-content: flex-start; } }
  .nav-box__link-line.link-line-green a.lang.active {
    color: #72D19C !important; }
  .nav-box__link-line .nav-box__link-group {
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .nav-box__link-line .nav-box__link-group:first-child {
        margin-right: 4.5rem; } }
    .nav-box__link-line .nav-box__link-group a:not(:last-child) {
      margin-right: 1.1rem; }
      @media (max-width: 767px) {
        .nav-box__link-line .nav-box__link-group a:not(:last-child) {
          margin-right: 2rem; } }

.nav-list-box__content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 110.8rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  @media (max-width: 767px) {
    .nav-list-box__content {
      padding-left: 3rem;
      padding-right: 3rem; } }

.nav-list-box__content-action {
  display: none;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .nav-list-box__content-action {
      display: block; } }

.nav-list-box__content-title {
  display: none;
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.4rem;
  padding-left: 2.5rem;
  margin-bottom: 5.3rem; }
  @media (max-width: 767px) {
    .nav-list-box__content-title {
      display: block; } }
  @media (max-width: 360px) {
    .nav-list-box__content-title {
      padding-left: 0; } }

.nav-list-box__content-items {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }

.nav-list-box__content-item {
  width: 33.33333%;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  @media (max-width: 1280px) {
    .nav-list-box__content-item {
      width: 50%; } }
  @media (max-width: 991px) {
    .nav-list-box__content-item {
      width: 100%; } }
  @media (max-width: 767px) {
    .nav-list-box__content-item {
      padding-left: 2.6rem;
      padding-right: 2.6rem; } }
  @media (max-width: 360px) {
    .nav-list-box__content-item {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1281px) {
    .nav-list-box__content-item:nth-child(n+4) {
      margin-top: 8.4rem; } }
  @media (min-width: 992px) and (max-width: 1280px) {
    .nav-list-box__content-item:nth-child(n+3) {
      margin-top: 8.4rem; } }
  @media (max-width: 991px) {
    .nav-list-box__content-item:nth-child(n+2) {
      margin-top: 6.4rem; } }

.conference-nav-box {
  display: flex;
  align-items: flex-start; }

.conference-nav-box__view {
  width: 3.1rem;
  height: 3.1rem;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  margin-right: 2.4rem; }
  .conference-nav-box__view i {
    display: block;
    position: absolute; }
    .conference-nav-box__view i.main {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0; }
    .conference-nav-box__view i.minor {
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 1.1rem; }

.conference-nav-box__title {
  display: flex;
  align-items: center;
  margin-bottom: .4rem; }
  .conference-nav-box__title i {
    font-weight: 900;
    font-size: 2.4rem;
    font-style: normal;
    line-height: 2.8rem; }
  .conference-nav-box__title b {
    font-size: 1rem;
    margin-left: .5rem;
    line-height: 1.1rem;
    text-transform: uppercase; }

.conference-nav-box__name {
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.8rem; }

.conference-nav-box__time {
  font-weight: 500;
  font-size: 1.6rem;
  margin-top: .9rem;
  line-height: 2.2rem; }

.nav-box__copywriting {
  color: #787878;
  font-size: 1.4rem;
  line-height: 1.6rem; }

.nav-box__social {
  margin-top: 1.6rem; }
  .nav-box__social ul {
    display: flex;
    align-items: center; }
    .nav-box__social ul li:not(:last-child) {
      margin-right: .8rem; }
      @media (max-width: 767px) {
        .nav-box__social ul li:not(:last-child) {
          margin-right: 1.5rem; } }
    .nav-box__social ul li a {
      display: block; }
      .nav-box__social ul li a svg {
        fill: #009BDF;
        font-size: 2.4rem; }
        @media (max-width: 767px) {
          .nav-box__social ul li a svg {
            font-size: 4rem; } }
      @media (min-width: 992px) {
        .nav-box__social ul li a:hover {
          transform: translateY(-0.4rem); } }

.cookies-box {
  z-index: 3;
  right: 3.2rem;
  width: 24.6rem;
  bottom: 3.2rem;
  position: fixed;
  padding-top: 2.9rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-bottom: 3.2rem;
  background-color: #ffffff;
  transition: all 0.5s ease;
  box-shadow: 0 2rem 3rem 0 rgba(15, 82, 109, 0.24); }
  @media (max-width: 767px) {
    .cookies-box {
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding-top: 2.8rem;
      padding-left: 2.4rem;
      padding-right: 11rem;
      padding-bottom: 3rem; } }
  @media (min-width: 768px) {
    .cookies-box.deactivate {
      opacity: 0;
      transform: translateX(150%); } }
  @media (max-width: 767px) {
    .cookies-box.deactivate {
      opacity: 0;
      transform: translateY(120%); } }

.cookies-box__title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: 1.1rem; }

.cookies-box__description {
  font-size: 1.4rem;
  line-height: 1.9rem; }

.cookies-box__action {
  font-size: 1.6rem;
  margin-top: 1.9rem; }
  @media (max-width: 767px) {
    .cookies-box__action {
      top: 1.3rem;
      right: 2.4rem;
      position: absolute; } }

.news-box.small .news-box__details, .news-box.speaker-position .news-box__details, .partner-boxes__wrapper .news-box.platform-box__type .news-box__details, .news-box.reg-aside__order-period .news-box__details {
  padding-top: 1.7rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-bottom: 2.7rem; }

.news-box.small .news-box__view, .news-box.speaker-position .news-box__view, .partner-boxes__wrapper .news-box.platform-box__type .news-box__view, .news-box.reg-aside__order-period .news-box__view {
  height: 14.4rem; }

.news-box.small .news-box__detail, .news-box.speaker-position .news-box__detail, .partner-boxes__wrapper .news-box.platform-box__type .news-box__detail, .news-box.reg-aside__order-period .news-box__detail {
  margin-top: 1.1rem;
  padding-right: 1.5rem; }

.news-box.small .news-box__action, .news-box.speaker-position .news-box__action, .partner-boxes__wrapper .news-box.platform-box__type .news-box__action, .news-box.reg-aside__order-period .news-box__action {
  margin-top: 1.7rem; }

.news-box__content {
  height: 100%;
  background-color: #F5F5F5; }

.news-box__view {
  height: 19.4rem;
  position: relative; }
  .news-box__view a {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative; }
    .news-box__view a:after {
      z-index: 0;
      content: '';
      top: -.5rem;
      opacity: 0.3;
      left: -.5rem;
      right: -.5rem;
      display: block;
      bottom: -.5rem;
      position: absolute;
      background-color: #000000;
      transition: all 0.3s ease; }
    .news-box__view a picture {
      width: 100%;
      height: 100%;
      display: block; }
    .news-box__view a svg {
      top: 50%;
      left: 50%;
      z-index: 1;
      fill: #ffffff;
      stroke: #ffffff;
      font-size: 3.4rem;
      position: absolute;
      margin-top: -1.7rem;
      margin-left: -1.7rem;
      transition: all 0.3s ease; }
    @media (min-width: 992px) {
      .news-box__view a:hover:after {
        opacity: 0; }
      .news-box__view a:hover svg {
        opacity: 0; } }

.news-box__label {
  display: flex;
  height: 4.8rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.5rem;
  align-items: center;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  text-transform: uppercase;
  justify-content: flex-start; }
  .news-box__label.red {
    background-color: #FC6621; }
  .news-box__label.yellow {
    background-color: #F2A900; }
  .news-box__label.green {
    background-color: #48D597; }

.news-box__details {
  display: flex;
  padding-top: 2.4rem;
  padding-left: 2.4rem;
  padding-bottom: 2rem;
  padding-right: 2.4rem;
  flex-direction: column; }

.news-box__time {
  color: #929292;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.6rem;
  white-space: nowrap;
  line-height: 1.6rem;
  margin-bottom: 1.5rem;
  text-overflow: ellipsis; }

.news-box__name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem; }

.news-box__detail {
  font-size: 1.6rem;
  margin-top: .5rem;
  line-height: 2.2rem; }

.news-box__action {
  margin-top: 1.5rem; }

.news-box__tags {
  margin-top: 3.4rem; }

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.4rem; }
  .tags .tag {
    margin-left: .4rem;
    margin-bottom: .4rem; }

.news-page__wrapper {
  position: relative;
  z-index: 1;
  padding: 6rem 0; }
  @media (max-width: 1439px) {
    .news-page__wrapper {
      padding: 8rem 0 6rem 0; } }
  @media (max-width: 767px) {
    .news-page__wrapper {
      padding: 1.4rem 0 6.5rem 0; } }

.news-page__title {
  text-transform: uppercase;
  font-size: 5.6rem;
  line-height: 6.4rem;
  margin-bottom: 0; }
  @media (max-width: 1199px) {
    .news-page__title {
      font-size: 5rem;
      line-height: 5.8rem; } }
  @media (max-width: 991px) {
    .news-page__title {
      font-size: 4.8rem;
      line-height: 5.2rem; } }
  .news-page__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 2.1rem; }
    @media (max-width: 1199px) {
      .news-page__title-wrap {
        margin-bottom: 2rem; } }
    @media (max-width: 991px) {
      .news-page__title-wrap {
        margin-bottom: 1rem; } }
    .news-page__title-wrap .link {
      text-transform: uppercase;
      margin-left: 3.2rem; }
      @media (max-width: 767px) {
        .news-page__title-wrap .link {
          display: none; } }

.news-page__subtitle {
  margin-bottom: 3.8rem; }
  @media (max-width: 1199px) {
    .news-page__subtitle {
      font-size: 2.2rem; } }
  @media (max-width: 991px) {
    .news-page__subtitle {
      font-size: 2rem; } }
  @media (max-width: 767px) {
    .news-page__subtitle {
      margin-bottom: 4.8rem;
      margin-top: 0;
      line-height: 2.3rem; } }

@media (max-width: 767px) {
  .news-page__filters-wrapper {
    z-index: 90;
    display: flex;
    padding: 5px 0 0 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
    position: fixed;
    bottom: 65px;
    width: 100%;
    left: 0; } }

.news-page__filters-wrapper:empty {
  background-color: transparent; }

.news-page__filters-top {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem; }
  @media (max-width: 767px) {
    .news-page__filters-top {
      margin-bottom: 4.8rem; } }
  .news-page__filters-top .link {
    flex-shrink: 0;
    text-transform: uppercase;
    margin-left: 2rem;
    display: none; }
    @media (max-width: 767px) {
      .news-page__filters-top .link {
        display: inline-flex; } }

.news-page__filters-trigger {
  width: 24rem;
  height: 4.8rem;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.6rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  opacity: 1;
  margin-right: 1.6rem;
  transition: opacity 0.3s ease; }
  @media (max-width: 991px) {
    .news-page__filters-trigger {
      height: 4.2rem;
      width: 22rem;
      font-size: 1.7rem; } }
  @media (max-width: 767px) {
    .news-page__filters-trigger {
      height: 5.5rem;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 5; } }
  @media (min-width: 992px) {
    .news-page__filters-trigger:hover {
      opacity: 0.8; } }
  .news-page__filters-trigger i {
    width: 1.6rem;
    height: 2px;
    margin-right: 1.8rem;
    display: block;
    background-color: #ffffff;
    position: relative;
    transition: background-color 0.3s ease; }
    .news-page__filters-trigger i:after, .news-page__filters-trigger i:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: inherit; }
    .news-page__filters-trigger i:before {
      top: -6px; }
    .news-page__filters-trigger i:after {
      bottom: -6px; }

.news-page__filters-form {
  display: flex;
  align-items: flex-end; }
  @media (max-width: 991px) {
    .news-page__filters-form {
      width: calc(100% - 23.6rem); } }
  @media (max-width: 767px) {
    .news-page__filters-form {
      width: 100%; } }

.news-page__filters-search {
  width: 38rem;
  height: 2.5rem;
  margin-right: 1rem;
  border: none;
  border-bottom: 1px solid #787878;
  box-shadow: none;
  padding: 0;
  background-color: transparent; }
  @media (max-width: 991px) {
    .news-page__filters-search {
      width: 100%; } }
  .news-page__filters-search:focus {
    box-shadow: none; }
  .news-page__filters-search::-webkit-input-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search:-moz-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search::-moz-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search:-ms-input-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }

.news-page__filters-submit {
  display: flex;
  align-items: center; }
  .news-page__filters-submit .icon-search {
    transform: rotate(70deg);
    font-size: 1.8rem; }

.news-page__filters-clear {
  margin-left: 1.6rem;
  text-transform: uppercase;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 900;
  height: 40px;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 991px) {
    .news-page__filters-clear {
      font-size: 1.4rem; } }
  @media (max-width: 767px) {
    .news-page__filters-clear {
      flex-shrink: 0;
      padding-right: 2rem; } }

.news-page__filter-item {
  padding: 0 1.6rem 0 1rem;
  height: 4rem;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  background-color: #000000;
  margin-right: 4px;
  margin-bottom: 4px; }
  @media (max-width: 991px) {
    .news-page__filter-item {
      height: 3.3rem; } }
  @media (max-width: 767px) {
    .news-page__filter-item {
      flex-shrink: 0; } }

.news-page__filter-name {
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #ffffff;
  display: block;
  margin-right: 1.2rem; }

.news-page__filter-remove {
  width: 8px;
  height: 8px;
  position: relative; }
  .news-page__filter-remove:after, .news-page__filter-remove:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: #ffffff;
    position: absolute; }
  .news-page__filter-remove:before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .news-page__filter-remove:after {
    transform: translate(-50%, -50%) rotate(-45deg); }

.news__wrapper {
  margin-top: 2.3rem; }
  @media (max-width: 991px) {
    .news__wrapper {
      margin-top: 4rem; } }
  @media (max-width: 767px) {
    .news__wrapper .top-block-container {
      padding-left: 0;
      padding-right: 0; } }

.news__top-block {
  height: auto;
  display: flex;
  flex-wrap: wrap; }
  .news__top-block:not(:last-child) {
    margin-bottom: 45px; }
  @media (max-width: 991px) {
    .news__top-block-title {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .news__top-block-title h3 {
    font-size: 23px;
    text-transform: uppercase; }
  @media (max-width: 991px) {
    .news__top-block {
      flex-wrap: wrap;
      height: auto; } }
  .news__top-block .news-box__time {
    width: 100%;
    margin-bottom: 0; }
  .news__top-block .news-box__name {
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    margin-bottom: 5px;
    color: #000;
    text-decoration: none; }
  .news__top-block .news-box__detail {
    margin-bottom: 8px;
    font-size: 1.6rem;
    margin-top: 0; }
  @media (max-width: 767px) {
    .news__top-block .news-box__tags {
      margin-top: 4.8rem; } }
  @media (max-width: 767px) {
    .news__top-block .news-box__label {
      height: 4rem; } }
  .news__top-block-left {
    width: 100%; }
  .news__top-block-body {
    width: 100%;
    display: flex; }
    .news__top-block-body-desc {
      margin-top: 3.6rem; }
      @media (max-width: 991px) {
        .news__top-block-body-desc {
          padding-left: 2.4rem;
          padding-right: 2.4rem;
          margin-top: 2.6rem; } }
      .news__top-block-body-desc .news-box__name {
        font-size: 24px;
        color: #000;
        text-decoration: none; }
      .news__top-block-body-desc .news-box__time {
        margin-top: 19px; }
    @media (max-width: 991px) {
      .news__top-block-body {
        flex-direction: column; } }

.news__top-video {
  width: calc(100% - 342px);
  /* width: 666px;
					height: 340px;
					flex-shrink: 0;
					position: relative;
					.news-box__tags {
							position: absolute;
							bottom: 15px;
							left: 15px;
							z-index: 2;
							a {
									background-color: #E2E2E2;
									z-index: 9;
							}
					}
					@media (max-width: $sw-lg-max) {
							width: 100%;
					}
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: 37.5rem;
					}
					.video-content__inner {
							width: 100%;
							height: 100%;
					}
					.icon {
							&-play, &-pause-path, &-big-play, &-pause {
									font-size: 6.6rem !important;
							}
							&-play {
									stroke: #fff;
									fill: #fff;
							}
					}
					.news-box__name {
						padding-top: 36px;
						font-size: 24px;
					} */ }
  @media (max-width: 991px) {
    .news__top-video {
      width: 100%;
      height: 37.5rem; } }
  .news__top-video .video-content__inner {
    width: 100%;
    height: 100%; }
  .news__top-video .icon-play, .news__top-video .icon-pause-path, .news__top-video .icon-big-play, .news__top-video .icon-pause {
    font-size: 6.6rem !important; }
  .news__top-video .icon-play {
    stroke: #fff;
    fill: #fff; }

.news__top-info {
  /* width: 328px;
					margin-left: 16px;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: auto;
							margin-left: 0;
							margin-top: 20px;
					}
					.link {
							text-transform: uppercase;
					}
					.link_arrow {
							margin-top: 1rem;
					}
					.news-box {
							&__details {
									background-color: #F5F5F5;
									margin-bottom: 10px;
									@media (max-width: $sw-sm-max) {
											margin-bottom: 6px;
											width: 100%;
									}
							}
							&__detail {
									@media (max-width: $sw-sm-max) {
											margin-bottom: 2rem;
									}
							}
					} */
  width: 342px;
  background-color: #F5F5F5; }
  @media (max-width: 991px) {
    .news__top-info {
      width: 100%;
      height: auto; } }
  .news__top-info .link {
    text-transform: uppercase; }
  .news__top-info .news-box__details {
    height: calc(100% - 4.8rem);
    background-color: #F5F5F5;
    width: 100%; }
    @media (max-width: 991px) {
      .news__top-info .news-box__details {
        margin-bottom: 6px; } }
  @media (max-width: 991px) {
    .news__top-info .news-box__detail {
      margin-bottom: 2rem; } }

.news__boxes-wrapper {
  margin-right: -1.6rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.6rem; }
  @media (max-width: 991px) {
    .news__boxes-wrapper {
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .news__boxes-wrapper {
      margin-bottom: 1.1rem; } }
  .news__boxes-wrapper .news-box {
    width: 32.5rem;
    margin-right: 1.6rem;
    margin-bottom: 1.6rem; }
    @media (max-width: 1199px) {
      .news__boxes-wrapper .news-box {
        width: 30rem; } }
    @media (max-width: 991px) {
      .news__boxes-wrapper .news-box {
        width: 47%; } }
    @media (max-width: 767px) {
      .news__boxes-wrapper .news-box {
        width: 100%; } }
    @media (max-width: 767px) {
      .news__boxes-wrapper .news-box__content {
        max-width: 33rem;
        margin: 0 auto; } }

.news__footer-wrapper {
  margin-top: 4.2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .news__footer-wrapper {
      margin-top: 1rem; } }
  .news__footer-wrapper .link {
    text-transform: uppercase; }

.industry_news .news__top-info {
  width: 328px;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: none; }
  @media (max-width: 991px) {
    .industry_news .news__top-info {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-top: 20px; } }
  .industry_news .news__top-info .link {
    text-transform: uppercase; }
  .industry_news .news__top-info .link_arrow {
    margin-top: 1rem; }
  .industry_news .news__top-info .news-box__details {
    background-color: #F5F5F5;
    margin-bottom: 10px; }
    @media (max-width: 991px) {
      .industry_news .news__top-info .news-box__details {
        margin-bottom: 6px;
        width: 100%; } }
  @media (max-width: 991px) {
    .industry_news .news__top-info .news-box__detail {
      margin-bottom: 2rem; } }

.industry_news .news__top-video {
  width: 666px;
  height: 340px;
  flex-shrink: 0;
  position: relative; }
  .industry_news .news__top-video .news-box__tags {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 2; }
    .industry_news .news__top-video .news-box__tags a {
      background-color: #E2E2E2;
      z-index: 9; }
  @media (max-width: 1439px) {
    .industry_news .news__top-video {
      width: 100%; } }
  @media (max-width: 991px) {
    .industry_news .news__top-video {
      width: 100%;
      height: 37.5rem; } }
  .industry_news .news__top-video .video-content__inner {
    width: 100%;
    height: 100%; }
  .industry_news .news__top-video .icon-play, .industry_news .news__top-video .icon-pause-path, .industry_news .news__top-video .icon-big-play, .industry_news .news__top-video .icon-pause {
    font-size: 6.6rem !important; }
  .industry_news .news__top-video .icon-play {
    stroke: #fff;
    fill: #fff; }
  .industry_news .news__top-video .news-box__name {
    padding-top: 36px;
    font-size: 24px; }

.pagination__wrap, .wp-pagenavi {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .pagination__wrap, .wp-pagenavi {
      margin-bottom: 2rem; } }
  @media (max-width: 479px) {
    .pagination__wrap, .wp-pagenavi {
      width: 100%; } }
  .pagination__wrap__item, .pagination__wrap a, .pagination__wrap span, .wp-pagenavi__item, .wp-pagenavi a, .wp-pagenavi span {
    font-size: 1.6rem;
    font-weight: 900;
    color: #000000;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease; }
    @media (max-width: 767px) {
      .pagination__wrap__item, .pagination__wrap a, .pagination__wrap span, .wp-pagenavi__item, .wp-pagenavi a, .wp-pagenavi span {
        font-size: 2.2rem; } }
    @media (min-width: 992px) {
      .pagination__wrap__item:hover, .pagination__wrap a:hover, .pagination__wrap span:hover, .wp-pagenavi__item:hover, .wp-pagenavi a:hover, .wp-pagenavi span:hover {
        opacity: 0.7; } }
    .pagination__wrap__item + .pagination__item, .pagination__wrap__item + a, .pagination__wrap__item + span, .pagination__wrap a + .pagination__item, .pagination__wrap a + a, .pagination__wrap a + span, .pagination__wrap span + .pagination__item, .pagination__wrap span + a, .pagination__wrap span + span, .wp-pagenavi__item + .pagination__item, .wp-pagenavi__item + a, .wp-pagenavi__item + span, .wp-pagenavi a + .pagination__item, .wp-pagenavi a + a, .wp-pagenavi a + span, .wp-pagenavi span + .pagination__item, .wp-pagenavi span + a, .wp-pagenavi span + span {
      margin-left: 1.5rem; }
      @media (max-width: 767px) {
        .pagination__wrap__item + .pagination__item, .pagination__wrap__item + a, .pagination__wrap__item + span, .pagination__wrap a + .pagination__item, .pagination__wrap a + a, .pagination__wrap a + span, .pagination__wrap span + .pagination__item, .pagination__wrap span + a, .pagination__wrap span + span, .wp-pagenavi__item + .pagination__item, .wp-pagenavi__item + a, .wp-pagenavi__item + span, .wp-pagenavi a + .pagination__item, .wp-pagenavi a + a, .wp-pagenavi a + span, .wp-pagenavi span + .pagination__item, .wp-pagenavi span + a, .wp-pagenavi span + span {
          margin-left: 4.1rem; } }
      .pagination__wrap__item + .pagination__item:after, .pagination__wrap__item + a:after, .pagination__wrap__item + span:after, .pagination__wrap a + .pagination__item:after, .pagination__wrap a + a:after, .pagination__wrap a + span:after, .pagination__wrap span + .pagination__item:after, .pagination__wrap span + a:after, .pagination__wrap span + span:after, .wp-pagenavi__item + .pagination__item:after, .wp-pagenavi__item + a:after, .wp-pagenavi__item + span:after, .wp-pagenavi a + .pagination__item:after, .wp-pagenavi a + a:after, .wp-pagenavi a + span:after, .wp-pagenavi span + .pagination__item:after, .wp-pagenavi span + a:after, .wp-pagenavi span + span:after {
        content: '.';
        display: block;
        font-weight: 900;
        color: #000000;
        position: absolute;
        pointer-events: none;
        cursor: default;
        top: 7px;
        left: -1rem;
        transform: translateY(-50%); }
        @media (max-width: 767px) {
          .pagination__wrap__item + .pagination__item:after, .pagination__wrap__item + a:after, .pagination__wrap__item + span:after, .pagination__wrap a + .pagination__item:after, .pagination__wrap a + a:after, .pagination__wrap a + span:after, .pagination__wrap span + .pagination__item:after, .pagination__wrap span + a:after, .pagination__wrap span + span:after, .wp-pagenavi__item + .pagination__item:after, .wp-pagenavi__item + a:after, .wp-pagenavi__item + span:after, .wp-pagenavi a + .pagination__item:after, .wp-pagenavi a + a:after, .wp-pagenavi a + span:after, .wp-pagenavi span + .pagination__item:after, .wp-pagenavi span + a:after, .wp-pagenavi span + span:after {
            top: 1rem;
            font-size: 2.7rem;
            left: -2.3rem; } }
  .pagination__wrap__divider, .wp-pagenavi__divider {
    font-weight: 900;
    color: #000000;
    font-size: 1.6rem;
    margin: 0 5px;
    position: relative;
    width: 18px;
    height: 22px; }
    @media (max-width: 767px) {
      .pagination__wrap__divider, .wp-pagenavi__divider {
        width: 22px;
        margin: 0 17px; } }
    .pagination__wrap__divider:after, .wp-pagenavi__divider:after {
      content: '...';
      display: block;
      font-size: 1.6rem;
      font-weight: 900;
      color: #000000;
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 767px) {
        .pagination__wrap__divider:after, .wp-pagenavi__divider:after {
          top: 7px;
          font-size: 2.5rem; } }

.video-content {
  width: 100%;
  height: 100%;
  position: relative; }
  .video-content:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease;
    background-color: rgba(0, 0, 0, 0.3); }
  .video-content.now-playing:after {
    opacity: 0;
    visibility: hidden; }
  .video-content.now-playing .cnt-play {
    opacity: 0;
    visibility: hidden; }
  .video-content.now-playing:hover .cnt-pause, .video-content.now-playing:hover .video-content__full-screen {
    opacity: 1;
    visibility: visible; }
  .video-content.now-playing .video-content__seek-bar {
    opacity: 1;
    visibility: visible; }
  .video-content.now-playing + .news-box__tags {
    opacity: 0;
    visibility: hidden; }
  .video-content__inner {
    width: 100%;
    height: 100%; }
  .video-content__play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity .3s ease;
    z-index: 2; }
    .video-content__play:hover .icon-play {
      opacity: 0 !important; }
    .video-content__play:hover .icon-big-play {
      opacity: 1 !important; }
    .video-content__play .cnt-play, .video-content__play .cnt-pause {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity .3s ease, visibility .3s ease; }
      .video-content__play .cnt-play .svg-icon, .video-content__play .cnt-pause .svg-icon {
        font-size: 3.9rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .3s ease; }
    .video-content__play .cnt-pause {
      opacity: 0;
      visibility: hidden; }
      .video-content__play .cnt-pause .icon-pause-path {
        stroke: #fff;
        fill: #fff; }
      .video-content__play .cnt-pause .icon-pause {
        opacity: 0;
        fill: #F2A900;
        stroke: #F2A900;
        color: #fff; }
      .video-content__play .cnt-pause:hover .icon-pause-path {
        opacity: 0 !important; }
      .video-content__play .cnt-pause:hover .icon-pause {
        opacity: 1 !important;
        fill: #F2A900;
        color: #fff;
        stroke: #F2A900; }
      .video-content__play .cnt-pause:hover .pause-circle {
        stroke: none; }
    .video-content__play .cnt-play {
      opacity: 1;
      visibility: visible; }
      .video-content__play .cnt-play .icon-big-play {
        opacity: 0;
        fill: #F2A900;
        stroke: #F2A900;
        color: #fff; }
      .video-content__play .cnt-play .icon-play {
        stroke: #fff;
        fill: #fff; }
  .video-content__full-screen {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 3.8rem;
    height: 3.8rem;
    z-index: 3;
    border-radius: 50%;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: background-color .3s ease, opacity .3s ease, visibility .3s ease; }
    .video-content__full-screen:hover {
      background-color: #F2A900; }
    .video-content__full-screen .icon-fullscreen {
      color: #fff;
      font-size: 1.7rem; }
  .video-content__seek-bar {
    position: absolute;
    width: calc(100% - 4.8rem);
    height: 4px;
    background-color: #f5f5f5;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease, visibility .3s ease; }
    .video-content__seek-bar span {
      display: block;
      width: 0;
      height: 100%;
      transition: width .1s ease;
      background-color: #F2A900; }
  .video-content__controls {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1); }
    .video-content__controls:hover .video-content__play {
      opacity: 1; }
    .video-content__controls:hover .video-content__full-screen {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__play {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__seek-bar {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__full-screen {
      opacity: 1; }
  .video-content__close {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: -3rem;
    right: -3rem;
    z-index: 1; }
    @media (max-width: 767px) {
      .video-content__close {
        position: relative;
        right: auto;
        top: auto; } }
    .video-content__close:after, .video-content__close:before {
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      left: 50%;
      top: 50%; }
    .video-content__close:before {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .video-content__close:after {
      transform: translate(-50%, -50%) rotate(45deg); }

.news__top-block-title {
  width: 100%; }

.news-page__wrapper {
  position: relative;
  z-index: 1;
  padding: 6rem 0; }
  @media (max-width: 1439px) {
    .news-page__wrapper {
      padding: 8rem 0 6rem 0; } }
  @media (max-width: 767px) {
    .news-page__wrapper {
      padding: 1.4rem 0 6.5rem 0; } }

.news-page__title {
  text-transform: uppercase;
  font-size: 5.6rem;
  line-height: 6.4rem;
  margin-bottom: 0; }
  @media (max-width: 1199px) {
    .news-page__title {
      font-size: 5rem;
      line-height: 5.8rem; } }
  @media (max-width: 991px) {
    .news-page__title {
      font-size: 4.8rem;
      line-height: 5.2rem; } }
  .news-page__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 2.1rem; }
    @media (max-width: 1199px) {
      .news-page__title-wrap {
        margin-bottom: 2rem; } }
    @media (max-width: 991px) {
      .news-page__title-wrap {
        margin-bottom: 1rem; } }
    .news-page__title-wrap .link {
      text-transform: uppercase;
      margin-left: 3.2rem; }
      @media (max-width: 767px) {
        .news-page__title-wrap .link {
          display: none; } }

.news-page__subtitle {
  margin-bottom: 3.8rem; }
  @media (max-width: 1199px) {
    .news-page__subtitle {
      font-size: 2.2rem; } }
  @media (max-width: 991px) {
    .news-page__subtitle {
      font-size: 2rem; } }
  @media (max-width: 767px) {
    .news-page__subtitle {
      margin-bottom: 4.8rem;
      margin-top: 0;
      line-height: 2.3rem; } }

@media (max-width: 767px) {
  .news-page__filters-wrapper {
    z-index: 90;
    display: flex;
    padding: 5px 0 0 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
    position: fixed;
    bottom: 65px;
    width: 100%;
    left: 0; } }

.news-page__filters-wrapper:empty {
  background-color: transparent; }

.news-page__filters-top {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem; }
  @media (max-width: 767px) {
    .news-page__filters-top {
      margin-bottom: 4.8rem; } }
  .news-page__filters-top .link {
    flex-shrink: 0;
    text-transform: uppercase;
    margin-left: 2rem;
    display: none; }
    @media (max-width: 767px) {
      .news-page__filters-top .link {
        display: inline-flex; } }

.news-page__filters-trigger {
  width: 24rem;
  height: 4.8rem;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.6rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  opacity: 1;
  margin-right: 1.6rem;
  transition: opacity 0.3s ease; }
  @media (max-width: 991px) {
    .news-page__filters-trigger {
      height: 4.2rem;
      width: 22rem;
      font-size: 1.7rem; } }
  @media (max-width: 767px) {
    .news-page__filters-trigger {
      height: 5.5rem;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 5; } }
  @media (min-width: 992px) {
    .news-page__filters-trigger:hover {
      opacity: 0.8; } }
  .news-page__filters-trigger i {
    width: 1.6rem;
    height: 2px;
    margin-right: 1.8rem;
    display: block;
    background-color: #ffffff;
    position: relative;
    transition: background-color 0.3s ease; }
    .news-page__filters-trigger i:after, .news-page__filters-trigger i:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: inherit; }
    .news-page__filters-trigger i:before {
      top: -6px; }
    .news-page__filters-trigger i:after {
      bottom: -6px; }

.news-page__filters-form {
  display: flex;
  align-items: flex-end; }
  @media (max-width: 991px) {
    .news-page__filters-form {
      width: calc(100% - 23.6rem); } }
  @media (max-width: 767px) {
    .news-page__filters-form {
      width: 100%; } }

.news-page__filters-search {
  width: 38rem;
  height: 2.5rem;
  margin-right: 1rem;
  border: none;
  border-bottom: 1px solid #787878;
  box-shadow: none;
  padding: 0;
  background-color: transparent; }
  @media (max-width: 991px) {
    .news-page__filters-search {
      width: 100%; } }
  .news-page__filters-search:focus {
    box-shadow: none; }
  .news-page__filters-search::-webkit-input-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search:-moz-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search::-moz-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .news-page__filters-search:-ms-input-placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }

.news-page__filters-submit {
  display: flex;
  align-items: center; }
  .news-page__filters-submit .icon-search {
    transform: rotate(70deg);
    font-size: 1.8rem; }

.news-page__filters-clear {
  margin-left: 1.6rem;
  text-transform: uppercase;
  color: #000000;
  font-size: 1.6rem;
  font-weight: 900;
  height: 40px;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 991px) {
    .news-page__filters-clear {
      font-size: 1.4rem; } }
  @media (max-width: 767px) {
    .news-page__filters-clear {
      flex-shrink: 0;
      padding-right: 2rem; } }

.news-page__filter-item {
  padding: 0 1.6rem 0 1rem;
  height: 4rem;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  background-color: #000000;
  margin-right: 4px;
  margin-bottom: 4px; }
  @media (max-width: 991px) {
    .news-page__filter-item {
      height: 3.3rem; } }
  @media (max-width: 767px) {
    .news-page__filter-item {
      flex-shrink: 0; } }

.news-page__filter-name {
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #ffffff;
  display: block;
  margin-right: 1.2rem; }

.news-page__filter-remove {
  width: 8px;
  height: 8px;
  position: relative; }
  .news-page__filter-remove:after, .news-page__filter-remove:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: #ffffff;
    position: absolute; }
  .news-page__filter-remove:before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .news-page__filter-remove:after {
    transform: translate(-50%, -50%) rotate(-45deg); }

.news__wrapper {
  margin-top: 2.3rem; }
  @media (max-width: 991px) {
    .news__wrapper {
      margin-top: 4rem; } }
  @media (max-width: 767px) {
    .news__wrapper .top-block-container {
      padding-left: 0;
      padding-right: 0; } }

.news__top-block {
  height: auto;
  display: flex;
  flex-wrap: wrap; }
  .news__top-block:not(:last-child) {
    margin-bottom: 45px; }
  @media (max-width: 991px) {
    .news__top-block-title {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  .news__top-block-title h3 {
    font-size: 23px;
    text-transform: uppercase; }
  @media (max-width: 991px) {
    .news__top-block {
      flex-wrap: wrap;
      height: auto; } }
  .news__top-block .news-box__time {
    width: 100%;
    margin-bottom: 0; }
  .news__top-block .news-box__name {
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    margin-bottom: 5px;
    color: #000;
    text-decoration: none; }
  .news__top-block .news-box__detail {
    margin-bottom: 8px;
    font-size: 1.6rem;
    margin-top: 0; }
  @media (max-width: 767px) {
    .news__top-block .news-box__tags {
      margin-top: 4.8rem; } }
  @media (max-width: 767px) {
    .news__top-block .news-box__label {
      height: 4rem; } }
  .news__top-block-left {
    width: 100%; }
  .news__top-block-body {
    width: 100%;
    display: flex; }
    .news__top-block-body-desc {
      margin-top: 3.6rem; }
      @media (max-width: 991px) {
        .news__top-block-body-desc {
          padding-left: 2.4rem;
          padding-right: 2.4rem;
          margin-top: 2.6rem; } }
      .news__top-block-body-desc .news-box__name {
        font-size: 24px;
        color: #000;
        text-decoration: none; }
      .news__top-block-body-desc .news-box__time {
        margin-top: 19px; }
    @media (max-width: 991px) {
      .news__top-block-body {
        flex-direction: column; } }

.news__top-video {
  width: calc(100% - 342px);
  /* width: 666px;
					height: 340px;
					flex-shrink: 0;
					position: relative;
					.news-box__tags {
							position: absolute;
							bottom: 15px;
							left: 15px;
							z-index: 2;
							a {
									background-color: #E2E2E2;
									z-index: 9;
							}
					}
					@media (max-width: $sw-lg-max) {
							width: 100%;
					}
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: 37.5rem;
					}
					.video-content__inner {
							width: 100%;
							height: 100%;
					}
					.icon {
							&-play, &-pause-path, &-big-play, &-pause {
									font-size: 6.6rem !important;
							}
							&-play {
									stroke: #fff;
									fill: #fff;
							}
					}
					.news-box__name {
						padding-top: 36px;
						font-size: 24px;
					} */ }
  @media (max-width: 991px) {
    .news__top-video {
      width: 100%;
      height: 37.5rem; } }
  .news__top-video .video-content__inner {
    width: 100%;
    height: 100%; }
  .news__top-video .icon-play, .news__top-video .icon-pause-path, .news__top-video .icon-big-play, .news__top-video .icon-pause {
    font-size: 6.6rem !important; }
  .news__top-video .icon-play {
    stroke: #fff;
    fill: #fff; }

.news__top-info {
  /* width: 328px;
					margin-left: 16px;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: auto;
							margin-left: 0;
							margin-top: 20px;
					}
					.link {
							text-transform: uppercase;
					}
					.link_arrow {
							margin-top: 1rem;
					}
					.news-box {
							&__details {
									background-color: #F5F5F5;
									margin-bottom: 10px;
									@media (max-width: $sw-sm-max) {
											margin-bottom: 6px;
											width: 100%;
									}
							}
							&__detail {
									@media (max-width: $sw-sm-max) {
											margin-bottom: 2rem;
									}
							}
					} */
  width: 342px;
  background-color: #F5F5F5; }
  @media (max-width: 991px) {
    .news__top-info {
      width: 100%;
      height: auto; } }
  .news__top-info .link {
    text-transform: uppercase; }
  .news__top-info .news-box__details {
    height: calc(100% - 4.8rem);
    background-color: #F5F5F5;
    width: 100%; }
    @media (max-width: 991px) {
      .news__top-info .news-box__details {
        margin-bottom: 6px; } }
  @media (max-width: 991px) {
    .news__top-info .news-box__detail {
      margin-bottom: 2rem; } }

.news__boxes-wrapper {
  margin-right: -1.6rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.6rem; }
  @media (max-width: 991px) {
    .news__boxes-wrapper {
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .news__boxes-wrapper {
      margin-bottom: 1.1rem; } }
  .news__boxes-wrapper .news-box {
    width: 32.5rem;
    margin-right: 1.6rem;
    margin-bottom: 1.6rem; }
    @media (max-width: 1199px) {
      .news__boxes-wrapper .news-box {
        width: 30rem; } }
    @media (max-width: 991px) {
      .news__boxes-wrapper .news-box {
        width: 47%; } }
    @media (max-width: 767px) {
      .news__boxes-wrapper .news-box {
        width: 100%; } }
    @media (max-width: 767px) {
      .news__boxes-wrapper .news-box__content {
        max-width: 33rem;
        margin: 0 auto; } }

.news__footer-wrapper {
  margin-top: 4.2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .news__footer-wrapper {
      margin-top: 1rem; } }
  .news__footer-wrapper .link {
    text-transform: uppercase; }

.industry_news .news__top-info {
  width: 328px;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: none; }
  @media (max-width: 991px) {
    .industry_news .news__top-info {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-top: 20px; } }
  .industry_news .news__top-info .link {
    text-transform: uppercase; }
  .industry_news .news__top-info .link_arrow {
    margin-top: 1rem; }
  .industry_news .news__top-info .news-box__details {
    background-color: #F5F5F5;
    margin-bottom: 10px; }
    @media (max-width: 991px) {
      .industry_news .news__top-info .news-box__details {
        margin-bottom: 6px;
        width: 100%; } }
  @media (max-width: 991px) {
    .industry_news .news__top-info .news-box__detail {
      margin-bottom: 2rem; } }

.industry_news .news__top-video {
  width: 666px;
  height: 340px;
  flex-shrink: 0;
  position: relative; }
  .industry_news .news__top-video .news-box__tags {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 2; }
    .industry_news .news__top-video .news-box__tags a {
      background-color: #E2E2E2;
      z-index: 9; }
  @media (max-width: 1439px) {
    .industry_news .news__top-video {
      width: 100%; } }
  @media (max-width: 991px) {
    .industry_news .news__top-video {
      width: 100%;
      height: 37.5rem; } }
  .industry_news .news__top-video .video-content__inner {
    width: 100%;
    height: 100%; }
  .industry_news .news__top-video .icon-play, .industry_news .news__top-video .icon-pause-path, .industry_news .news__top-video .icon-big-play, .industry_news .news__top-video .icon-pause {
    font-size: 6.6rem !important; }
  .industry_news .news__top-video .icon-play {
    stroke: #fff;
    fill: #fff; }
  .industry_news .news__top-video .news-box__name {
    padding-top: 36px;
    font-size: 24px; }

.pagination__wrap, .wp-pagenavi {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .pagination__wrap, .wp-pagenavi {
      margin-bottom: 2rem; } }
  @media (max-width: 479px) {
    .pagination__wrap, .wp-pagenavi {
      width: 100%; } }
  .pagination__wrap__item, .pagination__wrap a, .pagination__wrap span, .wp-pagenavi__item, .wp-pagenavi a, .wp-pagenavi span {
    font-size: 1.6rem;
    font-weight: 900;
    color: #000000;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease; }
    @media (max-width: 767px) {
      .pagination__wrap__item, .pagination__wrap a, .pagination__wrap span, .wp-pagenavi__item, .wp-pagenavi a, .wp-pagenavi span {
        font-size: 2.2rem; } }
    @media (min-width: 992px) {
      .pagination__wrap__item:hover, .pagination__wrap a:hover, .pagination__wrap span:hover, .wp-pagenavi__item:hover, .wp-pagenavi a:hover, .wp-pagenavi span:hover {
        opacity: 0.7; } }
    .pagination__wrap__item + .pagination__item, .pagination__wrap__item + a, .pagination__wrap__item + span, .pagination__wrap a + .pagination__item, .pagination__wrap a + a, .pagination__wrap a + span, .pagination__wrap span + .pagination__item, .pagination__wrap span + a, .pagination__wrap span + span, .wp-pagenavi__item + .pagination__item, .wp-pagenavi__item + a, .wp-pagenavi__item + span, .wp-pagenavi a + .pagination__item, .wp-pagenavi a + a, .wp-pagenavi a + span, .wp-pagenavi span + .pagination__item, .wp-pagenavi span + a, .wp-pagenavi span + span {
      margin-left: 1.5rem; }
      @media (max-width: 767px) {
        .pagination__wrap__item + .pagination__item, .pagination__wrap__item + a, .pagination__wrap__item + span, .pagination__wrap a + .pagination__item, .pagination__wrap a + a, .pagination__wrap a + span, .pagination__wrap span + .pagination__item, .pagination__wrap span + a, .pagination__wrap span + span, .wp-pagenavi__item + .pagination__item, .wp-pagenavi__item + a, .wp-pagenavi__item + span, .wp-pagenavi a + .pagination__item, .wp-pagenavi a + a, .wp-pagenavi a + span, .wp-pagenavi span + .pagination__item, .wp-pagenavi span + a, .wp-pagenavi span + span {
          margin-left: 4.1rem; } }
      .pagination__wrap__item + .pagination__item:after, .pagination__wrap__item + a:after, .pagination__wrap__item + span:after, .pagination__wrap a + .pagination__item:after, .pagination__wrap a + a:after, .pagination__wrap a + span:after, .pagination__wrap span + .pagination__item:after, .pagination__wrap span + a:after, .pagination__wrap span + span:after, .wp-pagenavi__item + .pagination__item:after, .wp-pagenavi__item + a:after, .wp-pagenavi__item + span:after, .wp-pagenavi a + .pagination__item:after, .wp-pagenavi a + a:after, .wp-pagenavi a + span:after, .wp-pagenavi span + .pagination__item:after, .wp-pagenavi span + a:after, .wp-pagenavi span + span:after {
        content: '.';
        display: block;
        font-weight: 900;
        color: #000000;
        position: absolute;
        pointer-events: none;
        cursor: default;
        top: 7px;
        left: -1rem;
        transform: translateY(-50%); }
        @media (max-width: 767px) {
          .pagination__wrap__item + .pagination__item:after, .pagination__wrap__item + a:after, .pagination__wrap__item + span:after, .pagination__wrap a + .pagination__item:after, .pagination__wrap a + a:after, .pagination__wrap a + span:after, .pagination__wrap span + .pagination__item:after, .pagination__wrap span + a:after, .pagination__wrap span + span:after, .wp-pagenavi__item + .pagination__item:after, .wp-pagenavi__item + a:after, .wp-pagenavi__item + span:after, .wp-pagenavi a + .pagination__item:after, .wp-pagenavi a + a:after, .wp-pagenavi a + span:after, .wp-pagenavi span + .pagination__item:after, .wp-pagenavi span + a:after, .wp-pagenavi span + span:after {
            top: 1rem;
            font-size: 2.7rem;
            left: -2.3rem; } }
  .pagination__wrap__divider, .wp-pagenavi__divider {
    font-weight: 900;
    color: #000000;
    font-size: 1.6rem;
    margin: 0 5px;
    position: relative;
    width: 18px;
    height: 22px; }
    @media (max-width: 767px) {
      .pagination__wrap__divider, .wp-pagenavi__divider {
        width: 22px;
        margin: 0 17px; } }
    .pagination__wrap__divider:after, .wp-pagenavi__divider:after {
      content: '...';
      display: block;
      font-size: 1.6rem;
      font-weight: 900;
      color: #000000;
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 767px) {
        .pagination__wrap__divider:after, .wp-pagenavi__divider:after {
          top: 7px;
          font-size: 2.5rem; } }

.video-content {
  width: 100%;
  height: 100%;
  position: relative; }
  .video-content:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease;
    background-color: rgba(0, 0, 0, 0.3); }
  .video-content.now-playing:after {
    opacity: 0;
    visibility: hidden; }
  .video-content.now-playing .cnt-play {
    opacity: 0;
    visibility: hidden; }
  .video-content.now-playing:hover .cnt-pause, .video-content.now-playing:hover .video-content__full-screen {
    opacity: 1;
    visibility: visible; }
  .video-content.now-playing .video-content__seek-bar {
    opacity: 1;
    visibility: visible; }
  .video-content.now-playing + .news-box__tags {
    opacity: 0;
    visibility: hidden; }
  .video-content__inner {
    width: 100%;
    height: 100%; }
  .video-content__play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity .3s ease;
    z-index: 2; }
    .video-content__play:hover .icon-play {
      opacity: 0 !important; }
    .video-content__play:hover .icon-big-play {
      opacity: 1 !important; }
    .video-content__play .cnt-play, .video-content__play .cnt-pause {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity .3s ease, visibility .3s ease; }
      .video-content__play .cnt-play .svg-icon, .video-content__play .cnt-pause .svg-icon {
        font-size: 3.9rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .3s ease; }
    .video-content__play .cnt-pause {
      opacity: 0;
      visibility: hidden; }
      .video-content__play .cnt-pause .icon-pause-path {
        stroke: #fff;
        fill: #fff; }
      .video-content__play .cnt-pause .icon-pause {
        opacity: 0;
        fill: #F2A900;
        stroke: #F2A900;
        color: #fff; }
      .video-content__play .cnt-pause:hover .icon-pause-path {
        opacity: 0 !important; }
      .video-content__play .cnt-pause:hover .icon-pause {
        opacity: 1 !important;
        fill: #F2A900;
        color: #fff;
        stroke: #F2A900; }
      .video-content__play .cnt-pause:hover .pause-circle {
        stroke: none; }
    .video-content__play .cnt-play {
      opacity: 1;
      visibility: visible; }
      .video-content__play .cnt-play .icon-big-play {
        opacity: 0;
        fill: #F2A900;
        stroke: #F2A900;
        color: #fff; }
      .video-content__play .cnt-play .icon-play {
        stroke: #fff;
        fill: #fff; }
  .video-content__full-screen {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 3.8rem;
    height: 3.8rem;
    z-index: 3;
    border-radius: 50%;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: background-color .3s ease, opacity .3s ease, visibility .3s ease; }
    .video-content__full-screen:hover {
      background-color: #F2A900; }
    .video-content__full-screen .icon-fullscreen {
      color: #fff;
      font-size: 1.7rem; }
  .video-content__seek-bar {
    position: absolute;
    width: calc(100% - 4.8rem);
    height: 4px;
    background-color: #f5f5f5;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease, visibility .3s ease; }
    .video-content__seek-bar span {
      display: block;
      width: 0;
      height: 100%;
      transition: width .1s ease;
      background-color: #F2A900; }
  .video-content__controls {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1); }
    .video-content__controls:hover .video-content__play {
      opacity: 1; }
    .video-content__controls:hover .video-content__full-screen {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__play {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__seek-bar {
      opacity: 1; }
    .video-content__controls.video-paused .video-content__full-screen {
      opacity: 1; }
  .video-content__close {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: -3rem;
    right: -3rem;
    z-index: 1; }
    @media (max-width: 767px) {
      .video-content__close {
        position: relative;
        right: auto;
        top: auto; } }
    .video-content__close:after, .video-content__close:before {
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      left: 50%;
      top: 50%; }
    .video-content__close:before {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .video-content__close:after {
      transform: translate(-50%, -50%) rotate(45deg); }

.news__top-block-title {
  width: 100%; }

form.valid-success .form-input input::placeholder, form.valid-success .form-input textarea::placeholder, form.valid-success-complete .form-input input::placeholder, form.valid-success-complete .form-input textarea::placeholder {
  opacity: 0; }

.form-select.select-hidden {
  display: none;
  visibility: hidden; }

.form-select-wrap {
  position: relative; }

.form-select_xxl + .select-trigger {
  height: 3.6rem;
  line-height: 3.6rem; }

.select-trigger {
  border-bottom: 1px solid #787878;
  height: 2.6rem;
  font-size: 1.6rem;
  position: relative; }
  .select-trigger:after {
    content: '';
    display: block;
    width: 17.5px;
    height: 15.5px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTUuNSAxNy45IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNS41IDE3Ljk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMjMxRjIwO308L3N0eWxlPjx0aXRsZT5DNjIzNkE0MS03QkU0LTREOEEtOTAyMy1DOEIwMjA2MTRENEE8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz48ZyBpZD0ic3R5bGVndWlkZSI+PGcgaWQ9InN0eWxlZ3VpZGVfeDJGX2ljb25zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDU4LjAwMDAwMCwgLTMwNS4wMDAwMDApIj48ZyBpZD0iYXJyb3ctdmVydGljYWwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQ2Ni4wMDAwMDAsIDMxNC4wMDAwMDApIHJvdGF0ZSgtMjcwLjAwMDAwMCkgdHJhbnNsYXRlKC00NjYuMDAwMDAwLCAtMzE0LjAwMDAwMCkgdHJhbnNsYXRlKDQ1Ny4wMDAwMDAsIDMwNi4wMDAwMDApIj48cGF0aCBpZD0iYXJyb3dfeDJGX2JsYWNrIiBjbGFzcz0ic3QwIiBkPSJNMTQuMSw3LjNMOC43LDEuOWwxLjQtMS40bDYuNCw2LjRsMS40LDEuNEwxMC4yLDE2bC0xLjQtMS40bDUuMy01LjNIMHYtMkgxNC4xeiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%); }

.select-options {
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.11);
  list-style: none;
  margin: 0;
  padding: 2rem 0.6rem 1.3rem 0.6rem;
  max-height: 32.7rem;
  overflow-y: auto;
  display: none;
  position: absolute;
  top: 100%;
  right: auto;
  min-width: 100%;
  left: 0;
  z-index: 999; }
  @media (max-width: 991px) {
    .select-options {
      padding: 1.5rem 1.9rem 1.3rem 1.7rem; } }
  .select-options.active:after {
    opacity: 1;
    visibility: visible; }
  .select-options:after {
    content: '';
    display: block;
    width: calc(100% - 17px);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 19rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
    background: linear-gradient(180deg, rgba(32, 100, 219, 0) 0%, #fff 95%); }
  .select-options li {
    cursor: pointer;
    margin-bottom: 2px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    opacity: 1;
    transition: opacity .3s ease; }
    @media (min-width: 992px) {
      .select-options li:not(.selected):hover {
        opacity: 0.6; } }
    .select-options li span {
      display: inline-block;
      padding: 3px 1rem;
      border-radius: 1.2rem; }
    .select-options li.selected span {
      background-color: #E2E2E2; }

.form-group {
  margin-bottom: 0;
  position: relative; }
  .form-group:not(:first-child) {
    margin-top: 1.2rem; }
    @media (max-width: 767px) {
      .form-group:not(:first-child) {
        margin-top: 1.1rem; } }
  .form-group-descr {
    margin-top: 3px; }
  .form-group-title {
    color: #787878;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 0.7rem; }
  .form-group label {
    margin: 0;
    color: #1c1c1c;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: .6rem; }
    .form-group label.invalid {
      margin: 0;
      color: red;
      font-weight: 400;
      font-size: 1.4rem;
      margin-top: .5rem;
      line-height: 1.4rem; }
  .form-group.button-group {
    margin-top: 30px; }
  .form-group.caption-group {
    font-size: 12px;
    margin-top: 16px;
    line-height: 16px;
    text-align: center; }
    .form-group.caption-group_md {
      font-size: 1.4rem;
      line-height: 2rem; }
    .form-group.caption-group_lg {
      font-size: 1.6rem;
      line-height: 2.2rem; }
    .form-group.caption-group_grey {
      opacity: .5; }
  .form-group .input-file label input {
    display: none; }
  .form-group .input-file__output {
    display: none;
    color: #70787f;
    font-size: 1.3rem;
    margin-top: .8rem;
    line-height: 1.6rem;
    align-items: flex-start; }
    .form-group .input-file__output svg {
      fill: #eb50a3;
      flex-shrink: 0;
      font-size: 2.2rem;
      margin-right: 1rem; }
    .form-group .input-file__output span {
      display: block;
      padding-top: .2rem; }
  .form-group .input-file.active .input-file__output {
    display: flex; }
  .form-group + .form-group_submit {
    margin-top: 2.8rem; }
  .form-group.form-file input {
    display: none; }
  .form-group.form-file label {
    display: flex;
    justify-content: space-between; }
  .form-group_xl:not(:first-child) {
    margin-top: 2rem; }
  .form-group_xxl:not(:first-child) {
    margin-top: 2.2rem; }
  .form-group_xxl + .form-group_submit {
    margin-top: 6.8rem; }
  .form-group.form-file input {
    display: none; }
  .form-group.form-file label {
    margin: 0; }
    .form-group.form-file label > span {
      text-transform: uppercase; }

.form-file__caption {
  font-size: 1.4rem;
  margin-top: .9rem;
  line-height: 1.6rem; }

.form-input input, .form-input textarea {
  width: 100%;
  border: none;
  outline: none;
  height: 2.2rem;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  overflow: hidden;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
  border-bottom: 1px solid #787878; }
  .form-input input::-webkit-input-placeholder, .form-input textarea::-webkit-input-placeholder {
    transition: opacity 0.3s ease; }
  .form-input input:-moz-placeholder, .form-input textarea:-moz-placeholder {
    transition: opacity 0.3s ease; }
  .form-input input::-moz-placeholder, .form-input textarea::-moz-placeholder {
    transition: opacity 0.3s ease; }
  .form-input input:-ms-input-placeholder, .form-input textarea:-ms-input-placeholder {
    transition: opacity 0.3s ease; }
  .form-input input::placeholder, .form-input textarea::placeholder {
    color: #787878; }
  .form-input input:focus::-webkit-input-placeholder, .form-input textarea:focus::-webkit-input-placeholder {
    opacity: 0; }
  .form-input input:focus::-moz-placeholder, .form-input textarea:focus::-moz-placeholder {
    opacity: 0; }
  .form-input input:focus:-moz-placeholder, .form-input textarea:focus:-moz-placeholder {
    opacity: 0; }

.form-input textarea {
  padding: 0;
  resize: none;
  padding-top: .6rem;
  min-height: 15.7rem;
  padding-bottom: .6rem; }

.form-input input[type=number]::-webkit-inner-spin-button,
.form-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.form-input.invalid input {
  border-bottom: 1px solid #EE2737; }

.form-input__value {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.6rem;
  position: absolute;
  line-height: 2.2rem; }

.form-input__placeholder {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  color: #787878;
  font-size: 1.6rem;
  visibility: hidden;
  position: absolute;
  line-height: 2.2rem;
  white-space: nowrap;
  text-overflow: ellipsis; }

.form-input_xxl input {
  height: 3.6rem; }

.form-input_xxl .form-input__value {
  line-height: 3.6rem; }

.form-input_xxl .form-input__placeholder {
  line-height: 3.6rem; }

.form-input_xl input {
  height: 3.4rem; }

.form-input_xl .form-input__value {
  line-height: 3.4rem; }

.form-input_xl .form-input__placeholder {
  line-height: 3.4rem; }

.form-input_md input {
  height: 3rem; }

.form-input_md .form-input__value {
  line-height: 3rem; }

.form-input_md .form-input__placeholder {
  line-height: 3rem; }

.form-input_sm input {
  height: 2.8rem; }

.form-input_sm .form-input__value {
  line-height: 2.8rem; }

.form-input_sm .form-input__placeholder {
  line-height: 2.8rem; }

.form-input_optional {
  position: relative; }
  .form-input_optional:after {
    left: 0;
    top: 100%;
    color: #787878;
    font-size: 1.4rem;
    margin-top: .3rem;
    position: absolute;
    line-height: 1.4rem;
    content: attr(data-optional); }

.form-box__title {
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .form-box__title {
      margin-bottom: 1.9rem; } }
  .form-box__title_lg {
    margin-bottom: 2.4rem; }

.form-box__content {
  padding-right: 1.8rem; }
  @media (max-width: 767px) {
    .form-box__content {
      padding-right: 0; } }
  .form-box__content .row {
    margin-left: -.9rem;
    margin-right: -.9rem; }
    .form-box__content .row .col {
      padding-left: .9rem;
      padding-right: .9rem; }
      @media (max-width: 767px) {
        .form-box__content .row .col:not(:last-child) {
          margin-bottom: 1.1rem; } }
      @media (max-width: 767px) {
        .form-box__content .row .col_xl:not(:last-child) {
          margin-bottom: 3.3rem; } }
    .form-box__content .row:not(:last-child) {
      margin-bottom: 2.1rem; }
    .form-box__content .row_xl:not(:last-child) {
      margin-bottom: 3.3rem; }

.form-box:not(:last-child) {
  margin-bottom: 4.6rem; }
  @media (max-width: 767px) {
    .form-box:not(:last-child) {
      margin-bottom: 6.9rem; } }

@media (max-width: 767px) {
  .form-box_submit {
    margin-top: -2.7rem; } }

.form-check {
  padding: 0; }
  .form-check label {
    margin: 0;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem; }
  .form-check input {
    display: none; }
    .form-check input:checked + span:after {
      border-radius: 50%;
      transform: rotateZ(45deg);
      background-color: #009BD7; }
  .form-check span {
    display: block;
    position: relative;
    padding-left: 2.6rem; }
    .form-check span:after {
      left: 0;
      top: .2rem;
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      position: absolute;
      background-color: #D8D8D8;
      transition: all 0.3s ease; }
  .form-check input[type="radio"] {
    display: none; }
    .form-check input[type="radio"]:checked + span:after {
      transform: none;
      border-radius: 50%;
      background-color: #D8D8D8; }
    .form-check input[type="radio"]:checked + span:before {
      opacity: 1; }
    .form-check input[type="radio"] + span:after {
      border-radius: 50%; }
    .form-check input[type="radio"] + span:before {
      top: .6rem;
      opacity: 0;
      z-index: 1;
      left: .4rem;
      content: '';
      width: .8rem;
      height: .8rem;
      display: block;
      border-radius: 50%;
      position: absolute;
      background-color: #FC6621;
      transition: all 0.3s ease; }
  .form-check_orange input {
    display: none; }
    .form-check_orange input:checked + span:after {
      background-color: #FC6621; }

.section-main-screen {
  height: 100vh;
  overflow: hidden;
  background-color: #009BD7; }

.main-screen__wrapper {
  padding-top: 32vh; }
  @media (max-width: 1439px) {
    .main-screen__wrapper {
      padding-top: 28vh; } }
  @media (max-width: 991px) {
    .main-screen__wrapper {
      padding-top: 36vh; } }
  @media (max-width: 767px) {
    .main-screen__wrapper {
      padding-top: 24vh; } }

.main-screen__content {
  position: relative;
  padding-right: 22rem; }
  @media (max-width: 991px) {
    .main-screen__content {
      padding-right: 18rem; } }
  @media (max-width: 767px) {
    .main-screen__content {
      padding-right: 0; } }

.main-screen__title.h-reset.section-title {
  z-index: 1;
  height: 25.6rem;
  position: relative;
  align-items: center; }
  @media (max-width: 991px) {
    .main-screen__title.h-reset.section-title {
      height: 17.6rem; } }
  @media (max-width: 767px) {
    .main-screen__title.h-reset.section-title {
      height: auto; } }
  .main-screen__title.h-reset.section-title h2 {
    font-size: 5.6rem;
    line-height: 6.4rem; }
    @media (max-width: 991px) {
      .main-screen__title.h-reset.section-title h2 {
        font-size: 4.2rem;
        line-height: 4.4rem; } }
    @media (max-width: 767px) {
      .main-screen__title.h-reset.section-title h2 {
        font-size: 3.2rem;
        line-height: 3.4rem; } }
    @media (max-width: 360px) {
      .main-screen__title.h-reset.section-title h2 {
        font-size: 3rem;
        line-height: 3.2rem; } }

.main-screen__view {
  top: 50%;
  left: 50%;
  width: 100rem;
  height: 100rem;
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 0.3rem solid #ffffff;
  transform: translate(-68%, -60%) rotateZ(39deg); }
  @media (max-width: 991px) {
    .main-screen__view {
      width: 80rem;
      height: 80rem;
      transform: translate(-65%, -52%) rotateZ(30deg); } }
  @media (max-width: 767px) {
    .main-screen__view {
      width: 60.1rem;
      height: 60.1rem;
      transform: translate(-75%, -55%) rotateZ(35deg); } }
  .main-screen__view i {
    top: 50%;
    left: 50%;
    height: 1px;
    width: 50rem;
    display: block;
    position: absolute;
    transform-origin: 0 0;
    background-color: transparent; }
    @media (max-width: 991px) {
      .main-screen__view i {
        width: 40rem; } }
    @media (max-width: 767px) {
      .main-screen__view i {
        width: 30.05rem; } }
    .main-screen__view i:after {
      top: -1rem;
      z-index: 1;
      content: '';
      width: 2rem;
      height: 2rem;
      right: -1rem;
      border-radius: 50%;
      position: absolute;
      background-color: #ffffff; }

.main-screen__more {
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100rem;
  display: flex;
  height: 100rem;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: flex-end;
  border: .3rem solid transparent;
  transform: translate(-68%, -60%) rotateZ(39deg); }
  @media (max-width: 991px) {
    .main-screen__more {
      width: 80rem;
      height: 80rem;
      transform: translate(-65%, -52%) rotateZ(30deg); } }
  @media (max-width: 767px) {
    .main-screen__more {
      width: 60.1rem;
      height: 60.1rem;
      transform: translate(-75%, -55%) rotateZ(35deg); } }
  .main-screen__more a {
    position: relative;
    margin-top: -2.6rem;
    margin-right: -2.6rem;
    transform: rotateZ(-39deg);
    transform-origin: 100% 50%; }
    @media (max-width: 991px) {
      .main-screen__more a {
        transform: rotateZ(-30deg); } }
    @media (max-width: 767px) {
      .main-screen__more a {
        transform: rotateZ(-35deg); } }
    .main-screen__more a i {
      width: 5.2rem;
      height: 5.2rem;
      position: relative;
      border-radius: 50%;
      margin-left: 1.8rem;
      background-color: #ffffff; }
      .main-screen__more a i svg {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        fill: #009BD7;
        position: absolute; }

.filters__menu-wrapper {
  position: fixed;
  height: 100vh;
  width: calc(100vw - 22.8rem);
  top: 0;
  left: 22.8rem;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 13;
  display: none; }
  @media (max-width: 1439px) {
    .filters__menu-wrapper {
      width: 100%;
      left: 0;
      height: calc(100% - 7.4rem);
      top: 7.4rem; } }

@media (max-width: 991px) {
  .filters__form {
    display: flex;
    flex-wrap: wrap; } }

.filters__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3.2rem;
  margin-bottom: 2rem;
  width: 100%; }
  @media (max-width: 1199px) {
    .filters__header {
      margin-bottom: 0;
      padding: 3.2rem 3.2rem 0 3.2rem; } }
  @media (max-width: 767px) {
    .filters__header {
      height: 6.7rem;
      justify-content: center;
      padding: 0;
      order: 1;
      background-color: #000;
      border-top: 1px solid rgba(255, 255, 255, 0.3); } }
  .filters__header.active .filters__clear {
    opacity: 1;
    transition: opacity .3s ease 1.3s, color .3s ease; }
    @media (max-width: 767px) {
      .filters__header.active .filters__clear {
        transition: opacity .3s ease; } }
  .filters__header.active .filters__submit {
    opacity: 1;
    transition: opacity .3s ease 1.3s, color .3s ease; }
    @media (max-width: 767px) {
      .filters__header.active .filters__submit {
        transition: opacity .3s ease, color .3s ease; } }
  .filters__header.active .filters__close {
    opacity: 1;
    transition: opacity .3s ease 1.3s; }

.filters__clear, .filters__submit {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 900;
  cursor: pointer; }

.filters__clear {
  color: #ffffff;
  margin-right: 19px;
  opacity: 0;
  transition: opacity .3s ease 1.1s, color .3s ease; }
  @media (max-width: 767px) {
    .filters__clear {
      transition: opacity .3s ease 1.4s, color .3s ease; } }
  @media (min-width: 992px) {
    .filters__clear:hover {
      color: rgba(255, 255, 255, 0.8); } }

.filters__submit {
  color: #009BD7;
  margin-right: 24px;
  opacity: 0;
  transition: opacity .3s ease .2s, color .3s ease; }
  @media (max-width: 767px) {
    .filters__submit {
      transition: opacity .3s ease 1.4s, color .3s ease; } }
  @media (min-width: 992px) {
    .filters__submit:hover {
      color: #0081b3; } }

.filters__close {
  width: 4.7rem;
  height: 4.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  transition: opacity .3s ease .2s; }
  @media (max-width: 767px) {
    .filters__close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem; } }
  @media (max-width: 479px) {
    .filters__close {
      top: 0;
      right: 0; } }
  @media (min-width: 992px) {
    .filters__close:hover i:after, .filters__close:hover i:before {
      background-color: rgba(255, 255, 255, 0.8); } }
  .filters__close i {
    width: 1.6rem;
    height: 2px;
    display: block;
    background-color: transparent;
    position: relative; }
    .filters__close i:after, .filters__close i:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: background-color .3s ease; }
  .filters__close i:after, .filters__close i:before {
    background-color: #ffffff; }
  .filters__close i:before {
    top: 0;
    transform: rotate(45deg); }
  .filters__close i:after {
    bottom: 0;
    transform: rotate(-45deg); }

.filters__content {
  padding: 0 10.2rem 5rem 10.2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto; }
  @media (max-width: 1199px) {
    .filters__content {
      padding: 0 5rem 4rem 5rem;
      flex-wrap: wrap; } }
  @media (max-width: 991px) {
    .filters__content {
      width: 100%;
      height: calc(100vh - 14.1rem);
      overflow-y: auto; } }
  @media (max-width: 767px) {
    .filters__content {
      padding: 0 3rem 4rem 3rem; } }

.filters__list {
  margin: 0;
  padding: 0;
  width: 100%; }
  @media (max-width: 1199px) {
    .filters__list-wrap {
      margin-top: 5rem; } }
  .filters__list-wrap.active .filters__list-title {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s ease-in-out .3s, opacity .4s ease-in-out .3s; }
  .filters__list-wrap.active .filters__list-item:after {
    width: 100%;
    transition: width .3s ease-in-out .6s; }
  .filters__list-wrap.active .filters__list-name {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s ease-in-out .9s, opacity .4s ease-in-out .9s; }
  .filters__list-wrap.active .filters__list-label .icon-check {
    transform: translateY(0px);
    transition: transform .3s ease-in-out .9s, opacity .3s ease-in-out; }
  .filters__list-wrap.list-conferences {
    width: 327px; }
    @media (max-width: 1199px) {
      .filters__list-wrap.list-conferences {
        width: 100%; } }
  .filters__list-wrap.list-types {
    width: 242px; }
    @media (max-width: 1199px) {
      .filters__list-wrap.list-types {
        width: 100%; } }
  .filters__list-wrap.list-fields {
    width: 242px; }
    @media (max-width: 1199px) {
      .filters__list-wrap.list-fields {
        width: 100%; } }
  .filters__list-title {
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 3.2rem;
    opacity: 0;
    transform: translateY(15px);
    transition: transform .3s ease-in-out .5s, opacity .4s ease-in-out .5s; }
    @media (max-width: 991px) {
      .filters__list-title {
        margin-bottom: 1.5rem; } }
  .filters__list-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    opacity: 1; }
    .filters__list-item:after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.3);
      transition: width .3s ease-in-out .8s; }
    .filters__list-item:last-child:after {
      display: none; }
  .filters__list-name {
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #ffffff;
    display: block;
    margin-right: 10px;
    opacity: 0;
    transform: translateY(calc(100% + 19px));
    transition: transform .3s ease-in-out 1.1s, opacity .4s ease-in-out 1.1s; }
  .filters__list-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    padding-top: 15px;
    margin-bottom: 0;
    cursor: pointer; }
    @media (min-width: 992px) {
      .filters__list-label:hover .icon-check {
        opacity: 1; } }
    .filters__list-label input {
      width: 0;
      height: 0;
      position: absolute;
      left: -9990em;
      top: -9999em;
      opacity: 0;
      visibility: hidden; }
      .filters__list-label input:checked + .icon-check {
        opacity: 1; }
    .filters__list-label .icon-check {
      font-size: 1.2rem;
      margin-left: auto;
      display: block;
      color: #ffffff;
      opacity: 0;
      flex-shrink: 0;
      transform: translateY(calc(100% + 30px));
      transition: transform .3s ease-in-out 1.1s, opacity .3s ease-in-out 1.1s; }

.section-title {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .section-title {
      flex-direction: column; } }
  .section-title a {
    font-size: 1.6rem;
    line-height: 1.6rem; }
  .section-title_inline {
    align-items: center; }
    @media (max-width: 991px) {
      .section-title_inline {
        align-items: flex-start; } }
    .section-title_inline .section-title__main {
      width: auto;
      margin-right: 1.6rem; }
    .section-title_inline .section-title__minor {
      width: auto;
      flex-shrink: 0;
      white-space: nowrap; }
  .section-title_down {
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .section-title_down {
        align-items: flex-start; } }
    .section-title_down .section-title__main {
      width: 65%; }
      @media (max-width: 991px) {
        .section-title_down .section-title__main {
          width: 100%; } }
    .section-title_down .section-title__minor {
      width: 32%; }
      @media (max-width: 991px) {
        .section-title_down .section-title__minor {
          width: 100%; } }

.section-title__main {
  width: 70%;
  z-index: 1;
  position: relative; }
  @media (max-width: 991px) {
    .section-title__main {
      width: 100%; } }
  .section-title__main h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: 4.8rem;
    line-height: 5.2rem; }
    @media (max-width: 1199px) {
      .section-title__main h2 {
        font-size: 4.3rem;
        line-height: 4.8rem; } }
    @media (max-width: 991px) {
      .section-title__main h2 {
        font-size: 3.8rem;
        line-height: 4.4rem; } }
    @media (max-width: 767px) {
      .section-title__main h2 {
        font-size: 3.2rem;
        line-height: 3.4rem; } }
    .section-title__main h2 + a {
      margin-top: 1.9rem; }
  .section-title__main h5 {
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .section-title__main h5 {
        margin-top: 1.7rem; } }

.section-title__minor {
  width: 30%;
  z-index: 1;
  position: relative; }
  @media (max-width: 991px) {
    .section-title__minor {
      width: 100%;
      margin-top: 3.2rem; } }
  .section-title__minor h3 {
    margin: 0;
    font-size: 2.4rem;
    padding-right: 10%;
    line-height: 2.8rem; }
    .section-title__minor h3 + a {
      margin-top: 2.9rem; }
      @media (max-width: 767px) {
        .section-title__minor h3 + a {
          margin-top: 2.2rem; } }

.conference-box {
  width: 100%;
  height: 50rem;
  display: block;
  position: relative; }
  @media (max-width: 767px) {
    .conference-box {
      height: 100%; } }
  @media (max-width: 678px) {
    .conference-box {
      height: auto; } }
  @media (min-width: 992px) {
    .conference-box:hover .conference-box__content:before {
      transform: translateX(0); } }
  .conference-box:nth-child(3n + 3) {
    margin-right: 0; }
  .conference-box.active .conference-box__form {
    display: block; }
  .conference-box.orange .conference-box__content {
    background-color: #FC6621; }
    .conference-box.orange .conference-box__content:before {
      background-color: #8a9297; }
  .conference-box.blue .conference-box__content {
    background-color: #107BD2; }
    .conference-box.blue .conference-box__content:before {
      background-color: #41b6e6; }
  .conference-box.green .conference-box__content {
    background-color: #70CC61; }
    .conference-box.green .conference-box__content:before {
      background-color: #ece81a; }
  .conference-box.light-green .conference-box__content {
    background-color: #48D597; }
    .conference-box.light-green .conference-box__content:before {
      background-color: #ee2737; }
  .conference-box.light-violet .conference-box__content {
    background-color: #8787D5; }
    .conference-box.light-violet .conference-box__content:before {
      background-color: #8bd3e6; }
  .conference-box.light-orange .conference-box__content {
    background-color: #F2A900; }
    .conference-box.light-orange .conference-box__content:before {
      background-color: #7474c1; }
  .conference-box.violet .conference-box__content {
    background-color: #8F60E2; }
    .conference-box.violet .conference-box__content:before {
      background-color: #8a9297; }
  .conference-box.light-blue .conference-box__content {
    background-color: #69B3E7; }
    .conference-box.light-blue .conference-box__content:before {
      background-color: #97d700; }
  .conference-box.wish-box .conference-box__content {
    background-color: #ffffff; }
  .conference-box.wish-box .conference-box__info {
    margin-bottom: 6.4rem;
    font-weight: 400; }
  .conference-box__wrapp {
    font-size: 0;
    width: 100.7rem;
    margin-right: -1.6rem; }
  .conference-box__open, .conference-box__close {
    top: 0;
    right: 0;
    width: 4.8rem;
    display: flex;
    height: 4.8rem;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #000000; }
    @media (max-width: 767px) {
      .conference-box__open, .conference-box__close {
        display: none; } }
    .conference-box__open i, .conference-box__close i {
      width: 1.6rem;
      height: 2px;
      display: block;
      background-color: #ffffff;
      position: relative;
      transition: background-color 0.3s ease; }
      .conference-box__open i:after, .conference-box__open i:before, .conference-box__close i:after, .conference-box__close i:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute; }
  .conference-box__open i:after, .conference-box__open i:before {
    left: 0;
    background-color: inherit; }
  .conference-box__open i:before {
    top: -6px; }
  .conference-box__open i:after {
    bottom: -6px; }
  .conference-box__close {
    transform: scale(0);
    opacity: 0;
    transition: transform .3s ease .3s, opacity .4s ease; }
    .conference-box__close.active {
      opacity: 1;
      transform: scale(1);
      transition: transform .1s ease; }
    .conference-box__close i {
      background-color: transparent; }
      .conference-box__close i:after, .conference-box__close i:before {
        background-color: #ffffff; }
      .conference-box__close i:before {
        top: 0;
        transform: rotate(45deg); }
      .conference-box__close i:after {
        bottom: 0;
        transform: rotate(-45deg); }
  .conference-box__content {
    overflow: hidden;
    position: relative;
    height: calc(100% - 5.6rem);
    padding: 4rem 3.2rem 3.2rem 3.2rem; }
    @media (max-width: 767px) {
      .conference-box__content {
        height: 100%;
        padding: 2.4rem;
        min-height: 31rem;
        padding-bottom: 10.4rem; } }
    .conference-box__content:before {
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      display: block;
      width: 11.6rem;
      position: absolute;
      transform: translateX(-11.6rem);
      transition: transform .3s ease-in-out; }
      @media (max-width: 767px) {
        .conference-box__content:before {
          width: 6.9rem;
          transform: translateX(0); } }
  .conference-box__num-wrapp {
    z-index: 1;
    color: #000000;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: .8rem; }
    @media (max-width: 767px) {
      .conference-box__num-wrapp {
        margin-bottom: 1.4rem; } }
    .conference-box__num-wrapp h3 {
      font-size: 3.2rem;
      line-height: 3rem; }
    .conference-box__num-wrapp span {
      font-weight: 700;
      font-size: 1.2rem;
      margin-top: .4rem;
      margin-left: .8rem;
      line-height: 1.3rem;
      text-transform: uppercase; }
  .conference-box__title {
    z-index: 1;
    position: relative;
    text-transform: uppercase; }
    @media (max-width: 1199px) {
      .conference-box__title h3 {
        font-size: 2.4rem;
        line-height: 2.8rem; } }
  .conference-box__info {
    z-index: 1;
    color: #231F20;
    font-weight: 500;
    font-size: 1.6rem;
    position: relative;
    margin-top: 1.7rem;
    line-height: 1.8rem; }
  .conference-box__footer {
    display: flex;
    align-items: center;
    background-color: #000000;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .conference-box__footer {
        left: 2.4rem;
        right: 2.4rem;
        bottom: 2.4rem;
        position: absolute;
        justify-content: flex-start;
        background-color: transparent; } }
    .conference-box__footer a {
      width: 100%;
      color: #ffffff;
      height: 5.6rem;
      font-weight: 900;
      font-size: 1.6rem;
      position: relative;
      text-align: center;
      line-height: 5.6rem;
      text-decoration: none;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .conference-box__footer a {
          width: auto;
          color: #000000;
          height: 3.2rem;
          line-height: 3.2rem; } }
      @media (max-width: 767px) {
        .conference-box__footer a + a {
          margin-left: 3.2rem; } }
      .conference-box__footer a + a:before {
        width: 2px;
        top: 2.1rem;
        content: '';
        left: -.1rem;
        bottom: 1.8rem;
        display: block;
        position: absolute;
        pointer-events: none;
        background-color: #ffffff; }
        @media (max-width: 767px) {
          .conference-box__footer a + a:before {
            top: .8rem;
            width: 1px;
            left: -1.6rem;
            bottom: 0.7rem;
            background-color: #000000; } }
  .conference-box__submit {
    width: 100%;
    height: 5.6rem;
    background-color: rgba(35, 31, 32, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.6rem; }
  .conference-box__form-title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #787878; }
  .conference-box__text {
    margin-top: 1rem;
    resize: none;
    border: none;
    border-bottom: 1px solid #787878;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; }
    .conference-box__text:focus {
      border-color: transparent; }
  .conference-box__menu {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    background-color: #000000; }
    .conference-box__menu-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end; }
  .conference-box__list {
    width: 100%;
    padding: 0 3.2rem;
    margin: 5rem 0 0 0; }
    @media (min-width: 992px) {
      .conference-box__list:hover .conference-box__list-item {
        opacity: 0.5; } }
    .conference-box__list.active .conference-box__list-item {
      width: 100%;
      transition: opacity 0.3s ease, width 0.25s ease-in-out 0.1s; }
      .conference-box__list.active .conference-box__list-item a {
        transform: translateY(0);
        opacity: 1;
        transition: transform .35s ease-in-out .3s, opacity .5s ease-in-out .3s; }
    .conference-box__list-item {
      font-size: 1.6rem;
      font-weight: 500;
      color: #ffffff;
      letter-spacing: 0.5px;
      display: block;
      padding: 0 0 10px 0;
      margin-bottom: 5px;
      position: relative;
      opacity: 1;
      width: 0;
      overflow: hidden;
      transition: opacity 0.3s ease, width 0.35s ease-in-out; }
      @media (min-width: 992px) {
        .conference-box__list-item:hover {
          opacity: 1 !important; } }
      .conference-box__list-item:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #ffffff; }
      .conference-box__list-item a {
        text-decoration: none;
        color: #fff;
        white-space: nowrap;
        display: block;
        opacity: 0;
        transition: transform .4s ease-in-out, opacity .25s ease-in-out;
        transform: translateY(calc(100% + 15px)); }

.conference-box__form {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: none;
  position: absolute;
  background-color: #ffffff; }
  @media (max-width: 1199px) {
    .conference-box__form {
      position: static; } }
  @media (max-width: 991px) {
    .conference-box__form {
      position: static; } }
  @media (max-width: 767px) {
    .conference-box__form {
      position: static; } }
  .conference-box__form .conference-box__content {
    background-color: #ffffff !important; }

.conference-box__form-content {
  height: 100%;
  display: flex;
  padding: 3.2rem;
  padding-bottom: 0;
  padding-top: 4rem;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .conference-box__form-content {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0; } }

.conference-box__form-content-title {
  flex-shrink: 0;
  overflow: hidden;
  max-height: 8.4rem; }

.conference-box__form-content-caption {
  height: 8.8rem;
  flex-shrink: 0;
  overflow: hidden;
  font-size: 1.6rem;
  margin-top: 1.1rem;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .conference-box__form-content-caption {
      height: 4.4rem; } }

.conference-box__form-content-fields {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .conference-box__form-content-fields textarea {
    min-height: 5.8rem; }
    @media (max-width: 767px) {
      .conference-box__form-content-fields textarea {
        min-height: 8.2rem; } }
  .conference-box__form-content-fields .form-group label.invalid {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  @media (max-width: 767px) {
    .conference-box__form-content-fields .form-group + .form-group_submit {
      margin-top: 2rem; } }
  .conference-box__form-content-fields .form-group_submit {
    margin-left: -3.2rem;
    margin-right: -3.2rem; }
    @media (max-width: 767px) {
      .conference-box__form-content-fields .form-group_submit {
        margin-left: 0;
        margin-right: 0; } }

.section-leaders {
  padding-top: 7.5rem;
  padding-bottom: 5.6rem;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .section-leaders {
      padding-top: 3.2rem;
      padding-bottom: 6.4rem; } }
  .section-leaders.section_pink {
    background-color: #F5F5F5; }
  .section-leaders.conf-trusted .slick-dots li.slick-active button {
    background-color: #FC6621; }

.leaders__content {
  margin-top: 7rem; }
  @media (max-width: 767px) {
    .leaders__content {
      margin-top: 3.7rem; } }

.leaders__carousel {
  position: relative; }

.leaders__carousel-item {
  padding-right: 4.5rem; }
  @media (max-width: 1199px) {
    .leaders__carousel-item {
      padding-right: 2.4rem; } }

.leaders-box {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .leaders-box {
      flex-direction: column; } }
  .leaders-box_lg .leaders-box__view {
    width: 15.3rem;
    height: 15.3rem; }
    .leaders-box_lg .leaders-box__view img {
      width: 100%;
      height: 100%; }
  .leaders-box_lg .leaders-box__details {
    padding-top: 6rem; }

.leaders-box__view {
  width: 6.9rem;
  height: 6.9rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1.5rem; }
  @media (max-width: 991px) {
    .leaders-box__view {
      width: 9.2rem;
      height: 9.2rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3.5rem; } }
  @media (max-width: 767px) {
    .leaders-box__view {
      width: 6.9rem;
      height: 6.9rem; } }
  .leaders-box__view picture {
    width: 100%;
    height: 100%;
    display: block;
    position: relative; }
    .leaders-box__view picture img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute; }

.leaders-box__details {
  padding-top: .7rem; }

.leaders-box__name {
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-transform: uppercase; }

.leaders-box__position {
  font-size: 1.6rem;
  margin-top: .2rem;
  line-height: 2.2rem; }

.leaders-box__direction {
  font-weight: 900;
  font-size: 2.4rem;
  margin-top: .3rem;
  line-height: 2.8rem; }
  @media (max-width: 767px) {
    .leaders-box__direction {
      font-size: 2rem;
      line-height: 2.2rem; } }

.leaders-box__quote {
  font-size: 1.6rem;
  margin-top: 1.7rem;
  line-height: 2.2rem; }
  @media (max-width: 991px) {
    .leaders-box__quote {
      font-size: 2rem;
      margin-top: 2rem;
      line-height: 2.8rem; } }

.leaders-box__action {
  margin-top: 2.6rem; }
  @media (max-width: 991px) {
    .leaders-box__action {
      margin-top: 2.9rem; } }

.leaders__carousel-control {
  display: flex;
  margin-top: 7.9rem;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991px) {
    .leaders__carousel-control {
      left: 0;
      right: 0;
      margin: 0;
      top: 3.7rem;
      position: absolute;
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .leaders__carousel-control {
      top: 2.5rem; } }

.leaders__carousel-action button, .partner__carousel-action button {
  display: block;
  position: relative;
  transition: all 0.3s ease; }
  .leaders__carousel-action button:after, .partner__carousel-action button:after {
    top: 50%;
    left: 50%;
    content: '';
    width: 3.2rem;
    height: 3.2rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    margin-top: -1.6rem;
    margin-left: -1.6rem;
    background-color: transparent; }
  @media (min-width: 992px) {
    .leaders__carousel-action button:hover, .partner__carousel-action button:hover {
      transform: translateX(0.8rem); } }
  .leaders__carousel-action button svg, .partner__carousel-action button svg {
    display: block;
    font-size: 1.8rem; }
  .leaders__carousel-action button.prev svg, .partner__carousel-action button.prev svg {
    transform: rotateZ(180deg); }
  @media (min-width: 992px) {
    .leaders__carousel-action button.prev:hover, .partner__carousel-action button.prev:hover {
      transform: translateX(-0.8rem); } }

.leaders__carousel-dots {
  margin-left: 3.2rem;
  margin-right: 3.2rem; }
  @media (max-width: 991px) {
    .leaders__carousel-dots {
      display: none; } }

.slick-dots {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none; }
  .slick-dots li:not(:last-child) {
    margin-right: 1.6rem; }
  .slick-dots li button {
    font-size: 0;
    width: .8rem;
    height: .8rem;
    display: block;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: #cbcaca; }
  .slick-dots li.slick-active button {
    background-color: #009BD7; }

.video-mini__section {
  width: 65.5rem;
  height: 14.4rem;
  z-index: 10;
  position: fixed;
  bottom: 3.3rem;
  right: 3.2rem;
  display: none;
  transition: transform .3s ease; }
  @media (min-width: 768px) {
    .video-mini__section {
      transform: translate(0, 100px); } }
  @media (max-width: 991px) {
    .video-mini__section {
      width: 58rem;
      height: 14rem;
      bottom: 2rem;
      right: 2rem; } }
  @media (max-width: 767px) {
    .video-mini__section {
      transform: translate(0, 0);
      height: 7rem;
      width: 100%;
      bottom: 7rem;
      left: 0;
      right: auto; } }
  .video-mini__section.active {
    transform: translate(0, 0); }

.video-mini__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  transform: none;
  left: 0; }
  .video-mini__wrapper.video-content:after {
    display: none; }
  .video-mini__wrapper .link {
    text-transform: uppercase;
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
    z-index: 3;
    transition: none; }
    @media (max-width: 991px) {
      .video-mini__wrapper .link {
        right: 2rem;
        bottom: 2rem; } }
    @media (max-width: 767px) {
      .video-mini__wrapper .link {
        position: relative;
        right: auto;
        bottom: auto; } }
    @media (max-width: 479px) {
      .video-mini__wrapper .link {
        font-size: 1.4rem; } }
  .video-mini__wrapper .video-content-box {
    height: 100%;
    width: 24rem;
    overflow: hidden;
    position: relative; }
    @media (max-width: 991px) {
      .video-mini__wrapper .video-content-box {
        width: 22rem; } }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content-box {
        position: static;
        width: 14rem; } }
    @media (max-width: 479px) {
      .video-mini__wrapper .video-content-box {
        width: 10.5rem; } }
  @media (max-width: 767px) {
    .video-mini__wrapper .video-content__inner {
      position: relative !important;
      object-fit: unset;
      bottom: auto;
      top: auto;
      left: auto;
      right: auto; } }
  .video-mini__wrapper .video-content__seek-bar {
    display: block;
    opacity: 1;
    visibility: visible; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__seek-bar {
        position: fixed;
        width: 100%;
        bottom: -5px;
        display: block;
        opacity: 1;
        height: 5px;
        background-color: #009bdf; } }
  .video-mini__wrapper .video-content__full-screen {
    display: block;
    opacity: 1;
    visibility: visible; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__full-screen {
        display: none !important; } }
  @media (max-width: 767px) {
    .video-mini__wrapper .video-content__play {
      opacity: 1;
      width: 3.4rem;
      height: 3.4rem;
      position: relative;
      top: auto;
      left: auto; } }
  @media (max-width: 479px) {
    .video-mini__wrapper .video-content__play {
      width: 3rem;
      height: 3rem; } }
  @media (max-width: 479px) {
    .video-mini__wrapper .video-content__play .icon-play, .video-mini__wrapper .video-content__play .icon-pause-path {
      font-size: 3rem; } }
  .video-mini__wrapper .video-content__controls {
    width: 24rem; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__controls {
        width: auto;
        left: auto;
        top: auto;
        position: relative;
        display: flex;
        align-items: center; } }

.video-mini__info {
  width: calc(100% - 24rem);
  background-color: #000000;
  padding: 2.1rem 6rem 5.1rem 2.4rem; }
  @media (max-width: 991px) {
    .video-mini__info {
      width: calc(100% - 22rem);
      padding: 1.6rem 5rem 5.1rem 2rem; } }
  @media (max-width: 767px) {
    .video-mini__info {
      width: calc(100% - 14rem);
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  @media (max-width: 479px) {
    .video-mini__info {
      padding: 0 1rem;
      width: calc(100% - 10.5rem); } }

.video-mini__name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: #ffffff; }
  @media (max-width: 767px) {
    .video-mini__name {
      display: none; } }

.video-mini__close {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.3rem;
  right: 1.6rem;
  z-index: 1; }
  @media (max-width: 767px) {
    .video-mini__close {
      position: relative;
      right: auto;
      top: auto; } }
  .video-mini__close:after, .video-mini__close:before {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%; }
  .video-mini__close:before {
    transform: translate(-50%, -50%) rotate(-45deg); }
  .video-mini__close:after {
    transform: translate(-50%, -50%) rotate(45deg); }

.video-mini__minimize {
  width: 3rem;
  height: 3rem;
  top: -3rem;
  right: -3rem;
  display: none; }
  .video-mini__minimize:after, .video-mini__minimize:before {
    height: 2rem; }

.section-question {
  padding-top: 12.2rem;
  padding-bottom: 17.5rem;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .section-question {
      padding-top: 1.8rem;
      padding-bottom: 5rem; } }
  .section-question .section-content {
    display: flex;
    padding-right: 8.4rem;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .section-question .section-content {
        padding-right: 0;
        flex-direction: column; } }
  .section-question .section-title {
    position: relative;
    padding-right: 3.2rem; }
    .section-question .section-title:after {
      top: -103%;
      right: 59%;
      content: '';
      width: 46rem;
      height: 46rem;
      display: block;
      border-radius: 50%;
      position: absolute;
      border: 0.3rem solid #009BDF; }
      @media (max-width: 991px) {
        .section-question .section-title:after {
          top: -150%;
          right: 50%; } }
      @media (max-width: 767px) {
        .section-question .section-title:after {
          display: none; } }
    .section-question .section-title__main {
      width: 100%; }

.question__content {
  z-index: 1;
  width: 41.3rem;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  position: relative; }
  @media (max-width: 991px) {
    .question__content {
      width: 100%;
      margin-top: 4.2rem; } }
  .question__content ul li:not(:last-child) {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #7A7A7A; }

.video-fs-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: none; }

.video-fs-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .video-fs-inner .video-content {
    width: auto;
    height: auto;
    max-height: calc(100vh - 80px);
    max-width: calc(100vw - 80px); }
    .video-fs-inner .video-content:after {
      display: none; }
    .video-fs-inner .video-content .link {
      text-transform: uppercase;
      position: absolute;
      z-index: 10;
      right: 23px;
      bottom: 50px; }
    .video-fs-inner .video-content__inner {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative !important; }
    .video-fs-inner .video-content__seek-bar {
      display: block; }
      .video-fs-inner .video-content__seek-bar-wrap {
        position: absolute;
        bottom: 0;
        height: 45px;
        width: 100%;
        z-index: 50; }
    .video-fs-inner .video-content__play .icon-pause-path, .video-fs-inner .video-content__play .icon-play, .video-fs-inner .video-content__play .icon-big-play, .video-fs-inner .video-content__play .icon-pause {
      font-size: 6.6rem !important; }
    @media (max-width: 767px) {
      .video-fs-inner .video-content__close {
        position: absolute;
        right: auto;
        top: -6rem;
        left: 50%;
        width: 5rem;
        height: 5rem;
        transform: translateX(-50%); } }

.section-platforms {
  padding-top: 10rem;
  padding-bottom: 12.6rem; }
  @media (max-width: 991px) {
    .section-platforms {
      background-color: #F5F5F5; } }
  @media (max-width: 767px) {
    .section-platforms {
      padding-top: 4.3rem;
      padding-bottom: 9.4rem; } }

.platforms__content {
  margin-top: 6.1rem; }
  @media (max-width: 767px) {
    .platforms__content {
      margin-top: 6.4rem; } }

.platforms__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-bottom: -2rem; }
  @media (max-width: 991px) {
    .platforms__items {
      width: 100vw;
      margin-bottom: 0;
      margin-left: calc((100vw - 70.8rem) / -2); } }
  @media (max-width: 767px) {
    .platforms__items {
      width: auto;
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }

.platforms__item {
  padding-left: 1.6rem;
  margin-bottom: 2rem; }
  @media (max-width: 991px) {
    .platforms__item {
      opacity: .5;
      margin-bottom: 0;
      transition: opacity .3s ease; }
      .platforms__item.slick-active.slick-center {
        opacity: 1; } }

.platform-box {
  display: block;
  width: 15.4rem;
  height: 15.4rem;
  padding: 1.6rem;
  overflow: hidden;
  position: relative;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .platform-box:hover .platform-box__case {
      transform: translateX(0); } }
  @media (max-width: 991px) {
    .platform-box {
      width: 20.8rem;
      height: 20.8rem; } }

.platform-box__view {
  width: 100%;
  height: 100%;
  display: block;
  position: relative; }
  .platform-box__view picture {
    width: 100%;
    height: 100%;
    display: block;
    position: relative; }
    .platform-box__view picture img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      /* filter: grayscale(100%); */ }
      @media (max-width: 991px) {
        .platform-box__view picture img {
          max-width: 90%;
          max-height: 90%; } }

.platform-box__case {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  padding: 1.6rem;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #009BDF;
  transition: all 0.375s ease;
  transform: translateX(-100%); }
  @media (max-width: 991px) {
    .platform-box__case {
      transform: none;
      background-color: transparent; } }

.platform-box__case-btn {
  color: #ffffff;
  font-weight: 900;
  font-size: 1.6rem;
  align-items: center;
  line-height: 1.6rem;
  display: inline-flex; }
  .platform-box__case-btn svg {
    fill: #ffffff;
    font-size: 1.8rem;
    margin-left: .8rem; }
  @media (max-width: 991px) {
    .platform-box__case-btn {
      color: #000000; }
      .platform-box__case-btn svg {
        fill: #000000; } }

.section-news-list {
  padding-top: 7.9rem;
  padding-bottom: 7.9rem;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .section-news-list {
      padding-top: 6.2rem;
      padding-bottom: 3.6rem; } }

.news-list__content {
  overflow: hidden;
  margin-top: 3.4rem; }

.news-list__items {
  display: flex;
  align-items: center;
  margin-left: -1.6rem; }
  .news-list__items .news-box {
    width: 33.33333%;
    padding-left: 1.6rem; }

.video-mini__section {
  width: 65.5rem;
  height: 14.4rem;
  z-index: 10;
  position: fixed;
  bottom: 3.3rem;
  right: 3.2rem;
  display: none;
  transition: transform .3s ease; }
  @media (min-width: 768px) {
    .video-mini__section {
      transform: translate(0, 100px); } }
  @media (max-width: 991px) {
    .video-mini__section {
      width: 58rem;
      height: 14rem;
      bottom: 2rem;
      right: 2rem; } }
  @media (max-width: 767px) {
    .video-mini__section {
      transform: translate(0, 0);
      height: 7rem;
      width: 100%;
      bottom: 7rem;
      left: 0;
      right: auto; } }
  .video-mini__section.active {
    transform: translate(0, 0); }

.video-mini__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  transform: none;
  left: 0; }
  .video-mini__wrapper.video-content:after {
    display: none; }
  .video-mini__wrapper .link {
    text-transform: uppercase;
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
    z-index: 3;
    transition: none; }
    @media (max-width: 991px) {
      .video-mini__wrapper .link {
        right: 2rem;
        bottom: 2rem; } }
    @media (max-width: 767px) {
      .video-mini__wrapper .link {
        position: relative;
        right: auto;
        bottom: auto; } }
    @media (max-width: 479px) {
      .video-mini__wrapper .link {
        font-size: 1.4rem; } }
  .video-mini__wrapper .video-content-box {
    height: 100%;
    width: 24rem;
    overflow: hidden;
    position: relative; }
    @media (max-width: 991px) {
      .video-mini__wrapper .video-content-box {
        width: 22rem; } }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content-box {
        position: static;
        width: 14rem; } }
    @media (max-width: 479px) {
      .video-mini__wrapper .video-content-box {
        width: 10.5rem; } }
  @media (max-width: 767px) {
    .video-mini__wrapper .video-content__inner {
      position: relative !important;
      object-fit: unset;
      bottom: auto;
      top: auto;
      left: auto;
      right: auto; } }
  .video-mini__wrapper .video-content__seek-bar {
    display: block;
    opacity: 1;
    visibility: visible; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__seek-bar {
        position: fixed;
        width: 100%;
        bottom: -5px;
        display: block;
        opacity: 1;
        height: 5px;
        background-color: #009bdf; } }
  .video-mini__wrapper .video-content__full-screen {
    display: block;
    opacity: 1;
    visibility: visible; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__full-screen {
        display: none !important; } }
  @media (max-width: 767px) {
    .video-mini__wrapper .video-content__play {
      opacity: 1;
      width: 3.4rem;
      height: 3.4rem;
      position: relative;
      top: auto;
      left: auto; } }
  @media (max-width: 479px) {
    .video-mini__wrapper .video-content__play {
      width: 3rem;
      height: 3rem; } }
  @media (max-width: 479px) {
    .video-mini__wrapper .video-content__play .icon-play, .video-mini__wrapper .video-content__play .icon-pause-path {
      font-size: 3rem; } }
  .video-mini__wrapper .video-content__controls {
    width: 24rem; }
    @media (max-width: 767px) {
      .video-mini__wrapper .video-content__controls {
        width: auto;
        left: auto;
        top: auto;
        position: relative;
        display: flex;
        align-items: center; } }

.video-mini__info {
  width: calc(100% - 24rem);
  background-color: #000000;
  padding: 2.1rem 6rem 5.1rem 2.4rem; }
  @media (max-width: 991px) {
    .video-mini__info {
      width: calc(100% - 22rem);
      padding: 1.6rem 5rem 5.1rem 2rem; } }
  @media (max-width: 767px) {
    .video-mini__info {
      width: calc(100% - 14rem);
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  @media (max-width: 479px) {
    .video-mini__info {
      padding: 0 1rem;
      width: calc(100% - 10.5rem); } }

.video-mini__name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: #ffffff; }
  @media (max-width: 767px) {
    .video-mini__name {
      display: none; } }

.video-mini__close {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.3rem;
  right: 1.6rem;
  z-index: 1; }
  @media (max-width: 767px) {
    .video-mini__close {
      position: relative;
      right: auto;
      top: auto; } }
  .video-mini__close:after, .video-mini__close:before {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%; }
  .video-mini__close:before {
    transform: translate(-50%, -50%) rotate(-45deg); }
  .video-mini__close:after {
    transform: translate(-50%, -50%) rotate(45deg); }

.video-mini__minimize {
  width: 3rem;
  height: 3rem;
  top: -3rem;
  right: -3rem;
  display: none; }
  .video-mini__minimize:after, .video-mini__minimize:before {
    height: 2rem; }

.video-fs-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: none; }

.video-fs-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .video-fs-inner .video-content {
    width: auto;
    height: auto;
    max-height: calc(100vh - 80px);
    max-width: calc(100vw - 80px); }
    .video-fs-inner .video-content:after {
      display: none; }
    .video-fs-inner .video-content .link {
      text-transform: uppercase;
      position: absolute;
      z-index: 10;
      right: 23px;
      bottom: 50px; }
    .video-fs-inner .video-content__inner {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative !important; }
    .video-fs-inner .video-content__seek-bar {
      display: block; }
      .video-fs-inner .video-content__seek-bar-wrap {
        position: absolute;
        bottom: 0;
        height: 45px;
        width: 100%;
        z-index: 50; }
    .video-fs-inner .video-content__play .icon-pause-path, .video-fs-inner .video-content__play .icon-play, .video-fs-inner .video-content__play .icon-big-play, .video-fs-inner .video-content__play .icon-pause {
      font-size: 6.6rem !important; }
    @media (max-width: 767px) {
      .video-fs-inner .video-content__close {
        position: absolute;
        right: auto;
        top: -6rem;
        left: 50%;
        width: 5rem;
        height: 5rem;
        transform: translateX(-50%); } }

.news-inner__top {
  min-height: 40.7rem;
  padding: 6.1rem 8.5rem;
  position: relative;
  margin-bottom: 2.5rem;
  background-size: cover; }
  @media (max-width: 1199px) {
    .news-inner__top {
      padding: 5rem 6rem; } }
  @media (max-width: 991px) {
    .news-inner__top {
      padding: 4rem;
      min-height: 37rem; } }
  @media (max-width: 767px) {
    .news-inner__top {
      margin-bottom: 6rem;
      margin-top: 5rem;
      min-height: 30rem; } }
  .news-inner__top:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.38);
    z-index: 0; }
  .news-inner__top-mobile {
    display: none; }
    @media (max-width: 767px) {
      .news-inner__top-mobile {
        display: block; } }
  @media (max-width: 767px) {
    .news-inner__top > .news-inner__title, .news-inner__top > .news-inner__time {
      display: none; } }
  .news-inner__top .news-box__label {
    display: inline-flex;
    width: auto;
    position: relative;
    z-index: 1;
    height: 33px;
    padding-left: 10px;
    padding-right: 10px; }
    @media (max-width: 767px) {
      .news-inner__top .news-box__label {
        height: 4rem;
        position: absolute;
        left: 0;
        bottom: -4rem;
        width: 100%; } }

.news-inner__navigation {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.8rem;
  margin-left: -2.3rem; }
  @media (max-width: 991px) {
    .news-inner__navigation {
      margin-bottom: 1.7rem; } }
  @media (max-width: 767px) {
    .news-inner__navigation {
      margin-bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
      top: -5rem;
      justify-content: space-between;
      height: 5rem;
      padding: 0 2rem;
      margin-left: 0; } }
  .news-inner__navigation .link {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.6rem; }
    @media (max-width: 767px) {
      .news-inner__navigation .link {
        color: #000; } }
    @media (max-width: 767px) {
      .news-inner__navigation .link .icon-arrow {
        fill: #000; } }
    .news-inner__navigation .link + .link {
      margin-left: 3rem;
      position: relative; }
      @media (max-width: 767px) {
        .news-inner__navigation .link + .link {
          margin-left: 0; } }
      .news-inner__navigation .link + .link:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: #f5f5f5;
        width: 1px;
        height: 1.2rem;
        cursor: default;
        pointer-events: none; }
        @media (max-width: 767px) {
          .news-inner__navigation .link + .link:before {
            display: none; } }

.news-inner__title {
  position: relative;
  z-index: 1;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 2.1rem; }
  @media (max-width: 1199px) {
    .news-inner__title {
      font-size: 4.4rem;
      margin-bottom: 2rem; } }
  @media (max-width: 991px) {
    .news-inner__title {
      font-size: 4rem; } }
  @media (max-width: 767px) {
    .news-inner__title {
      color: #000;
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem; } }

.news-inner__time {
  color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.6rem;
  white-space: nowrap;
  line-height: 1.6rem;
  margin-bottom: 2.2rem;
  text-overflow: ellipsis; }
  @media (max-width: 767px) {
    .news-inner__time {
      color: #929292; } }

.news-inner__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .news-inner__content {
      flex-wrap: wrap; } }

.news-inner__footer {
  margin-top: 4.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .news-inner__footer {
      margin-top: 9rem; } }
  @media (max-width: 991px) {
    .news-inner__footer {
      margin-top: 7rem;
      margin-bottom: 6rem; } }
  @media (max-width: 767px) {
    .news-inner__footer {
      flex-wrap: wrap;
      margin-top: 5rem; } }
  .news-inner__footer p {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 900;
    text-transform: uppercase;
    width: 100%;
    max-width: 420px; }
    @media (max-width: 1199px) {
      .news-inner__footer p {
        font-size: 2.2rem;
        max-width: 380px; } }
    @media (max-width: 991px) {
      .news-inner__footer p {
        font-size: 2rem; } }
    @media (max-width: 767px) {
      .news-inner__footer p {
        max-width: 100%; } }

.news-inner__info {
  width: 66.6rem; }
  @media (max-width: 1199px) {
    .news-inner__info {
      width: 60rem; } }
  @media (max-width: 991px) {
    .news-inner__info {
      width: 100%; } }
  .news-inner__info .text-main {
    margin-bottom: 7rem;
    margin-bottom: 2.6rem; }
    @media (max-width: 991px) {
      .news-inner__info .text-main {
        font-size: 2.2rem;
        margin-bottom: 4rem; } }

.news-inner__aside {
  width: 32.8rem;
  padding-top: 5px; }
  @media (max-width: 991px) {
    .news-inner__aside {
      width: 100%; } }
  .news-inner__aside-text {
    font-weight: 500; }
  .news-inner__aside-title {
    margin-bottom: 5rem; }
  .news-inner__aside .conference-nav-box {
    flex-wrap: wrap;
    margin-bottom: 6.5rem; }
    @media (max-width: 991px) {
      .news-inner__aside .conference-nav-box {
        margin-bottom: 4rem; } }
    .news-inner__aside .conference-nav-box__view {
      margin-bottom: 1rem;
      margin-right: 0; }
    .news-inner__aside .conference-nav-box .link {
      width: 100%;
      margin-top: 2.5rem; }
    @media (max-width: 991px) {
      .news-inner__aside .conference-nav-box__detail {
        width: 100%; } }
  .news-inner__aside-form {
    background: #F5F5F5;
    padding: 22px 24px; }
    .news-inner__aside-form .form-group-check:not(:first-child) {
      margin-top: 1rem; }
    .news-inner__aside-form .form-box__content {
      padding-top: 3px; }
    .news-inner__aside-form .form-box__title {
      font-size: 2rem;
      text-transform: inherit; }
    .news-inner__aside-form .form-group input {
      background-color: transparent; }
    .news-inner__aside-form .form-box__content--inputs {
      padding-top: 18px;
      padding-right: 0; }
      .news-inner__aside-form .form-box__content--inputs .form-group:not(:first-child) {
        margin-top: 2.1rem; }
    .news-inner__aside-form .form-box_submit button {
      width: 100%;
      font-size: 2rem; }
    .news-inner__aside-form .form-input_phone {
      margin-top: 15px !important; }
  .news-inner__aside-banner {
    position: relative;
    height: 548px;
    width: 328px;
    margin-top: 56px;
    overflow: hidden; }
    .news-inner__aside-banner:after, .news-inner__aside-banner:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1; }
    .news-inner__aside-banner:after {
      width: 116px;
      background-color: #01bcef;
      left: 0; }
    .news-inner__aside-banner:before {
      width: 212px;
      background-color: #0068af;
      right: 0; }
    .news-inner__aside-banner-inner {
      z-index: 999; }
    .news-inner__aside-banner-logo {
      padding-top: 65px;
      padding-right: 5px;
      margin-left: auto;
      width: 100%;
      text-align: right; }
      @media (max-width: 479px) {
        .news-inner__aside-banner-logo {
          text-align: center; } }
      .news-inner__aside-banner-logo .icon-logo {
        color: #fff;
        width: 68px;
        height: 28px; }
    .news-inner__aside-banner-circle {
      height: 230px;
      width: 230px;
      border-radius: 50%;
      border: 1px solid white;
      padding-top: 38px;
      padding-left: 65px;
      margin-top: 35px;
      margin-left: -40px; }
      .news-inner__aside-banner-circle-2 {
        display: flex;
        flex-direction: column; }
        .news-inner__aside-banner-circle-2 b {
          text-transform: uppercase;
          font-size: 27px;
          line-height: 29px;
          font-weight: 900; }
      .news-inner__aside-banner-circle-1 {
        display: flex;
        padding-left: 3px;
        align-items: center;
        font-weight: bold; }
        .news-inner__aside-banner-circle-1 b {
          font-size: 24px; }
        .news-inner__aside-banner-circle-1-r {
          display: flex;
          flex-direction: column;
          padding-left: 12px;
          padding-top: 3px;
          text-transform: uppercase; }
          .news-inner__aside-banner-circle-1-r span {
            font-size: 10px; }
      .news-inner__aside-banner-circle-3 {
        display: flex;
        flex-direction: column; }
        .news-inner__aside-banner-circle-3 span {
          font-size: 9px;
          font-weight: bold;
          letter-spacing: 1px;
          text-transform: uppercase; }
    .news-inner__aside-banner-text {
      padding-left: 28px;
      padding-top: 30px;
      max-width: 185px; }
      .news-inner__aside-banner-text b {
        text-transform: uppercase;
        font-size: 10px;
        line-height: 14px;
        display: flex; }

.news-inner__share {
  margin-right: 3.2rem; }
  .news-inner__share span {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.8rem; }
  .news-inner__share .nav-box__social {
    margin-top: 7px; }

.news-inner__person-name {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 900;
  margin-bottom: 5px;
  text-transform: uppercase; }

.news-inner__person-position {
  font-size: 1.6rem;
  line-height: 2.2rem; }

.invested-project__box {
  margin-bottom: 4.5rem;
  background-color: #F5F5F5;
  padding: 3.2rem 3.2rem 1.7rem 3.2rem; }
  .invested-project__box .invested-project__blockquote:after, .invested-project__box .invested-project__blockquote:before {
    background-color: #F5F5F5; }
  .invested-project__box + .news-inner__footer {
    margin-top: 12.2rem; }

.invested-project__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2.5rem; }
  @media (max-width: 767px) {
    .invested-project__header {
      flex-wrap: wrap; } }
  .invested-project__header + .invested-project__blockquote {
    margin-top: 4.7rem; }

.invested-project__logo {
  width: 12.2rem;
  height: 12.2rem;
  margin-right: 1.6rem;
  flex-shrink: 0; }
  @media (max-width: 767px) {
    .invested-project__logo {
      margin-bottom: 2.5rem; } }

.invested-project__title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
  margin-bottom: 0; }
  @media (max-width: 1199px) {
    .invested-project__title {
      font-size: 3rem;
      line-height: 3.8rem; } }
  @media (max-width: 991px) {
    .invested-project__title {
      font-size: 2.6rem;
      line-height: 3.4rem; } }
  @media (max-width: 767px) {
    .invested-project__title {
      width: 100%;
      font-size: 2.2rem;
      line-height: 3rem; } }

.invested-project__text {
  margin-bottom: 2.8rem; }
  @media (max-width: 767px) {
    .invested-project__text {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 2.5rem; } }

.invested-project__blockquote {
  padding: 2.5rem 0 3.1rem 0;
  margin-bottom: 3.2rem;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  position: relative;
  margin-top: 5.2rem; }
  @media (max-width: 991px) {
    .invested-project__blockquote {
      padding: 2rem 0; } }
  .invested-project__blockquote:after, .invested-project__blockquote:before {
    display: block;
    font-weight: 900;
    content: '“';
    position: absolute;
    font-size: 3.2rem;
    width: 3.4rem;
    height: 3.4rem;
    background-color: #fff; }
  .invested-project__blockquote:after {
    bottom: -18px;
    right: 0;
    top: auto;
    left: auto;
    text-align: right; }
  .invested-project__blockquote:before {
    top: -18px;
    left: 0;
    text-align: left; }
  .invested-project__blockquote p {
    margin-bottom: 2.2rem; }

b,
strong {
  font-size: 2rem;
  line-height: 2.8rem; }

.text-main h1, .text-main h2, .text-main h3, .text-main h4, .text-main h5, .text-main h6 {
  font-weight: 500; }

blockquote {
  padding: 2.5rem 0 3.1rem 0;
  margin-bottom: 3.2rem;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  position: relative;
  margin-top: 5.2rem; }
  @media (max-width: 991px) {
    blockquote {
      padding: 2rem 0; } }
  blockquote:after, blockquote:before {
    display: block;
    font-weight: 900;
    content: '“';
    position: absolute;
    font-size: 3.2rem;
    width: 3.4rem;
    height: 3.4rem;
    background-color: #fff; }
  blockquote:after {
    bottom: -18px;
    right: 0;
    top: auto;
    left: auto;
    text-align: right; }
  blockquote:before {
    top: -18px;
    left: 0;
    text-align: left; }
  blockquote p {
    margin-bottom: 2.2rem; }

.news-inner__wrapper, .video-inner__wrapper {
  padding: 6.3rem 0 4rem 0; }
  @media (max-width: 1439px) {
    .news-inner__wrapper, .video-inner__wrapper {
      padding: 11rem 0 4rem 0; } }
  @media (max-width: 991px) {
    .news-inner__wrapper, .video-inner__wrapper {
      padding: 11rem 0 0 0; } }
  @media (max-width: 767px) {
    .news-inner__wrapper, .video-inner__wrapper {
      padding: 0; } }
  .news-inner__wrapper .tags, .video-inner__wrapper .tags {
    margin-bottom: 7.7rem; }
    @media (max-width: 767px) {
      .news-inner__wrapper .tags .tag, .video-inner__wrapper .tags .tag {
        margin-bottom: 7px; } }
  @media (max-width: 767px) {
    .news-inner__wrapper .inner-top-container, .video-inner__wrapper .inner-top-container {
      padding-left: 0;
      padding-right: 0; } }

.news-top__navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  margin-left: -2.3rem; }
  @media (max-width: 767px) {
    .news-top__navigation {
      margin-left: 0;
      justify-content: space-between; } }
  .news-top__navigation .link {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.6rem; }
    @media (max-width: 767px) {
      .news-top__navigation .link .icon-arrow {
        fill: #000; } }
    .news-top__navigation .link + .link {
      margin-left: 3rem;
      position: relative; }
      @media (max-width: 767px) {
        .news-top__navigation .link + .link {
          margin-left: 0; } }
      .news-top__navigation .link + .link:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: #787878;
        width: 1px;
        height: 1.2rem;
        cursor: default;
        pointer-events: none; }
        @media (max-width: 767px) {
          .news-top__navigation .link + .link:before {
            display: none; } }

.news__video-block {
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .news__video-block {
      flex-wrap: wrap; } }
  .news__video-block-title {
    margin-bottom: 2.5rem; }
  @media (max-width: 767px) {
    .news__video-block .tags {
      margin-bottom: 3rem; } }
  .news__video-block .news__top-video {
    width: calc(100% - 25.7rem);
    height: auto; }
    @media (max-width: 991px) {
      .news__video-block .news__top-video {
        width: 100%; } }
  .news__video-block .news__top-info {
    width: 252px;
    padding-left: 1.7rem;
    justify-content: flex-start;
    background-color: transparent; }
    @media (max-width: 991px) {
      .news__video-block .news__top-info {
        width: 100%;
        padding-left: 0;
        margin-bottom: 7rem; } }
    @media (max-width: 767px) {
      .news__video-block .news__top-info {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
  .news__video-block .video-content {
    height: 45rem;
    margin-bottom: 2.3rem; }
    @media (max-width: 991px) {
      .news__video-block .video-content {
        height: 40rem;
        margin-bottom: 0; } }
    @media (max-width: 767px) {
      .news__video-block .video-content {
        height: 23rem; } }
  .news__video-block .video__info-title {
    margin-bottom: 1.5rem;
    margin-top: 3px;
    width: 100%; }
  .news__video-block .video__info-photo {
    border-radius: 50%;
    width: 6.9rem;
    height: 6.9rem; }
    .news__video-block .video__info-photo img {
      border-radius: 50%; }
  .news__video-block .video__info-name {
    text-transform: uppercase;
    margin-bottom: 3px;
    width: 100%; }
  .news__video-block .video__info-position {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #000;
    margin-bottom: 3px;
    width: 100%; }
  .news__video-block .video__info-company {
    margin-top: 0;
    line-height: 2.4rem;
    width: 100%; }

@media (max-width: 991px) {
  .news__video-info-wrap {
    display: flex;
    flex-wrap: wrap; } }

.news__video-info-wrap .news-box__label {
  display: inline-flex;
  height: 3.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2.5rem; }
  @media (max-width: 991px) {
    .news__video-info-wrap .news-box__label {
      order: 0;
      display: flex;
      width: 100%;
      margin-bottom: 1rem; } }

.news__video-info-wrap .news-inner__time {
  color: #929292; }
  @media (max-width: 991px) {
    .news__video-info-wrap .news-inner__time {
      order: 2; } }
  @media (max-width: 767px) {
    .news__video-info-wrap .news-inner__time {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

@media (max-width: 991px) {
  .news__video-info-wrap .tags {
    order: 3;
    width: 100%; } }

@media (max-width: 767px) {
  .news__video-info-wrap .tags {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

.news__video-name {
  font-weight: 700;
  margin-bottom: 1.5rem; }
  @media (max-width: 1439px) {
    .news__video-name {
      font-size: 4.2rem; } }
  @media (max-width: 991px) {
    .news__video-name {
      font-size: 3.8rem;
      line-height: 4.3rem;
      order: 1;
      margin-bottom: 2rem; } }
  @media (max-width: 767px) {
    .news__video-name {
      font-size: 3.1rem;
      line-height: 3.6rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

@media (max-width: 767px) {
  .video-inner__wrapper {
    padding-top: 3rem; } }

@media (max-width: 767px) {
  .video-inner__wrapper .top-block-container {
    padding-left: 0;
    padding-right: 0; } }

.partner__section {
  padding-top: 8rem;
  height: 100%;
  background-color: #f5f5f5; }
  @media (max-width: 1439px) {
    .partner__section {
      padding-top: 11rem; } }
  @media (max-width: 767px) {
    .partner__section {
      padding-top: 3rem; } }
  .partner__section .section-navigation {
    margin-bottom: 3.5rem; }
  @media (max-width: 767px) {
    .partner__section .partner-about-container {
      padding-left: 0;
      padding-right: 0; } }

.partner__info-wrap {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  padding: 4.1rem 0 6.8rem 0;
  margin-bottom: 7.9rem; }
  @media (max-width: 991px) {
    .partner__info-wrap {
      flex-wrap: wrap;
      padding: 4rem; } }
  @media (max-width: 767px) {
    .partner__info-wrap {
      padding: 4rem 2.5rem; } }

.partner__logo {
  width: 15.4rem;
  height: 15.4rem;
  flex-shrink: 0;
  margin-left: 8.5rem;
  margin-right: 10rem; }
  @media (max-width: 1199px) {
    .partner__logo {
      margin-left: 6rem;
      margin-right: 6rem; } }
  @media (max-width: 991px) {
    .partner__logo {
      margin-left: auto;
      margin-right: auto; } }

.partner__info {
  margin-top: 3.4rem; }

.partner__name {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  margin-top: 0; }

.partner__descr {
  font-size: 1.6rem;
  line-height: 2.2rem; }

.partner__link {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 4.2rem 6.5rem 0 10rem;
  font-weight: 500;
  transition: opacity .3s ease;
  opacity: 1; }
  @media (max-width: 1199px) {
    .partner__link {
      margin: 3rem 5rem 0 5rem; } }
  @media (max-width: 991px) {
    .partner__link {
      margin: 3rem 0 0 0; } }
  @media (min-width: 992px) {
    .partner__link:hover {
      opacity: 0.8; } }

.partner__about-wrap {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16rem; }
  @media (max-width: 991px) {
    .partner__about-wrap {
      flex-wrap: wrap;
      margin-bottom: 10rem; } }

.partner__about-content {
  padding-left: 8.5rem;
  padding-right: 10rem;
  width: calc(100% - 15.4rem); }
  @media (max-width: 1199px) {
    .partner__about-content {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (max-width: 991px) {
    .partner__about-content {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }

.partner__about-partners {
  width: 15.4rem;
  flex-shrink: 0; }
  @media (max-width: 991px) {
    .partner__about-partners {
      width: 100%; } }
  @media (max-width: 767px) {
    .partner__about-partners {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  .partner__about-partners .platforms__item {
    padding-left: 0; }
    @media (max-width: 991px) {
      .partner__about-partners .platforms__item {
        margin-right: 1rem; } }
  @media (max-width: 991px) {
    .partner__about-partners .platform-box {
      width: 20.8rem;
      height: 20.8rem; } }
  @media (max-width: 991px) {
    .partner__about-partners .platform-box__case {
      transform: translateX(0px);
      background-color: transparent; }
      .partner__about-partners .platform-box__case-btn {
        color: #000; }
        .partner__about-partners .platform-box__case-btn svg {
          fill: #000; } }
  @media (max-width: 991px) {
    .partner__about-partners .platform-box__view picture img {
      bottom: 2.5rem; } }
  @media (max-width: 991px) {
    .partner__about-partners .slick-dots {
      justify-content: center; } }

.partner__about-title {
  text-transform: uppercase;
  margin-bottom: 1.8rem; }
  @media (max-width: 767px) {
    .partner__about-title {
      font-size: 3.2rem;
      line-height: 3.4rem;
      margin-bottom: 3.5rem; } }

.partner__content-block {
  margin-bottom: 6rem;
  line-height: 4rem; }
  @media (max-width: 767px) {
    .partner__content-block {
      margin-bottom: 8rem; } }
  .partner__content-block h1, .partner__content-block h2, .partner__content-block h3, .partner__content-block h4 {
    margin-bottom: 4rem;
    font-weight: 700; }
    @media (max-width: 767px) {
      .partner__content-block h1, .partner__content-block h2, .partner__content-block h3, .partner__content-block h4 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        line-height: 4rem;
        margin-bottom: 2rem; } }
  .partner__content-block p {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 4rem; }
    @media (max-width: 767px) {
      .partner__content-block p {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }

.partner__carousel {
  height: 35rem;
  overflow: hidden; }
  @media (max-width: 767px) {
    .partner__carousel {
      height: 24rem; } }
  .partner__carousel-item {
    width: 58rem;
    height: 35rem !important;
    display: inline-block;
    vertical-align: top; }
    @media (max-width: 767px) {
      .partner__carousel-item {
        width: 100%;
        height: 24rem !important; } }
  .partner__carousel-wrap {
    width: 58rem;
    position: relative;
    margin-bottom: 4rem; }
    @media (max-width: 991px) {
      .partner__carousel-wrap {
        margin: 0 auto 4rem auto; } }
    @media (max-width: 767px) {
      .partner__carousel-wrap {
        width: 100%; } }
  .partner__carousel-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.1rem; }
    .partner__carousel-controls .partner__carousel-action {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition: opacity .3s ease; }
      @media (max-width: 767px) {
        .partner__carousel-controls .partner__carousel-action {
          display: none; } }
      .partner__carousel-controls .partner__carousel-action.slick-disabled {
        opacity: 0.5; }
      .partner__carousel-controls .partner__carousel-action.action-next {
        right: -3.5rem; }
      .partner__carousel-controls .partner__carousel-action.action-prev {
        left: -3.5rem; }

.section-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem; }
  @media (max-width: 767px) {
    .section-navigation {
      justify-content: space-between; } }
  .section-navigation .link {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.6rem; }
    @media (max-width: 767px) {
      .section-navigation .link .icon-arrow {
        fill: #000; } }
    .section-navigation .link + .link {
      margin-left: 3rem;
      position: relative; }
      @media (max-width: 767px) {
        .section-navigation .link + .link {
          margin-left: 0; } }
      .section-navigation .link + .link:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: #787878;
        width: 1px;
        height: 1.2rem;
        cursor: default;
        pointer-events: none; }
        @media (max-width: 767px) {
          .section-navigation .link + .link:before {
            display: none; } }

.ju-section {
  height: 100%; }

.ju__top-block {
  background-color: #009BD7;
  color: #fff; }

.ju__image-block {
  min-height: 69vh;
  width: calc(100% + 200px);
  margin-left: -100px;
  border-radius: 0 0 990px 990px / 0 0 495px 495px;
  overflow: visible !important;
  display: flex;
  align-items: flex-end;
  padding: 15rem 0 0 0;
  position: relative; }
  @media (max-width: 1439px) {
    .ju__image-block {
      padding: 13rem 0 0 0;
      min-height: 70vh;
      align-items: center;
      border-radius: 0 0 1200px 1200px / 0 0 450px 450px; } }
  @media (max-width: 1199px) {
    .ju__image-block {
      padding: 7rem 0 0 0; } }
  @media (max-width: 991px) {
    .ju__image-block {
      padding: 10rem 0 0 0;
      border-radius: 0 0 1140px 1140px / 0 0 570px 570px;
      min-height: 49.5rem; } }
  @media (max-width: 991px) {
    .ju__image-block .container {
      max-width: calc(100% - 240px); } }
  @media (max-width: 767px) {
    .ju__image-block .container {
      max-width: calc(100% - 200px); } }
  .ju__image-block img {
    border-radius: inherit; }
  .ju__image-block:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2; }
  .ju__image-block .link {
    z-index: 3;
    position: relative; }
    @media (max-width: 1439px) {
      .ju__image-block .link {
        position: absolute;
        left: 50%;
        bottom: -2rem;
        transform: translateX(-50%);
        flex-wrap: wrap;
        justify-content: center;
        text-align: center; } }
    @media (max-width: 1439px) {
      .ju__image-block .link span {
        width: 100%;
        order: 0;
        margin-bottom: 1.3rem; } }
    @media (max-width: 1439px) {
      .ju__image-block .link-svg-wrap {
        order: 1; } }
    @media (min-width: 992px) {
      .ju__image-block .link:hover svg {
        animation: none;
        transform: scale(0.9) rotateZ(90deg); }
      .ju__image-block .link:hover .link-svg-wrap {
        transform: scale(1.1); } }
    .ju__image-block .link svg {
      fill: #009BD7; }
  .ju__image-block .link-svg-wrap {
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
    transform: scale(1);
    transition: transform .4s ease; }

.ju__image-text {
  display: block;
  width: 100%;
  margin-bottom: 5rem; }
  @media (max-width: 1700px) {
    .ju__image-text {
      margin-bottom: 6rem; } }
  @media (max-width: 1550px) {
    .ju__image-text {
      margin-bottom: 7.2rem; } }
  @media (max-width: 1439px) {
    .ju__image-text {
      margin-bottom: 13rem; } }
  .ju__image-text .ju__title {
    position: relative;
    z-index: 3; }
    @media (max-width: 767px) {
      .ju__image-text .ju__title {
        font-size: 4.8rem;
        line-height: 5rem; } }
  .ju__image-text .big, .ju__image-text .partner-aside__text, .ju__image-text .reg-failed__text, .ju__image-text .reg-content__main .text-block-title, .reg-content__main .ju__image-text .text-block-title, .ju__image-text .reg-content__confirm-info p, .reg-content__confirm-info .ju__image-text p {
    margin-bottom: 5.3rem;
    position: relative;
    z-index: 3; }

.ju__title {
  font-size: 5.6rem;
  text-transform: uppercase; }

.ju__text-block {
  margin-top: 17rem;
  padding-bottom: 8.3rem; }
  @media (max-width: 1439px) {
    .ju__text-block {
      margin-top: 10rem;
      padding-bottom: 7rem; } }
  @media (max-width: 991px) {
    .ju__text-block {
      margin-top: 8.5rem;
      padding-bottom: 6.9rem; } }
  .ju__text-block .section-title {
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .ju__text-block .section-title {
        margin-bottom: 2.9rem; } }

.vacancies-section {
  background-color: #fff;
  padding: 6.5rem 0 0 0; }
  @media (max-width: 767px) {
    .vacancies-section {
      padding: 4.7rem 0 0 0; } }
  @media (max-width: 767px) {
    .vacancies-section .section-title {
      display: none; } }

.vacancies-content {
  margin-top: 11.2rem; }
  @media (max-width: 767px) {
    .vacancies-content {
      margin-top: 0; } }
  .vacancies-content .section-title {
    margin-bottom: 4.6rem; }
    @media (max-width: 767px) {
      .vacancies-content .section-title {
        display: flex;
        margin-bottom: 3rem; } }

.vacancies-block {
  min-height: 17rem;
  padding: 2.8rem 3.2rem 3.4rem 3.2rem;
  background-color: #F5F5F5;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px; }
  @media (max-width: 991px) {
    .vacancies-block {
      flex-wrap: wrap;
      margin-bottom: 1.6rem;
      padding: 2.4rem; } }
  .vacancies-block__main-side, .vacancies-block__info-side {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* justify-content: space-between; */ }
    @media (max-width: 991px) {
      .vacancies-block__main-side, .vacancies-block__info-side {
        display: block; } }
  .vacancies-block__main-side {
    width: 41%; }
    @media (max-width: 991px) {
      .vacancies-block__main-side {
        width: 100%; } }
  .vacancies-block__info-side {
    width: 59%;
    padding-left: 1rem; }
    @media (max-width: 991px) {
      .vacancies-block__info-side {
        width: 100%;
        padding-left: 0; } }
  .vacancies-block__title {
    margin-bottom: 1.1rem; }
    @media (max-width: 991px) {
      .vacancies-block__title {
        margin-bottom: 1rem; } }
    .vacancies-block__title h4 {
      margin-top: 0;
      margin-bottom: 0; }
      @media (max-width: 1199px) {
        .vacancies-block__title h4 {
          font-size: 2.2rem;
          line-height: 2.4rem; } }
      @media (max-width: 767px) {
        .vacancies-block__title h4 {
          font-size: 2rem;
          line-height: 2.2rem; } }
  .vacancies-block__descr {
    margin-bottom: 3.1rem; }
    @media (max-width: 991px) {
      .vacancies-block__descr {
        margin-bottom: 3.9rem; } }
    .vacancies-block__descr .small, .vacancies-block__descr .speaker-position, .vacancies-block__descr .partner-boxes__wrapper .platform-box__type, .partner-boxes__wrapper .vacancies-block__descr .platform-box__type, .vacancies-block__descr .reg-aside__order-period {
      margin-bottom: 0; }
  @media (max-width: 991px) {
    .vacancies-block__location {
      margin-bottom: 2.7rem; } }
  .vacancies-block__location .small, .vacancies-block__location .speaker-position, .vacancies-block__location .partner-boxes__wrapper .platform-box__type, .partner-boxes__wrapper .vacancies-block__location .platform-box__type, .vacancies-block__location .reg-aside__order-period {
    margin-bottom: 0; }
  .vacancies-block__action {
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .vacancies-block__action {
        justify-content: space-between; } }
    .vacancies-block__action.active .link-get-info svg {
      transform: rotate(270deg);
      animation: none; }
    .vacancies-block__action.active .link_form {
      opacity: 0;
      visibility: hidden; }
    .vacancies-block__action .link-get-info {
      margin-right: 4.4rem; }
      @media (max-width: 991px) {
        .vacancies-block__action .link-get-info {
          margin-right: 3rem; } }
    .vacancies-block__action .link_form {
      transition: opacity .3s ease, visibility .3s ease;
      opacity: 1;
      visibility: visible; }
  .vacancies-block__dropdown {
    padding: 4.3rem 8.5rem 5.6rem 8.5rem;
    background-color: #F5F5F5;
    margin-bottom: 3px;
    margin-top: -2px;
    display: none; }
    @media (max-width: 1199px) {
      .vacancies-block__dropdown {
        padding: 3.5rem 6rem; } }
    @media (max-width: 991px) {
      .vacancies-block__dropdown {
        margin-top: -1.6rem;
        margin-bottom: 1.6rem;
        padding: 2.4rem; } }
    @media (max-width: 767px) {
      .vacancies-block__dropdown {
        padding: 3.2rem 2.4rem 4.7rem 2.4rem; } }
    .vacancies-block__dropdown.active .vacancies-block__dropdown-content {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .3s ease-in-out .3s, transform .3s ease-in-out .3s; }
    .vacancies-block__dropdown-content {
      opacity: 0;
      transform: translateY(3rem);
      transition: opacity .3s ease-in-out, transform .3s ease-in-out; }
    .vacancies-block__dropdown .vacancies-block__side {
      margin-bottom: 4.4rem; }
      @media (max-width: 767px) {
        .vacancies-block__dropdown .vacancies-block__side {
          margin-bottom: 5.4rem; } }
      .vacancies-block__dropdown .vacancies-block__side:last-child {
        margin-bottom: 0; }
    .vacancies-block__dropdown .dropdown-side {
      width: 350px; }
      @media (max-width: 991px) {
        .vacancies-block__dropdown .dropdown-side {
          width: 100%;
          margin-bottom: 4rem; } }
      @media (max-width: 991px) {
        .vacancies-block__dropdown .dropdown-side:last-child {
          margin-bottom: 0; } }
      .vacancies-block__dropdown .dropdown-side:nth-child(odd) {
        margin-right: 7.5rem; }
        @media (max-width: 991px) {
          .vacancies-block__dropdown .dropdown-side:nth-child(odd) {
            margin-right: 0; } }
      .vacancies-block__dropdown .dropdown-side__wrap {
        display: flex;
        align-items: flex-start; }
        @media (max-width: 991px) {
          .vacancies-block__dropdown .dropdown-side__wrap {
            flex-wrap: wrap; } }
      .vacancies-block__dropdown .dropdown-side__title {
        text-transform: uppercase; }
        .vacancies-block__dropdown .dropdown-side__title h1, .vacancies-block__dropdown .dropdown-side__title h2, .vacancies-block__dropdown .dropdown-side__title h3, .vacancies-block__dropdown .dropdown-side__title h4, .vacancies-block__dropdown .dropdown-side__title h5, .vacancies-block__dropdown .dropdown-side__title h6 {
          margin-top: 0;
          margin-bottom: 1.1rem; }
      .vacancies-block__dropdown .dropdown-side__list {
        list-style: none;
        margin: 2.1rem 0 0 0;
        padding: 0; }
        .vacancies-block__dropdown .dropdown-side__list li {
          padding-left: 2.4rem;
          position: relative;
          font-size: 1.6rem;
          line-height: 2.2rem; }
          .vacancies-block__dropdown .dropdown-side__list li:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #009BD7;
            position: absolute;
            left: 0;
            top: 5px; }
          .vacancies-block__dropdown .dropdown-side__list li + li {
            margin-top: 2.2rem; }

.find-out__section {
  background-color: #fff;
  padding-bottom: 10.8rem;
  padding-top: 8rem; }
  @media (max-width: 767px) {
    .find-out__section {
      padding-bottom: 9.6rem; } }

.find-out__content {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .find-out__content {
      flex-wrap: wrap; } }
  .find-out__content .form-group {
    margin-bottom: 2.8rem;
    margin-top: 0; }
    .find-out__content .form-group:last-child {
      margin-top: 4rem;
      margin-bottom: 1.5rem; }
  .find-out__content .form-input input, .find-out__content .form-input textarea {
    padding-bottom: .7rem; }
  .find-out__content textarea {
    height: 12.5rem;
    padding-top: 0;
    min-height: auto; }
  .find-out__content .section-title {
    margin-bottom: 1.7rem; }
    @media (max-width: 767px) {
      .find-out__content .section-title {
        margin-bottom: 3.2rem; } }

.find-out__info-side {
  margin-right: 10.7rem;
  width: 40.3rem; }
  @media (max-width: 991px) {
    .find-out__info-side {
      margin-right: 0;
      width: 100%; } }

.find-out__form-side {
  width: 32.2rem;
  margin-top: 8px; }
  @media (max-width: 991px) {
    .find-out__form-side {
      width: 100%;
      max-width: 400px; } }
  @media (max-width: 767px) {
    .find-out__form-side {
      margin-top: 5.6rem; } }
  .find-out__form-side .btn_submit {
    text-transform: uppercase; }
  .find-out__form-side .form-file-name {
    font-size: 1.6rem;
    line-height: 2.2rem; }
  @media (max-width: 767px) {
    .find-out__form-side .form-box_submit {
      margin-top: 0; } }

.find-out__text {
  font-size: 2rem;
  font-size: 2.8rem;
  margin-bottom: 2.2rem; }

.section-about-main-screen {
  height: 100vh;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .section-about-main-screen {
      height: auto; } }

.about-main-screen__wrapper {
  padding-top: 29vh; }
  @media (max-width: 991px) {
    .about-main-screen__wrapper {
      padding-top: 32vh; } }
  @media (max-width: 767px) {
    .about-main-screen__wrapper {
      padding-top: 3.8rem; } }

.about-main-screen__content {
  position: relative;
  padding-right: 35rem; }
  @media (max-width: 1199px) {
    .about-main-screen__content {
      padding-right: 28rem; } }
  @media (max-width: 991px) {
    .about-main-screen__content {
      padding-right: 8rem; } }
  @media (max-width: 767px) {
    .about-main-screen__content {
      display: flex;
      padding-right: 0;
      flex-direction: column; } }

.about-main-screen__title {
  z-index: 2;
  position: relative; }
  @media (max-width: 767px) {
    .about-main-screen__title {
      order: 1; } }
  .about-main-screen__title h2 {
    font-size: 4.8rem;
    line-height: 5.2rem; }
    @media (max-width: 767px) {
      .about-main-screen__title h2 {
        font-size: 3.2rem;
        line-height: 3.4rem; } }
  @media (max-width: 767px) {
    .about-main-screen__title span {
      display: block; } }

.about-main-screen__caption {
  z-index: 2;
  font-weight: 500;
  margin-top: 2rem;
  font-size: 2.4rem;
  line-height: 3rem;
  position: relative; }
  @media (max-width: 767px) {
    .about-main-screen__caption {
      order: 2;
      margin-top: 2.9rem; } }

.about-main-screen__view {
  top: -54%;
  left: 23%;
  width: 53.6rem;
  height: 53.6rem;
  position: absolute;
  font-size: .536rem;
  transform-style: preserve-3d;
  transform: rotateX(75deg) rotateY(-30deg); }
  @media (max-width: 767px) {
    .about-main-screen__view {
      order: 0;
      top: auto;
      left: auto;
      width: 21.2rem;
      height: 21.2rem;
      margin-left: auto;
      position: relative;
      margin-right: auto;
      margin-bottom: 6.6rem; } }

.about-main-screen__planet {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  background-color: #ffffff;
  transform: rotateX(-75deg);
  border: 0.3rem solid #009BD7; }

.about-main-screen__moon {
  z-index: 1;
  top: -13rem;
  left: -13rem;
  right: -13rem;
  bottom: -13rem;
  position: absolute;
  border-radius: 50%;
  animation-duration: 5s;
  animation-name: moonAnimate;
  transform-style: preserve-3d;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }
  @media (max-width: 767px) {
    .about-main-screen__moon {
      top: -6rem;
      left: -6rem;
      right: -6rem;
      bottom: -6rem; } }
  @media (max-width: 360px) {
    .about-main-screen__moon {
      top: -5rem;
      left: -5rem;
      right: -5rem;
      bottom: -5rem; } }

.about-main-screen__moon-position {
  top: 0;
  left: 50%;
  position: absolute;
  animation-name: invert;
  transform-style: preserve-3d;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }
  .about-main-screen__moon-position i {
    top: 0;
    left: 50%;
    width: 3.4rem;
    height: 3.4rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    margin-top: -1.7rem;
    margin-left: -1.7rem;
    animation-name: invert;
    animation-duration: 5s;
    background-color: #009BD7;
    transform-style: preserve-3d;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
    @media (max-width: 767px) {
      .about-main-screen__moon-position i {
        width: 2.4rem;
        height: 2.4rem;
        margin-top: -1.2rem;
        margin-left: -1.2rem; } }

@keyframes invert {
  0% {
    transform: rotateX(-90deg) rotateY(-360deg); }
  100% {
    transform: rotateX(-90deg) rotateY(0deg); } }

@keyframes moonAnimate {
  0% {
    transform: rotateZ(0); }
  100% {
    transform: rotateZ(360deg); } }

.section-question-detail {
  margin-top: 8.1rem;
  margin-bottom: 10.5rem; }
  @media (max-width: 1439px) {
    .section-question-detail {
      margin-top: 4.1rem;
      margin-bottom: 8.2rem; } }
  @media (max-width: 767px) {
    .section-question-detail {
      margin-bottom: 5.9rem; } }

.question-detail__content {
  display: flex;
  margin-top: 5.8rem;
  padding-right: 8.8%;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .question-detail__content {
      padding-right: 0; } }
  @media (max-width: 767px) {
    .question-detail__content {
      margin-top: 3.5rem;
      flex-direction: column; } }

.question-detail__contacts {
  width: 44.7%; }
  @media (max-width: 991px) {
    .question-detail__contacts {
      width: 48%; } }
  @media (max-width: 767px) {
    .question-detail__contacts {
      width: 100%; } }

.question-detail__contacts-unit {
  width: 100%; }
  .question-detail__contacts-unit:not(:last-child) {
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #7A7A7A; }

.question-detail__contacts-unit-title {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: .2rem; }

.question-detail__contacts-unit-content {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem; }

.question-detail__form {
  width: 35.2%;
  padding-top: 2.7rem; }
  @media (max-width: 991px) {
    .question-detail__form {
      width: 40%; } }
  @media (max-width: 767px) {
    .question-detail__form {
      width: 100%;
      padding-top: 0;
      margin-top: 11.1rem; } }

.question-detail__form-title {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-bottom: 2.8rem; }

.section-question-people {
  padding-top: 6.9rem;
  padding-bottom: 12rem;
  background-color: #F5F5F5; }
  @media (max-width: 991px) {
    .section-question-people {
      padding-bottom: 9rem; } }
  @media (max-width: 767px) {
    .section-question-people {
      padding-top: 4.3rem; } }

.question-people__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2%;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .question-people__items {
      margin-left: -10%; } }
  @media (max-width: 767px) {
    .question-people__items {
      margin-left: -5%; } }
  @media (max-width: 479px) {
    .question-people__items {
      margin-left: 0; } }

.question-people__item {
  width: 23%;
  margin-left: 2%;
  padding-right: 3.2rem; }
  @media (max-width: 991px) {
    .question-people__item {
      width: 40%;
      margin-left: 10%;
      padding-right: 0; } }
  @media (max-width: 767px) {
    .question-people__item {
      width: 45%;
      margin-left: 5%; } }
  @media (max-width: 479px) {
    .question-people__item {
      width: 100%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .question-people__item:nth-child(n+5) {
      margin-top: 3.6rem; } }
  @media (min-width: 480px) and (max-width: 991px) {
    .question-people__item:nth-child(n+3) {
      margin-top: 5.2rem; } }
  @media (max-width: 479px) {
    .question-people__item:nth-child(n+2) {
      margin-top: 7.4rem; } }

.people-box {
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.2rem; }

.people-box__position {
  font-weight: 900;
  font-size: 2.4rem;
  min-height: 8.4rem;
  line-height: 2.8rem;
  margin-bottom: .4rem;
  text-transform: uppercase; }
  @media (max-width: 479px) {
    .people-box__position {
      min-height: auto;
      margin-bottom: 2.2rem; } }

.people-box__details {
  position: relative; }
  @media (max-width: 479px) {
    .people-box__details {
      padding-top: 1.7rem;
      padding-left: 9.2rem; } }

.people-box__pic {
  width: 6.8rem;
  height: 6.8rem;
  overflow: hidden;
  border-radius: 50%; }
  @media (max-width: 479px) {
    .people-box__pic {
      position: absolute;
      top: 0;
      left: 0; } }
  .people-box__pic picture {
    width: 100%;
    height: 100%;
    display: block; }

.people-box__name {
  font-weight: 900;
  font-size: 1.6rem;
  min-height: 3.6rem;
  margin-top: 1.7rem;
  line-height: 1.8rem;
  text-transform: uppercase; }
  @media (max-width: 479px) {
    .people-box__name {
      margin-top: 0;
      min-height: auto; } }

.people-box__sub-position {
  margin-top: .4rem; }

.people-box__contacts {
  /* margin-top: 1.8rem; */ }
  @media (max-width: 767px) {
    .people-box__contacts {
      margin-top: .9rem; } }

.section-error {
  color: #ffffff;
  min-height: 100vh;
  padding-top: 4rem;
  padding-bottom: 22rem;
  background-color: #000000; }
  @media (max-width: 1199px) {
    .section-error {
      padding-bottom: 18rem; } }
  @media (max-width: 767px) {
    .section-error {
      margin-top: -7.2rem;
      padding-top: 5.9rem; } }

.section-error__header {
  padding-left: 5.5rem;
  padding-right: 5.5rem; }
  @media (max-width: 1199px) {
    .section-error__header {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 767px) {
    .section-error__header {
      padding-left: 5.6rem;
      padding-right: 5.6rem; } }

.section-error__brand a {
  display: inline-block; }
  .section-error__brand a svg {
    color: #ffffff;
    font-size: 14.9rem; }

.section-error__body {
  margin-top: 8rem; }
  @media (max-width: 767px) {
    .section-error__body {
      margin-top: 4.5rem;
      padding-left: 3.2rem;
      padding-right: 3.2rem; } }

.section-error__name {
  display: flex;
  font-size: 1.6rem;
  align-items: center; }
  .section-error__name b {
    display: block;
    font-weight: 900;
    font-size: 3.2rem; }
    .section-error__name b + span {
      margin-left: .7rem; }
  .section-error__name span {
    display: block;
    font-weight: 500; }

.section-error__caption {
  max-width: 40rem;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem; }

.section-error__action {
  margin-top: 2.3rem;
  margin-left: -2.4rem; }
  @media (max-width: 1199px) {
    .section-error__action {
      margin-left: 0; } }
  @media (max-width: 767px) {
    .section-error__action {
      margin-top: 2.9rem;
      margin-left: -2.4rem; } }

.section-error__events {
  margin-top: 12.6rem; }
  @media (max-width: 767px) {
    .section-error__events {
      margin-top: 9.5rem; } }

.section-error__events-title {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-bottom: 7.5rem;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .section-error__events-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 6.1rem; } }

.section-error__events-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -6.5rem;
  margin-right: -6.5rem; }
  @media (max-width: 1199px) {
    .section-error__events-list {
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }

.section-error__events-item {
  width: 33.33333%;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  @media (max-width: 991px) {
    .section-error__events-item {
      width: 50%; } }
  @media (max-width: 678px) {
    .section-error__events-item {
      width: 100%; } }
  @media (min-width: 992px) {
    .section-error__events-item:nth-child(n+4) {
      margin-top: 6.8rem; } }
  @media (min-width: 679px) and (max-width: 991px) {
    .section-error__events-item:nth-child(n+3) {
      margin-top: 8.4rem; } }
  @media (max-width: 678px) {
    .section-error__events-item:nth-child(n+2) {
      margin-top: 7rem; } }

.section-conferences {
  padding-top: 8.6rem;
  padding-bottom: 13.2rem;
  background-color: #F5F5F5; }
  @media (max-width: 767px) {
    .section-conferences {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
      background-color: transparent; } }

.conferences__content {
  margin-top: 4rem; }
  @media (max-width: 767px) {
    .conferences__content {
      margin-top: 4.7rem; } }

.conferences__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem; }

.conferences__item {
  width: 33.33333%;
  padding-left: 1.6rem; }
  @media (max-width: 991px) {
    .conferences__item {
      width: 50%; } }
  @media (max-width: 678px) {
    .conferences__item {
      width: 100%; } }
  @media (min-width: 992px) {
    .conferences__item:nth-child(n+4) {
      margin-top: 1.6rem; } }
  @media (min-width: 679px) and (max-width: 991px) {
    .conferences__item:nth-child(n+3) {
      margin-top: 1.6rem; }
    .conferences__item.last_one {
      min-height: 40rem;
      width: 100%;
      margin-top: 4.7rem; } }
  @media (max-width: 678px) {
    .conferences__item:nth-child(n+2) {
      margin-top: 1.6rem; } }

.section-conferences-detail {
  margin-bottom: 8rem; }

.conferences-detail__content {
  display: flex;
  align-items: flex-start; }

.conferences-detail__description {
  width: calc(100% - 25.1rem); }
  @media (max-width: 991px) {
    .conferences-detail__description {
      width: 100%; } }

.conferences-detail__tab-nav ul {
  display: flex; }
  .conferences-detail__tab-nav ul li {
    width: 100%; }
    .conferences-detail__tab-nav ul li a {
      color: #000000;
      display: block;
      font-weight: 900;
      font-size: 2.4rem;
      line-height: 2.8rem;
      padding-top: 1.6rem;
      text-decoration: none;
      padding-bottom: 1.6rem;
      border-bottom: 0.8rem solid #D8D8D8; }
      @media (max-width: 767px) {
        .conferences-detail__tab-nav ul li a {
          font-size: 1.5rem;
          line-height: 1.7rem;
          padding-top: 1.1rem;
          padding-bottom: 1.1rem; } }
      .conferences-detail__tab-nav ul li a.active {
        border-bottom: 0.8rem solid #000000; }

.tab-nav {
  display: flex; }
  .tab-nav li {
    width: 100%; }
    .tab-nav li a {
      color: #000000;
      display: block;
      font-weight: 900;
      font-size: 2.4rem;
      line-height: 2.8rem;
      padding-top: 1.6rem;
      text-decoration: none;
      padding-bottom: 1.6rem;
      border-bottom: 0.8rem solid #D8D8D8;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      display: inline-block;
      margin-right: 1.6rem; }
      @media (max-width: 767px) {
        .tab-nav li a {
          font-size: 2rem;
          line-height: 2.2rem;
          padding-top: 1.1rem;
          padding-bottom: 1.1rem; } }
      .tab-nav li a.active {
        border-bottom: 0.8rem solid #000000; }

.conferences-detail__tab-content {
  margin-top: .4rem; }

.conferences-timeline-unit {
  font-size: 1.6rem;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit {
      margin-left: -2.4rem;
      margin-right: -2.4rem; } }
  .conferences-timeline-unit:not(:last-child) {
    border-bottom: 1px solid #787878; }
  .conferences-timeline-unit.descriptor .conferences-timeline-unit__name {
    width: 79%;
    font-weight: 900;
    font-size: 2.4rem; }
    @media (max-width: 767px) {
      .conferences-timeline-unit.descriptor .conferences-timeline-unit__name {
        width: 85%;
        font-size: 2rem;
        line-height: 2.2rem; } }
  .conferences-timeline-unit.active .conferences-timeline-unit__action {
    transform: rotateZ(180deg); }

.conferences-timeline-unit-header {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-header {
      flex-wrap: wrap;
      padding-top: 4.1rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-bottom: 3.8rem; } }

.conferences-timeline-unit__time {
  width: 11%;
  padding-top: .7rem;
  padding-left: .2rem;
  padding-right: 1rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit__time {
      width: 100%;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: .9rem; } }

.conferences-timeline-unit__name {
  width: 69%;
  font-size: 2rem;
  line-height: 2.8rem;
  padding-right: 2.4rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit__name {
      width: 70%;
      font-size: 2rem; } }

.conferences-timeline-unit__picture {
  width: 20%;
  position: relative; }
  @media (max-width: 767px) {
    .conferences-timeline-unit__picture {
      width: 30%; } }
  .conferences-timeline-unit__picture picture {
    top: 50%;
    left: .5rem;
    right: .5rem;
    display: block;
    text-align: right;
    position: absolute;
    transform: translateY(-25%); }
    .conferences-timeline-unit__picture picture img {
      max-height: 6.4rem; }

.conferences-timeline-unit__action {
  width: 10%;
  text-align: center; }
  @media (max-width: 767px) {
    .conferences-timeline-unit__action {
      width: 15%; } }

.conferences-timeline-unit-description {
  display: none;
  padding-left: 11%;
  padding-top: 1.2rem;
  padding-bottom: 5.4rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.conferences-timeline-unit__description {
  padding-left: 11%;
  margin-top: -2rem;
  margin-bottom: 2rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit__description {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }

.conferences-timeline-unit-description__sub-unit:not(:first-child) {
  margin-top: 5.4rem;
  padding-top: 3.3rem;
  border-top: 1px solid #D8D8D8; }

.conferences-timeline-unit-description__sub-unit-description {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description__sub-unit-description {
      flex-direction: column; } }

.conferences-timeline-unit-description__sub-unit-small-detail {
  width: 70%;
  padding-right: 1.6rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description__sub-unit-small-detail {
      width: 100%; } }

.conferences-timeline-unit-description__sub-unit-small-time {
  font-size: 1.6rem;
  position: relative;
  line-height: 2.2rem;
  padding-left: 2.5rem;
  margin-bottom: 1.6rem; }
  .conferences-timeline-unit-description__sub-unit-small-time:after {
    left: 0;
    top: .5rem;
    width: 1rem;
    content: '';
    height: 1rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6621; }
  .conferences-timeline-unit-description__sub-unit-small-time ul {
    display: flex;
    align-items: center; }
    .conferences-timeline-unit-description__sub-unit-small-time ul li:not(:last-child):after {
      width: 1px;
      content: '';
      height: 1.6rem;
      margin-left: .8rem;
      margin-right: .8rem;
      display: inline-block;
      background-color: #787878; }

.conferences-timeline-unit-description__sub-unit-small-caption {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem; }

.conferences-timeline-unit-description__sub-unit-small-view {
  width: 30%;
  text-align: right;
  padding-right: 3.2rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description__sub-unit-small-view {
      width: 100%;
      text-align: left;
      padding-right: 0;
      margin-top: 2.4rem; } }

.conferences-timeline-unit-description__sub-unit-full-description {
  margin-top: 2.4rem; }

.conferences-timeline-unit-description__sub-unit-reporters {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.4rem; }

.conferences-timeline-unit-description__sub-unit-reporter {
  width: 50%;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding-right: 3.2rem;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description__sub-unit-reporter {
      width: 100%;
      padding-right: 0; } }
  @media (min-width: 768px) {
    .conferences-timeline-unit-description__sub-unit-reporter:nth-child(n+3) {
      margin-top: 5.3rem; } }
  @media (max-width: 767px) {
    .conferences-timeline-unit-description__sub-unit-reporter:nth-child(n+2) {
      margin-top: 5.3rem; } }

.conferences-timeline-unit-description__sub-unit-reporter-name {
  line-height: 1.8rem;
  margin-bottom: .3rem; }

.conferences-timeline-unit-description__sub-unit-reporter-specialisation {
  font-weight: 400;
  text-transform: none; }

.conferences-timeline-unit-description__sub-unit-reporter-company {
  font-size: 2rem;
  margin-top: .9rem; }

.conferences-detail__aside {
  width: 23.9rem;
  margin-top: 5.9rem;
  margin-left: 1.2rem; }
  @media (max-width: 991px) {
    .conferences-detail__aside {
      display: none; } }

.conferences-detail__aside-item:not(:last-child) {
  margin-bottom: 1.6rem; }

.special-event-box {
  display: block;
  padding-top: 2.5rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  background-color: #F5F5F5; }

.special-event-box__title {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1.1rem; }

.special-event-box__name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  padding-right: .5rem; }

.special-event-box__view {
  width: auto;
  height: 23.9rem;
  margin-top: 2.8rem;
  margin-left: -2.4rem;
  margin-right: -2.4rem; }
  .special-event-box__view picture {
    width: 100%;
    height: 100%;
    display: block; }

.conferences-detail__aside-action {
  padding-left: 1.6rem;
  padding-right: 1.6rem; }

.conferences-detail__color-descr {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px; }
  .conferences-detail__color-descr__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .conferences-detail__color-descr__item {
        width: 100%;
        margin-right: 0; } }
    .conferences-detail__color-descr__item.plannary-section {
      color: #000000; }
      .conferences-detail__color-descr__item.plannary-section .conferences-detail__color-descr__circle {
        background-color: #000000; }
    .conferences-detail__color-descr__item.technical-stream {
      color: #98CB4E; }
      .conferences-detail__color-descr__item.technical-stream .conferences-detail__color-descr__circle {
        background-color: #98CB4E; }
    .conferences-detail__color-descr__item.strategic-stream {
      color: #009BD7; }
      .conferences-detail__color-descr__item.strategic-stream .conferences-detail__color-descr__circle {
        background-color: #009BD7; }
  .conferences-detail__color-descr__circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0; }
  .conferences-detail__color-descr__name {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    line-height: 1; }

.sub-unit-list {
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .sub-unit-list {
      margin-bottom: 40px; } }
  .sub-unit-list li {
    /* font-weight: 800; */
    font-size: 16px;
    line-height: 1.38;
    margin-bottom: 7px; }

.sub-unit-inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; }
  .sub-unit-inner-item {
    width: calc((100% - 46px) / 2); }
    @media (max-width: 767px) {
      .sub-unit-inner-item {
        width: 100%; }
        .sub-unit-inner-item:not(:last-child) {
          margin-bottom: 30px; } }
    .sub-unit-inner-item__icon {
      margin-bottom: 16px; }
      .sub-unit-inner-item__icon img, .sub-unit-inner-item__icon svg {
        width: 1em;
        font-size: 61px; }
    .sub-unit-inner-item__title {
      margin-bottom: 10px;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
      text-transform: uppercase; }
      .sub-unit-inner-item__title span {
        font-weight: 700; }
    .sub-unit-inner-item__text {
      font-size: 16px;
      line-height: 1.35; }

.icon-special-mitting {
  width: 1em;
  font-size: 61px; }

.icon-meeting-group {
  width: 1em;
  font-size: 61px; }

.conferences-timeline-unit-double__wrapper {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-double__wrapper {
      flex-wrap: wrap;
      padding-top: 4rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media (max-width: 767px) {
    .conferences-timeline-unit-double__wrapper .conferences-timeline-unit {
      margin-left: 0;
      margin-right: 0; } }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit-header {
    padding-top: 0; }
    @media (max-width: 767px) {
      .conferences-timeline-unit-double__wrapper .conferences-timeline-unit-header {
        padding-left: 0;
        padding-right: 0; } }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__time {
    flex-shrink: 0; }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__name {
    width: 90%;
    padding-right: 10px;
    font-weight: 900;
    font-size: 2.4rem; }
    @media (max-width: 767px) {
      .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__name {
        width: 85%; } }
    .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__name span {
      font-weight: 700; }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__description {
    padding-left: 0; }
    @media (max-width: 767px) {
      .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__description {
        margin-bottom: 3rem; } }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit__trigger-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 35px; }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit.technical-stream .conferences-timeline-unit__name {
    color: #98CB4E; }
  .conferences-timeline-unit-double__wrapper .conferences-timeline-unit.strategic-stream .conferences-timeline-unit__name {
    color: #009BD7; }

.conferences-timeline-unit-double__inner {
  width: 93%; }
  @media (max-width: 767px) {
    .conferences-timeline-unit-double__inner {
      width: 100%; } }
  .conferences-timeline-unit-double__inner__triggers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden; }
    @media (max-width: 767px) {
      .conferences-timeline-unit-double__inner__triggers {
        flex-wrap: wrap;
        width: 100%; } }
    .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit {
      width: calc((100% - 22px) / 2);
      border-bottom: none;
      padding-right: 22px;
      transition: width .4s ease, border-color .3s ease;
      position: relative; }
      @media (max-width: 767px) {
        .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit {
          width: 100%;
          padding-right: 0; } }
      .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit:first-child:after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #4F4F4F; }
        @media (max-width: 767px) {
          .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit:first-child:after {
            right: auto;
            left: 50%;
            top: auto;
            bottom: 0;
            width: 95%;
            height: 1px;
            transform: translateX(-50%); } }
      @media (max-width: 767px) {
        .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit:last-child {
          padding-top: 2.5rem; } }
      @media (max-width: 767px) {
        .conferences-timeline-unit-double__inner__triggers .conferences-timeline-unit:last-child .conferences-timeline-unit__description {
          margin-bottom: 0; } }
  .conferences-timeline-unit-double__inner__content {
    width: 100%;
    display: flex;
    overflow: hidden; }
    .conferences-timeline-unit-double__inner__content-wrap {
      display: none; }
    .conferences-timeline-unit-double__inner__content .conferences-timeline-unit {
      width: 100%;
      flex-shrink: 0;
      border: none;
      transition: transform .5s ease, opacity .5s ease; }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit:first-child {
        transform: translateX(-100%);
        opacity: 0; }
        .conferences-timeline-unit-double__inner__content .conferences-timeline-unit:first-child.active {
          opacity: 1;
          transform: translateX(0); }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit:last-child {
        transform: translateX(0);
        opacity: 0; }
        .conferences-timeline-unit-double__inner__content .conferences-timeline-unit:last-child.active {
          opacity: 1;
          transform: translateX(-100%); }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit__name {
        width: 85%; }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit-description__sub-unit {
        border: none;
        margin-top: 0;
        padding-top: 0; }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit.technical-stream .conferences-timeline-unit__name {
        color: #98CB4E; }
      .conferences-timeline-unit-double__inner__content .conferences-timeline-unit.strategic-stream .conferences-timeline-unit__name {
        color: #009BD7; }

.faq-section {
  padding-bottom: 10rem; }
  @media (max-width: 991px) {
    .faq-section .container {
      padding-left: 0;
      padding-right: 0; } }

.faq-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.faq-tabs {
  width: 41.1rem; }
  @media (max-width: 1199px) {
    .faq-tabs {
      width: 37rem; } }
  @media (max-width: 991px) {
    .faq-tabs {
      display: none; } }
  .faq-tabs-content__wrap {
    width: 49.6rem; }
    @media (max-width: 1199px) {
      .faq-tabs-content__wrap {
        width: 48rem; } }
    @media (max-width: 991px) {
      .faq-tabs-content__wrap {
        width: 100%; } }
    .faq-tabs-content__wrap .tab-item {
      display: none; }
      @media (max-width: 991px) {
        .faq-tabs-content__wrap .tab-item {
          display: flex; } }

.faq-tab-box .tab-content {
  display: none; }

.faq-tab-box.active .tab-content {
  display: block; }

.tab-item {
  width: 100%;
  min-height: 10.8rem;
  transition: background-color .3s ease, opacity .3s ease;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 3.2rem;
  margin-bottom: 2px;
  cursor: pointer;
  opacity: 1;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1199px) {
    .tab-item {
      min-height: 10rem; } }
  @media (max-width: 991px) {
    .tab-item {
      padding: 0 2.4rem 1.9rem 2.4rem;
      border-bottom: 1px solid #787878;
      min-height: auto;
      background-color: transparent;
      margin-bottom: 4rem;
      align-items: flex-start;
      justify-content: space-between; } }
  @media (min-width: 992px) {
    .tab-item:hover {
      opacity: 0.7; } }
  .tab-item.active {
    background-color: transparent; }
  .tab-item .icon-arrow {
    flex-shrink: 0;
    margin-left: 3rem;
    transform: rotate(90deg);
    font-size: 1.6rem;
    fill: #FC6621;
    margin-top: 5px; }

.tab-inner {
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 0; }

.tab-content {
  width: 100%; }
  .tab-content-title {
    line-height: 4rem;
    font-weight: 700;
    margin-top: 1.8rem;
    margin-bottom: 3rem; }
  .tab-content-block {
    margin-bottom: 10.4rem; }
    @media (max-width: 991px) {
      .tab-content-block {
        margin-bottom: 5rem;
        padding: 0 2.4rem; } }

.section-conference-header {
  padding-top: 2.8rem;
  padding-bottom: 5.5rem; }
  @media (max-width: 1439px) {
    .section-conference-header {
      padding-top: 12rem; } }
  @media (max-width: 767px) {
    .section-conference-header {
      padding-top: 4rem; } }
  @media (max-width: 479px) {
    .section-conference-header {
      padding-top: 2rem; } }
  .section-conference-header.wildsand {
    background-color: #F5F5F5; }
  .section-conference-header .section-title {
    max-width: 60rem; }
    .section-conference-header .section-title__main {
      text-transform: uppercase;
      width: 100%; }
    @media (max-width: 991px) {
      .section-conference-header .section-title {
        max-width: 100%;
        margin-bottom: 3rem; } }
    @media (max-width: 767px) {
      .section-conference-header .section-title {
        max-width: 100%;
        margin-bottom: 3rem; } }
    .section-conference-header .section-title h1 {
      margin-bottom: 0;
      font-size: 5.8rem;
      line-height: 6.4rem; }
      @media (max-width: 1199px) {
        .section-conference-header .section-title h1 {
          font-size: 4.8rem;
          line-height: 5rem; } }
      @media (max-width: 767px) {
        .section-conference-header .section-title h1 {
          font-size: 3.2rem;
          line-height: 3.4rem; } }

.conference-header__buttons {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  justify-content: flex-end; }
  @media (max-width: 1439px) {
    .conference-header__buttons {
      margin-bottom: 2rem; } }
  @media (max-width: 991px) {
    .conference-header__buttons {
      display: none; } }
  .conference-header__buttons.buttons-equal .link {
    min-width: 21rem; }
    .conference-header__buttons.buttons-equal .link + .link {
      margin-left: 0; }
  .conference-header__buttons.buttons-equal + .conference-header__title-content {
    margin-top: -2rem; }
    @media (max-width: 1200px) {
      .conference-header__buttons.buttons-equal + .conference-header__title-content {
        margin-top: 0; } }
    .conference-header__buttons.buttons-equal + .conference-header__title-content .conference-header__title {
      margin-bottom: 2rem; }
  .conference-header__buttons .link {
    text-transform: uppercase; }
    .conference-header__buttons .link + .link {
      margin-left: 8px; }

.conference-header__text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .conference-header__text {
      flex-wrap: wrap; } }

.conference-header__promp {
  max-width: 45rem; }
  @media (max-width: 991px) {
    .conference-header__promp {
      max-width: 100%;
      margin-bottom: 2rem; } }

.partnering__top-block {
  min-height: 69rem;
  width: calc(100% + 200px);
  margin-left: -100px;
  border-radius: 0 0 990px 990px / 0 0 495px 495px;
  overflow: visible !important;
  display: flex;
  padding: 8rem 0 0 0;
  position: relative; }
  @media (max-width: 1439px) {
    .partnering__top-block {
      padding: 13rem 0 0 0;
      min-height: 70vh;
      align-items: center;
      border-radius: 0 0 1200px 1200px / 0 0 450px 450px; } }
  @media (max-width: 1199px) {
    .partnering__top-block {
      padding: 7rem 0 0 0; } }
  @media (max-width: 991px) {
    .partnering__top-block {
      border-radius: 0 0 1140px 1140px / 0 0 570px 570px;
      min-height: 49.5rem; } }
  @media (max-width: 991px) {
    .partnering__top-block .container {
      max-width: calc(100% - 240px); } }
  @media (max-width: 767px) {
    .partnering__top-block .container {
      max-width: calc(100% - 200px); } }
  .partnering__top-block img {
    border-radius: inherit; }
  .partnering__top-block:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2; }

.partnering__top-text {
  display: block;
  width: 100%;
  margin-bottom: 5rem; }
  @media (max-width: 1700px) {
    .partnering__top-text {
      margin-bottom: 6rem; } }
  @media (max-width: 1550px) {
    .partnering__top-text {
      margin-bottom: 7.2rem; } }
  @media (max-width: 1439px) {
    .partnering__top-text {
      margin-bottom: 13rem; } }
  .partnering__top-text .big, .partnering__top-text .partner-aside__text, .partnering__top-text .reg-failed__text, .partnering__top-text .reg-content__main .text-block-title, .reg-content__main .partnering__top-text .text-block-title, .partnering__top-text .reg-content__confirm-info p, .reg-content__confirm-info .partnering__top-text p {
    margin-bottom: 5.3rem;
    position: relative;
    z-index: 3;
    color: #fff; }

.partnering__title {
  font-size: 5.6rem;
  line-height: 6.4rem;
  text-transform: uppercase;
  position: relative;
  color: #fff;
  z-index: 3;
  margin-bottom: 3.3rem; }
  @media (max-width: 767px) {
    .partnering__title {
      font-size: 4rem;
      line-height: 5rem; } }

.partnering__info-wrap {
  padding: 6rem 0 8rem 0; }
  .partnering__info-wrap .partnering__info-contact .people-box__details {
    padding-left: 0; }
  .partnering__info-wrap .partnering__info-contact .people-box__name {
    min-height: auto; }
  @media (max-width: 767px) {
    .partnering__info-wrap .partnering__info-contact .people-box__pic {
      position: relative;
      margin-bottom: 2rem; } }

.partnering__info-content {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .partnering__info-content {
      flex-wrap: wrap;
      justify-content: space-between; } }

.partnering__info-box {
  width: calc((100% - 32rem) / 2);
  padding-right: 2.5rem; }
  @media (max-width: 991px) {
    .partnering__info-box {
      width: 47%;
      padding-right: 0;
      margin-bottom: 3rem; } }
  @media (max-width: 767px) {
    .partnering__info-box {
      width: 100%; } }

.partnering__info-contact {
  width: 24rem;
  margin-left: 8rem;
  padding-top: 9.5rem; }
  @media (max-width: 991px) {
    .partnering__info-contact {
      width: 100%;
      margin-left: 0;
      padding-top: 0; } }
  .partnering__info-contact .big, .partnering__info-contact .partner-aside__text, .partnering__info-contact .reg-failed__text, .partnering__info-contact .reg-content__main .text-block-title, .reg-content__main .partnering__info-contact .text-block-title, .partnering__info-contact .reg-content__confirm-info p, .reg-content__confirm-info .partnering__info-contact p {
    font-weight: 500;
    margin-bottom: 1.5rem; }

.partnering__view-section {
  padding-top: 9.5rem;
  background-color: #fff; }
  @media (max-width: 767px) {
    .partnering__view-section {
      padding-top: 7rem; } }
  .partnering__view-section.section-dark {
    background-color: #F5F5F5; }
  .partnering__view-section .section-title {
    margin-bottom: 7.5rem; }
    @media (max-width: 767px) {
      .partnering__view-section .section-title {
        margin-bottom: 6rem; } }
    .partnering__view-section .section-title + .section-subtitle {
      margin-top: -5.5rem; }
      @media (max-width: 767px) {
        .partnering__view-section .section-title + .section-subtitle {
          margin-top: -4rem; } }
  .partnering__view-section .section-subtitle {
    max-width: 50rem;
    margin-bottom: 7rem; }

.partnering__view-box {
  width: 50%;
  min-height: 49.3rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  vertical-align: top; }
  @media (max-width: 1199px) {
    .partnering__view-box {
      min-height: 46rem; } }
  @media (max-width: 991px) {
    .partnering__view-box {
      width: 100%;
      min-height: 40rem; } }
  @media (max-width: 767px) {
    .partnering__view-box {
      min-height: 35rem; } }
  .partnering__view-box.text-box {
    padding: 1rem 15rem 1rem 10rem; }
    @media (max-width: 1199px) {
      .partnering__view-box.text-box {
        padding: 1rem 6rem; } }
    @media (max-width: 991px) {
      .partnering__view-box.text-box {
        order: 0;
        min-height: auto;
        padding: 5rem;
        text-align: center; } }
  @media (max-width: 991px) {
    .partnering__view-box.image-box {
      order: 1; } }
  .partnering__view-box.dark {
    background-color: #000;
    color: #fff; }
  .partnering__view-box.silver {
    background-color: #F5F5F5;
    color: #000; }
  .partnering__view-box.blue {
    background-color: #009BD7;
    color: #000; }
  .partnering__view-box-wrap {
    display: flex;
    flex-wrap: wrap; }
  .partnering__view-box .view-box-title {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 0; }
  .partnering__view-box .view-box-text {
    margin-bottom: 0; }
    .partnering__view-box .view-box-text + .view-box-text {
      margin-top: 2.8rem; }

.partnering__contact-section {
  padding: 10.7rem 0 15.5rem 0; }
  @media (max-width: 1439px) {
    .partnering__contact-section {
      padding: 10rem 0; } }
  @media (max-width: 991px) {
    .partnering__contact-section {
      padding: 9rem 0; } }
  @media (max-width: 767px) {
    .partnering__contact-section {
      padding: 7rem 0; } }
  .partnering__contact-section .section-title {
    padding-right: 3.2rem; }
    @media (max-width: 991px) {
      .partnering__contact-section .section-title {
        padding-right: 0;
        margin-bottom: 6rem; } }
    @media (max-width: 767px) {
      .partnering__contact-section .section-title {
        margin-bottom: 4.5rem; } }
  .partnering__contact-section .partnering__info-contact {
    width: 41.4rem;
    margin-left: 0;
    padding-top: 0;
    flex-shrink: 0; }
    @media (max-width: 991px) {
      .partnering__contact-section .partnering__info-contact {
        width: 100%; } }

.partnering__contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .partnering__contact-content {
      flex-wrap: wrap; } }

.info-box__icon {
  min-height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2.5rem; }
  .info-box__icon .icon-chat {
    font-size: 7.2rem; }
  .info-box__icon .icon-sun {
    font-size: 6.6rem; }

.info-box__title {
  text-transform: uppercase;
  margin-bottom: 3rem; }

.conference-hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  transition: background-color .3s ease;
  padding-top: 7rem;
  padding-bottom: 7rem; }
  .conference-hero:after {
    content: '';
    width: 24%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    transition: background-color .3s ease;
    display: none; }
  @media (max-width: 1439px) {
    .conference-hero {
      padding-top: 12rem; } }
  @media (max-width: 991px) {
    .conference-hero:after {
      display: block; } }
  @media (max-width: 767px) {
    .conference-hero {
      padding-top: 3rem; } }
  .conference-hero.orange {
    background-color: #FC6621; }
    .conference-hero.orange:after {
      background-color: #bababa; }
  .conference-hero.green {
    background-color: #98CB4E; }
    .conference-hero.green:after {
      background-color: #bababa; }
  .conference-hero__links {
    position: absolute;
    top: 3rem;
    right: 3rem;
    z-index: 2; }
    @media (max-width: 991px) {
      .conference-hero__links {
        display: none; } }
    .conference-hero__links .link {
      text-transform: uppercase; }
      .conference-hero__links .link + .link {
        margin-left: 8px; }
  .conference-hero__logo {
    margin-bottom: 6rem;
    position: relative;
    display: inline-block;
    z-index: 2; }
    @media (max-width: 767px) {
      .conference-hero__logo {
        margin-bottom: 7.5rem; } }
    @media (max-width: 479px) {
      .conference-hero__logo {
        margin-bottom: 4rem; } }
    .conference-hero__logo .icon-logo {
      font-size: 15rem;
      color: #fff; }
  .conference-hero__info {
    max-width: 76.5rem;
    color: #fff;
    transition: color .3s ease;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1439px) {
      .conference-hero__info {
        max-width: 70rem; } }
    @media (max-width: 1199px) {
      .conference-hero__info {
        max-width: 46rem; } }
    @media (max-width: 991px) {
      .conference-hero__info {
        max-width: 100%; } }
    .conference-hero__info .hero-title {
      text-transform: uppercase;
      font-size: 5.2rem;
      line-height: 6.4rem;
      margin-top: 0;
      margin-bottom: 4rem;
      position: relative;
      z-index: 2; }
      @media (max-width: 1199px) {
        .conference-hero__info .hero-title {
          font-size: 4.3rem;
          line-height: 4.8rem; } }
      @media (max-width: 991px) {
        .conference-hero__info .hero-title {
          font-size: 3.8rem;
          line-height: 4.4rem;
          margin-top: 38rem;
          color: #000; } }
      @media (max-width: 767px) {
        .conference-hero__info .hero-title {
          font-size: 3.2rem;
          line-height: 3.4rem;
          margin-top: 43rem; } }
    .conference-hero__info .conference-nav-box {
      z-index: 2;
      width: auto; }
      @media (max-width: 991px) {
        .conference-hero__info .conference-nav-box {
          position: absolute;
          top: 21rem;
          flex-wrap: wrap;
          max-width: 36rem; } }
      @media (max-width: 767px) {
        .conference-hero__info .conference-nav-box {
          top: 14rem; } }
      .conference-hero__info .conference-nav-box .link {
        display: none;
        margin-top: 2rem;
        text-transform: uppercase; }
        @media (max-width: 991px) {
          .conference-hero__info .conference-nav-box .link {
            display: inline-flex; } }
    .conference-hero__info .leaders-box__action {
      display: none;
      margin-top: 0;
      z-index: 1; }
      @media (max-width: 991px) {
        .conference-hero__info .leaders-box__action {
          display: block; } }
      .conference-hero__info .leaders-box__action a.link_icon-descript i {
        width: 4.8rem;
        height: 4.8rem; }
        .conference-hero__info .leaders-box__action a.link_icon-descript i .icon-big-play {
          fill: #000;
          stroke: #000;
          color: #FC6621;
          font-size: 4.8rem; }
    .conference-hero__info .conference-nav-box__detail {
      max-width: 36rem; }
      @media (max-width: 991px) {
        .conference-hero__info .conference-nav-box__detail {
          max-width: 100%; } }
    .conference-hero__info .conference-nav-box__view {
      display: none; }
      @media (max-width: 991px) {
        .conference-hero__info .conference-nav-box__view {
          display: block;
          margin-bottom: 1.5rem; } }
    .conference-hero__info .conference-nav-box__time {
      margin-top: 2.4rem;
      font-size: 2rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      font-weight: 900; }
  .conference-hero__image {
    border-radius: inherit;
    width: 100%;
    height: 100%; }
    .conference-hero__image img {
      border-radius: inherit;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
    .conference-hero__image-wrap {
      width: 99.7rem;
      height: 99.7rem;
      border-radius: 50%;
      position: absolute;
      top: -21.2rem;
      right: -21.2rem;
      z-index: 1; }
      @media (max-width: 1439px) {
        .conference-hero__image-wrap {
          top: -26.2rem;
          right: -26.2rem; } }
      @media (max-width: 1199px) {
        .conference-hero__image-wrap {
          top: -30rem;
          right: -35rem; } }
      @media (max-width: 991px) {
        .conference-hero__image-wrap {
          top: -51rem;
          right: -5rem;
          width: 110rem;
          height: 110rem; } }
      @media (max-width: 767px) {
        .conference-hero__image-wrap {
          width: 105rem;
          height: 105rem; } }
      @media (max-width: 479px) {
        .conference-hero__image-wrap {
          top: -41.2rem;
          right: -12rem;
          width: 95rem;
          height: 95rem; } }
      .conference-hero__image-wrap:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: inherit;
        background-color: rgba(0, 0, 0, 0.4); }
      .conference-hero__image-wrap .icon-big-play {
        font-size: 4.8rem;
        fill: #fff;
        stroke: #fff;
        color: #FC6621; }
      .conference-hero__image-wrap .link_icon-descript {
        color: #fff; }
        .conference-hero__image-wrap .link_icon-descript span {
          margin-left: 1.6rem; }
        @media (min-width: 992px) {
          .conference-hero__image-wrap .link_icon-descript:hover .icon-big-play {
            font-size: 5.2rem; } }
      .conference-hero__image-wrap .leaders-box__action {
        position: absolute;
        top: 697px;
        left: 46px;
        width: calc(99.7rem / 2);
        z-index: 20; }
        @media (max-width: 991px) {
          .conference-hero__image-wrap .leaders-box__action {
            display: none; } }

.conf-register__section {
  padding: 6.3rem 0 7rem 0;
  position: relative;
  z-index: 112;
  background: #fff; }
  @media (max-width: 991px) {
    .conf-register__section {
      padding: 4rem 0 5rem 0; } }
  .conf-register__section .conf-dot {
    margin-bottom: 3.5rem; }

.conf-register__title {
  line-height: 4rem;
  font-weight: 700;
  margin-top: 0;
  max-width: 80rem;
  margin-bottom: 4.5rem; }
  @media (max-width: 1199px) {
    .conf-register__title {
      font-size: 3rem;
      line-height: 3.6rem;
      margin-bottom: 4rem; } }
  @media (max-width: 991px) {
    .conf-register__title {
      font-size: 2.8rem;
      line-height: 3.4rem;
      margin-bottom: 3.2rem; } }
  @media (max-width: 767px) {
    .conf-register__title {
      font-size: 2.4rem;
      line-height: 3rem;
      margin-bottom: 2.5rem; } }

.conf-register__text {
  max-width: 50rem; }
  @media (max-width: 1199px) {
    .conf-register__text {
      max-width: 41rem; } }
  @media (max-width: 991px) {
    .conf-register__text {
      max-width: 100%; } }
  .conf-register__text-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .conf-register__text-wrap {
        flex-wrap: wrap; } }

.conf-register__promp {
  max-width: 41.1rem; }
  @media (max-width: 1199px) {
    .conf-register__promp {
      max-width: 40rem; } }
  @media (max-width: 991px) {
    .conf-register__promp {
      max-width: 100%; } }
  .conf-register__promp-text {
    font-weight: 500; }

.conf-visit__section {
  padding: 4rem 0 5rem 0;
  background-color: #BABABA; }
  @media (max-width: 991px) {
    .conf-visit__section {
      padding: 3rem 0 1.5rem 0; } }

.conf-visit__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .conf-visit__content {
      flex-wrap: wrap; } }

.conf-visit__info-side {
  width: 51%; }
  @media (max-width: 991px) {
    .conf-visit__info-side {
      width: 100%;
      margin-bottom: 3rem; } }

.conf-visit__descr-side {
  width: 40%; }
  @media (max-width: 991px) {
    .conf-visit__descr-side {
      width: 100%; } }

.conf-visit__time {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 2.2rem; }

.conf-dot {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%; }

.agenda-ss__section {
  background-color: #F5F5F5;
  padding: 8rem 0 7rem 0; }
  @media (max-width: 991px) {
    .agenda-ss__section {
      padding: 6rem 0 4rem 0; } }
  .agenda-ss__section .section-title {
    margin-bottom: 4.5rem; }

.agenda-ss__box, .agenda-ss__box-info {
  width: 32.6rem;
  min-height: 27.6rem;
  padding: 2.5rem 2rem 2.5rem 2.5rem;
  background-color: #FFFFFF;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative; }
  @media (max-width: 1199px) {
    .agenda-ss__box, .agenda-ss__box-info {
      width: 30.2rem;
      padding: 2.2rem 1.8rem 2.2rem 2.2rem;
      margin-right: 1.2rem;
      margin-bottom: 1.2rem; } }
  @media (max-width: 991px) {
    .agenda-ss__box, .agenda-ss__box-info {
      width: 26rem;
      padding: 2rem 1.7rem;
      margin-right: 0.8rem;
      margin-bottom: 0.8rem; } }
  @media (max-width: 767px) {
    .agenda-ss__box, .agenda-ss__box-info {
      width: 23.4rem; } }
  .agenda-ss__box .conf-dot, .agenda-ss__box-info .conf-dot {
    margin-bottom: 1.5rem; }
  .agenda-ss__box-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem; }
    @media (max-width: 1199px) {
      .agenda-ss__box-wrap {
        margin-right: -1.2rem; } }
    @media (max-width: 991px) {
      .agenda-ss__box-wrap {
        margin-right: 0; } }
  .agenda-ss__box-title {
    min-height: 7rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 700; }
  .agenda-ss__box-info {
    background-color: #FC6621;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .agenda-ss__box-info {
        width: 32.6rem;
        min-height: 27.6rem;
        padding: 2.5rem 2rem 2.5rem 2.5rem;
        margin: 2.1rem auto 0 auto; } }
    @media (max-width: 479px) {
      .agenda-ss__box-info {
        width: 100%; } }
    .agenda-ss__box-info .box-info__title {
      margin-bottom: 1.5rem; }

.speakers-section {
  padding: 5.6rem 0 0 0; }
  .speakers-section .section-title {
    margin-bottom: 4.4rem; }
    .speakers-section .section-title__minor {
      display: none; }
      @media (max-width: 991px) {
        .speakers-section .section-title__minor {
          display: block; } }

.speakers-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -3rem; }
  @media (max-width: 1199px) {
    .speakers-wrapper {
      margin-right: -2.2rem; } }
  @media (max-width: 991px) {
    .speakers-wrapper {
      margin-right: 0; } }

.speaker-block {
  width: 100%; }
  .speaker-block-wrap {
    width: 22.9rem;
    margin-right: 3rem;
    margin-bottom: 7.7rem;
    display: inline-block;
    vertical-align: top; }
    @media (max-width: 1199px) {
      .speaker-block-wrap {
        width: calc((100% - 6.6rem) / 3);
        margin-right: 2.2rem;
        margin-bottom: 6.5rem; } }
    @media (max-width: 991px) {
      .speaker-block-wrap {
        width: calc((100% - 2.5rem) / 2);
        margin-right: 2.5rem;
        margin-bottom: 5.5rem; } }
    @media (max-width: 479px) {
      .speaker-block-wrap {
        width: 100%;
        margin-right: 0; } }
    @media (max-width: 991px) {
      .speaker-block-wrap:nth-child(even) {
        margin-right: 0; } }
    .speaker-block-wrap.all-speakers {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 991px) {
        .speaker-block-wrap.all-speakers {
          display: none; } }

.speaker-header {
  display: flex;
  align-items: center;
  margin-bottom: 3.1rem; }
  @media (max-width: 991px) {
    .speaker-header {
      margin-bottom: 2.4rem; } }

.speaker-view {
  width: 6.9rem;
  height: 6.9rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1.5rem; }
  @media (max-width: 479px) {
    .speaker-view {
      margin-right: 2.2rem; } }
  .speaker-view picture {
    width: 100%;
    height: 100%;
    display: block;
    position: relative; }
    .speaker-view picture img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute; }

.speaker-links {
  display: flex;
  align-items: center; }
  .speaker-links a {
    transform: none;
    transition: transform .3s ease; }
    @media (min-width: 992px) {
      .speaker-links a:hover {
        transform: translateY(-0.4rem); } }
  .speaker-links svg {
    font-size: 2.4rem;
    margin-right: 6px;
    fill: #000; }
    @media (max-width: 479px) {
      .speaker-links svg {
        font-size: 4.4rem;
        margin-right: 8px; } }

@media (max-width: 479px) {
  .speaker-content {
    padding-left: 9.3rem; } }

.speaker-name {
  margin-top: 0;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.speaker-position {
  margin-bottom: 1.2rem; }

.speaker-company {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0; }

.section-speakers {
  margin-bottom: 8rem; }
  @media (max-width: 767px) {
    .section-speakers {
      margin-top: 0; } }
  .section-speakers .conferences-detail__description {
    padding-top: 5.2rem; }
    @media (max-width: 991px) {
      .section-speakers .conferences-detail__description {
        padding-top: 0; } }
  .section-speakers .conferences-detail__aside {
    margin-top: 0; }

.speakers__list {
  display: flex;
  flex-wrap: wrap; }

.speakers__item {
  width: 33.33333%;
  padding-right: 1.6rem; }
  @media (max-width: 767px) {
    .speakers__item {
      width: 50%; } }
  @media (max-width: 479px) {
    .speakers__item {
      width: 100%; } }
  @media (min-width: 768px) {
    .speakers__item:nth-child(n+4) {
      margin-top: 7rem; } }
  @media (min-width: 0) and (max-width: 767px) {
    .speakers__item:nth-child(n+3) {
      margin-top: 7rem; } }
  @media (max-width: 479px) {
    .speakers__item:nth-child(n+2) {
      margin-top: 8rem; } }

.section-speakers-question {
  margin-top: 17.8rem;
  margin-bottom: 13.6rem; }
  @media (max-width: 767px) {
    .section-speakers-question {
      margin-top: 14.4rem;
      margin-bottom: 11rem; } }

.speakers-question__content {
  display: flex;
  padding-right: 17%;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .speakers-question__content {
      padding-right: 0; } }
  @media (max-width: 767px) {
    .speakers-question__content {
      flex-direction: column; } }

.speakers-question__detail {
  width: 45%; }
  @media (max-width: 767px) {
    .speakers-question__detail {
      width: 100%; } }

.speakers-question__detail-title {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 3.4rem; }
  @media (max-width: 767px) {
    .speakers-question__detail-title {
      font-size: 2.4rem;
      line-height: 2.8rem; } }

.speakers-question__detail-caption {
  font-size: 2rem;
  margin-top: 2.2rem;
  line-height: 2.8rem; }
  @media (max-width: 767px) {
    .speakers-question__detail-caption {
      margin-top: 4rem; } }

.speakers-question__form {
  width: 39%;
  padding-top: 1rem; }
  @media (max-width: 991px) {
    .speakers-question__form {
      width: 42%; } }
  @media (max-width: 767px) {
    .speakers-question__form {
      width: 100%;
      padding-top: 0;
      margin-top: 5.5rem; } }

.partner-boxes__section {
  background-color: #F5F5F5;
  padding: 9rem 0 9rem 0; }
  @media (max-width: 1199px) {
    .partner-boxes__section {
      padding: 7rem 0 10rem 0; } }
  @media (max-width: 991px) {
    .partner-boxes__section {
      padding: 5.5rem 0 5rem 0; } }
  .partner-boxes__section.our-partners .partner-boxes__wrapper {
    margin-bottom: -6.3rem; }
    @media (max-width: 1199px) {
      .partner-boxes__section.our-partners .partner-boxes__wrapper {
        margin-bottom: -4.5rem; } }
    @media (max-width: 991px) {
      .partner-boxes__section.our-partners .partner-boxes__wrapper {
        margin-bottom: 6rem; } }
  .partner-boxes__section.our-partners .partner-boxes__aside {
    margin-bottom: 7rem; }
    @media (max-width: 1199px) {
      .partner-boxes__section.our-partners .partner-boxes__aside {
        margin-bottom: 9rem; } }
    @media (max-width: 991px) {
      .partner-boxes__section.our-partners .partner-boxes__aside {
        margin-bottom: 0; } }
  @media (max-width: 991px) {
    .partner-boxes__section.our-partners .platform-box {
      padding: 1.6rem 1.6rem 4rem 1.6rem; } }
  @media (max-width: 479px) {
    .partner-boxes__section.our-partners .partner-boxes__item {
      opacity: 0.5; } }
  @media (max-width: 479px) {
    .partner-boxes__section.our-partners .partner-boxes__item.slick-current {
      opacity: 1; } }
  .partner-boxes__section.media-partners .partner-boxes__aside {
    margin-bottom: 5.6rem; }
  @media (max-width: 991px) {
    .partner-boxes__section.media-partners .partner-boxes__wrapper {
      margin-bottom: 3rem; } }
  .partner-boxes__section .section-title {
    margin-bottom: 5rem; }
    @media (max-width: 991px) {
      .partner-boxes__section .section-title {
        margin-bottom: 4.2rem; } }
    .partner-boxes__section .section-title__minor {
      display: none; }
      @media (max-width: 991px) {
        .partner-boxes__section .section-title__minor {
          margin-top: 1.5rem;
          display: block; } }

.partner-boxes__content {
  display: flex; }
  @media (max-width: 991px) {
    .partner-boxes__content {
      flex-wrap: wrap; } }

.partner-boxes__wrapper {
  width: calc(100% - 32.6rem);
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 1199px) {
    .partner-boxes__wrapper {
      width: calc(100% - 29rem); } }
  @media (max-width: 991px) {
    .partner-boxes__wrapper {
      width: 100%; } }
  .partner-boxes__wrapper .platform-box__case {
    background-color: #FC6621; }
    @media (max-width: 991px) {
      .partner-boxes__wrapper .platform-box__case {
        transform: none;
        background-color: transparent; } }
    .partner-boxes__wrapper .platform-box__case-btn {
      text-transform: uppercase;
      color: #000; }
      .partner-boxes__wrapper .platform-box__case-btn svg {
        fill: #000; }
  .partner-boxes__wrapper .platform-box__type {
    margin-top: 8px;
    margin-bottom: 4.7rem; }
    @media (max-width: 1199px) {
      .partner-boxes__wrapper .platform-box__type {
        margin-bottom: 2.7rem; } }
  .partner-boxes__wrapper .platform-box.all-partners {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
    text-transform: uppercase; }
    @media (max-width: 1199px) {
      .partner-boxes__wrapper .platform-box.all-partners {
        padding: 1.2rem;
        height: 14.4rem;
        width: 14.4rem; } }
    @media (max-width: 991px) {
      .partner-boxes__wrapper .platform-box.all-partners {
        display: none; } }
  @media (max-width: 991px) {
    .partner-boxes__wrapper .partner-boxes__block {
      width: 100vw; } }

.partner-boxes__item {
  margin-bottom: 1.6rem;
  margin-right: 1.6rem;
  width: 15.4rem;
  opacity: 1;
  transition: opacity .3s ease;
  display: inline-block;
  vertical-align: top; }
  @media (max-width: 1199px) {
    .partner-boxes__item {
      width: 14.4rem; } }
  @media (max-width: 991px) {
    .partner-boxes__item {
      width: 21rem;
      margin-bottom: 0; } }
  .partner-boxes__item .platform-box {
    width: 100%; }
    @media (max-width: 1199px) {
      .partner-boxes__item .platform-box {
        height: 14.4rem; } }
    @media (max-width: 991px) {
      .partner-boxes__item .platform-box {
        height: 21rem; } }

.partner-boxes__aside {
  width: 32.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .partner-boxes__aside {
      width: 29rem; } }
  @media (max-width: 991px) {
    .partner-boxes__aside {
      width: 100%;
      background-color: transparent; } }
  .partner-boxes__aside .people-box__name {
    min-height: auto; }
    @media (max-width: 991px) {
      .partner-boxes__aside .people-box__name {
        margin-top: 0;
        min-height: auto; } }
  @media (max-width: 991px) {
    .partner-boxes__aside .people-box__details {
      padding-top: 1.7rem;
      padding-left: 11.6rem; } }
  @media (max-width: 991px) {
    .partner-boxes__aside .people-box__position {
      min-height: auto;
      margin-bottom: 2.2rem; } }
  @media (max-width: 991px) {
    .partner-boxes__aside .people-box__pic {
      position: absolute;
      top: 0;
      left: 0;
      width: 9.2rem;
      height: 9.2rem; } }
  .partner-boxes__aside .link {
    font-size: 1.6rem;
    line-height: 2.2rem; }
  .partner-boxes__aside-title {
    text-transform: uppercase; }
  .partner-boxes__aside-content {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 4rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .partner-boxes__aside-content {
        padding: 3rem; } }
    @media (max-width: 991px) {
      .partner-boxes__aside-content {
        padding: 0;
        background-color: transparent; } }
    @media (max-width: 991px) {
      .partner-boxes__aside-content.aside-gold-partner {
        background-color: #fff;
        padding: 4rem; } }
  .partner-boxes__aside-footer {
    padding-top: 4.7rem;
    margin-top: auto; }
    .partner-boxes__aside-footer .partner-aside__text {
      margin-bottom: 1rem; }
    .partner-boxes__aside-footer .link {
      text-transform: uppercase; }
  .partner-boxes__aside-form {
    margin-top: 3.1rem; }
    @media (max-width: 991px) {
      .partner-boxes__aside-form {
        width: 100%;
        max-width: 46.5rem;
        margin: 3rem auto 0 auto; } }
    .partner-boxes__aside-form .form-box_submit {
      width: 100%; }
      @media (max-width: 767px) {
        .partner-boxes__aside-form .form-box_submit {
          margin-top: 0; } }
      .partner-boxes__aside-form .form-box_submit .btn {
        width: 100%;
        text-transform: uppercase; }
    .partner-boxes__aside-form .form-group {
      margin-bottom: 2rem;
      margin-top: 0; }
      .partner-boxes__aside-form .form-group input {
        padding-bottom: .7rem;
        background-color: transparent; }

.partner-aside__text {
  font-weight: 500; }

.cta__section {
  position: relative;
  padding-bottom: 9.8rem; }
  @media (max-width: 991px) {
    .cta__section {
      padding-bottom: 8rem; } }
  .cta__section:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0; }
  .cta__section .container {
    position: relative;
    z-index: 1; }
  .cta__section .link {
    text-transform: uppercase;
    margin-top: 1.7rem; }
  .cta__section.cta__section-simple {
    padding-top: 7rem;
    position: relative; }
    @media (max-width: 991px) {
      .cta__section.cta__section-simple {
        padding-top: 5rem; } }

.cta__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.cta__partner-logo {
  width: 6.9rem;
  height: 7.8rem;
  margin-bottom: 2.2rem; }

.cta__title {
  color: #fff;
  text-transform: uppercase;
  max-width: 60rem; }
  @media (max-width: 991px) {
    .cta__title {
      max-width: 100%; } }
  .cta__title p {
    margin-bottom: 0; }

.cta__subtitle {
  margin-top: 10px;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase; }

.cta__date {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #fff;
  margin-bottom: 2.2rem; }

.cta-update__section {
  background-color: #000;
  padding: 5rem 0; }
  @media (max-width: 479px) {
    .cta-update__section {
      width: calc(100% - 4.8rem);
      margin: 0 auto;
      padding: 3.7rem 0; } }
  @media (max-width: 991px) {
    .cta-update__section .container {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .cta-update__section.cta-section_red {
    background-color: #DB3539; }
  .cta-update__section.cta-section_info .cta-update__content {
    flex-wrap: wrap; }
  .cta-update__section.cta-section_info .cta__title {
    width: 100%;
    color: #000; }

.cta-update__content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .cta-update__content {
      flex-wrap: wrap; } }
  .cta-update__content .link {
    margin-left: 2.5rem; }
    @media (max-width: 991px) {
      .cta-update__content .link {
        margin-left: 0; } }
  .cta-update__content .cta__title {
    max-width: 100%; }
    @media (max-width: 991px) {
      .cta-update__content .cta__title {
        width: 100%; } }

.cta__title {
  color: #fff;
  text-transform: uppercase;
  max-width: 60rem;
  margin-top: 1rem;
  font-size: 3.2rem;
  line-height: 3.4rem;
  font-weight: 900; }

.section-about-business {
  margin-bottom: 10rem; }
  @media (max-width: 767px) {
    .section-about-business {
      margin-bottom: 8rem; } }

.about-business__content {
  margin-top: 2.1rem; }

.about-business__header {
  display: flex;
  align-items: center;
  margin-left: -1.6rem;
  margin-bottom: 5.4rem;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .about-business__header {
      margin-left: 0; } }
  @media (max-width: 767px) {
    .about-business__header {
      margin-bottom: 4.4rem;
      flex-direction: column; } }
  .about-business__header > div {
    padding-left: 1.6rem; }
    @media (max-width: 991px) {
      .about-business__header > div {
        padding-left: 0; } }

.about-business__header-motto {
  max-width: 50%;
  width: 66.66666%;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem; }
  @media (max-width: 991px) {
    .about-business__header-motto {
      width: 50%; } }
  @media (max-width: 767px) {
    .about-business__header-motto {
      width: 100%;
      max-width: 100%; } }

.about-business__header-action {
  width: 33.33333%; }
  @media (max-width: 991px) {
    .about-business__header-action {
      width: 45%;
      text-align: right; } }
  @media (max-width: 767px) {
    .about-business__header-action {
      width: 100%;
      text-align: left;
      margin-top: 2.8rem; } }

.about-business__list {
  display: flex;
  align-items: stretch;
  margin-left: -1.6rem; }
  @media (max-width: 991px) {
    .about-business__list {
      margin-left: 0;
      flex-wrap: wrap; } }

.about-business__item {
  width: 33.33333%;
  margin-left: 1.6rem; }
  @media (max-width: 991px) {
    .about-business__item {
      width: 100%;
      margin-left: 0; }
      .about-business__item:nth-child(n+2) {
        margin-top: 1.6rem; } }

.about-business-box {
  height: 100%;
  color: #ffffff;
  padding: 3.2rem;
  font-weight: 500;
  padding-bottom: 6.3rem;
  background-color: #009BD7; }

.about-business-box__view {
  height: 15.6rem; }
  .about-business-box__view svg {
    fill: #ffffff;
    font-size: 8rem; }

.about-business-box__name {
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.8rem; }

.about-business-box__detail {
  font-size: 2rem;
  margin-top: 2.6rem;
  line-height: 2.8rem; }

.section-about-team {
  padding-top: 5.8rem;
  padding-bottom: 14.7rem;
  background-color: #F5F5F5; }
  @media (max-width: 767px) {
    .section-about-team {
      padding-bottom: 0; } }
  .section-about-team .section-title {
    max-width: 80%; }
    @media (max-width: 991px) {
      .section-about-team .section-title {
        max-width: 90%; } }
    @media (max-width: 767px) {
      .section-about-team .section-title {
        max-width: 100%; } }

.about-team__content {
  margin-top: 7.9rem; }
  @media (max-width: 767px) {
    .about-team__content {
      margin-top: 6rem; } }

.about-team__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  align-items: flex-start; }
  @media (max-width: 699px) {
    .about-team__list {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto; } }

.about-team__item {
  width: 25%;
  padding-left: 1.6rem; }
  @media (max-width: 991px) {
    .about-team__item {
      width: 33.33333%; } }
  @media (max-width: 767px) {
    .about-team__item {
      width: 50%; } }
  @media (max-width: 699px) {
    .about-team__item {
      width: 100%;
      padding-left: 0; } }
  @media (min-width: 992px) {
    .about-team__item:nth-child(n+5) {
      margin-top: 8.4rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .about-team__item:nth-child(n+4) {
      margin-top: 8.4rem; } }
  @media (min-width: 700px) and (max-width: 767px) {
    .about-team__item:nth-child(n+3) {
      margin-top: 5.6rem; } }
  @media (max-width: 699px) {
    .about-team__item:nth-child(n+2) {
      margin-top: 5rem; } }

.about-team-box {
  position: relative; }
  @media (max-width: 767px) {
    .about-team-box {
      display: flex;
      min-height: 11.7rem;
      padding-top: 3.9rem;
      padding-left: 14.6rem;
      flex-direction: column; } }

.about-team-box__view {
  display: block;
  width: 15.4rem;
  height: 15.4rem;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1.9rem; }
  @media (max-width: 767px) {
    .about-team-box__view {
      top: 0;
      left: 0;
      width: 11.7rem;
      height: 11.7rem;
      position: absolute; } }
  .about-team-box__view picture {
    width: 100%;
    height: 100%;
    display: block; }
    .about-team-box__view picture img {
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%; }

.about-team-box__name {
  font-size: 1.6rem;
  line-height: 1.8rem; }
  @media (max-width: 767px) {
    .about-team-box__name {
      order: 1; } }

.about-team-box__position {
  font-size: 1.6rem;
  margin-top: .2rem;
  line-height: 2.2rem; }
  @media (max-width: 767px) {
    .about-team-box__position {
      order: 2;
      margin-top: .4rem; } }

.about-team-box__social {
  margin-top: .7rem; }
  @media (max-width: 767px) {
    .about-team-box__social {
      order: 0;
      margin-top: 0;
      margin-bottom: .9rem; } }
  .about-team-box__social ul {
    display: flex;
    align-items: center; }
    .about-team-box__social ul li:not(:last-child) {
      margin-right: .8rem; }
      @media (max-width: 767px) {
        .about-team-box__social ul li:not(:last-child) {
          margin-right: 1.3rem; } }
    .about-team-box__social ul li a {
      display: block;
      position: relative; }
      .about-team-box__social ul li a svg {
        font-size: 2.4rem; }
        @media (max-width: 767px) {
          .about-team-box__social ul li a svg {
            font-size: 4rem; } }
      .about-team-box__social ul li a.blue svg {
        fill: #009BDF; }
      @media (min-width: 992px) {
        .about-team-box__social ul li a:hover {
          transform: translateY(-0.4rem); } }

.about-team__official-info {
  display: flex;
  margin-top: 17rem;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .about-team__official-info {
      margin-top: 12rem;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .about-team__official-info {
      margin-top: 6rem; } }

.about-team__official-info-title {
  width: 47%;
  padding-top: 3.5rem; }
  @media (max-width: 991px) {
    .about-team__official-info-title {
      width: 100%; } }

.about-team__official-info-title-name {
  font-weight: 900;
  font-size: 4.8rem;
  line-height: 5.2rem; }
  @media (max-width: 767px) {
    .about-team__official-info-title-name {
      font-size: 3.2rem;
      line-height: 3.4rem; } }

.about-team__official-info-title-action {
  margin-top: 2.5rem; }
  @media (max-width: 767px) {
    .about-team__official-info-title-action {
      margin-top: 2.2rem; } }

.about-team__official-info-detail {
  width: 49.3%;
  padding: 5.7rem;
  padding-top: 4.6rem;
  padding-bottom: 8rem;
  background-color: #ffffff; }
  @media (max-width: 991px) {
    .about-team__official-info-detail {
      width: 100%;
      margin-top: 6.4rem; } }
  @media (max-width: 767px) {
    .about-team__official-info-detail {
      padding-left: 2.4rem;
      margin-left: -2.4rem;
      padding-right: 2.4rem;
      width: calc(100% + 4.8rem); } }

.about-team__official-info-detail-title {
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-bottom: 3.3rem; }

.about-team__official-info-detail-content {
  font-size: 1.6rem;
  line-height: 2.2rem;
  white-space: pre-line; }

.ds-project__section {
  transition: background-color .3s ease;
  padding-top: 6.8rem;
  padding-bottom: 4.3rem; }
  @media (max-width: 991px) {
    .ds-project__section {
      padding-top: 6rem;
      padding-bottom: 4rem; } }

.ds-project__wrapper {
  overflow: hidden; }
  .ds-project__wrapper.green {
    background-color: #48D597; }

.ds-project__image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 66%;
  border-radius: 0 500px 500px 0 / 0 1000px 1000px 0;
  padding: 6rem 1rem 8rem 5.5rem;
  margin-top: -2rem;
  margin-bottom: -2rem;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1199px) {
    .ds-project__image {
      width: 80%; } }
  @media (max-width: 991px) {
    .ds-project__image {
      width: 85%;
      padding: 5rem 1rem 6rem 4rem; } }
  @media (max-width: 767px) {
    .ds-project__image {
      width: 100%;
      border-radius: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 3rem 3rem 4rem 3rem; } }
  .ds-project__image:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: inherit; }

.ds-project__content {
  position: relative;
  z-index: 1; }

.ds-project__subtitle {
  max-width: 27rem;
  margin-bottom: 1.9rem;
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.2rem; }

.ds-project__title {
  margin-bottom: 1.1rem;
  margin-top: 0;
  color: #fff; }
  @media (max-width: 1199px) {
    .ds-project__title {
      font-size: 4.3rem;
      line-height: 4.8rem; } }
  @media (max-width: 991px) {
    .ds-project__title {
      font-size: 3.8rem;
      line-height: 4.4rem; } }
  @media (max-width: 767px) {
    .ds-project__title {
      font-size: 3.2rem;
      line-height: 3.4rem; } }

.ds-project__time {
  margin-bottom: 4.7rem;
  color: #fff; }

.all-photoes__section {
  padding-top: 6.9rem;
  padding-bottom: 5.2rem; }
  @media (max-width: 991px) {
    .all-photoes__section {
      padding-top: 2rem;
      padding-bottom: 4rem; } }
  @media (max-width: 479px) {
    .all-photoes__section .container {
      padding-left: 0;
      padding-right: 0; } }

.all-photoes__wrap {
  display: flex;
  flex-wrap: wrap; }

.all-photoes__item {
  width: 32.5rem;
  height: 32.5rem;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  vertical-align: top; }
  @media (max-width: 1199px) {
    .all-photoes__item {
      width: 30rem;
      height: 30rem !important; } }
  @media (max-width: 991px) {
    .all-photoes__item {
      margin-right: 1.5rem;
      display: block;
      margin-bottom: 0;
      width: 37.6rem;
      height: 37.6rem !important; } }
  @media (max-width: 479px) {
    .all-photoes__item {
      margin-right: 0; } }
  .all-photoes__item:nth-child(3n) {
    margin-right: 0; }
    @media (max-width: 991px) {
      .all-photoes__item:nth-child(3n) {
        margin-right: 1.5rem; } }
    @media (max-width: 479px) {
      .all-photoes__item:nth-child(3n) {
        margin-right: 0; } }
  .all-photoes__item .link {
    margin-bottom: 2.8rem;
    margin-left: 2.8rem;
    text-transform: uppercase;
    position: relative; }
    @media (max-width: 991px) {
      .all-photoes__item .link {
        display: none; } }

.all-photoes__link {
  text-transform: uppercase;
  text-align: center;
  margin-top: 1.5rem;
  display: none; }
  @media (max-width: 991px) {
    .all-photoes__link {
      display: block; } }

.all-photoes__dots {
  margin: 2.4rem 0 1.5rem 0; }
  .all-photoes__dots .slick-dots {
    justify-content: center; }
    .all-photoes__dots .slick-dots li.slick-active button {
      background-color: #FC6621; }

.hotel-carousel {
  width: 100%;
  height: 100%;
  border-radius: inherit; }
  @media (max-width: 991px) {
    .hotel-carousel {
      height: auto; } }
  .hotel-carousel__section {
    background-color: #000000;
    padding: 21rem 0 6rem 0;
    position: relative;
    overflow: hidden; }
    @media (max-width: 991px) {
      .hotel-carousel__section {
        padding: 5.3rem 0 0 0; } }
    @media (max-width: 991px) {
      .hotel-carousel__section .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0; } }
  .hotel-carousel__info {
    max-width: 52rem;
    color: #fff;
    position: relative;
    z-index: 3; }
    @media (max-width: 991px) {
      .hotel-carousel__info {
        max-width: 73.8rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .hotel-carousel__info {
        padding-left: 2.4rem;
        padding-right: 2.4rem; } }
    .hotel-carousel__info .info-icon {
      width: 100%;
      margin-bottom: 2rem; }
      .hotel-carousel__info .info-icon svg {
        font-size: 7.1rem; }
    .hotel-carousel__info .info-title {
      width: 100%;
      margin-bottom: 1rem; }
    .hotel-carousel__info .info-subtitle {
      width: 100%;
      text-transform: uppercase;
      margin-bottom: .5rem; }
    .hotel-carousel__info .info-addredd {
      width: 100%;
      margin-bottom: 4rem; }
  .hotel-carousel__wrapper {
    width: 67rem;
    height: 67rem;
    border-radius: 50%;
    position: absolute;
    top: -19rem;
    right: -10rem;
    z-index: 2; }
    @media (max-width: 991px) {
      .hotel-carousel__wrapper {
        position: relative;
        border-radius: 0;
        top: auto;
        right: auto;
        width: 100%;
        height: auto;
        margin-top: 7rem;
        background-color: #F5F5F5; } }
  .hotel-carousel__item {
    width: 67rem;
    height: 67rem !important;
    border-radius: 50%;
    overflow: hidden; }
    @media (max-width: 991px) {
      .hotel-carousel__item {
        border-radius: 0;
        width: 37.6rem;
        height: 37.6rem !important; } }
    .hotel-carousel__item img {
      border-radius: inherit; }
  .hotel-carousel__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.2rem; }
  .hotel-carousel__action {
    opacity: 1;
    transition: opacity .3s ease; }
    @media (max-width: 991px) {
      .hotel-carousel__action {
        display: none; } }
    .hotel-carousel__action button {
      display: flex;
      align-items: center; }
    .hotel-carousel__action.slick-disabled {
      opacity: 0.5; }
    .hotel-carousel__action.hotel-prev {
      transform: rotate(180deg);
      margin-right: 3.3rem; }
    .hotel-carousel__action.hotel-next {
      margin-left: 3.3rem; }
    .hotel-carousel__action .icon-arrow {
      fill: #fff;
      font-size: 1.6rem; }
  .hotel-carousel__dots li.slick-active button {
    background-color: #FC6621; }

.speaker-inner__section {
  padding-top: 1.7rem;
  padding-bottom: 12.1rem;
  background: #F5F5F5; }
  @media (max-width: 991px) {
    .speaker-inner__section {
      padding-bottom: 0; } }

.speaker-inner__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .speaker-inner__content {
      flex-wrap: wrap; } }

.speaker__navigation {
  margin-bottom: 2.9rem;
  margin-left: -2.3rem; }
  @media (max-width: 767px) {
    .speaker__navigation {
      margin-left: 0; } }
  .speaker__navigation .link {
    text-transform: uppercase; }

.speaker__main-side {
  width: 25rem; }
  @media (max-width: 991px) {
    .speaker__main-side {
      width: 100%;
      margin-bottom: 10.5rem; } }
  .speaker__main-side .speaker-block-wrap {
    width: 100%; }
    @media (max-width: 991px) {
      .speaker__main-side .speaker-block-wrap {
        margin-bottom: 0; } }
  @media (max-width: 991px) {
    .speaker__main-side .speaker-header {
      justify-content: center;
      margin-bottom: 6.4rem; } }
  @media (max-width: 991px) {
    .speaker__main-side .speaker-view {
      width: 9rem;
      height: 9rem; } }
  @media (max-width: 991px) {
    .speaker__main-side .speaker-content {
      padding-left: 0; } }

.speaker__text-side {
  width: 54%; }
  @media (max-width: 991px) {
    .speaker__text-side {
      width: 100%;
      margin-bottom: 10rem; } }
  @media (max-width: 767px) {
    .speaker__text-side {
      margin-bottom: 7rem; } }
  .speaker__text-side p.bold {
    font-weight: 500; }

.speaker__text-footer {
  margin-top: 7rem; }
  .speaker__text-footer p {
    margin-bottom: 1.5rem; }
    @media (max-width: 991px) {
      .speaker__text-footer p {
        margin-bottom: 2.5rem; } }

.speaker__text-time {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  margin-bottom: 1rem; }
  @media (max-width: 991px) {
    .speaker__text-time {
      margin-bottom: 2rem; } }

.lead-partners__section {
  background-color: #F5F5F5; }
  .lead-partners__section .partner-boxes__content {
    align-items: flex-start; }
  .lead-partners__section .partner-boxes__aside {
    margin-bottom: 0;
    margin-top: 5.7rem; }
  .lead-partners__section .partner-boxes__title {
    width: 100%;
    margin-bottom: 1.8rem;
    text-transform: uppercase; }
  .lead-partners__section .partner-boxes__aside-content {
    height: auto; }
    .lead-partners__section .partner-boxes__aside-content + .partner-boxes__aside-content {
      margin-top: 1.6rem; }

.partner-boxes__block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem; }

.platform-box_lg {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 6rem; }
  @media (max-width: 767px) {
    .platform-box_lg {
      width: 32.5rem; } }
  .platform-box_lg-wrap {
    width: 32.5rem;
    height: 32.5rem;
    margin-right: 1.6rem;
    margin-bottom: 1.6rem; }
    @media (max-width: 1199px) {
      .platform-box_lg-wrap {
        width: 30.5rem;
        height: 30.5rem; } }
    @media (max-width: 991px) {
      .platform-box_lg-wrap {
        width: 32.5rem;
        height: 32.5rem; } }
    @media (max-width: 767px) {
      .platform-box_lg-wrap {
        margin-right: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start; } }
    @media (max-width: 479px) {
      .platform-box_lg-wrap {
        height: 30rem; } }
  .platform-box_lg-partner {
    position: relative;
    z-index: 2; }
  .platform-box_lg.with_img:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .platform-box_lg.with_img-partner img {
    filter: grayscale(100%); }

.registration-step__section {
  background-color: #F5F5F5;
  padding-bottom: 5rem; }
  @media (max-width: 767px) {
    .registration-step__section {
      padding-bottom: 2rem; } }
  .registration-step__section .step__wrapper {
    background-color: #fff;
    padding: 2.4rem 1.6rem; }
    @media (max-width: 1199px) {
      .registration-step__section .step__wrapper {
        padding: 2rem 1rem; } }
    @media (max-width: 767px) {
      .registration-step__section .step__wrapper {
        background-color: transparent;
        padding: 0 0 2rem 0;
        margin-left: -1.6rem;
        margin-right: -1.6rem; } }
  .registration-step__section .step__boxes {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .registration-step__section .step__box {
    width: 100%;
    text-align: right;
    position: relative;
    white-space: nowrap; }
    .registration-step__section .step__box:after {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      min-width: 15px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      content: '';
      background-color: rgba(0, 0, 0, 0.3); }
    .registration-step__section .step__box span {
      background-color: #fff;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.6rem;
      font-weight: 900;
      text-transform: uppercase;
      z-index: 1;
      position: relative; }
      @media (max-width: 767px) {
        .registration-step__section .step__box span {
          background: #F5F5F5; } }
      @media (max-width: 479px) {
        .registration-step__section .step__box span {
          font-size: 1.5rem;
          padding-left: 9px;
          padding-right: 9px; } }
    .registration-step__section .step__box:first-child {
      width: auto; }
      .registration-step__section .step__box:first-child:after {
        display: none; }
    .registration-step__section .step__box.active span {
      color: #FC6621; }
    .registration-step__section .step__box.done span {
      color: #000; }
    .registration-step__section .step__box.done + .step__box:after {
      background-color: #000; }

.reg-price__section {
  background-color: #F5F5F5;
  padding-bottom: 9.7rem; }

.reg-price__wrapper {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .reg-price__wrapper {
      flex-wrap: wrap; } }

.reg-price__content {
  width: calc(100% - 32.4rem);
  padding-right: 1.6rem; }
  @media (max-width: 991px) {
    .reg-price__content {
      width: 100%;
      padding-right: 0;
      margin-bottom: 6.5rem; } }

.reg-price__block {
  margin-bottom: 1.6rem; }
  .reg-price__block .link {
    text-transform: uppercase; }
    .reg-price__block .link.active .icon-arrow {
      transform: rotate(270deg);
      fill: #FC6621;
      animation: none; }
    .reg-price__block .link + .link {
      margin-left: 3.5rem; }
  .reg-price__block:last-child {
    margin-bottom: 0; }
  .reg-price__block-content {
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    background-color: #fff; }
    @media (max-width: 767px) {
      .reg-price__block-content {
        flex-wrap: wrap;
        padding: 3rem 0 0 0; } }
  .reg-price__block-dropdown {
    background-color: #fff;
    margin-top: 1px; }
    .reg-price__block-dropdown .dropdown-link__wrap {
      display: none;
      padding: 1.8rem 3rem;
      border-top: 1px solid #d8d8d8; }
      @media (max-width: 767px) {
        .reg-price__block-dropdown .dropdown-link__wrap {
          display: block; } }
  .reg-price__block-list {
    padding: 4.6rem 8.5rem 7.4rem 8.5rem;
    display: none; }
    @media (max-width: 991px) {
      .reg-price__block-list {
        padding: 3rem 3.3rem; } }
    @media (max-width: 767px) {
      .reg-price__block-list {
        padding: 1rem 3.3rem 3rem 3.3rem; } }
    .reg-price__block-list .price__block-list {
      list-style: none;
      margin: 0;
      padding: 0; }
      .reg-price__block-list .price__block-list li {
        font-size: 2rem;
        line-height: 2.8rem;
        position: relative;
        padding-left: 2.4rem; }
        .reg-price__block-list .price__block-list li:before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #FC6621;
          position: absolute;
          top: 10px;
          left: 0; }
  .reg-price__block-table {
    padding: 5px 3rem 2.5rem 3rem;
    display: none; }
    @media (max-width: 767px) {
      .reg-price__block-table {
        padding: 0 3rem 2.5rem 3rem; } }
    .reg-price__block-table .table-row {
      padding: 1.2rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.5);
      position: relative;
      width: 100%; }
      @media (max-width: 767px) {
        .reg-price__block-table .table-row {
          flex-wrap: wrap;
          padding: 2rem 0; } }
      .reg-price__block-table .table-row.active {
        color: #000; }
        .reg-price__block-table .table-row.active:before {
          content: '';
          display: none;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 50%;
          background-color: #FC6621;
          position: absolute;
          left: -37px; }
        .reg-price__block-table .table-row.active .row-name:after {
          content: 'now';
          display: inline-block;
          font-size: 1.6rem;
          color: #FC6621;
          font-weight: 500;
          margin-left: 1.4rem;
          position: absolute;
          right: -35px;
          top: 1px; }
      .reg-price__block-table .table-row + .table-row {
        border-top: 1px solid #D8D8D8; }
    .reg-price__block-table .row-name {
      min-width: 15rem;
      font-size: 2rem;
      line-height: 2.6rem;
      display: inline-flex;
      align-items: center;
      position: relative; }
      .reg-price__block-table .row-name.late-price {
        color: #000; }
      @media (max-width: 767px) {
        .reg-price__block-table .row-name {
          min-width: auto;
          width: auto;
          order: 0; } }
    .reg-price__block-table .row-time {
      min-width: 10rem;
      font-size: 1.6rem;
      line-height: 2.2rem; }
      @media (max-width: 767px) {
        .reg-price__block-table .row-time {
          min-width: auto;
          width: 100%;
          order: 2;
          margin-top: 1rem; } }
    .reg-price__block-table .row-sum {
      min-width: 14.5rem;
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 700;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .reg-price__block-table .row-sum {
          min-width: auto;
          order: 1;
          width: auto; } }
  .reg-price__block-info, .reg-price__block-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .reg-price__block-info {
    width: calc(100% - 16.5rem); }
    @media (max-width: 767px) {
      .reg-price__block-info {
        width: 100%; } }
    @media (max-width: 767px) {
      .reg-price__block-info .block-info__links {
        display: none; } }
  .reg-price__block-price {
    width: 14.5rem; }
    @media (max-width: 767px) {
      .reg-price__block-price {
        width: 100%; } }
    @media (max-width: 767px) {
      .reg-price__block-price .block-info__top {
        display: flex;
        align-items: center; } }
    @media (max-width: 479px) {
      .reg-price__block-price .block-info__top {
        flex-wrap: wrap; } }
    @media (max-width: 767px) {
      .reg-price__block-price .block-info__links {
        margin-top: 6rem;
        padding-bottom: 1.7rem; } }
  .reg-price__block-title {
    text-transform: uppercase;
    margin-top: 0; }
    @media (max-width: 767px) {
      .reg-price__block-title {
        font-size: 2rem;
        line-height: 2.2rem;
        margin-bottom: 1.7; } }
  .reg-price__block-duration {
    margin-bottom: 0; }
  .reg-price__block-conf {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1.5rem; }
    @media (max-width: 767px) {
      .reg-price__block-conf {
        margin-bottom: 5px; } }
    .reg-price__block-conf + .reg-price__block-duration {
      margin-top: 2.2rem; }
  .reg-price__block .block-info__top {
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .reg-price__block .block-info__top {
        margin-bottom: 7px;
        padding: 0 3rem; } }
  @media (max-width: 767px) {
    .reg-price__block .block-info__links {
      padding: 0 3rem;
      padding-bottom: 1.7rem; } }
  .reg-price__block .block-info__title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #FC6621;
    font-weight: 500;
    display: block;
    width: 100%; }
    @media (max-width: 767px) {
      .reg-price__block .block-info__title {
        display: inline-block;
        width: auto;
        order: 1;
        margin: 0 10px 0 0; } }
  .reg-price__block .block-info__sum {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.6rem; }
    @media (max-width: 767px) {
      .reg-price__block .block-info__sum {
        display: inline-block;
        width: auto;
        order: 0;
        margin-right: 10px; } }
  .reg-price__block .block-info__time {
    font-size: 1.6rem;
    line-height: 2.2rem; }
    @media (max-width: 767px) {
      .reg-price__block .block-info__time {
        display: inline-block;
        width: auto;
        order: 2; } }

.reg-price__aside {
  width: 32.4rem; }
  @media (max-width: 991px) {
    .reg-price__aside {
      width: 100%; } }
  .reg-price__aside-content {
    background-color: #fff;
    margin-bottom: 1.6rem;
    padding: 4rem; }
    @media (max-width: 991px) {
      .reg-price__aside-content {
        padding: 3rem; } }
    .reg-price__aside-content.aside-conditions {
      padding: 3.3rem 4.2rem .7rem 3rem; }
      @media (max-width: 991px) {
        .reg-price__aside-content.aside-conditions {
          background-color: transparent;
          padding: 0;
          margin-bottom: 3.2rem; } }
    .reg-price__aside-content:last-child {
      margin-bottom: 0; }
    @media (max-width: 767px) {
      .reg-price__aside-content .people-box__details {
        padding-left: 0; } }
    @media (max-width: 767px) {
      .reg-price__aside-content .people-box__pic {
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: 1.5rem; } }
  .reg-price__aside-title {
    margin-top: 0;
    margin-bottom: 2.6rem;
    text-transform: uppercase; }
  .reg-price__aside-list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .reg-price__aside-list li {
      margin-bottom: 2.3rem;
      position: relative; }
      .reg-price__aside-list li:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FC6621;
        margin-bottom: 7px; }
        @media (max-width: 991px) {
          .reg-price__aside-list li:before {
            width: 1rem;
            height: 1rem; } }
  .reg-price__aside .people-box__name {
    min-height: auto; }
  .reg-price__aside .people-box__sub-position,
  .reg-price__aside .people-box__contacts {
    font-size: 1.6rem;
    line-height: 2.2rem; }

.section-post-main {
  color: #ffffff;
  overflow: hidden;
  min-height: 100vh;
  background-color: #FC6621; }
  .section-post-main .section-conference-header {
    padding-bottom: 0; }
    @media (max-width: 1199px) {
      .section-post-main .section-conference-header {
        padding-top: 3.6rem; } }
  .section-post-main .conference-header__buttons {
    margin-bottom: 0; }

.section-post-main-content {
  margin-top: 8vh; }
  @media (max-width: 991px) {
    .section-post-main-content {
      margin-top: 12vh;
      padding-bottom: 8.6rem; } }
  @media (max-width: 767px) {
    .section-post-main-content {
      margin-top: 1.5rem; } }

.post-main__content-header .section-title .section-title__main h2 {
  font-size: 5.6rem;
  line-height: 6.4rem; }
  @media (max-width: 767px) {
    .post-main__content-header .section-title .section-title__main h2 {
      font-size: 3.2rem;
      line-height: 3.4rem; } }

.post-main__content-body {
  display: flex;
  margin-top: 4.1rem;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .post-main__content-body {
      flex-direction: column; } }
  @media (max-width: 767px) {
    .post-main__content-body {
      margin-top: 4.6rem; } }

.post-main__content-values {
  width: 51%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.6rem;
  align-items: stretch; }
  @media (max-width: 991px) {
    .post-main__content-values {
      width: 100%;
      padding-top: 0; } }

.post-main__content-value {
  width: 33.33333%;
  padding-right: 1.6rem; }
  @media (max-width: 600px) {
    .post-main__content-value {
      width: 50%;
      padding-right: 1rem; } }
  @media (max-width: 360px) {
    .post-main__content-value {
      width: 100%; } }
  @media (min-width: 601px) {
    .post-main__content-value:nth-child(n+4) {
      margin-top: 3.7rem; } }
  @media (min-width: 361px) and (max-width: 600px) {
    .post-main__content-value:nth-child(n+3) {
      margin-top: 3.7rem; } }
  @media (max-width: 360px) {
    .post-main__content-value:nth-child(n+2) {
      margin-top: 3.5rem; } }
  .post-main__content-value b {
    display: block;
    font-weight: 900;
    font-size: 4.8rem;
    line-height: 5rem; }
  .post-main__content-value span {
    display: block;
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: .7rem;
    line-height: 2.2rem; }

.post-main__content-view {
  width: 41%;
  display: flex; }
  @media (max-width: 991px) {
    .post-main__content-view {
      width: 100%;
      margin-top: 3.9rem;
      justify-content: center; } }

.post-main__content-view-content {
  position: relative; }
  .post-main__content-view-content picture {
    width: 24rem;
    height: 24rem;
    display: block;
    overflow: hidden;
    border-radius: 50%; }

.post-main__content-view-action {
  top: 50%;
  left: 100%;
  position: absolute;
  white-space: nowrap;
  margin-top: -2.4rem;
  margin-left: -2.4rem; }
  @media (max-width: 767px) {
    .post-main__content-view-action {
      left: 50%; } }
  @media (max-width: 767px) {
    .post-main__content-view-action a i {
      margin-right: 0; } }
  @media (max-width: 767px) {
    .post-main__content-view-action a span {
      display: none; } }

.section-expect {
  margin-top: 11.1rem;
  margin-bottom: 11.1rem; }
  @media (max-width: 767px) {
    .section-expect {
      margin-top: 10.5rem;
      margin-bottom: 10.5rem; } }
  .section-expect .section-title .section-title__main h5 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3rem;
    margin-top: 2.8rem; }
    @media (max-width: 767px) {
      .section-expect .section-title .section-title__main h5 {
        margin-top: 3.2rem; } }
  @media (max-width: 991px) {
    .section-expect .section-img {
      display: none; } }

.expect__content {
  margin-top: 14.1rem; }
  @media (max-width: 767px) {
    .expect__content {
      margin-top: 7.2rem; } }

.expect__items {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }

.expect__item {
  width: 25%;
  padding-right: 2rem; }
  @media (max-width: 991px) {
    .expect__item {
      width: 33.33333%; } }
  @media (max-width: 767px) {
    .expect__item {
      width: 50%; } }
  @media (max-width: 479px) {
    .expect__item {
      width: 100%;
      padding-right: 0; } }
  @media (min-width: 992px) {
    .expect__item:nth-child(n+5) {
      margin-top: 5.1rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .expect__item:nth-child(n+4) {
      margin-top: 5.1rem; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .expect__item:nth-child(n+3) {
      margin-top: 5.1rem; } }
  @media (max-width: 479px) {
    .expect__item:nth-child(n+2) {
      margin-top: 5.1rem; } }

.expect-box__view {
  height: 9.8rem; }
  .expect-box__view svg {
    width: 1em;
    height: 1em;
    vertical-align: top;
    display: inline-block;
    font-size: 7rem; }
  .expect-box__view img {
    width: 7rem;
    height: auto; }

.expect-box__name {
  font-weight: 900;
  font-size: 2rem;
  min-height: 6.2rem;
  line-height: 2.2rem; }
  @media (max-width: 479px) {
    .expect-box__name {
      min-height: auto; } }

.expect-box__caption {
  font-size: 2rem;
  margin-top: .8rem;
  line-height: 2.8rem; }
  @media (max-width: 767px) {
    .expect-box__caption {
      margin-top: 1.3rem; } }

.section-takeways {
  margin-top: 7.6rem;
  margin-bottom: 7.6rem; }
  @media (max-width: 767px) {
    .section-takeways {
      margin-top: 5.2rem; } }

.takeways__content-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .takeways__content-header {
      flex-direction: column; } }

.takeways__content-header-text {
  width: 33%;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  position: relative;
  padding-top: 6.7rem; }
  @media (max-width: 991px) {
    .takeways__content-header-text {
      width: 100%; } }
  .takeways__content-header-text:after {
    top: 0;
    left: 0;
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6621; }

.takeways__content-header-caption {
  width: 57.7%;
  font-size: 2rem;
  padding-right: 8%;
  line-height: 2.8rem;
  padding-top: 6.7rem; }
  @media (max-width: 1199px) {
    .takeways__content-header-caption {
      padding-right: 0; } }
  @media (max-width: 991px) {
    .takeways__content-header-caption {
      width: 100%;
      padding-top: 0;
      margin-top: 4.5rem; } }

.takeways__content-charts {
  /* margin-top: 12.1rem; */ }
  @media (max-width: 767px) {
    .takeways__content-charts {
      /* margin-top: 10.2rem; */ } }

.takeways__content-charts-nav {
  width: 49%;
  margin-bottom: 6rem; }
  @media (max-width: 991px) {
    .takeways__content-charts-nav {
      width: 65%; } }
  @media (max-width: 767px) {
    .takeways__content-charts-nav {
      width: 100%;
      margin-bottom: 10.3rem; }
      .takeways__content-charts-nav ul {
        overflow-x: auto; } }

.takeways__content-charts-content {
  margin-bottom: 6rem; }

@media (max-width: 767px) {
  .takeways-chart {
    margin-left: -2.4rem;
    width: calc(100% + 4.8rem); } }

.takeways-chart__items {
  display: flex; }
  @media (max-width: 991px) {
    .takeways-chart__items {
      overflow-x: auto; } }
  @media (max-width: 767px) {
    .takeways-chart__items {
      padding-left: 2.4rem; } }

.takeways-chart__item {
  width: 100%; }
  @media (max-width: 991px) {
    .takeways-chart__item {
      min-width: 15.6rem; } }
  .takeways-chart__item > i {
    width: 100%;
    display: flex;
    height: 24.3rem;
    position: relative;
    align-items: flex-end;
    margin-bottom: 1.3rem;
    border-bottom: 0.2rem solid #D8D8D8; }
    .takeways-chart__item > i i {
      width: .2rem;
      display: block;
      background-color: #000000; }
  .takeways-chart__item b {
    display: block;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.8rem; }
  .takeways-chart__item span {
    display: block;
    font-size: 1.6rem;
    margin-top: .7rem;
    line-height: 2.2rem;
    padding-right: 1.6rem; }
  .takeways-chart__item.first > i {
    margin-bottom: .6rem; }
    .takeways-chart__item.first > i i {
      width: .9rem;
      background-color: #FC6621; }
  .takeways-chart__item.first b {
    font-size: 2.4rem;
    line-height: 2.8rem; }
  .takeways-chart__item.first span {
    margin-top: .4rem; }
  .takeways-chart__item.second > i {
    margin-bottom: .6rem; }
  .takeways-chart__item.second b {
    font-size: 2.4rem;
    line-height: 2.8rem; }
  .takeways-chart__item.second span {
    margin-top: .4rem; }

.takeways__content-answer {
  margin-top: 10.9rem;
  margin-bottom: 10.9rem; }
  @media (max-width: 767px) {
    .takeways__content-answer {
      margin-top: 13.9rem; } }

.takeways__content-answer-header {
  margin-bottom: 5.1rem; }

.takeways__content-answer-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .takeways__content-answer-content {
      flex-direction: column; } }

.takeways__content-answer-nav {
  width: 40.7%; }
  @media (max-width: 1199px) {
    .takeways__content-answer-nav {
      width: 45%; } }
  @media (max-width: 767px) {
    .takeways__content-answer-nav {
      width: 100%; } }
  .takeways__content-answer-nav ul li + li {
    margin-top: .2rem; }
  .takeways__content-answer-nav ul li a {
    color: #000000;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    min-height: 8rem;
    position: relative;
    align-items: center;
    line-height: 2.6rem;
    padding-top: 1.6rem;
    padding-left: 4.8rem;
    padding-right: 3.2rem;
    text-decoration: none;
    padding-bottom: 1.6rem;
    background-color: #F5F5F5; }
    .takeways__content-answer-nav ul li a span {
      display: block; }
    .takeways__content-answer-nav ul li a:after {
      top: 50%;
      content: '';
      left: 2.4rem;
      width: .8rem;
      height: .8rem;
      display: block;
      position: absolute;
      margin-top: -.4rem;
      border-radius: 50%;
      background-color: #D8D8D8;
      transition: all 0.3s ease; }
    .takeways__content-answer-nav ul li a.active:after {
      background-color: #FC6621; }

.takeways__content-answer-list {
  width: 49.2%;
  padding-right: 8%; }
  @media (max-width: 1199px) {
    .takeways__content-answer-list {
      width: 48%;
      padding-right: 0; } }
  @media (max-width: 767px) {
    .takeways__content-answer-list {
      width: 100%;
      margin-top: 6.4rem; } }

.takeways__content-answer-item {
  position: relative;
  padding-top: 4.8rem; }
  .takeways__content-answer-item:after {
    left: 0;
    top: .4rem;
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6621; }

.takeways__content-answer-item-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 2.4rem; }

.reg-success__section {
  background-color: #F5F5F5;
  padding-bottom: 7.6rem; }
  @media (max-width: 991px) {
    .reg-success__section {
      padding-top: 13rem; } }
  @media (max-width: 767px) {
    .reg-success__section {
      padding-top: 3rem;
      padding-bottom: 4rem; } }
  @media (max-width: 479px) {
    .reg-success__section {
      padding-bottom: 0; } }
  @media (max-width: 991px) {
    .reg-success__section .section-conference-header {
      padding-top: 0;
      padding-bottom: 0; } }

.reg-success__icon {
  margin-bottom: 2.3rem; }
  .reg-success__icon .icon-success {
    font-size: 7.2rem;
    color: #000; }

.reg-success__title {
  text-transform: uppercase;
  margin-top: 0; }
  @media (max-width: 1199px) {
    .reg-success__title {
      font-size: 4.3rem;
      line-height: 4.8rem; } }
  @media (max-width: 991px) {
    .reg-success__title {
      font-size: 3.8rem;
      line-height: 4.4rem; } }
  @media (max-width: 767px) {
    .reg-success__title {
      font-size: 3.2rem;
      line-height: 3.4rem; } }

.reg-success__subtitle {
  font-weight: 500;
  margin-bottom: 4.8rem;
  font-size: 2.4rem;
  line-height: 3rem;
  white-space: pre-line; }

.reg-success__boxes-wrap {
  margin-right: -1.6rem;
  display: flex;
  justify-content: center;
  width: 100%; }
  @media (max-width: 991px) {
    .reg-success__boxes-wrap {
      flex-wrap: wrap; } }
  @media (max-width: 479px) {
    .reg-success__boxes-wrap {
      margin-right: 0; } }

.reg-success__box {
  width: 32.5rem;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  vertical-align: top;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem; }
  @media (max-width: 479px) {
    .reg-success__box {
      margin-right: 0; } }
  .reg-success__box-content {
    padding: 2.4rem 1.5rem 2rem 2.4rem;
    min-height: 24.5rem;
    width: 100%;
    background-color: #fff; }
    @media (max-width: 479px) {
      .reg-success__box-content {
        min-height: 22.5rem;
        padding: 2rem 1.5rem 1rem 1.7rem; } }
    .reg-success__box-content p {
      margin-bottom: 0; }
  .reg-success__box-link {
    height: 6.3rem;
    padding: 0 2.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    width: 100%; }
    @media (max-width: 479px) {
      .reg-success__box-link {
        padding: 0 1.7rem; } }
  .reg-success__box-title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 0;
    margin-bottom: 1.6rem; }

.reg-failed__section {
  padding-bottom: 14rem;
  background: #F5F5F5; }
  @media (max-width: 1439px) {
    .reg-failed__section {
      padding-bottom: 12rem; } }
  @media (max-width: 1199px) {
    .reg-failed__section {
      padding-bottom: 9rem; } }
  @media (max-width: 991px) {
    .reg-failed__section {
      padding-bottom: 6rem;
      padding-top: 13rem; } }
  @media (max-width: 767px) {
    .reg-failed__section {
      padding-top: 3rem; } }
  @media (max-width: 991px) {
    .reg-failed__section .section-conference-header {
      padding-bottom: 0;
      padding-top: 0; } }

.reg-failed__icon {
  margin-bottom: 2.3rem; }
  .reg-failed__icon .icon-failed {
    font-size: 7.2rem;
    color: #000; }

.reg-failed__content {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .reg-failed__content {
      flex-wrap: wrap; } }

.reg-failed__main {
  width: calc(100% - 37rem);
  padding-right: 5rem; }
  @media (max-width: 991px) {
    .reg-failed__main {
      width: 100%;
      padding-right: 0;
      margin-bottom: 8.4rem; } }
  .reg-failed__main .link {
    text-transform: uppercase;
    margin-left: -2rem; }
    @media (max-width: 991px) {
      .reg-failed__main .link {
        margin-left: 0; } }

.reg-failed__info {
  width: 37rem; }
  @media (max-width: 991px) {
    .reg-failed__info {
      width: 100%; } }
  @media (max-width: 991px) {
    .reg-failed__info .people-box__details {
      min-height: 12rem;
      padding-top: 1rem;
      padding-left: 14.4rem; } }
  @media (max-width: 767px) {
    .reg-failed__info .people-box__details {
      padding-left: 11.7rem; } }
  @media (max-width: 991px) {
    .reg-failed__info .people-box__pic {
      position: absolute;
      left: 0;
      top: 0;
      width: 12rem;
      height: 12rem; } }
  @media (max-width: 767px) {
    .reg-failed__info .people-box__pic {
      width: 9.3rem;
      height: 9.3rem; } }

.reg-failed__title {
  margin-top: 0;
  margin-bottom: 3rem;
  text-transform: uppercase; }
  @media (max-width: 1199px) {
    .reg-failed__title {
      font-size: 4.3rem;
      line-height: 4.8rem; } }
  @media (max-width: 991px) {
    .reg-failed__title {
      font-size: 3.8rem;
      line-height: 4.4rem; } }
  @media (max-width: 767px) {
    .reg-failed__title {
      font-size: 3.2rem;
      line-height: 3.4rem; } }

.reg-failed__text {
  font-weight: 500; }

.section-registration {
  padding-bottom: 15.5rem;
  background-color: #F5F5F5; }
  @media (max-width: 767px) {
    .section-registration {
      padding-bottom: 8rem; } }

.registration__content {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .registration__content {
      flex-direction: column; } }

.registration__list {
  width: 67.7%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  align-items: flex-start; }
  @media (max-width: 991px) {
    .registration__list {
      width: 100%; } }
  @media (max-width: 767px) {
    .registration__list {
      margin-left: 0; } }

.registration__item {
  width: 50%;
  padding-left: 1.6rem;
  margin-bottom: 3.4rem; }
  @media (max-width: 767px) {
    .registration__item {
      width: 100%;
      padding-left: 0; } }

.registration-step-box {
  height: 100%;
  position: relative;
  padding-bottom: 3rem;
  background-color: #ffffff; }

.registration-step-box__header {
  color: #ffffff;
  height: 10.8rem;
  padding-top: 1.6rem;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  padding-bottom: 1.6rem;
  background-color: #000000; }

.registration-step-box__title {
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  margin-bottom: .6rem; }

.registration-step-box__caption {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem; }

.registration-step-box__body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 10.8rem); }

.registration-step-box__include {
  flex-shrink: 0;
  border-bottom: 1px solid #D8D8D8; }

.registration-step-box__include-btn a {
  width: 100%;
  height: 5.9rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }

.registration-step-box__include-btn.active a svg {
  fill: #FC6621;
  transform: rotateZ(-90deg); }

@media (min-width: 992px) {
  .registration-step-box__include-btn.active a:hover svg {
    animation-name: none; } }

.registration-step-box__include-content {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  top: 16.8rem;
  display: none;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 2rem;
  position: absolute;
  padding-left: 2.4rem;
  padding-bottom: 2rem;
  background-color: #ffffff; }
  .registration-step-box__include-content ul {
    height: 100%;
    /* overflow-y: hidden; */
    padding-right: 2.4rem; }
    .registration-step-box__include-content ul li {
      font-size: 1.6rem;
      position: relative;
      line-height: 2.2rem;
      padding-left: 1.7rem; }
      .registration-step-box__include-content ul li:after {
        left: 0;
        top: .6rem;
        content: '';
        width: .8rem;
        height: .8rem;
        display: block;
        position: absolute;
        border-radius: 50%;
        background-color: #FC6621; }

.registration-step-box__info {
  display: flex;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.5rem;
  align-items: center;
  margin-top: 1.6rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  justify-content: space-between; }
  .registration-step-box__info svg {
    flex-shrink: 0;
    font-size: 2.4rem;
    margin-left: 2.4rem; }

.registration-step-box__form-list {
  margin-top: 2.2rem;
  padding-left: 3.1rem;
  padding-right: 3.1rem; }
  .registration-step-box__form-list dl {
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .registration-step-box__form-list dl:not(:last-child) {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #D8D8D8; }
    .registration-step-box__form-list dl dt {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.6rem;
      width: 50%; }
    .registration-step-box__form-list dl dd {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem; }

.registration-step-box__form-price {
  flex-shrink: 0;
  margin-top: .9rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem; }
  .registration-step-box__form-price .registration-step-box__price {
    opacity: .3;
    display: flex;
    padding-left: 0;
    position: relative;
    padding-top: .9rem;
    padding-bottom: .6rem;
    align-items: flex-start;
    justify-content: space-between; }
    .registration-step-box__form-price .registration-step-box__price:not(:last-child) {
      border-bottom: 1px solid #D8D8D8; }
    .registration-step-box__form-price .registration-step-box__price > b {
      display: block;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.8rem; }
    .registration-step-box__form-price .registration-step-box__price i {
      display: block;
      width: 11.8rem;
      text-align: left;
      font-style: normal;
      font-size: 1.6rem;
      line-height: 2.2rem; }
      .registration-step-box__form-price .registration-step-box__price i b {
        display: block;
        font-weight: 500; }
    .registration-step-box__form-price .registration-step-box__price.active {
      opacity: 1; }
      .registration-step-box__form-price .registration-step-box__price.active:after {
        top: 1.5rem;
        left: -3rem;
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        border-radius: 50%;
        position: absolute;
        background-color: #FC6621; }

.registration-step-box__form-delegates {
  height: 100%;
  display: flex;
  margin-top: 3.3rem;
  padding-left: 2.4rem;
  align-items: flex-end;
  padding-right: 2.4rem;
  justify-content: space-between; }

.item_register_link {
  display: flex;
  margin-top: 3.3rem;
  padding-left: 2.4rem;
  align-items: flex-end;
  padding-right: 2.4rem; }

.registration-step-box__form-delegates-title {
  font-size: 2rem;
  line-height: 2.8rem;
  white-space: nowrap; }

.registration-step-box__form-delegates-input {
  width: 5.9rem; }

.registration__aside {
  width: 32.4%;
  padding-top: 2rem; }
  @media (max-width: 991px) {
    .registration__aside {
      width: 100%;
      padding-top: 0;
      margin-top: 6.4rem; } }

.registration__aside-form {
  margin-bottom: 3.4rem; }
  .registration__aside-form .form-group_submit + .form-group {
    margin-top: .8rem; }

.registration__conditions {
  padding-left: 3.4rem;
  padding-right: 3.4rem; }
  @media (max-width: 991px) {
    .registration__conditions {
      padding-left: 0;
      padding-right: 0; } }

.registration__conditions-title {
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-bottom: 2.6rem; }

.registration__conditions-list ul li {
  font-size: 1.6rem;
  position: relative;
  padding-top: 1.6rem;
  line-height: 2.2rem; }
  .registration__conditions-list ul li:not(:last-child) {
    margin-bottom: 2.8rem; }
  .registration__conditions-list ul li:after {
    top: 0;
    left: 0;
    content: '';
    width: 1rem;
    height: 1rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6621; }

.registration__reg-process {
  margin-top: 5rem;
  padding-left: 4.2rem;
  padding-right: 4.2rem; }
  @media (max-width: 991px) {
    .registration__reg-process {
      padding-left: 0;
      padding-right: 0; } }

.registration__reg-process-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 2.5rem; }

.registration__reg-process-unit {
  font-size: 1.6rem;
  line-height: 2.2rem; }

.registration__reg-process-unit-view {
  width: 7rem;
  height: 7rem;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1.4rem; }
  .registration__reg-process-unit-view picture {
    width: 100%;
    height: 100%;
    display: block; }

.registration__reg-process-unit-name {
  text-transform: uppercase; }

.registration__reg-process-unit-position {
  margin-top: .3rem; }

.registration__reg-process-unit-address {
  font-weight: 500; }

.reg-content__section {
  background: #F5F5F5;
  padding-bottom: 9rem; }
  @media (max-width: 991px) {
    .reg-content__section {
      padding-bottom: 9rem; } }
  @media (max-width: 767px) {
    .reg-content__section {
      padding-bottom: 3rem; } }

.reg-content__wrapper {
  display: flex;
  align-items: flex-start;
  position: relative; }
  @media (max-width: 991px) {
    .reg-content__wrapper {
      flex-wrap: wrap; } }

.reg-content__main {
  width: calc(100% - 32.6rem);
  padding-right: 1.6rem; }
  @media (max-width: 991px) {
    .reg-content__main {
      width: 100%;
      padding-right: 0; } }
  .reg-content__main .text-block-title {
    margin-bottom: 2rem;
    font-weight: 500;
    width: 100%; }
    @media (max-width: 767px) {
      .reg-content__main .text-block-title {
        font-size: 2.1rem; } }

.reg-content__aside {
  width: 32.6rem;
  margin-top: 5.6rem; }
  @media (max-width: 991px) {
    .reg-content__aside {
      margin: 0 auto; } }
  @media (max-width: 479px) {
    .reg-content__aside {
      width: 100%; } }
  .reg-content__aside-link {
    width: 100%;
    height: 4.8rem;
    text-transform: uppercase;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background-color: #FC6621;
    opacity: 1;
    transition: opacity .3s ease;
    font-weight: 900; }
    @media (min-width: 992px) {
      .reg-content__aside-link:hover {
        opacity: 0.8; } }
  .reg-content__aside .form-box_submit {
    display: none;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .reg-content__aside .form-box_submit {
        margin-top: 0; } }
    .reg-content__aside .form-box_submit.active {
      display: block; }
    .reg-content__aside .form-box_submit .btn {
      text-transform: uppercase; }

.reg-content__navigation {
  margin-bottom: 3.5rem; }
  .reg-content__navigation .link {
    text-transform: uppercase; }

.reg-content__boxes-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;
  margin-right: -1.6rem; }
  @media (max-width: 767px) {
    .reg-content__boxes-wrapper {
      margin-right: 0;
      margin-bottom: 2.2rem; } }
  .reg-content__boxes-wrapper.boxes-text {
    margin-bottom: 8.5rem; }
    @media (max-width: 991px) {
      .reg-content__boxes-wrapper.boxes-text {
        margin-bottom: 4rem; } }
    .reg-content__boxes-wrapper.boxes-text .reg-content__box {
      background-color: transparent;
      padding: 0; }
    .reg-content__boxes-wrapper.boxes-text .worker-name {
      margin-bottom: 6px; }

.reg-content__box {
  width: calc((100% - 3.2rem) / 2);
  padding: 2.4rem;
  background-color: #fff;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem; }
  @media (max-width: 767px) {
    .reg-content__box {
      width: 100%;
      margin-right: 0; } }
  @media (min-width: 992px) {
    .reg-content__box a:hover {
      color: #FC6621; } }
  .reg-content__box .worker-name {
    margin-bottom: 1.6rem;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-transform: uppercase; }
  .reg-content__box .worker-position, .reg-content__box .worker-mail, .reg-content__box .worker-address {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 2px;
    margin-top: 0; }
  .reg-content__box .worker-mail {
    display: block;
    margin-bottom: 1.6rem; }

.reg-content__confirm-info {
  margin-top: 6rem;
  margin-bottom: 8.2rem; }
  @media (max-width: 991px) {
    .reg-content__confirm-info {
      margin-bottom: 6rem;
      margin-top: 5rem; } }
  @media (max-width: 479px) {
    .reg-content__confirm-info p {
      font-size: 2.2rem;
      line-height: 2.6rem; } }

.reg-content__add-info {
  margin-bottom: 4rem; }
  @media (max-width: 991px) {
    .reg-content__add-info {
      margin-bottom: 9rem; } }
  .reg-content__add-info .text-block-title {
    margin-bottom: 1.1rem; }

.reg-content__section {
  background: #F5F5F5;
  padding-bottom: 9rem; }
  @media (max-width: 991px) {
    .reg-content__section {
      padding-bottom: 9rem; } }
  @media (max-width: 767px) {
    .reg-content__section {
      padding-bottom: 3rem; } }

.reg-content__wrapper {
  display: flex;
  position: relative; }
  @media (max-width: 991px) {
    .reg-content__wrapper {
      flex-wrap: wrap; } }

.reg-content__main {
  width: calc(100% - 32.6rem);
  padding-right: 1.6rem; }
  @media (max-width: 991px) {
    .reg-content__main {
      width: 100%;
      padding-right: 0; } }
  .reg-content__main .text-block-title {
    margin-bottom: 2rem;
    font-weight: 500;
    width: 100%; }
    @media (max-width: 767px) {
      .reg-content__main .text-block-title {
        font-size: 2.1rem; } }

.reg-content__aside {
  width: 32.6rem;
  margin-top: 5.6rem; }
  @media (max-width: 991px) {
    .reg-content__aside {
      margin: 0 auto; } }
  @media (max-width: 479px) {
    .reg-content__aside {
      width: 100%; } }
  .reg-content__aside-inner {
    transform: translateY(0);
    transition: transform .3s ease; }
    .reg-content__aside-inner.sticked {
      transform: translateY(4rem); }
      @media (max-width: 991px) {
        .reg-content__aside-inner.sticked {
          transform: translateY(0); } }
  .reg-content__aside-link {
    width: 100%;
    height: 4.8rem;
    text-transform: uppercase;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background-color: #FC6621;
    opacity: 1;
    transition: opacity .3s ease;
    font-weight: 900; }
    @media (min-width: 992px) {
      .reg-content__aside-link:hover {
        opacity: 0.8; } }
  .reg-content__aside .form-box_submit {
    display: none;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .reg-content__aside .form-box_submit {
        margin-top: 0; } }
    .reg-content__aside .form-box_submit.active {
      display: block; }
    .reg-content__aside .form-box_submit .btn {
      text-transform: uppercase; }

.reg-content__navigation {
  margin-bottom: 3.5rem; }
  .reg-content__navigation .link {
    text-transform: uppercase; }

.reg-content__boxes-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4rem; }
  @media (max-width: 767px) {
    .reg-content__boxes-wrapper {
      margin-bottom: 2.2rem; } }
  .reg-content__boxes-wrapper.boxes-text {
    margin-bottom: 8.5rem; }
    @media (max-width: 991px) {
      .reg-content__boxes-wrapper.boxes-text {
        margin-bottom: 4rem; } }
    .reg-content__boxes-wrapper.boxes-text .reg-content__box {
      background-color: transparent;
      padding: 0; }
    .reg-content__boxes-wrapper.boxes-text .worker-name {
      margin-bottom: 6px; }

.reg-content__boxes-inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.6rem; }
  @media (max-width: 991px) {
    .reg-content__boxes-inner {
      width: 100vw;
      margin-right: 0;
      display: block; } }
  @media (max-width: 991px) {
    .reg-content__boxes-inner .reg-content__box {
      width: 235px !important;
      height: 235px !important;
      margin-bottom: 0; } }

.reg-content__box {
  width: calc((100% - 3.2rem) / 2);
  padding: 2.4rem;
  background-color: #fff;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  display: inline-block; }
  @media (max-width: 991px) {
    .reg-content__box {
      width: 100%; } }
  @media (min-width: 992px) {
    .reg-content__box a:hover {
      color: #FC6621; } }
  .reg-content__box .worker-name {
    margin-bottom: 1.6rem;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-transform: uppercase; }
  .reg-content__box .worker-position, .reg-content__box .worker-mail, .reg-content__box .worker-address {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 2px;
    margin-top: 0; }
  .reg-content__box .worker-mail {
    display: block;
    margin-bottom: 1.6rem; }

.reg-content__confirm-info {
  margin-top: 6rem;
  margin-bottom: 8.2rem; }
  @media (max-width: 991px) {
    .reg-content__confirm-info {
      margin-bottom: 6rem;
      margin-top: 5rem; } }
  @media (max-width: 479px) {
    .reg-content__confirm-info p {
      font-size: 2.2rem;
      line-height: 2.6rem; } }

.reg-content__add-info {
  margin-bottom: 4rem; }
  @media (max-width: 991px) {
    .reg-content__add-info {
      margin-bottom: 9rem; } }
  .reg-content__add-info .text-block-title {
    margin-bottom: 1.1rem; }

.reg-content__tab-wrapper {
  width: 100%;
  background-color: #fff; }
  .reg-content__tab-wrapper .tab-nav {
    width: 100%; }
    @media (max-width: 767px) {
      .reg-content__tab-wrapper .tab-nav {
        justify-content: center; } }
    .reg-content__tab-wrapper .tab-nav li {
      width: auto; }
      .reg-content__tab-wrapper .tab-nav li a {
        padding: 0 2.5rem;
        min-height: 7.3rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        border-bottom: 4px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media (max-width: 767px) {
          .reg-content__tab-wrapper .tab-nav li a {
            font-size: 1.5rem; } }
        @media (max-width: 479px) {
          .reg-content__tab-wrapper .tab-nav li a {
            padding: 0 1.5rem; } }
        .reg-content__tab-wrapper .tab-nav li a.active {
          border-bottom: 4px solid #FC6621; }

.reg-content__tab-content .form-text {
  margin-top: 2.8rem;
  margin-bottom: 4rem; }

.reg-content__tab-content .form-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 4rem auto 0 auto; }
  .reg-content__tab-content .form-wrapper .row-box {
    margin-bottom: 2.5rem; }
  .reg-content__tab-content .form-wrapper .select-trigger {
    height: 2.2rem; }
  .reg-content__tab-content .form-wrapper .form-check input:checked + span:after {
    background-color: #FC6621; }

.reg-content__tab-content .form-check {
  margin-bottom: 6.4rem; }

.reg-content__tab-content .form-group {
  margin-bottom: 3.5rem; }
  .reg-content__tab-content .form-group input {
    background-color: transparent; }

.content-title {
  margin-top: 0;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 3.3rem; }
  @media (max-width: 767px) {
    .content-title {
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin-bottom: 2.4rem; } }
  @media (max-width: 479px) {
    .content-title {
      font-size: 2.4rem;
      line-height: 3rem;
      margin-bottom: 2rem; } }

.reg-aside__block {
  margin-bottom: 2.2rem;
  width: 100%; }

.reg-aside__content {
  background-color: #fff;
  padding: 2.1rem 3.2rem 3.8rem 3.2rem; }
  @media (max-width: 479px) {
    .reg-aside__content {
      padding: 2rem 2.5rem 3rem 2.5rem; } }

.reg-aside__footer {
  background-color: #000;
  padding: 3rem 3.2rem;
  color: #fff; }
  @media (max-width: 479px) {
    .reg-aside__footer {
      padding: 2rem 2.5rem; } }
  .reg-aside__footer-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
    font-size: 1.6rem;
    line-height: 2.2rem; }
  .reg-aside__footer-total {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2.6rem;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 900; }
    @media (max-width: 479px) {
      .reg-aside__footer-total {
        font-size: 1.8rem; } }
    .reg-aside__footer-total .total-price {
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem; }
      @media (max-width: 479px) {
        .reg-aside__footer-total .total-price {
          font-size: 2.2rem; } }
  .reg-aside__footer-side {
    min-width: 11rem;
    margin-right: 2.8rem; }

.reg-aside__title {
  text-transform: uppercase;
  margin-bottom: 2.6rem;
  margin-top: 0; }
  @media (max-width: 479px) {
    .reg-aside__title {
      font-size: 2.2re; } }

.reg-aside__order-block + .reg-aside__order-block {
  margin-top: 3rem; }

.reg-aside__order-title {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 2px; }
  @media (max-width: 479px) {
    .reg-aside__order-title {
      font-size: 1.5rem; } }

.reg-aside__order-period {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  margin-bottom: 0; }
  @media (max-width: 479px) {
    .reg-aside__order-period {
      line-height: 2rem; } }

.reg-aside__order-price {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 0; }
  @media (max-width: 479px) {
    .reg-aside__order-price {
      font-size: 2.2rem;
      line-height: 2.8rem; } }
  .reg-aside__order-price .price-multiplier {
    color: rgba(0, 0, 0, 0.3);
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 4px;
    font-size: 1.7rem;
    display: inline-block;
    line-height: 1;
    vertical-align: middle; }
    @media (max-width: 479px) {
      .reg-aside__order-price .price-multiplier {
        font-size: 1.6rem; } }

.reg-aside__promocode {
  position: relative;
  margin-top: 4.2rem; }

.reg-aside__promocode-title {
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: 1.8rem; }

.reg-aside__promocode-form {
  position: relative; }
  .reg-aside__promocode-form input {
    padding-right: 12rem; }
  .reg-aside__promocode-form button {
    top: 0;
    right: 0;
    position: absolute; }

.section-registration-options {
  padding-bottom: 7.6rem;
  background-color: #F5F5F5; }
  .section-registration-options .row {
    margin-left: -1rem;
    margin-right: -1rem; }
    .section-registration-options .row .col {
      padding-left: 1rem;
      padding-right: 1rem; }
      @media (max-width: 767px) {
        .section-registration-options .row .col:not(:last-child) {
          margin-bottom: 2.6rem; } }
  .section-registration-options .registration__list {
    margin: 0;
    flex-direction: column; }
  .section-registration-options .registration__aside {
    padding-left: 1.6rem; }
    @media (max-width: 991px) {
      .section-registration-options .registration__aside {
        padding-left: 0; } }
  .section-registration-options .reg-content__aside-inner {
    transform: translateY(0);
    transition: transform .3s ease; }
    .section-registration-options .reg-content__aside-inner.sticked {
      transform: translateY(4rem); }

.registration-options__back {
  margin-bottom: 5.4rem; }

.registration-options__currency {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3.6rem; }

.registration-options__currency-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-right: 1.7rem; }

.registration-options__currency-list ul {
  display: flex;
  align-items: center; }
  .registration-options__currency-list ul li:not(:last-child) {
    margin-right: .8rem; }
  .registration-options__currency-list ul li a {
    color: #000000;
    width: 3.2rem;
    height: 3.2rem;
    display: block;
    overflow: hidden;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 50%;
    text-align: center;
    line-height: 3.2rem;
    text-decoration: none;
    background-color: #D8D8D8; }
    @media (min-width: 992px) {
      .registration-options__currency-list ul li a:hover {
        transform: translateY(-0.4rem); } }
  .registration-options__currency-list ul li.active a {
    color: #ffffff;
    background-color: #FC6621; }

.registration-options__basic {
  width: 100%;
  padding-bottom: 3.6rem;
  background-color: #ffffff; }
  @media (max-width: 767px) {
    .registration-options__basic {
      padding-bottom: 5.6rem; } }
  .registration-options__basic:not(:last-child) {
    margin-bottom: 1.6rem; }

.registration-options__basic-header {
  padding-top: 2.7rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin-bottom: 2.8rem;
  border-bottom: 1px solid #D8D8D8; }

.registration-options__basic-title {
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.2rem;
  margin-bottom: .3rem; }
  @media (max-width: 767px) {
    .registration-options__basic-title {
      margin-bottom: 3.2rem; } }

.registration-options__basic-nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .registration-options__basic-nav {
      flex-direction: column;
      align-items: flex-start; } }

.registration-options__basic-tabs {
  margin-bottom: -.2rem; }
  @media (max-width: 767px) {
    .registration-options__basic-tabs {
      order: 1; } }
  .registration-options__basic-tabs .tab-nav {
    flex-wrap: wrap; }
    .registration-options__basic-tabs .tab-nav li {
      width: auto; }
      .registration-options__basic-tabs .tab-nav li:not(:last-child) {
        margin-right: 5rem; }
      .registration-options__basic-tabs .tab-nav li a {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.2rem;
        white-space: nowrap;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        border-bottom: .4rem solid transparent; }
        .registration-options__basic-tabs .tab-nav li a.active {
          border-bottom: 0.4rem solid #FC6621; }

.registration-options__basic-add {
  flex-shrink: 0;
  margin-top: 1.8rem; }
  @media (max-width: 767px) {
    .registration-options__basic-add {
      margin: 0;
      margin-bottom: 1.6rem; } }

.registration-options__basic-content {
  padding-left: 2.4rem;
  padding-right: 2.4rem; }
  .registration-options__basic-content .delegate_content {
    display: none; }
    .registration-options__basic-content .delegate_content.active {
      display: flex; }

.registration-options__basic-content-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 3.3rem; }

.registration-options__basic-content-list .row:not(:last-child) {
  margin-bottom: 2.6rem; }

.registration-options__basic-content-list .row .form-check {
  margin-top: 1.8rem;
  margin-bottom: -.4rem; }

.registration-options__basic-content-list .row .form-file {
  margin-top: 1.8rem; }

.registration-options__email {
  margin-top: .6rem;
  padding-top: 3.4rem;
  padding-left: 3.2rem;
  padding-bottom: 4rem;
  padding-right: 3.2rem;
  background-color: #ffffff; }

.registration-options__email-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: .1rem; }

.registration-options__email-form {
  max-width: 39%; }

.registration-options__additional {
  margin-top: 3.2rem;
  padding-top: 3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-bottom: 1.4rem;
  background-color: #ffffff; }

.registration-options__additional-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 3.9rem; }

.registration-options__additional-form {
  padding-left: 5.2rem;
  padding-right: 5.2rem; }
  @media (max-width: 767px) {
    .registration-options__additional-form {
      padding-left: 0;
      padding-right: 0; } }
  .registration-options__additional-form .row:not(:last-child) {
    margin-bottom: 5.2rem; }
  .registration-options__additional-form .form-group textarea {
    min-height: 10.6rem; }
  .registration-options__additional-form .caption-group {
    margin-top: 0;
    text-align: left;
    margin-bottom: 2.4rem; }
    .registration-options__additional-form .caption-group p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-bottom: 1.4rem; }

.registration-options__optional {
  margin-top: 4.6rem; }

.registration-options__optional-title {
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-bottom: 3.2rem; }

.registration-options__optional-content {
  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-bottom: 6.2rem;
  background-color: #ffffff; }

.registration-options__optional-box:not(:last-child) {
  margin-bottom: 6.4rem; }

.registration-options__optional-box-title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 3.9rem; }

.registration-options__optional-box-subtitle {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 0;
  color: #787878;
  text-align: right; }

.registration-options__optional-box-content .row:not(:last-child) {
  margin-bottom: 2.6rem; }

.reg-aside__order-promocode {
  margin-top: 4.4rem; }

.reg-aside__order-promocode-title {
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: 1.6rem; }

.reg-aside__order-promocode-form {
  position: relative; }
  .reg-aside__order-promocode-form input {
    padding-right: 10.6rem; }
  .reg-aside__order-promocode-form button {
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%); }

.registration-fixed-nav {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  display: none;
  position: fixed;
  background-color: #ffffff; }

.registration-fixed-nav__form form > .form-group {
  padding-top: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem; }

.form-buttons-group {
  display: flex;
  padding: .2rem;
  margin-top: 1.8rem;
  background-color: #FC6621; }
  .form-buttons-group .form-group {
    width: 100%; }
    .form-buttons-group .form-group + .form-group_submit {
      margin: 0; }

.section-custom-video {
  padding-top: 6.4rem;
  padding-bottom: 7.6rem;
  background-color: #F5F5F5; }
  @media (max-width: 767px) {
    .section-custom-video {
      padding-top: 4.2rem;
      padding-bottom: 5.4rem; } }

.custom-video__content {
  margin-top: 4.2rem; }
  @media (max-width: 767px) {
    .custom-video__content {
      margin-top: 3.6rem; } }

@media (max-width: 991px) {
  .custom-video__list .row {
    margin-left: -.8rem;
    margin-right: -.8rem; } }

@media (max-width: 991px) {
  .custom-video__list .col {
    padding-left: .8rem;
    padding-right: .8rem; } }

@media (max-width: 767px) {
  .custom-video__list .col:not(:last-child) {
    margin-bottom: 2.4rem; } }

.custom-video-box {
  height: 0;
  padding-top: 25px;
  position: relative;
  padding-bottom: 56.25%; }
  .custom-video-box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.section-videos {
  margin-top: 5rem; }
  .section-videos__wrap, .section-videos-sm__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-right: -3rem; }
    @media (max-width: 1199px) {
      .section-videos__wrap, .section-videos-sm__wrap {
        margin-right: -2.4rem; } }
    @media (max-width: 991px) {
      .section-videos__wrap, .section-videos-sm__wrap {
        margin-right: -2.9rem; } }
    @media (max-width: 767px) {
      .section-videos__wrap, .section-videos-sm__wrap {
        margin-right: 0; } }
    .section-videos__wrap .news-box__view, .section-videos-sm__wrap .news-box__view {
      width: 31.3rem;
      height: 17.6rem;
      margin-right: 3rem;
      margin-bottom: 3rem; }
      @media (max-width: 1199px) {
        .section-videos__wrap .news-box__view, .section-videos-sm__wrap .news-box__view {
          width: 29.5rem;
          height: 17rem;
          margin-right: 2.3rem;
          margin-bottom: 2.3rem; } }
      @media (max-width: 991px) {
        .section-videos__wrap .news-box__view, .section-videos-sm__wrap .news-box__view {
          width: calc(50% - 22px);
          margin-right: 1.5rem;
          margin-bottom: 1.5rem; } }
      @media (max-width: 767px) {
        .section-videos__wrap .news-box__view, .section-videos-sm__wrap .news-box__view {
          width: calc(50% - 8px);
          height: 15rem; } }
      @media (max-width: 479px) {
        .section-videos__wrap .news-box__view, .section-videos-sm__wrap .news-box__view {
          width: 100%;
          height: 15rem;
          margin-right: 0; } }
      @media (max-width: 767px) {
        .section-videos__wrap .news-box__view:nth-child(2n), .section-videos-sm__wrap .news-box__view:nth-child(2n) {
          margin-right: 0; } }
  .section-videos-sm__wrap .news-box__view {
    height: 31.3rem; }
    @media (max-width: 767px) {
      .section-videos-sm__wrap .news-box__view {
        width: 100%;
        margin-right: 0; } }

.section-profile__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-right: -3rem;
  margin-bottom: 2rem; }
  @media (max-width: 1199px) {
    .section-profile__wrap {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      margin-right: -2.5rem; } }
  @media (max-width: 767px) {
    .section-profile__wrap {
      margin-right: 0; } }

.section-profile__block {
  background-color: #fff;
  padding: 23px 25px;
  /* min-height: 390px; */
  width: calc((100% - 90px) / 3);
  margin-right: 3rem;
  /* margin-bottom: 1.5rem; */ }
  @media (max-width: 1199px) {
    .section-profile__block {
      margin-right: 2.5rem;
      min-height: 350px;
      width: calc((100% - 75px) / 3); } }
  @media (max-width: 991px) {
    .section-profile__block {
      width: calc((100% - 50px) / 2);
      min-height: auto;
      padding: 20px; } }
  @media (max-width: 767px) {
    .section-profile__block {
      padding: 15px 0 10px 0;
      width: 100%;
      margin-right: 0; } }
  .section-profile__block.block_sm {
    min-height: auto;
    padding: 55px 25px 70px 25px; }
  .section-profile__block.profile__block-read-more {
    background-color: #98CB4E;
    padding: 55px 25px 23px 25px; }
    .section-profile__block.profile__block-read-more .link {
      margin-top: 3.8rem; }
  .section-profile__block .conf-dot {
    margin-bottom: 1.5rem;
    width: 16px;
    height: 16px; }
    @media (max-width: 767px) {
      .section-profile__block .conf-dot {
        width: 12px;
        height: 12px; } }

.section-profile__title p {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.38;
  margin-bottom: 15px; }
  @media (max-width: 1199px) {
    .section-profile__title p {
      font-size: 1.9rem; } }

.section-profile__text p {
  font-size: 1.6rem;
  line-height: 1.72;
  margin-bottom: 2rem; }

.section-profile__img {
  width: 100%;
  height: 17.8rem;
  margin-bottom: 1.5rem; }

.section-history__wrap {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap; }

.section-history__text-block {
  margin-right: 80px;
  margin-bottom: 3rem;
  width: calc((100% - 80px) / 2); }
  .section-history__text-block:nth-child(2n) {
    margin-right: 0; }
  @media (max-width: 991px) {
    .section-history__text-block {
      margin-right: 50px;
      width: calc((100% - 50px) / 2); } }
  @media (max-width: 767px) {
    .section-history__text-block {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%; } }
  .section-history__text-block p {
    font-size: 2.4rem;
    line-height: 3rem;
    color: #000;
    font-weight: 500; }
    @media (max-width: 767px) {
      .section-history__text-block p {
        font-size: 2rem;
        line-height: 2.6rem; } }

.section-video-report {
  margin-bottom: 7rem; }
  @media (max-width: 1199px) {
    .section-video-report {
      margin-bottom: 5rem; } }

.video-report__wrap {
  margin-top: 3rem; }
  @media (max-width: 767px) {
    .video-report__wrap .container {
      padding-left: 0;
      padding-right: 0; } }
  .video-report__wrap .news__top-block-title {
    margin-bottom: 2.5rem;
    width: calc(100% - 25.7rem);
    padding-left: 0;
    padding-right: 0; }
    @media (max-width: 991px) {
      .video-report__wrap .news__top-block-title {
        width: 100%; } }
  .video-report__wrap .news__top-video {
    width: 100%;
    height: 56.2rem; }
    @media (max-width: 1439px) {
      .video-report__wrap .news__top-video {
        height: 50rem; } }
    @media (max-width: 1199px) {
      .video-report__wrap .news__top-video {
        height: 46rem; } }
    @media (max-width: 991px) {
      .video-report__wrap .news__top-video {
        height: 42rem; } }
    @media (max-width: 767px) {
      .video-report__wrap .news__top-video {
        height: 37.5rem; } }
    .video-report__wrap .news__top-video.news__top-video-sm {
      width: calc(100% - 25.7rem); }
      @media (max-width: 991px) {
        .video-report__wrap .news__top-video.news__top-video-sm {
          width: 100%; } }
      @media (max-width: 767px) {
        .video-report__wrap .news__top-video.news__top-video-sm {
          height: auto; } }
      .video-report__wrap .news__top-video.news__top-video-sm + .news__top-info .video__info-company {
        margin-top: 2rem; }
  .video-report__wrap .video__info-photo {
    width: 9.4rem;
    height: 9.4rem; }
    @media (max-width: 991px) {
      .video-report__wrap .video__info-photo {
        width: 8rem;
        height: 8rem; } }
    @media (max-width: 767px) {
      .video-report__wrap .video__info-photo {
        width: 6.9rem;
        height: 6.9rem; } }

.section-text-list {
  margin-bottom: 3rem;
  position: relative; }
  .section-text-list:after {
    top: -48px;
    left: 0;
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: #FC6621; }
    @media (max-width: 767px) {
      .section-text-list:after {
        top: -37px; } }
  .section-text-list .text-list__wrap {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 991px) {
      .section-text-list .text-list__wrap {
        flex-wrap: wrap; } }
  .section-text-list .text-list__info {
    margin-right: 5rem;
    /* width: 48rem; */ }
    @media (max-width: 991px) {
      .section-text-list .text-list__info {
        margin-right: 0;
        width: 100%; } }
    .section-text-list .text-list__info p {
      font-size: 2.4rem;
      line-height: 3rem;
      color: #000;
      font-weight: 500; }
      @media (max-width: 767px) {
        .section-text-list .text-list__info p {
          font-size: 2rem;
          line-height: 2.6rem; } }
  .section-text-list .text-list__item {
    list-style: none;
    margin-top: 1rem;
    padding-left: 0; }
    @media (max-width: 991px) {
      .section-text-list .text-list__item {
        width: 100%; } }
    .section-text-list .text-list__item li {
      margin-top: 0;
      padding: 0;
      margin-bottom: 1.2rem;
      position: relative;
      padding-left: 20px;
      font-weight: 900; }
      .section-text-list .text-list__item li:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #47D597;
        position: absolute;
        top: 5px;
        left: 0; }

.section-text-image {
  margin-bottom: 7rem;
  margin-top: 7rem; }
  @media (max-width: 1199px) {
    .section-text-image {
      margin-bottom: 5rem; } }
  .section-text-image .text-image__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .section-text-image .text-image__wrap {
        flex-wrap: wrap; } }
  .section-text-image .text-image__info {
    margin-right: 6.5rem;
    width: 100%; }
    @media (max-width: 991px) {
      .section-text-image .text-image__info {
        margin-right: 0;
        order: 1; } }
    .section-text-image .text-image__info p {
      font-size: 2.4rem;
      line-height: 3rem;
      color: #000;
      font-weight: 500; }
      @media (max-width: 767px) {
        .section-text-image .text-image__info p {
          font-size: 2rem;
          line-height: 2.6rem; } }
  .section-text-image .text-image__item {
    width: 27.5rem;
    height: 16.5rem;
    flex-shrink: 0; }
    @media (max-width: 991px) {
      .section-text-image .text-image__item {
        order: 0;
        margin-bottom: 2.5rem; } }
    @media (max-width: 479px) {
      .section-text-image .text-image__item {
        width: 100%; } }

.section-themes {
  margin-bottom: 7rem; }
  @media (max-width: 1199px) {
    .section-themes {
      margin-bottom: 5rem; } }
  .section-themes__title {
    margin-bottom: 3rem; }
    .section-themes__title h3 {
      text-transform: uppercase; }
  .section-themes__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -2rem; }
    @media (max-width: 479px) {
      .section-themes__wrap {
        margin-right: 0; } }
    .section-themes__wrap.central-themes .section-themes__block {
      width: calc((100% - 60px) / 3); }
      @media (max-width: 767px) {
        .section-themes__wrap.central-themes .section-themes__block {
          width: calc((100% - 40px) / 2); } }
      @media (max-width: 479px) {
        .section-themes__wrap.central-themes .section-themes__block {
          width: 100%;
          margin-right: 0; } }
  .section-themes__block {
    width: calc((100% - 80px) / 4);
    margin-right: 2rem;
    margin-bottom: 3rem; }
    @media (max-width: 991px) {
      .section-themes__block {
        width: calc((100% - 60px) / 3); } }
    @media (max-width: 767px) {
      .section-themes__block {
        width: calc((100% - 40px) / 2); } }
    @media (max-width: 479px) {
      .section-themes__block {
        width: 100%;
        margin-right: 0; } }
    .section-themes__block .conf-dot {
      margin-bottom: 2rem;
      width: 1.6rem;
      height: 1.6rem; }
      @media (max-width: 991px) {
        .section-themes__block .conf-dot {
          margin-bottom: 1.5rem; } }
      @media (max-width: 479px) {
        .section-themes__block .conf-dot {
          width: 1.4rem;
          height: 1.4rem;
          margin-bottom: 1.2rem; } }
    .section-themes__block-text p {
      font-size: 1.4rem;
      line-height: 1.7;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 0; }
      @media (max-width: 991px) {
        .section-themes__block-text p {
          font-size: 1.3rem; } }

.sponsorship-section {
  padding: 8.5rem 0 5rem 0;
  margin-bottom: 1.6rem;
  background-color: #F5F5F5; }
  @media (max-width: 1439px) {
    .sponsorship-section {
      padding: 7rem 0 4.5rem 0; } }
  @media (max-width: 1199px) {
    .sponsorship-section {
      padding: 6rem 0 4rem 0; } }
  @media (max-width: 991px) {
    .sponsorship-section {
      padding: 5rem 0 3rem 0; } }
  @media (max-width: 767px) {
    .sponsorship-section {
      padding: 4rem 0 3rem 0; } }
  @media (max-width: 767px) {
    .sponsorship-section .section-profile__block {
      padding: 2rem 1.5rem; } }
  .sponsorship-section .section-title_inline {
    justify-content: space-between; }

.projects-hero {
  padding: 40px 0 0 0; }
  @media (max-width: 1439px) {
    .projects-hero {
      padding-top: 10rem; } }
  @media (max-width: 767px) {
    .projects-hero {
      padding-top: 2rem; } }
  .projects-hero__links {
    position: absolute;
    top: 17px;
    right: 29px;
    z-index: 2; }
    @media (max-width: 991px) {
      .projects-hero__links {
        display: none; } }
    .projects-hero__links .btn_green {
      padding: 0 6.2rem; }
    .projects-hero__links .btn_black {
      padding: 0 2.9rem; }
  .projects-hero__img {
    height: 400px; }
    @media (max-width: 991px) {
      .projects-hero__img {
        height: 350px; } }
    @media (max-width: 767px) {
      .projects-hero__img {
        height: 300px; } }
    .projects-hero__img-inner {
      height: 100%; }
  .projects-hero__title {
    display: flex;
    justify-content: space-between;
    padding-top: 78px; }
    @media (max-width: 1199px) {
      .projects-hero__title {
        padding-top: 40px;
        display: flex;
        flex-direction: column; } }
    @media (max-width: 767px) {
      .projects-hero__title {
        padding-top: 20px;
        display: flex;
        flex-direction: column; } }
    .projects-hero__title-txt {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      max-width: 630px; }
      @media (max-width: 1199px) {
        .projects-hero__title-txt {
          max-width: 100%; } }
      @media (max-width: 767px) {
        .projects-hero__title-txt {
          font-size: 20px;
          line-height: 25px; } }
      @media (max-width: 479px) {
        .projects-hero__title-txt {
          font-size: 1.4rem; } }
      .projects-hero__title-txt a {
        text-decoration: none;
        color: #009BDF; }
    .projects-hero__title-txt-logo {
      display: flex;
      flex-direction: column;
      padding-right: 5px; }
      @media (max-width: 991px) {
        .projects-hero__title-txt-logo {
          flex-direction: row;
          align-items: center;
          justify-content: space-between; } }
      @media (max-width: 1199px) {
        .projects-hero__title-txt-logo {
          margin-top: 20px; } }
      @media (max-width: 479px) {
        .projects-hero__title-txt-logo {
          flex-direction: column; } }
      .projects-hero__title-txt-logo span {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase; }
        @media (max-width: 479px) {
          .projects-hero__title-txt-logo span {
            font-size: 1.4rem; } }
      .projects-hero__title-txt-logo img {
        margin-top: 18px;
        width: 313px; }
        @media (max-width: 991px) {
          .projects-hero__title-txt-logo img {
            height: 50px;
            width: 250px;
            margin-top: 0; } }
  .projects-hero__subtitle {
    padding-top: 32px;
    max-width: 70rem; }
    .projects-hero__subtitle p {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.5rem;
      margin-bottom: 0; }
      @media (max-width: 479px) {
        .projects-hero__subtitle p {
          font-size: 1.4rem;
          line-height: 2rem; } }
      .projects-hero__subtitle p:first-child {
        margin-bottom: 1.6rem; }

.projects-body__items {
  margin-top: 2.1rem;
  margin-bottom: 6.2rem; }

.projects-body__item:not(:last-child) {
  margin-bottom: 6.8rem; }
  @media (max-width: 991px) {
    .projects-body__item:not(:last-child) {
      margin-bottom: 5rem; } }

.projects-body__item-title h2 {
  font-size: 2.4rem;
  font-weight: 800;
  text-transform: capitalize; }
  @media (max-width: 991px) {
    .projects-body__item-title h2 {
      font-size: 2rem;
      line-height: normal; } }
  @media (max-width: 479px) {
    .projects-body__item-title h2 {
      font-size: 1.4rem; } }

.projects-body__item-body {
  display: flex; }
  @media (max-width: 991px) {
    .projects-body__item-body {
      flex-direction: column-reverse;
      align-items: center; } }

.projects-body__item-desc {
  max-width: 63rem;
  padding-top: 0.7rem;
  padding-left: 1.8rem; }
  @media (max-width: 479px) {
    .projects-body__item-desc {
      padding-left: 0; } }
  .projects-body__item-desc p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.2px;
    margin-bottom: 1.5rem; }
    @media (max-width: 991px) {
      .projects-body__item-desc p {
        font-size: 1.3rem; } }
  .projects-body__item-desc a {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 900;
    color: #009BD7;
    display: flex;
    align-items: center; }
    @media (max-width: 479px) {
      .projects-body__item-desc a {
        font-size: 1.4rem; } }
    .projects-body__item-desc a:hover .icon-arrow {
      transform: translateX(0.6rem); }
    .projects-body__item-desc a .icon-arrow {
      margin-left: 4px;
      fill: #009BD7;
      transition: all 0.3s ease;
      flex-shrink: 0; }

.projects-body__item-img {
  width: 20.1rem;
  height: 15rem;
  flex-shrink: 0;
  margin-left: 7.5rem;
  margin-top: 0.7rem; }
  @media (max-width: 991px) {
    .projects-body__item-img {
      margin-left: 0;
      margin-bottom: 1rem;
      width: 30rem; } }
  @media (max-width: 479px) {
    .projects-body__item-img {
      width: 100%; } }
  .projects-body__item-img-inner {
    height: 100%; }

.projects-body__disclaimer p {
  font-weight: 500;
  font-style: italic;
  font-size: 1.6rem;
  letter-spacing: -0.1px; }

.projects-body__report {
  margin-top: 5.6rem;
  margin-bottom: 2.7rem;
  display: flex; }
  @media (max-width: 991px) {
    .projects-body__report {
      flex-direction: column;
      margin-top: 3rem;
      align-items: center; } }
  .projects-body__report-question {
    max-width: 320px;
    flex-shrink: 0; }
    @media (max-width: 1199px) {
      .projects-body__report-question {
        max-width: 200px; } }
    @media (max-width: 479px) {
      .projects-body__report-question {
        max-width: 100%;
        text-align: center; } }
    .projects-body__report-question span {
      color: #47D597;
      font-size: 2.8rem;
      font-weight: 800;
      line-height: 4.4rem; }
      @media (max-width: 1199px) {
        .projects-body__report-question span {
          font-size: 1.6rem;
          line-height: 2rem; } }
  .projects-body__report-form {
    margin-left: 1.8rem;
    margin-top: 0.4rem; }
    @media (max-width: 1199px) {
      .projects-body__report-form {
        margin-left: 0; } }
    @media (max-width: 991px) {
      .projects-body__report-form {
        margin-top: 20px; } }
    .projects-body__report-form .news-inner__aside-form {
      padding-top: 0;
      background: none;
      width: 32.7rem; }
      @media (max-width: 991px) {
        .projects-body__report-form .news-inner__aside-form {
          padding-left: 0; } }
      .projects-body__report-form .news-inner__aside-form .form-box_submit button {
        background-color: #47D597; }
    .projects-body__report-form .form-box__content--inputs {
      padding-top: 0; }
  .projects-body__report-list {
    /* padding-top: 3.6rem;
			padding-bottom: 2rem;
			padding-left: 1.2rem;
			padding-right: 1.4rem; */
    background-color: #DDDDDD;
    margin-left: 3rem;
    padding: 2.6rem; }
    @media (max-width: 991px) {
      .projects-body__report-list {
        width: 100%;
        margin-left: 0; } }
    .projects-body__report-list span {
      font-weight: 400;
      font-size: 1.6rem; }
    .projects-body__report-list ul {
      list-style-type: inherit;
      padding-top: 2.5rem;
      padding-left: 1.6rem; }
      .projects-body__report-list ul li {
        font-size: 1.6rem; }
        .projects-body__report-list ul li:not(:last-child) {
          margin-bottom: 0.8rem; }

.regional-profile {
  padding-top: 4rem;
  /* .projects-body__report-list {
			padding-bottom: 13rem;
		} */ }
  @media (max-width: 1439px) {
    .regional-profile {
      padding-top: 10rem; } }
  @media (max-width: 767px) {
    .regional-profile {
      padding-top: 2rem; } }
  .regional-profile__links {
    position: absolute;
    right: 3rem;
    top: 1.7rem; }
    @media (max-width: 991px) {
      .regional-profile__links {
        display: none; } }
    .regional-profile__links .btn_blue {
      padding: 0 6.2rem; }
    .regional-profile__links .btn_black {
      padding: 0 2.9rem; }
  .regional-profile__title h2 {
    font-size: 5.6rem;
    letter-spacing: 0.4px; }
    @media (max-width: 991px) {
      .regional-profile__title h2 {
        font-size: 4rem; } }
    @media (max-width: 479px) {
      .regional-profile__title h2 {
        font-size: 3rem; } }
  .regional-profile__subtitle {
    max-width: 61rem;
    margin-top: 2.4rem; }
    @media (max-width: 991px) {
      .regional-profile__subtitle {
        margin-top: 1.3rem; } }
    .regional-profile__subtitle span {
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.5rem; }
      @media (max-width: 991px) {
        .regional-profile__subtitle span {
          font-size: 1.6rem; } }
  .regional-profile__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.9rem;
    margin-right: 5.7rem;
    margin-bottom: -46px; }
    @media (max-width: 991px) {
      .regional-profile__items {
        margin-right: 0; } }
  .regional-profile__item {
    margin-bottom: 6rem;
    width: 45.2%;
    margin-right: 6rem;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .regional-profile__item {
        width: 46%; } }
    @media (max-width: 991px) {
      .regional-profile__item {
        width: 47%;
        margin-right: 3rem; } }
    @media (max-width: 767px) {
      .regional-profile__item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 4rem; } }
    .regional-profile__item:nth-child(even) {
      margin-right: 0; }
    .regional-profile__item-img {
      height: 27.3rem;
      width: 100%; }
      .regional-profile__item-img-inner {
        height: 100%; }
    .regional-profile__item-link {
      padding-left: 0.5rem;
      padding-top: 1.7rem;
      display: inline-block; }
      .regional-profile__item-link a {
        display: inline-block;
        letter-spacing: -0.1px; }
        .regional-profile__item-link a .icon-arrow {
          margin-top: 3px; }
    .regional-profile__item-desc {
      margin-top: 2rem;
      margin-left: 4px; }
      .regional-profile__item-desc span {
        font-size: 1.6rem; }
  .regional-profile__report .projects-body__report-question span {
    color: #009BD7; }
  .regional-profile__report .news-inner__aside-form .form-box_submit button {
    background-color: #009BD7; }

.exhibition-body {
  padding-bottom: 11rem; }
  .exhibition-body__include_content {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .exhibition-body__include_content .inner_html {
      padding: 5rem; }
      @media (max-width: 1199px) {
        .exhibition-body__include_content .inner_html {
          padding: 5rem 0 0; } }
    .exhibition-body__include_content.active {
      max-height: 1000px; }
  .exhibition-body__row {
    display: flex;
    padding-top: 7.5rem; }
    @media (max-width: 1199px) {
      .exhibition-body__row {
        flex-direction: column;
        padding-top: 4rem; } }
    .exhibition-body__row-ps {
      padding-top: 2.7rem; }
  .exhibition-body__links a {
    padding: 0 6.2rem; }
  .exhibition-body__subtitle {
    margin-top: 3.8rem; }
    @media (max-width: 767px) {
      .exhibition-body__subtitle {
        margin-top: 2rem; } }
    .exhibition-body__subtitle span {
      font-size: 2.4rem;
      line-height: 2.9rem; }
      @media (max-width: 767px) {
        .exhibition-body__subtitle span {
          font-size: 1.8rem; } }
  .exhibition-body__stand {
    flex-shrink: 0;
    width: 644px; }
    @media (max-width: 1199px) {
      .exhibition-body__stand {
        width: 100%; } }
    .exhibition-body__stand-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6rem; }
      @media (max-width: 1199px) {
        .exhibition-body__stand-actions {
          margin-left: 0; } }
    @media (max-width: 1199px) {
      .exhibition-body__stand-subtitle {
        margin-left: 0; } }
    .exhibition-body__stand-img {
      margin-top: 5rem; }
      @media (max-width: 1199px) {
        .exhibition-body__stand-img {
          margin-top: 2rem; } }
    .exhibition-body__stand-title {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 1199px) {
        .exhibition-body__stand-title {
          margin-left: 0;
          margin-right: 0; } }
      .exhibition-body__stand-title h3 {
        font-size: 2.4rem;
        text-transform: uppercase;
        line-height: 2.8rem; }
        @media (max-width: 767px) {
          .exhibition-body__stand-title h3 {
            font-size: 1.8rem; } }
      .exhibition-body__stand-title span {
        font-size: 2.4rem;
        text-transform: uppercase;
        line-height: 2.8rem;
        font-weight: 900; }
        @media (max-width: 767px) {
          .exhibition-body__stand-title span {
            font-size: 1.8rem; } }
    .exhibition-body__stand-subtitle span {
      font-size: 1.6rem;
      line-height: 1.9rem; }
    .exhibition-body__stand-img {
      height: 252px; }
      .exhibition-body__stand-img-inner {
        height: 100%; }
  .exhibition-body__list {
    margin-left: 6.2rem; }
    @media (max-width: 1199px) {
      .exhibition-body__list {
        margin-left: 0;
        margin-top: 20px; } }
    .exhibition-body__list-title h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-transform: uppercase; }
    .exhibition-body__list-body {
      margin-top: 40px; }
      .exhibition-body__list-body li {
        font-size: 1.6rem;
        margin-bottom: 31px;
        padding-left: 12px;
        position: relative;
        line-height: 2.4rem; }
        @media (max-width: 1199px) {
          .exhibition-body__list-body li {
            margin-bottom: 20px; } }
        .exhibition-body__list-body li:before {
          content: '';
          position: absolute;
          top: 5px;
          left: -10px;
          width: 10px;
          height: 10px;
          background: #FE5000;
          border-radius: 50%; }
  .exhibition-body__pick-title h3 {
    font-size: 2.4rem;
    line-height: 2.8rem; }
  .exhibition-body__pick-subtitle {
    margin-top: 10px; }
    .exhibition-body__pick-subtitle span {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 500; }
  .exhibition-body__pick-img {
    width: 65.6rem;
    height: 47rem;
    margin-top: 2rem; }
    @media (max-width: 1199px) {
      .exhibition-body__pick-img {
        width: 100%; } }
    @media (max-width: 767px) {
      .exhibition-body__pick-img {
        height: 30rem; } }
    .exhibition-body__pick-img-inner {
      height: 100%; }
  .exhibition-body__help {
    margin-left: 4.9rem;
    margin-top: 11rem; }
    @media (max-width: 1199px) {
      .exhibition-body__help {
        margin-left: 0;
        margin-top: 3rem; } }
    .exhibition-body__help-title h3 {
      font-size: 2.4rem;
      line-height: 2.8rem; }
    .exhibition-body__help-img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden; }
      .exhibition-body__help-img-inner {
        height: 70px; }
    .exhibition-body__help-name {
      margin-top: 23px; }
      .exhibition-body__help-name span {
        font-weight: 800;
        font-size: 1.6rem; }
    .exhibition-body__help-role span {
      font-weight: 400;
      font-size: 1.6rem; }
    .exhibition-body__help-phone {
      margin-top: 20px; }
      .exhibition-body__help-phone span {
        font-size: 1.6rem; }
      .exhibition-body__help-phone a {
        font-size: 1.6rem;
        color: black;
        text-decoration: none; }
    .exhibition-body__help-email span {
      font-size: 1.6rem;
      font-weight: bold;
      color: black; }
    .exhibition-body__help-email a {
      font-size: 1.6rem;
      font-weight: 400;
      color: #009BD7;
      text-decoration: none; }

.exhibition-body__stand-actions .active svg {
  fill: #FC6621;
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg); }

.exhibition-body__stand-actions .link_arrow-down:hover svg {
  -webkit-animation-iteration-count: unset;
  animation-iteration-count: unset;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: unset;
  animation-fill-mode: unset;
  -webkit-animation-name: unset;
  animation-name: unset;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset; }

.industry-profile__subtitle {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 3.4rem; }
  @media (max-width: 1199px) {
    .industry-profile__subtitle {
      flex-direction: column; } }
  .industry-profile__subtitle span {
    width: 600px;
    line-height: 2.8rem; }
    @media (max-width: 767px) {
      .industry-profile__subtitle span {
        width: 100%; } }
  .industry-profile__subtitle-small {
    width: 329px; }
    @media (max-width: 1199px) {
      .industry-profile__subtitle-small {
        margin-top: 20px; } }
    @media (max-width: 767px) {
      .industry-profile__subtitle-small {
        width: 100%; } }
    .industry-profile__subtitle-small span {
      font-size: 1.6rem; }

.industry-profile__item {
  display: flex;
  flex-direction: column;
  background: #F2F2F2;
  padding: 40px 12px 15px 12px;
  margin-top: 20px; }
  @media (max-width: 1199px) {
    .industry-profile__item {
      padding-top: 15px; } }
  .industry-profile__item-head {
    display: flex; }
    @media (max-width: 1199px) {
      .industry-profile__item-head {
        flex-direction: column;
        align-items: center; } }
    .industry-profile__item-head-img {
      width: 201px;
      height: 150px;
      flex-shrink: 0; }
      @media (max-width: 1199px) {
        .industry-profile__item-head-img {
          width: 100%;
          height: 200px; } }
      .industry-profile__item-head-img-inner {
        height: 100%; }
    .industry-profile__item-head-title {
      margin-left: 15px; }
      @media (max-width: 1199px) {
        .industry-profile__item-head-title {
          text-align: center;
          margin-top: 20px;
          margin-left: 0; } }
      .industry-profile__item-head-title h3 {
        font-size: 4.8rem;
        text-transform: uppercase; }
    .industry-profile__item-head-subtitle {
      margin-top: 38px;
      margin-left: 15px; }
      @media (max-width: 1199px) {
        .industry-profile__item-head-subtitle {
          text-align: center;
          margin-left: 0; } }
      .industry-profile__item-head-subtitle span {
        font-weight: 900;
        font-size: 2.4rem;
        text-transform: uppercase; }
  .industry-profile__item-body {
    margin-top: 39px; }
    .industry-profile__item-body ul {
      list-style-type: inherit;
      padding-left: 25px; }
      .industry-profile__item-body ul li {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 0rem !important; }
        .industry-profile__item-body ul li:last-child {
          margin-bottom: 0; }

.industry-profile__sources {
  margin-top: 34px; }
  .industry-profile__sources-title h3 {
    font-size: 2.2rem;
    text-transform: uppercase; }
  .industry-profile__sources-list {
    padding-left: 18px;
    padding-top: 13px; }
    @media (max-width: 767px) {
      .industry-profile__sources-list {
        padding-left: 0; } }
    .industry-profile__sources-list ul li {
      margin-bottom: 25px; }
      .industry-profile__sources-list ul li a {
        font-size: 1.6rem;
        text-decoration: none;
        color: black; }

.not-averege-event__links a {
  padding: 0 6rem; }

.not-averege-event__title {
  max-width: 800px; }
  .not-averege-event__title h2 {
    line-height: 6.2rem; }
    @media (max-width: 767px) {
      .not-averege-event__title h2 {
        line-height: 3rem;
        font-size: 30px; } }
    @media (max-width: 479px) {
      .not-averege-event__title h2 {
        line-height: 3rem; } }

.not-averege-event__subtitle {
  padding-top: 6rem;
  position: relative; }
  .not-averege-event__subtitle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #98CB4E;
    display: inline-block; }
  .not-averege-event__subtitle span {
    font-size: 2.4rem;
    line-height: 2.9rem; }
    @media (max-width: 479px) {
      .not-averege-event__subtitle span {
        font-size: 1.6rem; } }

.not-averege-event__items {
  display: flex;
  margin-top: 44px; }
  @media (max-width: 767px) {
    .not-averege-event__items {
      margin-top: 20px;
      margin-left: 0;
      flex-direction: column; } }

.not-averege-event__item {
  width: 33%; }
  .not-averege-event__item:not(:last-child) {
    margin-right: 2rem; }
  @media (max-width: 767px) {
    .not-averege-event__item {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      text-align: center; } }
  .not-averege-event__item-title h3 {
    font-size: 2.4rem;
    text-transform: uppercase; }
  .not-averege-event__item-subtitle {
    margin-top: 25px; }
    .not-averege-event__item-subtitle span {
      font-size: 1.4rem; }

.not-averege-event__section-title {
  margin-top: 94px; }
  @media (max-width: 767px) {
    .not-averege-event__section-title {
      margin-top: 30px; } }

.not-averege-event__section-meet-items {
  display: flex;
  margin-top: 40px; }
  @media (max-width: 767px) {
    .not-averege-event__section-meet-items {
      margin-left: 0;
      flex-direction: column; } }

.not-averege-event__section-meet-item {
  width: 25%; }
  @media (max-width: 767px) {
    .not-averege-event__section-meet-item {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .not-averege-event__section-meet-item:not(:last-child) {
    margin-right: 41px; }
  .not-averege-event__section-meet-item-name {
    margin-top: 17px; }
    .not-averege-event__section-meet-item-name span {
      font-size: 1.4rem;
      font-weight: 600; }
  .not-averege-event__section-meet-item-desc {
    margin-top: 17px; }
    .not-averege-event__section-meet-item-desc span {
      font-size: 1.4rem;
      font-weight: 500;
      margin-top: 5px; }

.not-averege-event__section-update {
  display: flex;
  margin-top: 10.8rem; }
  @media (max-width: 767px) {
    .not-averege-event__section-update {
      margin-top: 50px;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .not-averege-event__section-update-desc {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767px) {
      .not-averege-event__section-update-desc {
        text-align: center; } }
    .not-averege-event__section-update-desc b {
      color: #9E0B0F;
      font-size: 2.8rem;
      font-weight: 800; }
    .not-averege-event__section-update-desc span {
      margin-top: 37px;
      font-size: 1.4rem; }
  .not-averege-event__section-update-form {
    margin-left: 153px; }
    @media (max-width: 767px) {
      .not-averege-event__section-update-form {
        margin-left: 0;
        margin-top: 50px;
        width: 100%; } }
  .not-averege-event__section-update .news-inner__aside-form {
    background: none;
    padding-top: 0;
    width: 100%; }
    .not-averege-event__section-update .news-inner__aside-form .form-box__content.form-box__content--inputs {
      padding-top: 0; }

.announcement-section {
  padding: 8rem 0 13.2rem 0; }
  @media (max-width: 1439px) {
    .announcement-section {
      padding: 12rem 0 13.2rem 0; } }
  @media (max-width: 1199px) {
    .announcement-section {
      padding: 12rem 0 11rem 0; } }
  @media (max-width: 991px) {
    .announcement-section {
      padding: 12rem 0 9rem 0; } }
  @media (max-width: 767px) {
    .announcement-section {
      padding: 5rem 0 9rem 0; } }

.announcement__top {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 3.1rem; }
  @media (max-width: 991px) {
    .announcement__top {
      flex-wrap: wrap;
      margin-bottom: 4rem; } }
  .announcement__top .section-title {
    margin-bottom: 1.5rem; }
  .announcement__top h2 {
    text-transform: uppercase; }
  .announcement__top-main {
    width: 100%;
    max-width: 62.8rem;
    margin-right: 58px; }
    @media (max-width: 1199px) {
      .announcement__top-main {
        max-width: 55rem; } }
    @media (max-width: 991px) {
      .announcement__top-main {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px; } }
    .announcement__top-main p {
      font-weight: 500; }
  .announcement__top-info {
    margin-top: 26px; }
    @media (max-width: 991px) {
      .announcement__top-info {
        width: 100%;
        margin-top: 0; } }
    .announcement__top-info .top-info__date {
      color: #009BD7;
      display: block;
      width: 100%;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 18px; }
      @media (max-width: 991px) {
        .announcement__top-info .top-info__date {
          margin-bottom: 13px; } }
    .announcement__top-info .top-info__count {
      display: flex;
      flex-wrap: wrap;
      margin-right: -47px; }
      @media (max-width: 991px) {
        .announcement__top-info .top-info__count {
          margin-right: -35px; } }
      .announcement__top-info .top-info__count__item {
        margin-right: 47px;
        max-width: 120px;
        margin-bottom: 15px; }
        @media (max-width: 991px) {
          .announcement__top-info .top-info__count__item {
            margin-right: 35px; } }
      .announcement__top-info .top-info__count__num {
        font-size: 48px;
        line-height: 1.1;
        font-weight: 900;
        color: #000000;
        margin-bottom: 12px;
        display: block; }
        @media (max-width: 991px) {
          .announcement__top-info .top-info__count__num {
            font-size: 42px;
            margin-bottom: 10px; } }
      .announcement__top-info .top-info__count__name {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.25;
        color: #000000;
        text-transform: uppercase;
        display: block; }
        @media (max-width: 991px) {
          .announcement__top-info .top-info__count__name {
            font-size: 18px; } }

.announcement__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-right: -1.5rem; }
  @media (max-width: 1199px) {
    .announcement__wrapper {
      margin-left: -1rem;
      margin-right: -1rem; } }

.announcement__block {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem; }
  @media (max-width: 767px) {
    .announcement__block {
      max-width: 34.5rem; } }
  .announcement__block:hover .announcement__block__inner {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease; }
  .announcement__block:hover .announcement__block__title {
    opacity: 1; }
  .announcement__block:hover .announcement__block__list li {
    opacity: 1;
    transform: none; }
    .announcement__block:hover .announcement__block__list li:nth-child(1) {
      transition: transform 0.3s ease 0.15s, opacity 0.3s ease 0.15s; }
    .announcement__block:hover .announcement__block__list li:nth-child(2) {
      transition: transform 0.3s ease 0.2s, opacity 0.3s ease 0.2s; }
    .announcement__block:hover .announcement__block__list li:nth-child(3) {
      transition: transform 0.3s ease 0.25s, opacity 0.3s ease 0.25s; }
    .announcement__block:hover .announcement__block__list li:nth-child(4) {
      transition: transform 0.3s ease 0.3s, opacity 0.3s ease 0.3s; }
    .announcement__block:hover .announcement__block__list li:nth-child(5) {
      transition: transform 0.3s ease 0.35s, opacity 0.3s ease 0.35s; }
    .announcement__block:hover .announcement__block__list li:nth-child(6) {
      transition: transform 0.3s ease 0.4s, opacity 0.3s ease 0.4s; }
    .announcement__block:hover .announcement__block__list li:nth-child(7) {
      transition: transform 0.3s ease 0.45s, opacity 0.3s ease 0.45s; }
    .announcement__block:hover .announcement__block__list li:nth-child(8) {
      transition: transform 0.3s ease 0.5s, opacity 0.3s ease 0.5s; }
    .announcement__block:hover .announcement__block__list li:nth-child(9) {
      transition: transform 0.3s ease 0.55s, opacity 0.3s ease 0.55s; }
    .announcement__block:hover .announcement__block__list li:nth-child(10) {
      transition: transform 0.3s ease 0.6s, opacity 0.3s ease 0.6s; }
    .announcement__block:hover .announcement__block__list li:nth-child(11) {
      transition: transform 0.3s ease 0.65s, opacity 0.3s ease 0.65s; }
    .announcement__block:hover .announcement__block__list li:nth-child(12) {
      transition: transform 0.3s ease 0.7s, opacity 0.3s ease 0.7s; }
  .announcement__block:hover .announcement__block__link {
    opacity: 1;
    transform: none;
    transition: transform .3s ease .5s, opacity .3s ease .5s; }
  .announcement__block-wrap {
    width: calc(100% / 3);
    padding: 0 1.5rem;
    margin-bottom: 3rem; }
    @media (max-width: 1199px) {
      .announcement__block-wrap {
        padding: 0 1rem;
        margin-bottom: 2rem; } }
    @media (max-width: 991px) {
      .announcement__block-wrap {
        width: 50%; } }
    @media (max-width: 767px) {
      .announcement__block-wrap {
        width: 100%;
        display: flex;
        justify-content: center; } }
  .announcement__block__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    padding: 3rem 2.4rem 6rem 2.4rem;
    transition: opacity .3s ease, visibility .3s ease; }
  .announcement__block__title {
    margin-top: 0;
    margin-bottom: 2.4rem;
    color: #ffffff;
    padding-left: 7px;
    opacity: 0;
    transition: transform .3s ease, opacity .3s ease; }
  .announcement__block__list {
    margin: 0;
    padding: 0;
    list-style: none; }
    .announcement__block__list li {
      position: relative;
      padding-left: 1.3rem;
      color: #ffffff;
      font-size: 1.6rem;
      line-height: 1.25;
      opacity: 0;
      transform: translateY(20px);
      transition: transform .3s ease, opacity .3s ease; }
      .announcement__block__list li:before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        left: 1px;
        top: 7px; }
  .announcement__block__link {
    position: absolute;
    right: 1.8rem;
    bottom: 3.3rem;
    opacity: 0;
    transform: translateY(20px);
    transition: transform .3s ease, opacity .3s ease; }

@media (min-width: 768px) {
  html.announcement-modal .p-wrapp {
    filter: blur(1.5px); } }

@media (min-width: 768px) {
  html.announcement-modal .modal-backdrop.show {
    opacity: .2; } }

.modal-announcement .modal-content {
  min-height: auto;
  padding: 5rem 9rem 6rem 9rem; }
  @media (max-width: 767px) {
    .modal-announcement .modal-content {
      padding: 4rem 5rem 5rem 5rem;
      min-height: 100vh; } }

.modal-announcement .modal-dialog {
  max-width: 47rem; }

.modal-announcement .modal-body {
  padding-left: 0;
  padding-right: 0; }

.modal-announcement .modal-title {
  font-weight: 800;
  color: #47D597;
  font-size: 2.8rem;
  line-height: 1.55;
  margin-bottom: 3rem; }

.btn_dark-green {
  background-color: #47D597; }

.btn_submit.btn_dark-green:after {
  color: #47D597; }

.vacancy-adv {
  background-color: #F5F5F5;
  padding: 5rem 0 6rem 0; }
  .vacancy-adv__top {
    width: 100%;
    max-width: 64rem;
    margin-bottom: 3.1rem; }
    .vacancy-adv__top .section-title {
      margin-bottom: 1.5rem; }
    .vacancy-adv__top h2 {
      text-transform: uppercase; }
  .vacancy-adv__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 1.5rem; }
    @media (max-width: 991px) {
      .vacancy-adv__wrap {
        margin-left: -1rem;
        margin-right: -1rem; } }
    @media (max-width: 767px) {
      .vacancy-adv__wrap {
        margin-left: 0;
        margin-right: 0; } }
  .vacancy-adv__block {
    background-color: #ffffff;
    padding: 3.4rem 2.5rem 3rem 2.5rem;
    height: 100%; }
    @media (max-width: 767px) {
      .vacancy-adv__block {
        height: auto;
        max-width: 31.3rem;
        padding: 2.5rem 2rem; } }
    .vacancy-adv__block-wrap {
      width: calc(100% / 3);
      padding: 0 1.5rem;
      margin-bottom: 1.5rem;
      min-height: 37.1rem; }
      @media (max-width: 991px) {
        .vacancy-adv__block-wrap {
          width: 50%;
          padding: 0 1rem;
          margin-bottom: 2rem; } }
      @media (max-width: 767px) {
        .vacancy-adv__block-wrap {
          width: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          min-height: auto; } }
    .vacancy-adv__block__icon {
      margin-bottom: 3rem; }
      .vacancy-adv__block__icon svg {
        width: auto;
        height: 8.6rem; }
    .vacancy-adv__block__title {
      margin-bottom: 1.5rem;
      font-size: 2rem;
      line-height: 1.26;
      font-weight: 900; }
    .vacancy-adv__block__text {
      font-size: 1.6rem;
      line-height: 1.7;
      margin-bottom: 0; }
  .vacancy-adv__insta {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .vacancy-adv__insta {
        flex-wrap: wrap; } }
    .vacancy-adv__insta__block {
      position: relative;
      height: 33rem;
      flex-shrink: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
      @media (max-width: 991px) {
        .vacancy-adv__insta__block {
          height: 28rem; } }
      @media (max-width: 767px) {
        .vacancy-adv__insta__block {
          height: 23rem; } }
      .vacancy-adv__insta__block + .vacancy-adv__insta__block {
        margin-left: 1rem; }
        @media (max-width: 991px) {
          .vacancy-adv__insta__block + .vacancy-adv__insta__block {
            margin-left: 0; } }
      .vacancy-adv__insta__block.block_sm {
        width: 25.2rem; }
        @media (max-width: 991px) {
          .vacancy-adv__insta__block.block_sm {
            display: none; } }
      .vacancy-adv__insta__block.block_lg {
        width: calc(100% - 52.4rem); }
        @media (max-width: 991px) {
          .vacancy-adv__insta__block.block_lg {
            width: 100%; } }
      .vacancy-adv__insta__block__info {
        position: absolute;
        left: 2rem;
        bottom: 2.6rem; }
        .vacancy-adv__insta__block__info a {
          text-decoration: none; }
          @media (min-width: 992px) {
            .vacancy-adv__insta__block__info a:hover svg {
              transform: translateX(3px); } }
          .vacancy-adv__insta__block__info a span {
            color: #ffffff;
            font-size: 2rem;
            line-height: 1.1;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 900; }
          .vacancy-adv__insta__block__info a svg {
            width: 1.15em;
            font-size: 1.9rem;
            display: inline-block;
            vertical-align: text-bottom;
            margin-left: 7px;
            transform: none;
            transition: transform .3s ease; }

@keyframes enterWidthLeftToRight {
  from {
    width: 0; }
  to {
    width: calc(100% - 22.8rem); } }

@keyframes outWidthLeftToRight {
  from {
    width: calc(100% - 22.8rem); }
  to {
    width: 0;
    overflow: hidden; } }

@keyframes enterWidthLeftToRightSM {
  from {
    width: 0; }
  to {
    width: calc(100% - 29.2rem); } }

@keyframes outWidthLeftToRightSM {
  from {
    width: calc(100% - 29.2rem); }
  to {
    width: 0;
    overflow: hidden; } }

@keyframes enterWidthLeftToRightXS {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes outWidthLeftToRightXS {
  from {
    width: 100%; }
  to {
    width: 0;
    overflow: hidden; } }

@keyframes fadeInUpNavElements {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeOutNavElements {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 5%, 0); } }

.nav-submenu-active .nav-list-box {
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1; }
  @media (min-width: 1440px) {
    .nav-submenu-active .nav-list-box {
      animation-name: enterWidthLeftToRight; } }
  @media (max-width: 1439px) {
    .nav-submenu-active .nav-list-box {
      animation-name: enterWidthLeftToRightSM; } }
  @media (max-width: 767px) {
    .nav-submenu-active .nav-list-box {
      animation-name: enterWidthLeftToRightXS; } }
  .nav-submenu-active .nav-list-box .nav-list-box__content-item {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: fadeInUpNavElements; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(1) {
      animation-delay: .5s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(2) {
      animation-delay: .65s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(3) {
      animation-delay: .8s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(4) {
      animation-delay: .95s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(5) {
      animation-delay: 1.1s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(6) {
      animation-delay: 1.25s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(7) {
      animation-delay: 1.4s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(8) {
      animation-delay: 1.55s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(9) {
      animation-delay: 1.7s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(10) {
      animation-delay: 1.85s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(11) {
      animation-delay: 2s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(12) {
      animation-delay: 2.15s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(13) {
      animation-delay: 2.3s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(14) {
      animation-delay: 2.45s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(15) {
      animation-delay: 2.6s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(16) {
      animation-delay: 2.75s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(17) {
      animation-delay: 2.9s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(18) {
      animation-delay: 3.05s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(19) {
      animation-delay: 3.2s; }
    .nav-submenu-active .nav-list-box .nav-list-box__content-item:nth-child(20) {
      animation-delay: 3.35s; }

.nav-submenu-deactive .nav-list-box {
  animation-delay: .5s;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-iteration-count: 1; }
  @media (min-width: 1440px) {
    .nav-submenu-deactive .nav-list-box {
      animation-name: outWidthLeftToRight; } }
  @media (min-width: 768px) and (max-width: 1439px) {
    .nav-submenu-deactive .nav-list-box {
      animation-name: outWidthLeftToRightSM; } }
  @media (max-width: 767px) {
    .nav-submenu-deactive .nav-list-box {
      animation-name: outWidthLeftToRightXS; } }
  @media (min-width: 768px) {
    .nav-submenu-deactive .nav-list-box .nav-list-box__content-item {
      animation-duration: .5s;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-name: fadeOutNavElements; } }

@keyframes animateOrbitScreen {
  0% {
    transform: rotate(0deg); }
  92% {
    transform: rotate(359deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes animateOrbitPlanetScreen {
  0% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  92% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  96% {
    top: -.8rem;
    left: -.8rem;
    right: -.8rem;
    bottom: -.8rem; }
  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; } }

.anim-orbit {
  animation-duration: 6s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-name: animateOrbitScreen;
  animation-iteration-count: infinite; }

.anim-orbit-planet i:after {
  animation-duration: 6s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: animateOrbitPlanetScreen; }

@keyframes animateArrowLink {
  0% {
    transform: translateY(0rem) rotateZ(90deg); }
  25% {
    transform: translateY(0.4rem) rotateZ(90deg); }
  50% {
    transform: translateY(0rem) rotateZ(90deg); }
  75% {
    transform: translateY(-0.4rem) rotateZ(90deg); }
  100% {
    transform: translateY(0rem) rotateZ(90deg); } }

@media (min-width: 992px) {
  .link_arrow-down:hover svg {
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-name: animateArrowLink;
    animation-timing-function: linear;
    animation-iteration-count: infinite; } }

@keyframes fadeInMobileButtonFirstElement {
  0% {
    top: 0rem; }
  50% {
    top: .6rem;
    transform: rotate(0); }
  100% {
    top: .6rem;
    transform: rotate(-45deg); } }

@keyframes fadeInMobileButtonSecondElement {
  0% {
    opacity: 1; }
  50% {
    opacity: .5; }
  100% {
    opacity: 0; } }

@keyframes fadeInMobileButtonThirdElement {
  0% {
    top: 1.2rem; }
  50% {
    top: .6rem;
    transform: rotate(0); }
  100% {
    top: .6rem;
    transform: rotate(45deg); } }

@keyframes fadeInMobileButtonTextFirstElement {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeInMobileButtonTextSecondElement {
  0% {
    top: 100%;
    opacity: .25; }
  100% {
    top: 0%;
    opacity: 1; } }

@keyframes fadeOutMobileButtonFirstElement {
  0% {
    top: .6rem;
    transform: rotate(-45deg); }
  50% {
    top: .6rem;
    transform: rotate(0); }
  100% {
    top: 0rem;
    transform: rotate(0); } }

@keyframes fadeOutMobileButtonSecondElement {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes fadeOutMobileButtonThirdElement {
  0% {
    top: .6rem;
    transform: rotate(45deg); }
  50% {
    top: .6rem;
    transform: rotate(0); }
  100% {
    top: 1.2rem;
    transform: rotate(0); } }

@keyframes fadeOutMobileButtonTextFirstElement {
  0% {
    opacity: 0; }
  50% {
    opacity: .25; }
  100% {
    opacity: 1; } }

@keyframes fadeOutMobileButtonTextSecondElement {
  0% {
    top: 0%;
    opacity: 1; }
  100% {
    top: 100%;
    opacity: .25; } }

@keyframes fadeInMobileNav {
  0% {
    bottom: calc(100% - 8.4rem); }
  100% {
    bottom: 0; } }

@keyframes fadeOutMobileNav {
  0% {
    bottom: 0; }
  100% {
    bottom: calc(100% - 8.4rem); } }

@keyframes fadeInMobileNavXs {
  0% {
    bottom: calc(100% - 7.2rem); }
  100% {
    bottom: 0; } }

@keyframes fadeOutMobileNavXs {
  0% {
    bottom: 0; }
  100% {
    bottom: calc(100% - 7.2rem); } }

@keyframes fadeInMobileNavConferenceXs {
  0% {
    bottom: calc(100% - 5.6rem); }
  100% {
    bottom: 0; } }

@keyframes fadeOutMobileNavConferenceXs {
  0% {
    bottom: 0; }
  100% {
    bottom: calc(100% - 5.6rem); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUpNavList {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 50%, 0); } }

@keyframes fadeOutDownNavList {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 10%, 0); } }

@keyframes fadeInMobileNavBorder {
  from {
    opacity: 0;
    right: -.1rem; }
  to {
    right: 0;
    opacity: 1; } }

@keyframes fadeOutMobileNavBorder {
  from {
    right: 0;
    opacity: 1; }
  to {
    opacity: 0;
    right: -.1rem; } }

@keyframes fadeInMobileNavBorderXs {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOutMobileNavBorderXs {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@media (max-width: 1439px) {
  .nav-mobile-active .nav-box__toggle button .nav-box__toggle-view i {
    animation-duration: .6s;
    animation-fill-mode: both; }
    .nav-mobile-active .nav-box__toggle button .nav-box__toggle-view i:nth-child(1) {
      animation-name: fadeInMobileButtonFirstElement; }
    .nav-mobile-active .nav-box__toggle button .nav-box__toggle-view i:nth-child(2) {
      animation-name: fadeInMobileButtonSecondElement; }
    .nav-mobile-active .nav-box__toggle button .nav-box__toggle-view i:nth-child(3) {
      animation-name: fadeInMobileButtonThirdElement; }
  .nav-mobile-active .nav-box__toggle button .nav-box__toggle-caption span {
    animation-duration: .3s;
    animation-fill-mode: both; }
    .nav-mobile-active .nav-box__toggle button .nav-box__toggle-caption span.open {
      animation-name: fadeInMobileButtonTextFirstElement; }
    .nav-mobile-active .nav-box__toggle button .nav-box__toggle-caption span.close {
      animation-name: fadeInMobileButtonTextSecondElement; }
  .nav-mobile-active .nav-box {
    animation-delay: .6s;
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-name: fadeInMobileNav; } }
  @media (max-width: 1439px) and (max-width: 767px) {
    .nav-mobile-active .nav-box {
      animation-name: fadeInMobileNavXs; } }
  @media (max-width: 1439px) and (max-width: 767px) {
    .nav-mobile-active .nav-box_conference {
      animation-name: fadeInMobileNavConferenceXs; } }
  @media (max-width: 1439px) and (min-width: 768px) {
    .nav-mobile-active .nav-box:after {
      animation-delay: 1.1s;
      animation-duration: .3s;
      animation-fill-mode: both;
      animation-name: fadeInMobileNavBorder; } }

@media (max-width: 1439px) {
  .nav-mobile-active nav {
    animation-delay: 1.1s;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeInUpNavList; }
  .nav-mobile-active .nav-box__copywriting {
    animation-delay: 1.25s;
    animation-duration: .3s;
    animation-name: fadeInUp;
    animation-fill-mode: both; }
  .nav-mobile-active .nav-box__conference-copywriting {
    animation-delay: 1.25s;
    animation-duration: .3s;
    animation-name: fadeInUp;
    animation-fill-mode: both; }
  .nav-mobile-active .nav-box__conference-social, .nav-mobile-active .nav-box__social {
    animation-delay: 1.25s;
    animation-duration: .3s;
    animation-name: fadeInUp;
    animation-fill-mode: both; }
    .nav-mobile-active .nav-box__conference-social li, .nav-mobile-active .nav-box__social li {
      animation-name: fadeInUp;
      animation-fill-mode: both; }
      .nav-mobile-active .nav-box__conference-social li:nth-child(1), .nav-mobile-active .nav-box__social li:nth-child(1) {
        animation-delay: 1.55s;
        animation-duration: .3s; }
      .nav-mobile-active .nav-box__conference-social li:nth-child(2), .nav-mobile-active .nav-box__social li:nth-child(2) {
        animation-delay: 1.7s;
        animation-duration: .3s; }
      .nav-mobile-active .nav-box__conference-social li:nth-child(3), .nav-mobile-active .nav-box__social li:nth-child(3) {
        animation-delay: 1.85s;
        animation-duration: .3s; }
      .nav-mobile-active .nav-box__conference-social li:nth-child(4), .nav-mobile-active .nav-box__social li:nth-child(4) {
        animation-delay: 2s;
        animation-duration: .3s; }
      .nav-mobile-active .nav-box__conference-social li:nth-child(5), .nav-mobile-active .nav-box__social li:nth-child(5) {
        animation-delay: 2.15s;
        animation-duration: .3s; }
  .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-view i {
    animation-delay: 1.1s;
    animation-duration: .6s;
    animation-fill-mode: both; }
    .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-view i:nth-child(1) {
      animation-name: fadeOutMobileButtonFirstElement; }
    .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-view i:nth-child(2) {
      animation-name: fadeOutMobileButtonSecondElement; }
    .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-view i:nth-child(3) {
      animation-name: fadeOutMobileButtonThirdElement; }
  .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-caption span {
    animation-delay: 1.1s;
    animation-duration: .3s;
    animation-fill-mode: both; }
    .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-caption span.open {
      animation-name: fadeOutMobileButtonTextFirstElement; }
    .nav-mobile-deactive .nav-box__toggle button .nav-box__toggle-caption span.close {
      animation-name: fadeOutMobileButtonTextSecondElement; }
  .nav-mobile-deactive .nav-box {
    animation-delay: .6s;
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-name: fadeOutMobileNav; } }
  @media (max-width: 1439px) and (max-width: 767px) {
    .nav-mobile-deactive .nav-box {
      animation-name: fadeOutMobileNavXs; } }
  @media (max-width: 1439px) and (max-width: 767px) {
    .nav-mobile-deactive .nav-box_conference {
      animation-name: fadeOutMobileNavConferenceXs; } }
  @media (max-width: 1439px) and (min-width: 768px) {
    .nav-mobile-deactive .nav-box:after {
      animation-delay: .5s;
      animation-duration: .5s;
      animation-fill-mode: both;
      animation-name: fadeOutMobileNavBorder; } }

@media (max-width: 1439px) {
  .nav-mobile-deactive nav {
    animation-delay: .3s;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeOutDownNavList; }
  .nav-mobile-deactive .nav-box__copywriting {
    animation-delay: .15s;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeOutDown; }
  .nav-mobile-deactive .nav-box__conference-copywriting {
    animation-delay: .15s;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeOutDown; }
  .nav-mobile-deactive .nav-box__social {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeOutDown; }
  .nav-mobile-deactive .nav-box__conference-social {
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-name: fadeOutDown; } }

@keyframes successValidation {
  from {
    width: 0; }
  to {
    width: calc(100% - .6rem); } }

.valid-success .btn_submit:after {
  animation-duration: .375s;
  animation-fill-mode: both;
  animation-name: successValidation; }

@keyframes fadeOutDownInputs {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 50%, 0); } }

@keyframes fadeInUpInputs {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 50%, 0); }
  to {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0); } }

@keyframes successCompleteValidation {
  from {
    width: calc(100% - .6rem); }
  to {
    width: 0; } }

.valid-success-complete .form-input__value {
  animation-duration: .375s;
  animation-fill-mode: both;
  animation-name: fadeOutDownInputs; }

.valid-success-complete .form-input__placeholder {
  animation-delay: .375s;
  animation-duration: .375s;
  animation-fill-mode: both;
  animation-name: fadeInUpInputs; }

.valid-success-complete .btn_submit:after {
  animation-delay: .75s;
  animation-duration: .375s;
  animation-fill-mode: both;
  animation-name: successCompleteValidation; }
