.speakers {
    &-section {
        padding: 5.6rem 0 0 0;
        .section-title {
            margin-bottom: 4.4rem;
            &__minor {
                display: none;
                @media (max-width: $sw-sm-max) {
                    display: block;
                }
            }
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-right: -3rem;
        @media (max-width: $sw-md-max) {
            margin-right: -2.2rem;
        }
        @media (max-width: $sw-sm-max) {
            margin-right: 0;
        }
    }
}

.speaker {
    &-block {
        width: 100%;
        &-wrap {
            width: 22.9rem;
            margin-right: 3rem;
            margin-bottom: 7.7rem;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $sw-md-max) {
                width: calc((100% - 6.6rem) / 3);
                margin-right: 2.2rem;
                margin-bottom: 6.5rem;
            }
            @media (max-width: $sw-sm-max) {
                width: calc((100% - 2.5rem) / 2);
                margin-right: 2.5rem;
                margin-bottom: 5.5rem;
            }
            @media (max-width: $sw-xx-max) {
                width: 100%;
                margin-right: 0;
            }
            &:nth-child(even) {
                @media (max-width: $sw-sm-max) {
                    margin-right: 0;
                }
            }
            &.all-speakers {
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                @media (max-width: $sw-sm-max) {
                    display: none;
                }
            }
        }
    }
    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 3.1rem;
        @media (max-width: $sw-sm-max) {
            margin-bottom: 2.4rem;
        }
    }
    &-view {
        width: 6.9rem;
        height: 6.9rem;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 1.5rem;
        @media (max-width: $sw-xx-max) {
            margin-right: 2.2rem;
        }
        picture {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            img {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
            }
        }
    }
    &-links {
        display: flex;
        align-items: center;
        a {
            transform: none;
            transition: transform .3s ease;
            &:hover {
                @media (min-width: $sw-md-min) {
                    transform: translateY(-0.4rem);
                }
            }
        }
        svg {
            font-size: 2.4rem;
            margin-right: 6px;
            fill: #000;
            @media (max-width: $sw-xx-max) {
                font-size: 4.4rem;
                margin-right: 8px;
            }
        }
    }
    &-content {
        @media (max-width: $sw-xx-max) {
            padding-left: 9.3rem;
        }   
    }
    &-name {
        margin-top: 0;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }
    &-position {
        @extend .small;
        margin-bottom: 1.2rem;
    }
    &-company {
        text-transform: uppercase;
        margin-top: 0;
        //font-size: 1.3rem;
        margin-bottom: 0;
    }
}