.video-fs {
    &-wrap {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.6);
        z-index: 99;
        display: none;
    }
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .video-content {
            width: auto;
            height: auto;
            max-height: calc(100vh - 80px);
            max-width: calc(100vw - 80px);
            &:after {
                display: none;
            }
            .link {
                text-transform: uppercase;
                position: absolute;
                z-index: 10;
                right: 23px;
                bottom: 50px;
            }
            &__inner {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                position: relative !important;
            }
            &__seek-bar {
                display: block;
                &-wrap {
                    position: absolute;
                    bottom: 0;
                    height: 45px;
                    width: 100%;
                    z-index: 50;
                }
            }
            &__play {
                .icon {
                    &-pause-path, &-play, &-big-play, &-pause {
                        font-size: 6.6rem !important;
                    }
                }
            }
            &__close {
                @media (max-width: $sw-xs-max) {
                    position: absolute;
                    right: auto;
                    top: -6rem;
                    left: 50%;
                    width: 5rem;
                    height: 5rem;
                    transform: translateX(-50%);
                }
            }
        }
    }
}