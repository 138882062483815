.news-box{
	&.small{
		.news-box__details{
			padding-top: 1.7rem;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			padding-bottom: 2.7rem;
		}
		.news-box__view {
			height: 14.4rem;
		}
		.news-box__detail{
			margin-top: 1.1rem;
			padding-right: 1.5rem;
		}
		.news-box__action{
			margin-top: 1.7rem;
		}
	}
}

.news-box__content{
	height: 100%;
	background-color: $wildSand;
}

.news-box__view{
	height: 19.4rem;
	position: relative;
	a{
		width: 100%;
		height: 100%;
		display: block;
		overflow: hidden;
		position: relative;
		&:after{
			z-index: 0;
			content: '';
			top: -.5rem;
			opacity: 0.3;
			left: -.5rem;
			right: -.5rem;
			display: block;
			bottom: -.5rem;
			position: absolute;
			background-color: $black;
			transition: all 0.3s ease;
		}
		picture{
			width: 100%;
			height: 100%;
			display: block;
			img{

			}
		}
		svg{
			top: 50%;
			left: 50%;
			z-index: 1;
			fill: $white;
			stroke: $white;
			font-size: 3.4rem;
			position: absolute;
			margin-top: -1.7rem;
			margin-left: -1.7rem;
			transition: all 0.3s ease;
		}
		@media ( min-width: $sw-md-min) {
			&:hover{
				&:after{
					opacity: 0;
				}
				svg{
					opacity: 0;
				}
			}
		}
	}
}

.news-box__label{
	display: flex;
	height: 4.8rem;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 1.5rem;
	align-items: center;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	text-transform: uppercase;
	justify-content: flex-start;
	&.red{
		background-color: $orange;
	}
	&.yellow{
		background-color: $webOrange;
	}
	&.green{
		background-color: $shamrock;
	}
}

.news-box__details{
	display: flex;
	padding-top: 2.4rem;
	padding-left: 2.4rem;
	padding-bottom: 2rem;
	padding-right: 2.4rem;
	flex-direction: column;
}

.news-box__description{

}

.news-box__time{
	color: $gray;
	overflow: hidden;
	font-weight: 500;
	font-size: 1.6rem;
	white-space: nowrap;
	line-height: 1.6rem;
	margin-bottom: 1.5rem;
	text-overflow: ellipsis;
}

.news-box__name{
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.6rem;
}

.news-box__detail{
	font-size: 1.6rem;
	margin-top: .5rem;
	line-height: 2.2rem;
}

.news-box__action{
	margin-top: 1.5rem;
}

.news-box__tags{
	margin-top: 3.4rem;
}

.tags{
	display: flex;
	flex-wrap: wrap;
	margin-left: -.4rem;
	.tag{
		margin-left: .4rem;
		margin-bottom: .4rem;
	}
}