.all-photoes {
    &__section {
        padding-top: 6.9rem;
        padding-bottom: 5.2rem;
        @media (max-width: $sw-sm-max) {
            padding-top: 2rem;
            padding-bottom: 4rem;
        }
        .container {
            @media (max-width: $sw-xx-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        @extend .no-decoration;
        width: 32.5rem;
        height: 32.5rem;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        display: inline-flex;
        align-items: flex-end;
        justify-content: flex-start;
        vertical-align: top;
        @media (max-width: $sw-md-max) {
            width: 30rem;
            height: 30rem !important;
        }
        @media (max-width: $sw-sm-max) {
            margin-right: 1.5rem;
            display: block;
            margin-bottom: 0;
            width: 37.6rem;
            height: 37.6rem !important;
        }
        @media (max-width: $sw-xx-max) {
            margin-right: 0;
        }
        &:nth-child(3n) {
            margin-right: 0;
            @media (max-width: $sw-sm-max) {
                margin-right: 1.5rem;
            }
            @media (max-width: $sw-xx-max) {
                margin-right: 0;
            }
        }
        .link {
            margin-bottom: 2.8rem;
            margin-left: 2.8rem;
            text-transform: uppercase;
            position: relative;
            @media (max-width: $sw-sm-max) {
                display: none;
            }
        }
    }
    &__link {
        text-transform: uppercase;
        text-align: center;
        margin-top: 1.5rem;
        display: none;
        @media (max-width: $sw-sm-max) {
            display: block;
        }
    }
    &__dots {
        margin: 2.4rem 0 1.5rem 0;
        .slick-dots {
            justify-content: center;
            li.slick-active button {
                background-color: $orange;
            }
        }
    }
}