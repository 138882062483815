.section-conferences{
	padding-top: 8.6rem;
	padding-bottom: 13.2rem;
	background-color: $wildSand;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
		background-color: transparent;
	}
}

.conferences__content{
	margin-top: 4rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 4.7rem;
	}
}

.conferences__items{
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.6rem;
}

.conferences__item{
	width: 33.33333%;
	padding-left: 1.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {
	}
	@media ( max-width: $sw-sm-max) {
		width: 50%;
	}
	@media ( max-width: 678px) {
		width: 100%;
	}


	@media ( min-width: $sw-md-min) {
		&:nth-child(n+4){
			margin-top: 1.6rem;
		}
	}
	@media ( min-width: 679px) and ( max-width: $sw-sm-max) {
		&:nth-child(n+3){
			margin-top: 1.6rem;
		}
		&.last_one{
			min-height: 40rem;
		    width: 100%;
		    margin-top: 4.7rem;
		}
	}
	@media ( max-width: 678px) {
		&:nth-child(n+2){
			margin-top: 1.6rem;
		}
	}
	@media ( max-width: $sw-xs-max) {}
}