.conf-register {
    &__section {
				padding: 6.3rem 0 7rem 0;
                    position: relative;
    z-index: 112;
    background: #fff;
				@media (max-width: $sw-sm-max) {
					padding: 4rem 0 5rem 0;
			}
        .conf-dot {
            margin-bottom: 3.5rem;
        }
    }
    &__title {
        line-height: 4rem;
        font-weight: 700;
        margin-top: 0;
            max-width: 80rem;
        margin-bottom: 4.5rem;
        @media (max-width: $sw-md-max) {
            font-size: 3rem;
            line-height: 3.6rem;
            margin-bottom: 4rem;
        }
        @media (max-width: $sw-sm-max) {
            font-size: 2.8rem;
            line-height: 3.4rem;
            margin-bottom: 3.2rem;   
        }
        @media (max-width: $sw-xs-max) {
            font-size: 2.4rem;
            line-height: 3rem;
            margin-bottom: 2.5rem;   
        }
    }
    &__text {
        max-width: 50rem;
        @media (max-width: $sw-md-max) {
            max-width: 41rem;
        }
        @media (max-width: $sw-sm-max) {
            max-width: 100%;
        }
        &-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
            }
        }
    }
    &__promp {
        max-width: 41.1rem;
        @media (max-width: $sw-md-max) {
            max-width: 40rem;
        }
        @media (max-width: $sw-sm-max) {
            max-width: 100%;
        }
        &-text {
            font-weight: 500;
        }
    }
}

.conf-visit {
    &__section {
        padding: 4rem 0 5rem 0;
        background-color: #BABABA;
        @media (max-width: $sw-sm-max) {
            padding: 3rem 0 1.5rem 0;
        }
    }
    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &__info-side {
        width: 51%;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            margin-bottom: 3rem;
        }
    }
    &__descr-side {
        width: 40%;
        @media (max-width: $sw-sm-max) {
            width: 100%;
        }
    }
    &__time {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 2.2rem;
    }
}

.conf-dot {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
}