@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../fonts/' + $fontfile + '.eot');
		src: url('../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face ('TTNorms', 'TTNorms-Thin', 100, normal);
@include font-face ('TTNorms', 'TTNorms-ExtraLight', 200, normal);
@include font-face ('TTNorms', 'TTNorms-Light', 300, normal);
@include font-face ('TTNorms', 'TTNorms-Regular', 400, normal);
@include font-face ('TTNorms', 'TTNorms-Medium', 500, normal);
@include font-face ('TTNorms', 'TTNorms-Heavy', 600, normal);
@include font-face ('TTNorms', 'TTNorms-Bold', 700, normal);
@include font-face ('TTNorms', 'TTNorms-ExtraBold', 800, normal);
@include font-face ('TTNorms', 'TTNorms-Black', 900, normal);

@include font-face ('TTNorms', 'TTNorms-ThinItalic', 100, italic);
@include font-face ('TTNorms', 'TTNorms-ExtraLightItalic', 200, italic);
@include font-face ('TTNorms', 'TTNorms-LightItalic', 300, italic);
@include font-face ('TTNorms', 'TTNorms-Italic', 400, italic);
@include font-face ('TTNorms', 'TTNorms-MediumItalic', 500, italic);
@include font-face ('TTNorms', 'TTNorms-HeavyItalic', 600, italic);
@include font-face ('TTNorms', 'TTNorms-BoldItalic', 700, italic);
@include font-face ('TTNorms', 'TTNorms-ExtraBoldItalic', 800, italic);
@include font-face ('TTNorms', 'TTNorms-BlackItalic', 900, italic);