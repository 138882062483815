.section-about-business{
	//margin-top: 17rem;
	margin-bottom: 10rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		//margin-top: 14.6rem;
		margin-bottom: 8rem;
	}
}

.about-business__content{
	margin-top: 2.1rem;
}

.about-business__header{
	display: flex;
	align-items: center;
	margin-left: -1.6rem;
	margin-bottom: 5.4rem;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		margin-left: 0;
	}
	@media ( max-width: $sw-xs-max) {
		margin-bottom: 4.4rem;
		flex-direction: column;
	}
	>div{
		padding-left: 1.6rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {
			padding-left: 0;
		}
		@media ( max-width: $sw-xs-max) {}
	}
}

.about-business__header-motto{
	max-width: 50%;
	width: 66.66666%;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 50%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		max-width: 100%;
	}
}

.about-business__header-action{
	width: 33.33333%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 45%;
		text-align: right;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		text-align: left;
		margin-top: 2.8rem;
	}
}

.about-business__list{
	display: flex;
	align-items: stretch;
	margin-left: -1.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		margin-left: 0;
		flex-wrap: wrap;
	}
	@media ( max-width: $sw-xs-max) {}
}

.about-business__item{
	width: 33.33333%;
	margin-left: 1.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		margin-left: 0;
		&:nth-child(n+2){
			margin-top: 1.6rem;
		}
	}
	@media ( max-width: $sw-xs-max) {}
}

.about-business-box{
	height: 100%;
	color: $white;
	padding: 3.2rem;
	font-weight: 500;
	padding-bottom: 6.3rem;
	background-color: $cerulean;
}

.about-business-box__view{
	height: 15.6rem;
	svg{
		fill: $white;
		font-size: 8rem;
	}
}

.about-business-box__name{
	font-weight: 900;
	font-size: 2.4rem;
	line-height: 2.8rem;
}

.about-business-box__detail{
	font-size: 2rem;
	margin-top: 2.6rem;
	line-height: 2.8rem;
}