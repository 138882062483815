button{
	border: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	box-shadow: none;
	display: inline-block;
	outline: none !important;
	background-color: transparent;
}

.btn{
	width: auto;
	color: $white;
	height: 4.8rem;
	overflow: hidden;
	font-weight: 900;
	border-radius: 0;
	font-size: 1.6rem;
	padding: 0 2.4rem;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	transition: all .3s ease;
	background-color: $orange;
	&:hover{
		color: #fff;
		text-decoration: none;
	}
	&:focus{
		color: #fff;
		text-decoration: none;
	}
	// @media ( min-width: $sw-md-min) {
	// 	&:hover{
	// 		transform: scale(1.05);
	// 	}
	// }

	&:disabled{
		opacity: .3;
		pointer-events: none;
	}

	&_blue{
		background-color: $cerulean;
	}
	&_red {
		background: #9E0B0F;
	}
	&_black {
		background-color: black;
	}
	&_green {
		background-color: $vistaBlue;
	}
	&_braun{
		background-color: $thunder;
		.icon-arrow {
			fill: $orange;
		}
	}
	&_green {
		background-color: #73D19C;
	}
	&_xl{
		min-width: 24.1rem;
		@media ( max-width: $sw-xs-max) {
			min-width: 100%;
		}
	}
	&_sm{
		min-width: 15.6rem;
	}
	&_fl{
		width: 100%;
	}
	&_h-xl{
		height: 5.6rem;
	}

	&_close{
		margin: 0;
		padding: 0;
		width: 2.4rem;
		height: 2.4rem;
		position: relative;
		background-color: transparent;
		&:after{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			width: 2rem;
			margin: auto;
			height: .2rem;
			display: block;
			position: absolute;
			background-color: $alto;
			transform: rotateZ(45deg);
			transition: all 0.3s ease;
		}
		&:before{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			width: 2rem;
			margin: auto;
			height: .2rem;
			display: block;
			position: absolute;
			background-color: $alto;
			transform: rotateZ(-45deg);
			transition: all 0.3s ease;
		}
		@media ( min-width: $sw-md-min) {
			&:hover{
				transform: scale(1);
				background-color: transparent;
				&:after{
					background-color: $black;
				}
				&:before{
					background-color: $black;
				}
			}
		}
	}
	&_submit{
		&:after{
			width: 0;
			top: .3rem;
			left: .3rem;
			bottom: .3rem;
			display: flex;
			color: $orange;
			overflow: hidden;
			position: absolute;
			align-items: center;
			justify-content: center;
			background-color: $white;
			content: attr(data-success);
		}
		&.btn_blue{
			&:after{
				color: $cerulean;
			}
		}
	}
	&_arrow{
		padding: 0;
		width: 3rem;
		height: 3rem;
		position: relative;
		background-color: transparent;
		svg{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			fill: $orange;
			font-size: 1.8rem;
			position: absolute;
			transform: rotateZ(90deg);
		}
		@media ( min-width: $sw-md-min) {
			&:hover{
				transform: scale(1);
				transform: translateY(.6rem);
			}
		}
	}
}