.not-averege-event {
	&__links {
		a {
			padding: 0 6rem;
		}
	}
	&__title {
		max-width: 800px;
		h2 {
			line-height: 6.2rem;
			@media (max-width: $sw-xs-max) {
				line-height: 3rem;
				font-size: 30px;
			}
			@media (max-width: $sw-xx-max) {
				line-height: 3rem;
			}
		}
	}
	&__subtitle {
		padding-top: 6rem;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background: #98CB4E;
			display: inline-block;
		}
		span {
			font-size: 2.4rem;
			line-height: 2.9rem;
			@media (max-width: $sw-xx-max) {
				font-size: 1.6rem;
			}
		}
	}
	&__items {
		display: flex;
		margin-top: 44px;
		//margin-left: 28px;
		@media (max-width: $sw-xs-max) {
			margin-top: 20px;
			margin-left: 0;
			flex-direction: column;
		}
	}
	&__item {
		width: 33%;
		&:not(:last-child) {
			margin-right: 2rem;
		}
		@media (max-width: $sw-xs-max) {
			width: 100%;
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 0;
			text-align: center;
		}
		&-title {
			h3 {
				font-size: 2.4rem;
				text-transform: uppercase;
			}
		}
		&-subtitle {
			margin-top: 25px;
			span {
				font-size: 1.4rem;
			}
		}
	}
	&__section {
		&-title {
			margin-top: 94px;
			@media (max-width: $sw-xs-max) {
				margin-top: 30px;
			}
		}
		&-meet-items {
			display: flex;
			margin-top: 40px;
			//margin-left: 23px;
			@media (max-width: $sw-xs-max) {
				margin-left: 0;
				flex-direction: column;
			}
		}
		&-meet-item {
			width: 25%;
			@media (max-width: $sw-xs-max) {
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		 &:not(:last-child) {
			 margin-right: 41px;
		 }
		 &-name {
				margin-top: 17px;
			 span {
				font-size: 1.4rem;
				font-weight: 600;
				
			 }
		 }
		 &-desc {
			margin-top: 17px;
			 span {
				font-size: 1.4rem;
				font-weight: 500;
				margin-top: 5px;
			 }
		 }
		}
		&-update {
			display: flex;
			margin-top: 10.8rem;
			@media (max-width: $sw-xs-max) {
				margin-top: 50px;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			&-desc {
				display: flex;
				flex-direction: column;
				@media (max-width: $sw-xs-max) {
					text-align: center;
				}
				b {
					color: #9E0B0F;
					font-size: 2.8rem;
					font-weight: 800;
				}
				span {
					margin-top: 37px;
					font-size: 1.4rem;
				}
			}
			&-form {
				margin-left: 153px;
				@media (max-width: $sw-xs-max) {
					margin-left: 0;
					margin-top: 50px;
					width: 100%;
				}
			}
			.news-inner__aside-form {
				background: none;
				padding-top: 0;
				width: 100%;
				.form-box__content.form-box__content--inputs {
					padding-top: 0;
				}
			}
			
		}
		}
}
