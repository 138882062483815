.section-question{
	padding-top: 12.2rem;
	padding-bottom: 17.5rem;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 1.8rem;
		padding-bottom: 5rem;
	}
	.section-content{
		display: flex;
		padding-right: 8.4rem;
		align-items: flex-start;
		justify-content: space-between;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {
			padding-right: 0;
			flex-direction: column;
		}
		@media ( max-width: $sw-xs-max) {}
	}
	.section-title{
		position: relative;
		padding-right: 3.2rem;
		&:after{
			top: -103%;
			right: 59%;
			content: '';
			width: 46rem;
			height: 46rem;
			display: block;
			border-radius: 50%;
			position: absolute;
			border: .3rem solid $cerule;
			@media ( max-width: $sw-sm-max) {
				top: -150%;
				right: 50%;
			}
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				display: none;
			}
		}
		&__main{
			width: 100%;
		}
	}
}

.question__content{
	z-index: 1;
	width: 41.3rem;
	flex-shrink: 0;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	position: relative;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		margin-top: 4.2rem;
	}
	@media ( max-width: $sw-xs-max) {}
	ul{
		li{
			&:not(:last-child){
				margin-bottom: 2rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid $boulderSolid;
			}
		}
	}
}