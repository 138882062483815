.speaker {
    &-inner {
        &__section {
            padding-top: 1.7rem;
            padding-bottom: 12.1rem;
            background: $wildSand;
            @media (max-width: $sw-sm-max) {
                padding-bottom: 0;
            }
        }
        &__content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
            }
        }
    }
    &__navigation {
        margin-bottom: 2.9rem;
        margin-left: -2.3rem;
        @media (max-width: $sw-xs-max) {
            margin-left: 0;
        }
        .link {
            text-transform: uppercase;
        }
    }
    &__main-side {
        width: 25rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            margin-bottom: 10.5rem;
        }
        .speaker-block-wrap {
            width: 100%;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 0;
            }
        }
        .speaker-header {
            @media (max-width: $sw-sm-max) {
                justify-content: center;
                margin-bottom: 6.4rem;
            }
        }
        .speaker-view {
            @media (max-width: $sw-sm-max) {
                width: 9rem;
                height: 9rem;
            }
        }
        .speaker-content {
            @media (max-width: $sw-sm-max) {
                padding-left: 0;
            }
        }
    }
    &__text-side {
        width: 54%;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            margin-bottom: 10rem;
        }
        @media (max-width: $sw-xs-max) {
            margin-bottom: 7rem;
        }
        p.bold {
            font-weight: 500;
        }
    }
    &__text-footer {
        margin-top: 7rem;
        p {
            margin-bottom: 1.5rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 2.5rem;
            }
        }
    }
    &__text-time {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        @media (max-width: $sw-sm-max) {
            margin-bottom: 2rem;
        }
    }
}