.registration-fixed-nav{
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 6;
	display: none;
	position: fixed;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		// display: block;
	}
}

.registration-fixed-nav__form{
	form{
		>.form-group{
			padding-top: 1.6rem;
			padding-left: 2.4rem;
			padding-right: 2.4rem;
		}
	}
}

.form-buttons-group{
	display: flex;
	padding: .2rem;
	margin-top: 1.8rem;
	background-color: $orange;
	.form-group{
		width: 100%;
		& + .form-group_submit{
			margin: 0;
		}
	}
}