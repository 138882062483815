.section-registration{
	padding-bottom: 15.5rem;
	background-color: $wildSand;
	@media ( max-width: $sw-xs-max) {
		padding-bottom: 8rem;
	}
}

.registration__content{
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {}
}

.registration__list{
	width: 67.7%;
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.6rem;
	align-items: flex-start;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {
		margin-left: 0;
	}
}




.registration__item{
	width: 50%;
	padding-left: 1.6rem;
	margin-bottom: 3.4rem;
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		padding-left: 0;
	}
}

.registration-step-box{
	height: 100%;
	position: relative;
	padding-bottom: 3rem;
	background-color: $white;
}

.registration-step-box__header{
	color: $white;
	height: 10.8rem;
	padding-top: 1.6rem;
	padding-left: 2.6rem;
	padding-right: 2.6rem;
	padding-bottom: 1.6rem;
	background-color: $black;
}

.registration-step-box__title{
	font-size: 2rem;
	font-weight: 900;
	line-height: 2.2rem;
	margin-bottom: .6rem;
}

.registration-step-box__caption{
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2.2rem;
}

.registration-step-box__body{
	display: flex;
	flex-direction: column;
	height: calc(100% - 10.8rem);
}

.registration-step-box__include{
	flex-shrink: 0;
	border-bottom: 1px solid $alto;
}

.registration-step-box__include-btn{
	a{
		width: 100%;
		height: 5.9rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		span{

		}
		svg{

		}
	}
	&.active{
		a{
			svg{
				fill: $orange;
				transform: rotateZ(-90deg);
			}
			@media ( min-width: $sw-md-min) {
				&:hover{
					svg{
						animation-name: none;
					}
				}
			}
		}
	}
}

.registration-step-box__include-content{
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	top: 16.8rem;
	display: none;
	overflow: hidden;
	overflow-y: auto;
	padding-top: 2rem;
	position: absolute;
	padding-left: 2.4rem;
	padding-bottom: 2rem;
	background-color: $white;
	ul{
		height: 100%;
		/* overflow-y: hidden; */
		padding-right: 2.4rem;
		li{
			font-size: 1.6rem;
			position: relative;
			line-height: 2.2rem;
			padding-left: 1.7rem;
			&:after{
				left: 0;
				top: .6rem;
				content: '';
				width: .8rem;
				height: .8rem;
				display: block;
				position: absolute;
				border-radius: 50%;
				background-color: $orange;
			}
		}
	}
}

.registration-step-box__info{
	display: flex;
	flex-shrink: 0;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 1.5rem;
	align-items: center;
	margin-top: 1.6rem;
	padding-left: 2.2rem;
	padding-right: 2.2rem;
	margin-bottom: 0.8rem;
	text-transform: uppercase;
	justify-content: space-between;
	svg{
		flex-shrink: 0;
		font-size: 2.4rem;
		margin-left: 2.4rem;
	}
}

.registration-step-box__form-list{
	margin-top: 2.2rem;
	padding-left: 3.1rem;
	padding-right: 3.1rem;
	dl{
		margin: 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		&:not(:last-child){
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid $alto;
		}
		dt{
			font-size: 2rem;
			font-weight: 700;
			line-height: 2.6rem;
			width: 50%;
		}
		dd{
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
	}
}

.registration-step-box__form-price{
	flex-shrink: 0;
	margin-top: .9rem;
	padding-left: 2.2rem;
	padding-right: 2.2rem;
	.registration-step-box__price{
		opacity: .3;
		display: flex;
		padding-left: 0;
		position: relative;
		padding-top: .9rem;
		padding-bottom: .6rem;
		align-items: flex-start;
		justify-content: space-between;
		&:not(:last-child){
			border-bottom: 1px solid $alto;
		}
		>b{
			display: block;
			font-size: 2rem;
			font-weight: 500;
			line-height: 2.8rem;
		}
		i{
			display: block;
			width: 11.8rem;
			text-align: left;
			font-style: normal;
			font-size: 1.6rem;
			line-height: 2.2rem;
			b{
				display: block;
				font-weight: 500;
			}
		}
		&.active{
			opacity: 1;
			&:after{
				top: 1.5rem;
				left: -3rem;
				content: '';
				width: 1.6rem;
				height: 1.6rem;
				display: block;
				border-radius: 50%;
				position: absolute;
				background-color: $orange;
			}
		}
	}
}

.registration-step-box__form-delegates{
	height: 100%;
	display: flex;
	margin-top: 3.3rem;
	padding-left: 2.4rem;
	align-items: flex-end;
	padding-right: 2.4rem;
	justify-content: space-between;
}
.item_register_link{
	display: flex;
	margin-top: 3.3rem;
	padding-left: 2.4rem;
	align-items: flex-end;
	padding-right: 2.4rem;
	
}

.registration-step-box__form-delegates-title{
	font-size: 2rem;
	line-height: 2.8rem;
	white-space: nowrap;
}

.registration-step-box__form-delegates-input{
	width: 5.9rem;
}















































.registration__aside{
	width: 32.4%;
	padding-top: 2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		padding-top: 0;
		margin-top: 6.4rem;
	}
	@media ( max-width: $sw-xs-max) {}
}

.registration__aside-form{
	margin-bottom: 3.4rem;
	.form-group_submit + .form-group{
		margin-top: .8rem;
	}
}

.registration__conditions{
	padding-left: 3.4rem;
	padding-right: 3.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		padding-left: 0;
		padding-right: 0;
	}
	@media ( max-width: $sw-xs-max) {}
}

.registration__conditions-title{
	font-weight: 900;
	font-size: 2.4rem;
	line-height: 2.8rem;
	margin-bottom: 2.6rem;
}

.registration__conditions-list{
	ul{
		li{
			font-size: 1.6rem;
			position: relative;
			padding-top: 1.6rem;
			line-height: 2.2rem;
			&:not(:last-child){
				margin-bottom: 2.8rem;
			}
			&:after{
				top: 0;
				left: 0;
				content: '';
				width: 1rem;
				height: 1rem;
				display: block;
				position: absolute;
				border-radius: 50%;
				background-color: $orange;
			}
		}
	}
}

.registration__reg-process{
	margin-top: 5rem;
	padding-left: 4.2rem;
	padding-right: 4.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		padding-left: 0;
		padding-right: 0;
	}
	@media ( max-width: $sw-xs-max) {}
}

.registration__reg-process-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 2.5rem;
}

.registration__reg-process-units{

}

.registration__reg-process-unit{
	font-size: 1.6rem;
	line-height: 2.2rem;
}

.registration__reg-process-unit-view{
	width: 7rem;
	height: 7rem;
	display: block;
	overflow: hidden;
	border-radius: 50%;
	margin-bottom: 1.4rem;
	picture{
		width: 100%;
		height: 100%;
		display: block;
		img{

		}
	}
}

.registration__reg-process-unit-name{
	text-transform: uppercase;
}

.registration__reg-process-unit-position{
	margin-top: .3rem;
}

.registration__reg-process-unit-address{
	font-weight: 500;
	//margin-top: 1.6rem;
}