.section-registration-options{
	padding-bottom: 7.6rem;
	background-color: $wildSand;
	.row{
		margin-left: -1rem;
		margin-right: -1rem;
		.col{
			padding-left: 1rem;
			padding-right: 1rem;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				&:not(:last-child){
					margin-bottom: 2.6rem;
				}
			}
		}
	}
	.registration__list{
		margin: 0;
		flex-direction: column;
	}
	.registration__aside{
		padding-left: 1.6rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {
			padding-left: 0;
		}
		@media ( max-width: $sw-xs-max) {}
	}
	.reg-content__aside-inner {
		transform: translateY(0);
		transition: transform .3s ease;
		&.sticked {
			transform: translateY(4rem);
			
		}
	}
}



.registration-options__back{
	margin-bottom: 5.4rem;
}

.registration-options__currency{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 3.6rem;
}

.registration-options__currency-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-right: 1.7rem;
}

.registration-options__currency-list{
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
			}
			a{
				color: $black;
				width: 3.2rem;
				height: 3.2rem;
				display: block;
				overflow: hidden;
				font-weight: 700;
				font-size: 1.6rem;
				border-radius: 50%;
				text-align: center;
				line-height: 3.2rem;
				text-decoration: none;
				background-color: $alto;
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-.4rem);
					}
				}
			}
			&.active{
				a{
					color: $white;
					background-color: $orange;
				}
			}
		}
	}
}





.registration-options__basic{
	width: 100%;
	padding-bottom: 3.6rem;
	background-color: $white;
	@media ( max-width: $sw-xs-max) {
		padding-bottom: 5.6rem;
	}
	&:not(:last-child){
		margin-bottom: 1.6rem;
	}
}

.registration-options__basic-header{
	padding-top: 2.7rem;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	margin-bottom: 2.8rem;
	border-bottom: 1px solid $alto;
}

.registration-options__basic-title{
	font-size: 2rem;
	font-weight: 900;
	line-height: 2.2rem;
	margin-bottom: .3rem;
	@media ( max-width: $sw-xs-max) {
		margin-bottom: 3.2rem;
	}
}

.registration-options__basic-nav{
	display: flex;
	//align-items: center;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.registration-options__basic-tabs{
	margin-bottom: -.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 1;
	}
	.tab-nav{
		flex-wrap: wrap;
		li{
			width: auto;
			&:not(:last-child){
				margin-right: 5rem;
			}
			a{
				font-weight: 500;
				font-size: 1.6rem;
				line-height: 2.2rem;
				white-space: nowrap;
				padding-top: 1.8rem;
				padding-bottom: 1.8rem;
				border-bottom: .4rem solid transparent;
				&.active{
					border-bottom: .4rem solid $orange;
				}
			}
		}
	}
}

.registration-options__basic-add{
	flex-shrink: 0;
	margin-top: 1.8rem;
	@media ( max-width: $sw-xs-max) {
		margin: 0;
		margin-bottom: 1.6rem;
	}
}

.registration-options__basic-content{
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	.delegate_content{
		display: none;
		&.active{
			display: flex;
		}
	}
}

.registration-options__basic-content-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 3.3rem;
}

.registration-options__basic-content-list{
	.row{
		&:not(:last-child){
			margin-bottom: 2.6rem;
		}
		.form-check{
			margin-top: 1.8rem;
			margin-bottom: -.4rem;
		}
		.form-file{
			margin-top: 1.8rem;
		}
	}
}






.registration-options__email{
	margin-top: .6rem;
	padding-top: 3.4rem;
	padding-left: 3.2rem;
	padding-bottom: 4rem;
	padding-right: 3.2rem;
	background-color: $white;
}

.registration-options__email-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: .1rem;
}

.registration-options__email-form{
	max-width: 39%;
}




.registration-options__additional{
	margin-top: 3.2rem;
	padding-top: 3.2rem;
	padding-left: 3.2rem;
	padding-right: 3.2rem;
	padding-bottom: 1.4rem;
	background-color: $white;
}

.registration-options__additional-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 3.9rem;
}

.registration-options__additional-form{
	padding-left: 5.2rem;
	padding-right: 5.2rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 0;
		padding-right: 0;
	}
	.row{
		&:not(:last-child){
			margin-bottom: 5.2rem;
		}
	}
	.form-group{
		textarea{
			min-height: 10.6rem;
		}
	}
	.caption-group{
		margin-top: 0;
		text-align: left;
		margin-bottom: 2.4rem;
		p{
			font-size: 1.4rem;
    
    line-height: 2.4rem;
        margin-bottom: 1.4rem;
		}
	}
}




.registration-options__optional{
	margin-top: 4.6rem;
}

.registration-options__optional-title{
	font-weight: 900;
	font-size: 2.4rem;
	line-height: 2.8rem;
	margin-bottom: 3.2rem;
}

.registration-options__optional-content{
	padding-top: 2.4rem;
	padding-left: 3.2rem;
	padding-right: 3.2rem;
	padding-bottom: 6.2rem;
	background-color: $white;
}

.registration-options__optional-boxes{
}

.registration-options__optional-box{
	&:not(:last-child){
		margin-bottom: 6.4rem;
	}
}

.registration-options__optional-box-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 3.9rem;
}
.registration-options__optional-box-subtitle{
	    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0;
    color: #787878;
    text-align: right;
}

.registration-options__optional-box-content{
	.row{
		&:not(:last-child){
			margin-bottom: 2.6rem;
		}
	}
}

.reg-aside__order-promocode{
	margin-top: 4.4rem;
}
 
.reg-aside__order-promocode-title{
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 1.8rem;
	margin-bottom: 1.6rem;
}

.reg-aside__order-promocode-form{
	position: relative;
	input{
		padding-right: 10.6rem;
	}
	button{
		top: 50%;
		right: 0;
		position: absolute;
		transform: translateY(-50%);
	}
}






// .row
// .col.col_xl.col-sm-24
// .form-group.form-group_xl.form-input.form-input_blue.form-input_xl
// textarea(name="additional", placeholder="Write something important what we need to know if it is applicalable.")
// .row
// .col.col-sm-12
// .form-group.caption-group Please tick this box if you wish to receive information from us about this or related events via
// .form-group.form-check
// label
// input(type="checkbox")
// span E-mail
// .form-group.form-check
// label
// input(type="checkbox")
// span Phone
// .col.col-sm-12
// .form-group.caption-group Do you consent to share your data with the third parties (our partners) to receive any marketing communications via email related to your business profile?
// .form-group.form-check
// label
// input(type="radio", name="add")
// span Yes, I consent
// .form-group.form-check
// label
// input(type="radio", name="add")
// span No, I don’t consent
// .row
// .col.col_xl.col-sm-24
// .form-group.caption-group