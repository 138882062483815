.industry-profile {
	&__subtitle {
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		margin-top: 3.4rem;
		@media (max-width: $sw-md-max) {
			flex-direction: column;
		}
		span {
			width: 600px;
			line-height: 2.8rem;
			@media (max-width: $sw-xs-max) {
				width: 100%;
			}
		}
		&-small {
			width: 329px;
			@media (max-width: $sw-md-max) {
				margin-top: 20px;
			}
			@media (max-width: $sw-xs-max) {
				width: 100%;
			}
			span {
				font-size: 1.6rem;
				
			}
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		background: #F2F2F2;
		padding: 40px 12px 15px 12px;
		margin-top: 20px;
		@media (max-width: $sw-md-max) {
			padding-top: 15px;
		}
		&-head {
			display: flex;
			@media (max-width: $sw-md-max) {
				flex-direction: column;
				align-items: center;
			}
			&-img {
				width: 201px;
				height: 150px;
				flex-shrink: 0;
				@media (max-width: $sw-md-max) {
					width: 100%;
					height: 200px;
				}
				&-inner {
					height: 100%;
				}
			}
			&-title {
				margin-left: 15px;
				@media (max-width: $sw-md-max) {
					text-align: center;
					margin-top: 20px;
					margin-left: 0;
				}
				h3 {
					font-size: 4.8rem;
					text-transform: uppercase;
				}
			}
			&-subtitle {
				margin-top: 38px;
				margin-left: 15px;
				@media (max-width: $sw-md-max) {
					text-align: center;
					margin-left: 0;
				}
				span {
					font-weight: 900;
					font-size: 2.4rem;
					text-transform: uppercase;
				}
			}
		}
		&-body {
			margin-top: 39px;
			ul {
				list-style-type: inherit;
				padding-left: 25px;
				li {
					font-size: 1.6rem;
					line-height: 2rem;
					margin-bottom: 0rem !important;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&__sources {
		margin-top: 34px;
		&-title {
			h3 {
				font-size: 2.2rem;
				text-transform: uppercase;
			}
		}
		&-list {
			padding-left: 18px;
			padding-top: 13px;
			@media (max-width: $sw-xs-max) {
				padding-left: 0;
			}
			ul {
				li {
					margin-bottom: 25px;
					a {
						font-size: 1.6rem;
						text-decoration: none;
						color: black;
					}
				}
			}
		}
	}
}