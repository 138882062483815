.section-platforms{
	padding-top: 10rem;
	padding-bottom: 12.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		background-color: $wildSand;
	}
	@media ( max-width: $sw-xs-max) {
		padding-top: 4.3rem;
		padding-bottom: 9.4rem;
	}
}

.platforms__content{
	margin-top: 6.1rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 6.4rem;
	}
}

.platforms__items{
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.6rem;
	margin-bottom: -2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100vw;
		margin-bottom: 0;
		margin-left: calc((100vw - 70.8rem) / -2);
	}
	@media ( max-width: $sw-xs-max) {
		width: auto;
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}
}

.platforms__item{
	padding-left: 1.6rem;
	margin-bottom: 2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		opacity: .5;
		margin-bottom: 0;
		transition: opacity .3s ease;
		&.slick-active.slick-center{
			opacity: 1;
		}
	}
	@media ( max-width: $sw-xs-max) {
	}
}

.platform-box{
	display: block;
	width: 15.4rem;
	height: 15.4rem;
	padding: 1.6rem;
	overflow: hidden;
	position: relative;
	background-color: $white;
	@media ( min-width: $sw-md-min) {
		&:hover{
			.platform-box__case{
				transform: translateX(0);
			}
		}
	}
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 20.8rem;
		height: 20.8rem;
	}
	@media ( max-width: $sw-xs-max) {}
}

.platform-box__view{
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	picture{
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		img{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			position: absolute;
			/* filter: grayscale(100%); */
			@media ( max-width: $sw-sm-max) {
				max-width: 90%;
				max-height: 90%;
			}
		}
	}
}

.platform-box__case{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	padding: 1.6rem;
	position: absolute;
	flex-direction: column;
	justify-content: flex-end;
	background-color: $cerule;
	transition: all 0.375s ease;
	transform: translateX(-100%);
	@media ( max-width: $sw-sm-max) {
		transform: none;
		background-color: transparent;
	}
}

.platform-box__case-btn{
	color: $white;
	font-weight: 900;
	font-size: 1.6rem;
	align-items: center;
	line-height: 1.6rem;
	display: inline-flex;
	svg{
		fill: $white;
		font-size: 1.8rem;
		margin-left: .8rem;
	}
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		color: $black;
		svg{
			fill: $black;
		}
	}
	@media ( max-width: $sw-xs-max) {}
}