.section-history {
    &__wrap {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
    }
    &__text-block {
        margin-right: 80px;
        margin-bottom: 3rem;
        width: calc((100% - 80px) / 2);
        &:nth-child(2n) {
            margin-right: 0;
        }
        @media (max-width: $sw-sm-max) {
            margin-right: 50px;
            width: calc((100% - 50px) / 2);
        }
        @media (max-width: $sw-xs-max) {
            margin-right: 0;
            margin-bottom: 0;
            width: 100%;
        }
        p {
            font-size: 2.4rem;
            line-height: 3rem;
            color: #000;
            font-weight: 500;
            @media (max-width: $sw-xs-max) {
                font-size: 2rem;
                line-height: 2.6rem;
            }
        }
    }
}

.section-video-report {
    margin-bottom: 7rem;
    @media (max-width: $sw-md-max) {
        margin-bottom: 5rem;
    }
}

.video-report {
    &__wrap {
        margin-top: 3rem;
        .container {
            @media (max-width: $sw-xs-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .news__top-block-title {
            margin-bottom: 2.5rem;
            width: calc(100% - 25.7rem);
            padding-left: 0;
            padding-right: 0;
            @media (max-width: $sw-sm-max) {
                width: 100%;
            }
        }
        .news__top-video {
            width: 100%;
            height: 56.2rem;
            @media (max-width: $sw-lg-max) {
                height: 50rem;
            }
            @media (max-width: $sw-md-max) {
                height: 46rem;
            }
            @media (max-width: $sw-sm-max) {
                height: 42rem;
            }
            @media (max-width: $sw-xs-max) {
                height: 37.5rem;
            }
            &.news__top-video-sm {
                width: calc(100% - 25.7rem);
                @media (max-width: $sw-sm-max) {
                    width: 100%;
                }
                @media (max-width: $sw-xs-max) {
                    height: auto;
                }
                & + .news__top-info {
                    .video__info-company {
                        margin-top: 2rem;
                    }
                }
            }
        }
        .video__info-photo {
            width: 9.4rem;
            height: 9.4rem;
            @media (max-width: $sw-sm-max) {
                width: 8rem;
                height: 8rem;
            }
            @media (max-width: $sw-xs-max) {
                width: 6.9rem;
                height: 6.9rem;
            }
        }
    }
}

.section-text-list {
    margin-bottom: 3rem;
        position: relative;
    &:after{
            top: -48px;
        left: 0;
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        position: absolute;
        border-radius: 50%;
        background-color: $orange;
        @media (max-width: 767px){
                top: -37px;
        }
    }
    .text-list {
        &__wrap {
            display: flex;
            align-items: flex-start;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
            }
        }
        &__info {
            margin-right: 5rem;
            /* width: 48rem; */
            @media (max-width: $sw-sm-max) {
                margin-right: 0;
                width: 100%;
            }
            p {
                font-size: 2.4rem;
                line-height: 3rem;
                color: #000;
                font-weight: 500;
                @media (max-width: $sw-xs-max) {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }
            }
        }
        &__item {
            list-style: none;
            margin-top: 1rem;
            padding-left: 0;
            @media (max-width: $sw-sm-max) {
                width: 100%;
            }
            li {
                margin-top: 0;
                padding: 0;
                margin-bottom: 1.2rem;
                position: relative;
                padding-left: 20px;
                font-weight: 900;
                &:after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #47D597;
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }
        }

    }
}

.section-text-image {
    margin-bottom: 7rem;
        margin-top: 7rem;

    @media (max-width: $sw-md-max) {
        margin-bottom: 5rem;
    }
    .text-image {
        &__wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
            }
        }
        &__info {
            margin-right: 6.5rem;
            width: 100%;
            @media (max-width: $sw-sm-max) {
                margin-right: 0;
                order: 1;
            }
            p {
                font-size: 2.4rem;
                line-height: 3rem;
                color: #000;
                font-weight: 500;
                @media (max-width: $sw-xs-max) {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }
            }
        }
        &__item {
            width: 27.5rem;
            height: 16.5rem;
            flex-shrink: 0;
            @media (max-width: $sw-sm-max) {
                order: 0;
                margin-bottom: 2.5rem;
            }
            @media (max-width: $sw-xx-max) {
                width: 100%;
            }
        }
    }
}

.section-themes {
    margin-bottom: 7rem;
    @media (max-width: $sw-md-max) {
        margin-bottom: 5rem;
    }
    &__title {
        margin-bottom: 3rem;
        h3 {
            text-transform: uppercase;
        }   
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -2rem;
        @media (max-width: $sw-xx-max) {
            margin-right: 0;
        }
        &.central-themes {
            .section-themes__block {
                width: calc((100% - 60px) / 3);
                @media (max-width: $sw-xs-max) {
                    width: calc((100% - 40px) / 2);
                }
                @media (max-width: $sw-xx-max) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
    &__block {
        width: calc((100% - 80px) / 4);
        margin-right: 2rem;
        margin-bottom: 3rem;
        @media (max-width: $sw-sm-max) {
            width: calc((100% - 60px) / 3);
        }
        @media (max-width: $sw-xs-max) {
            width: calc((100% - 40px) / 2);
        }
        @media (max-width: $sw-xx-max) {
            width: 100%;
            margin-right: 0;
        }
        .conf-dot {
            margin-bottom: 2rem;
            width: 1.6rem;
            height: 1.6rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 1.5rem;
            }
            @media (max-width: $sw-xx-max) {
                width: 1.4rem;
                height: 1.4rem;
                margin-bottom: 1.2rem;
            }
        }
        &-text {
            p {
                font-size: 1.4rem;
                line-height: 1.7;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 0;
                @media (max-width: $sw-sm-max) {
                    font-size: 1.3rem;     
                }
            }
        }
    }
}