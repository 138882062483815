.video-mini {
    &__section {
        width: 65.5rem;
        height: 14.4rem;
        z-index: 10;
        position: fixed;
        bottom: 3.3rem;
        right: 3.2rem;
        display: none;
        transition: transform .3s ease;
        @media (min-width: $sw-sm-min) {
            transform: translate(0, 100px);
        }
        @media (max-width: $sw-sm-max) {
            width: 58rem;
            height: 14rem;
            bottom: 2rem;
            right: 2rem;
        }
        @media (max-width: $sw-xs-max) {
            transform: translate(0,0);
            height: 7rem;
            width: 100%;
            bottom: 7rem;
            left: 0;
            right: auto;
        }
        &.active {
            transform: translate(0,0);
        }
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        top: 0;
        transform: none;
        left: 0;
        @media (max-width: $sw-xs-max) {
            
        }
        &.video-content {
            &:after {
                display: none;
            }
        }
        .link {
            text-transform: uppercase;
            position: absolute;
            right: 2.5rem;
            bottom: 2.5rem;
            z-index: 3;
            transition: none;
            @media (max-width: $sw-sm-max) {
                right: 2rem;
                bottom: 2rem;
            }
            @media (max-width: $sw-xs-max) {
                position: relative;
                right: auto;
                bottom: auto;
            }
            @media (max-width: $sw-xx-max) {
                font-size: 1.4rem;
            }
        }
        .video-content {
            &-box {
                height: 100%;
                width: 24rem;
                overflow: hidden;
                position: relative;
                @media (max-width: $sw-sm-max) {
                    width: 22rem;
                }
                @media (max-width: $sw-xs-max) {
                    position: static;
                    width: 14rem;
                }
                @media (max-width: $sw-xx-max) {
                    width: 10.5rem;
                }
            }
            &__inner {
                @media (max-width: $sw-xs-max) {
                    position: relative !important;
                    object-fit: unset;
                    bottom: auto;
                    top: auto;
                    left: auto;
                    right: auto;
                }
            }
            &__seek-bar {
                display: block;
                opacity: 1;
                visibility: visible;
                @media (max-width: $sw-xs-max) {
                    position: fixed;
                    width: 100%;
                    bottom: -5px;
                    display: block;
                    opacity: 1;
                    height: 5px;
                    background-color: #009bdf;
                }
            }
            &__full-screen {
                display: block;
                opacity: 1;
                visibility: visible;
                @media (max-width: $sw-xs-max) {
                    display: none !important;
                }
            }
            &__play {
                @media (max-width: $sw-xs-max) {
                    opacity: 1;
                    width: 3.4rem;
                    height: 3.4rem;
                    position: relative;
                    top: auto;
                    left: auto;
                }
                @media (max-width: $sw-xx-max) {
                    width: 3rem;
                    height: 3rem;
                }
                .icon {
                    &-play, &-pause-path {
                        @media (max-width: $sw-xx-max) {
                            font-size: 3rem;
                        }
                    }
                }
            }
            &__controls {
                width: 24rem;
                @media (max-width: $sw-xs-max) {
                    width: auto;
                    left: auto;
                    top: auto;
                    position: relative;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    &__info {
        // border: 1px solid green;
        width: calc(100% - 24rem);
        background-color: $black;
        padding: 2.1rem 6rem 5.1rem 2.4rem;
        @media (max-width: $sw-sm-max) {
            width: calc(100% - 22rem);
            padding: 1.6rem 5rem 5.1rem 2rem;
        }
        @media (max-width: $sw-xs-max) {
            width: calc(100% - 14rem);
            padding: 0 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // position: relative;
        }
        @media (max-width: $sw-xx-max) {
            padding: 0 1rem;
            width: calc(100% - 10.5rem);
        }
    }
    &__name {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        color: $white;
        @media (max-width: $sw-xs-max) {
            display: none;
        }
    }
    &__close {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 1.3rem;
        right: 1.6rem;
        z-index: 1;
        @media (max-width: $sw-xs-max) {
            position: relative;
            right: auto;
            top: auto;
        }
        &:after, &:before {
            content: '';
            display: block;
            height: 100%;
            width: 2px;
            background-color: $white;
            position: absolute;
            left: 50%;
            top: 50%;
        }
        &:before {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
        &:after {
            transform: translate(-50%,-50%) rotate(45deg);
        }
    }
    &__minimize {
        width: 3rem;
        height: 3rem;
        top: -3rem;
        right: -3rem;
        display: none;
        &:after, &:before {
            height: 2rem;
        }
    }
}