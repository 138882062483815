@keyframes enterWidthLeftToRight {
	from {
		width: 0;
	}
	to {
		width: calc(100% - 22.8rem);
	}
}

@keyframes outWidthLeftToRight {
	from {
		width: calc(100% - 22.8rem);
	}
	to {
		width: 0;
		overflow: hidden;
	}
}





@keyframes enterWidthLeftToRightSM {
	from {
		width: 0;
	}
	to {
		width: calc(100% - 29.2rem);
	}
}

@keyframes outWidthLeftToRightSM {
	from {
		width: calc(100% - 29.2rem);
	}
	to {
		width: 0;
		overflow: hidden;
	}
}





@keyframes enterWidthLeftToRightXS {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

@keyframes outWidthLeftToRightXS {
	from {
		width: 100%;
	}
	to {
		width: 0;
		overflow: hidden;
	}
}






@keyframes fadeInUpNavElements {
	from {
		opacity: 0;
		transform: translate3d(0, 10%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeOutNavElements {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(0, 5%, 0);
	}
}



.nav-submenu-active{
	.nav-list-box{
		animation-duration: .5s;
		animation-fill-mode: both;
		animation-iteration-count: 1;
		@media ( min-width: $sw-xl-min) {
			animation-name: enterWidthLeftToRight;
		}
		@media ( max-width: $sw-lg-max) {
			animation-name: enterWidthLeftToRightSM;
		}
		@media ( max-width: $sw-xs-max) {
			animation-name: enterWidthLeftToRightXS;
		}
		.nav-list-box__content-item{
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-iteration-count: 1;
			animation-name: fadeInUpNavElements;
			&:nth-child(1){
				animation-delay: .5s;
			}
			&:nth-child(2){
				animation-delay: .65s;
			}
			&:nth-child(3){
				animation-delay: .8s;
			}
			&:nth-child(4){
				animation-delay: .95s;
			}
			&:nth-child(5){
				animation-delay: 1.1s;
			}
			&:nth-child(6){
				animation-delay: 1.25s;
			}
			&:nth-child(7){
				animation-delay: 1.4s;
			}
			&:nth-child(8){
				animation-delay: 1.55s;
			}
			&:nth-child(9){
				animation-delay: 1.7s;
			}
			&:nth-child(10){
				animation-delay: 1.85s;
			}
			&:nth-child(11){
				animation-delay: 2s;
			}
			&:nth-child(12){
				animation-delay: 2.15s;
			}
			&:nth-child(13){
				animation-delay: 2.3s;
			}
			&:nth-child(14){
				animation-delay: 2.45s;
			}
			&:nth-child(15){
				animation-delay: 2.6s;
			}
			&:nth-child(16){
				animation-delay: 2.75s;
			}
			&:nth-child(17){
				animation-delay: 2.9s;
			}
			&:nth-child(18){
				animation-delay: 3.05s;
			}
			&:nth-child(19){
				animation-delay: 3.2s;
			}
			&:nth-child(20){
				animation-delay: 3.35s;
			}
		}
	}
}

.nav-submenu-deactive{
	.nav-list-box{
		animation-delay: .5s;
		animation-duration: .5s;
		animation-fill-mode: both;
		animation-iteration-count: 1;
		@media ( min-width: $sw-xl-min) {
			animation-name: outWidthLeftToRight;
		}
		@media ( min-width: $sw-sm-min) and ( max-width: $sw-lg-max) {
			animation-name: outWidthLeftToRightSM;
		}
		@media ( max-width: $sw-xs-max) {
			animation-name: outWidthLeftToRightXS;
		}
		@media ( min-width: $sw-sm-min) {
			.nav-list-box__content-item{
				animation-duration: .5s;
				animation-fill-mode: both;
				animation-iteration-count: 1;
				animation-name: fadeOutNavElements;
			}
		}
	}
}







@keyframes animateOrbitScreen {
	0% {
		transform: rotate(0deg);
	}
	92% {
		transform: rotate(359deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

@keyframes animateOrbitPlanetScreen {
	0% {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	92% {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	96% {
		top: -.8rem;
		left: -.8rem;
		right: -.8rem;
		bottom: -.8rem;
	}
	100% {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.anim-orbit{
	animation-duration: 6s;
	animation-fill-mode: both;
	animation-timing-function: linear;
	animation-name: animateOrbitScreen;
	animation-iteration-count: infinite;
}

.anim-orbit-planet{
	i{
		&:after{
			animation-duration: 6s;
			animation-fill-mode: both;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			animation-name: animateOrbitPlanetScreen;
		}
	}
}













@keyframes animateArrowLink {
	0% {
		transform: translateY(0rem) rotateZ(90deg);
	}
	25% {
		transform: translateY(.4rem) rotateZ(90deg);
	}
	50% {
		transform: translateY(0rem) rotateZ(90deg);
	}
	75% {
		transform: translateY(-.4rem) rotateZ(90deg);
	}
	100% {
		transform: translateY(0rem) rotateZ(90deg);
	}
}

.link_arrow-down{
	@media ( min-width: $sw-md-min) {
		&:hover{
			svg{
				animation-duration: 1s;
				animation-fill-mode: backwards;
				animation-name: animateArrowLink;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
			}
		}
	}
}



@keyframes fadeInMobileButtonFirstElement {
	0% {
		top: 0rem;
	}
	50%{
		top: .6rem;
		transform: rotate(0);
	}
	100%{
		top: .6rem;
		transform: rotate(-45deg);
	}
}

@keyframes fadeInMobileButtonSecondElement {
	0% {
		opacity: 1;
	}
	50%{
		opacity: .5;
	}
	100%{
		opacity: 0;
	}
}

@keyframes fadeInMobileButtonThirdElement {
	0% {
		top: 1.2rem;
	}
	50%{
		top: .6rem;
		transform: rotate(0);
	}
	100%{
		top: .6rem;
		transform: rotate(45deg);
	}
}

@keyframes fadeInMobileButtonTextFirstElement {
	0% {
		opacity: 1;
	}
	50%{
		opacity: 0;
	}
	100%{
		opacity: 0;
	}
}

@keyframes fadeInMobileButtonTextSecondElement {
	0% {
		top: 100%;
		opacity: .25;
	}
	100%{
		top: 0%;
		opacity: 1;
	}
}


////////

@keyframes fadeOutMobileButtonFirstElement {
	0% {
		top: .6rem;
		transform: rotate(-45deg);
	}
	50%{
		top: .6rem;
		transform: rotate(0);
	}
	100%{
		top: 0rem;
		transform: rotate(0);
	}
}

@keyframes fadeOutMobileButtonSecondElement {
	0% {
		opacity: 0;
	}
	50%{
		opacity: .5;
	}
	100%{
		opacity: 1;
	}
}

@keyframes fadeOutMobileButtonThirdElement {
	0% {
		top: .6rem;
		transform: rotate(45deg);
	}
	50%{
		top: .6rem;
		transform: rotate(0);
	}
	100%{
		top: 1.2rem;
		transform: rotate(0);
	}
}

@keyframes fadeOutMobileButtonTextFirstElement {
	0% {
		opacity: 0;
	}
	50%{
		opacity: .25;
	}
	100%{
		opacity: 1;
	}
}

@keyframes fadeOutMobileButtonTextSecondElement {
	0% {
		top: 0%;
		opacity: 1;
	}
	100%{
		top: 100%;
		opacity: .25;
	}
}


/////


@keyframes fadeInMobileNav {
	0% {
		bottom: calc(100% - 8.4rem);
	}
	100%{
		bottom: 0;
	}
}

@keyframes fadeOutMobileNav {
	0% {
		bottom: 0;
	}
	100%{
		bottom: calc(100% - 8.4rem);
	}
}


@keyframes fadeInMobileNavXs {
	0% {
		bottom: calc(100% - 7.2rem);
	}
	100%{
		bottom: 0;
	}
}

@keyframes fadeOutMobileNavXs {
	0% {
		bottom: 0;
	}
	100%{
		bottom: calc(100% - 7.2rem);
	}
}

@keyframes fadeInMobileNavConferenceXs {
	0% {
		bottom: calc(100% - 5.6rem);
	}
	100%{
		bottom: 0;
	}
}

@keyframes fadeOutMobileNavConferenceXs {
	0% {
		bottom: 0;
	}
	100%{
		bottom: calc(100% - 5.6rem);
	}
}






// fade in up

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 50%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpNavList {
	from {
		opacity: 0;
		transform: translate3d(0, 10%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

// fade out down

@keyframes fadeOutDown {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translate3d(0, 50%, 0);
	}
}

@keyframes fadeOutDownNavList {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, 10%, 0);
	}
}


// animate vorder

@keyframes fadeInMobileNavBorder {
	from {
		opacity: 0;
		right: -.1rem;
	}
	to {
		right: 0;
		opacity: 1;
	}
}

@keyframes fadeOutMobileNavBorder {
	from {
		right: 0;
		opacity: 1;
	}
	to {
		opacity: 0;
		right: -.1rem;
	}
}


// animate vorder

@keyframes fadeInMobileNavBorderXs {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOutMobileNavBorderXs {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}



@media ( max-width: $sw-lg-max) {
	.nav-mobile-active{
		.nav-box__toggle{
			button{
				.nav-box__toggle-view{
					i{
						animation-duration: .6s;
						animation-fill-mode: both;
						&:nth-child(1){
							animation-name: fadeInMobileButtonFirstElement;
						}
						&:nth-child(2){
							animation-name: fadeInMobileButtonSecondElement;
						}
						&:nth-child(3){
							animation-name: fadeInMobileButtonThirdElement;
						}
					}
				}
				.nav-box__toggle-caption{
					span{
						animation-duration: .3s;
						animation-fill-mode: both;
						&.open{
							animation-name: fadeInMobileButtonTextFirstElement;
						}
						&.close{
							animation-name: fadeInMobileButtonTextSecondElement;
						}
					}
				}
			}
		}
		.nav-box{
			animation-delay: .6s;
			animation-duration: .5s;
			animation-fill-mode: both;
			animation-name: fadeInMobileNav;
			@media ( max-width: $sw-xs-max) {
				animation-name: fadeInMobileNavXs;
			}
			&_conference{
				@media ( max-width: $sw-xs-max) {
					animation-name: fadeInMobileNavConferenceXs;
				}
			}
			&:after{
				@media ( min-width: $sw-sm-min) {
					animation-delay: 1.1s;
					animation-duration: .3s;
					animation-fill-mode: both;
					animation-name: fadeInMobileNavBorder;
				}
			}
		}
		nav{
			animation-delay: 1.1s;
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeInUpNavList;
		}
		.nav-box__copywriting{
			animation-delay: 1.25s;
			animation-duration: .3s;
			animation-name: fadeInUp;
			animation-fill-mode: both;
		}
		.nav-box__conference-copywriting{
			animation-delay: 1.25s;
			animation-duration: .3s;
			animation-name: fadeInUp;
			animation-fill-mode: both;
		}
		.nav-box__conference-social, .nav-box__social{
			animation-delay: 1.25s;
			animation-duration: .3s;
			animation-name: fadeInUp;
			animation-fill-mode: both;
			li{
				animation-name: fadeInUp;
				animation-fill-mode: both;
				&:nth-child(1){
					animation-delay: 1.55s;
					animation-duration: .3s;
				}
				&:nth-child(2){
					animation-delay: 1.7s;
					animation-duration: .3s;
				}
				&:nth-child(3){
					animation-delay: 1.85s;
					animation-duration: .3s;
				}
				&:nth-child(4){
					animation-delay: 2s;
					animation-duration: .3s;
				}
				&:nth-child(5){
					animation-delay: 2.15s;
					animation-duration: .3s;
				}
			}
		}
	}

	.nav-mobile-deactive{
		.nav-box__toggle{
			button{
				.nav-box__toggle-view{
					i{
						animation-delay: 1.1s;
						animation-duration: .6s;
						animation-fill-mode: both;
						&:nth-child(1){
							animation-name: fadeOutMobileButtonFirstElement;
						}
						&:nth-child(2){
							animation-name: fadeOutMobileButtonSecondElement;
						}
						&:nth-child(3){
							animation-name: fadeOutMobileButtonThirdElement;
						}
					}
				}
				.nav-box__toggle-caption{
					span{
						animation-delay: 1.1s;
						animation-duration: .3s;
						animation-fill-mode: both;
						&.open{
							animation-name: fadeOutMobileButtonTextFirstElement;
						}
						&.close{
							animation-name: fadeOutMobileButtonTextSecondElement;
						}
					}
				}
			}
		}
		.nav-box{
			animation-delay: .6s;
			animation-duration: .5s;
			animation-fill-mode: both;
			animation-name: fadeOutMobileNav;
			@media ( max-width: $sw-xs-max) {
				animation-name: fadeOutMobileNavXs;
			}
			&_conference{
				@media ( max-width: $sw-xs-max) {
					animation-name: fadeOutMobileNavConferenceXs;
				}
			}
			&:after{
				@media ( min-width: $sw-sm-min) {
					animation-delay: .5s;
					animation-duration: .5s;
					animation-fill-mode: both;
					animation-name: fadeOutMobileNavBorder;
				}
			}
		}
		nav{
			animation-delay: .3s;
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeOutDownNavList;
		}
		.nav-box__copywriting{
			animation-delay: .15s;
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeOutDown;
		}
		.nav-box__conference-copywriting{
			animation-delay: .15s;
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeOutDown;
		}
		.nav-box__social{
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeOutDown;
		}
		.nav-box__conference-social{
			animation-duration: .3s;
			animation-fill-mode: both;
			animation-name: fadeOutDown;
		}
	}
}





@keyframes successValidation {
	from {
		width: 0;
	}
	to {
		width: calc(100% - .6rem);
	}
}


.valid-success{
	.btn_submit{
		&:after{
			animation-duration: .375s;
			animation-fill-mode: both;
			animation-name: successValidation;
		}
	}
}

@keyframes fadeOutDownInputs {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translate3d(0, 50%, 0);
	}
}

@keyframes fadeInUpInputs {
	from {
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0, 50%, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}


@keyframes successCompleteValidation {
	from {
		width: calc(100% - .6rem);
	}
	to {
		width: 0;
	}
}

.valid-success-complete{
	.form-input__value{
		animation-duration: .375s;
		animation-fill-mode: both;
		animation-name: fadeOutDownInputs;
	}
	.form-input__placeholder{
		animation-delay: .375s;
		animation-duration: .375s;
		animation-fill-mode: both;
		animation-name: fadeInUpInputs;
	}
	.btn_submit{
		&:after{
			animation-delay: .75s;
			animation-duration: .375s;
			animation-fill-mode: both;
			animation-name: successCompleteValidation;
		}
	}
}