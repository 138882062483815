.sponsorship {
    &-section {
        padding: 8.5rem 0 5rem 0;
        margin-bottom: 1.6rem;
        background-color: #F5F5F5;
        @media (max-width: $sw-lg-max) {
            padding: 7rem 0 4.5rem 0;
        }
        @media (max-width: $sw-md-max) {
            padding: 6rem 0 4rem 0;
        }
        @media (max-width: $sw-sm-max) {
            padding: 5rem 0 3rem 0;
        }
        @media (max-width: $sw-xs-max) {
            padding: 4rem 0 3rem 0;
        }
        .section-profile__block {
            @media (max-width: $sw-xs-max) {
                padding: 2rem 1.5rem;
            }
        }
        .section-title_inline {
            justify-content: space-between;
        }
    }
}