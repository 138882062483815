.section-custom-video{
	padding-top: 6.4rem;
	padding-bottom: 7.6rem;
	background-color: #F5F5F5;
	@media ( max-width: $sw-xs-max) {
		padding-top: 4.2rem;
		padding-bottom: 5.4rem;
	}

	.section-title__main{
		h2{

		}
	}
}

.custom-video__content{
	margin-top: 4.2rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 3.6rem;
	}
}

.custom-video__list{
	.row{
		@media ( max-width: $sw-sm-max) {
			margin-left: -.8rem;
			margin-right: -.8rem;
		}
	}
	.col{
		@media ( max-width: $sw-sm-max) {
			padding-left: .8rem;
			padding-right: .8rem;
		}
		@media ( max-width: $sw-xs-max) {
			&:not(:last-child){
				margin-bottom: 2.4rem;
			}
		}
	}
}

.custom-video-box{
	height: 0;
	padding-top: 25px;
	position: relative;
	padding-bottom: 56.25%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}