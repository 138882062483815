.reg-content {
	&__section {
		background: $wildSand;
		padding-bottom: 9rem;
		@media (max-width: $sw-sm-max) {
			padding-bottom: 9rem;
		}
		@media (max-width: $sw-xs-max) {
			padding-bottom: 3rem;
		}
	}
	&__wrapper {
		display: flex;
		align-items: flex-start;
		position: relative;
		@media (max-width: $sw-sm-max) {
			flex-wrap: wrap;
		}
	}
	&__main {
		width: calc(100% - 32.6rem);
		padding-right: 1.6rem;
		@media (max-width: $sw-sm-max) {
			width: 100%;
			padding-right: 0;
		}
		.text-block-title {
			@extend .big;
			margin-bottom: 2rem;
			font-weight: 500;
			width: 100%;
			@media (max-width: $sw-xs-max) {
				font-size: 2.1rem;
			}
		}
	}
	&__aside {
		width: 32.6rem;
		margin-top: 5.6rem;
		@media (max-width: $sw-sm-max) {
			margin: 0 auto;
		}
		@media (max-width: $sw-xx-max) {
			width: 100%;
		}
		&-link {
			width: 100%;
			height: 4.8rem;
			text-transform: uppercase;
			color: #fff !important;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			background-color: $orange;
			opacity: 1;
			transition: opacity .3s ease;
			font-weight: 900;
			&:hover {
				@media (min-width: $sw-md-min) {
					opacity: 0.8;
				}
			}
		}
		.form-box_submit {
			display: none;
			margin-bottom: 0;
			@media (max-width: $sw-xs-max) {
				margin-top: 0;
			}
			&.active {
				display: block;
			}
			.btn {
				text-transform: uppercase;
			}
		}
	}

	&__navigation {
		margin-bottom: 3.5rem;
		.link {
			text-transform: uppercase;
		}
	}
	&__boxes {
		&-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 2.4rem;
			margin-right: -1.6rem;
			@media (max-width: $sw-xs-max) {
				margin-right: 0;
				margin-bottom: 2.2rem;
			}
			&.boxes-text {
				margin-bottom: 8.5rem;
				@media (max-width: $sw-sm-max) {
					margin-bottom: 4rem;
				}
				.reg-content__box {
					background-color: transparent;
					padding: 0;
				}
				.worker-name {
					margin-bottom: 6px;
				}
			}
		}
	}
	&__box {
		width: calc((100% - 3.2rem) / 2);
		padding: 2.4rem;
		background-color: #fff;
		margin-right: 1.6rem;
		margin-bottom: 1.6rem;
		@media (max-width: $sw-xs-max) {
			width: 100%;
			margin-right: 0;
		}
		a {
			&:hover {
				@media (min-width: $sw-md-min) {
					color: $orange;
				}
			}
		}
		.worker {
			&-name {
				margin-bottom: 1.6rem;
				font-weight: 900;
				font-size: 1.6rem;
				line-height: 1.8rem;
				text-transform: uppercase;
			}
			&-position, &-mail, &-address {
				font-size: 1.6rem;
				line-height: 2.2rem;
				margin-bottom: 2px;
				margin-top: 0;
			}
			&-mail {
				display: block;
				margin-bottom: 1.6rem;
			}
		}
	}
	&__confirm-info {
		margin-top: 6rem;
		margin-bottom: 8.2rem;
		@media (max-width: $sw-sm-max) {
			margin-bottom: 6rem;
			margin-top: 5rem;
		}
		p {
			@extend .big;
			@media (max-width: $sw-xx-max) {
				font-size: 2.2rem;
				line-height: 2.6rem;
			}
		}
	}
	&__add-info {
		margin-bottom: 4rem;
		@media (max-width: $sw-sm-max) {
			margin-bottom: 9rem;
		}
		.text-block-title {
			margin-bottom: 1.1rem;
		}
	}
    &__section {
        background: $wildSand;
        padding-bottom: 9rem;
        @media (max-width: $sw-sm-max) {
            padding-bottom: 9rem;
        }
        @media (max-width: $sw-xs-max) {
            padding-bottom: 3rem;
        }
    }
    &__wrapper {
        display: flex;
        // align-items: flex-start;
        position: relative;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &__main {
        width: calc(100% - 32.6rem);
        padding-right: 1.6rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            padding-right: 0;
        }
        .text-block-title {
            @extend .big;
            margin-bottom: 2rem;
            font-weight: 500;
            width: 100%;
            @media (max-width: $sw-xs-max) {
                font-size: 2.1rem;
            }
        }
    }
    &__aside {
        width: 32.6rem;
        margin-top: 5.6rem;
        @media (max-width: $sw-sm-max) {
            margin: 0 auto;
        }
        @media (max-width: $sw-xx-max) {
            width: 100%;
        }
        &-inner {
			transform: translateY(0);
			transition: transform .3s ease;
			&.sticked {
                transform: translateY(4rem);
                @media ( max-width: $sw-sm-max) {
                    transform: translateY(0);
                }
			}
		}
        &-link {
            width: 100%;
            height: 4.8rem;
            text-transform: uppercase;
            color: #fff !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1.6rem;
            background-color: $orange;
            opacity: 1;
            transition: opacity .3s ease;
            font-weight: 900;
            &:hover {
                @media (min-width: $sw-md-min) {
                    opacity: 0.8;
                }
            }
        }
        .form-box_submit {
            display: none;
            margin-bottom: 0;
            @media (max-width: $sw-xs-max) {
                margin-top: 0;
            }
            &.active {
                display: block;
            }
            .btn {
                text-transform: uppercase;
            }
        }
    }

    &__navigation {
        margin-bottom: 3.5rem;
        .link {
            text-transform: uppercase;
        }
    }
    &__boxes {
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2.4rem;
            @media (max-width: $sw-xs-max) {
                margin-bottom: 2.2rem;
            }
            &.boxes-text {
                margin-bottom: 8.5rem;
                @media (max-width: $sw-sm-max) {
                    margin-bottom: 4rem;
                }
                .reg-content__box {
                    background-color: transparent;
                    padding: 0;
                }
                .worker-name {
                    margin-bottom: 6px;
                }
            }
        }
        &-inner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-right: -1.6rem;
            @media (max-width: $sw-sm-max) {
                width: 100vw;
                margin-right: 0;
                display: block;
            }
            .reg-content__box {
                @media (max-width: $sw-sm-max) {
                    width: 235px !important;
                    height: 235px !important;
                    margin-bottom: 0;
                }
            }
        }
    }
    &__box {
        width: calc((100% - 3.2rem) / 2);
        padding: 2.4rem;
        background-color: #fff;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        display: inline-block;
        @media (max-width: $sw-sm-max) {
            width: 100%;
        }
        a {
            &:hover {
                @media (min-width: $sw-md-min) {
                    color: $orange;
                }
            }
        }
        .worker {
            &-name {
                margin-bottom: 1.6rem;
                font-weight: 900;
                font-size: 1.6rem;
                line-height: 1.8rem;
                text-transform: uppercase;
            }
            &-position, &-mail, &-address {
                font-size: 1.6rem;
                line-height: 2.2rem;
                margin-bottom: 2px;
                margin-top: 0;
            }
            &-mail {
                display: block;
                margin-bottom: 1.6rem;
            }
        }
    }
    &__confirm-info {
        margin-top: 6rem;
        margin-bottom: 8.2rem;
        @media (max-width: $sw-sm-max) {
            margin-bottom: 6rem;
            margin-top: 5rem;
        }
        p {
            @extend .big;
            @media (max-width: $sw-xx-max) {
                font-size: 2.2rem;
                line-height: 2.6rem;
            }
        }
    }
    &__add-info {
        margin-bottom: 4rem;
        @media (max-width: $sw-sm-max) {
            margin-bottom: 9rem;
        }
        .text-block-title {
            margin-bottom: 1.1rem;
        }
    }

	// step 4
	&__tab {
		&-wrapper {
			width: 100%;
			background-color: #fff;
			.tab-nav {
				width: 100%;
				@media (max-width: $sw-xs-max) {
					justify-content: center;
				}
				li {
					width: auto;
					a {
						padding: 0 2.5rem;
						min-height: 7.3rem;
						font-size: 1.6rem;
						line-height: 1.6rem;
						border-bottom: 4px solid transparent;
						display: flex;
						align-items: center;
						justify-content: center;
						@media (max-width: $sw-xs-max) {
							font-size: 1.5rem;
						}
						@media (max-width: $sw-xx-max) {
							padding: 0 1.5rem;
						}
						&.active {
							border-bottom: 4px solid $orange;
						}
					}
				}
			}
		}
		&-content {
			.form-text {
				margin-top: 2.8rem;
				margin-bottom: 4rem;
			}
			.form-wrapper {
				width: 100%;
				max-width: 500px;
				margin: 4rem auto 0 auto;
				.row-box {
					margin-bottom: 2.5rem;
				}
				.select-trigger {
					height: 2.2rem;
				}
				.form-check input:checked + span:after {
					background-color: $orange;
				}
			}
			.form-check {
				margin-bottom: 6.4rem;
			}
			.form-group {
				margin-bottom: 3.5rem;
				input {
					background-color: transparent;
				}
			}
		}
	}
}

.content {
	&-title {
		margin-top: 0;
		text-transform: uppercase;
		width: 100%;
		margin-bottom: 3.3rem;
		@media (max-width: $sw-xs-max) {
			font-size: 2.8rem;
			line-height: 3.2rem;
			margin-bottom: 2.4rem;
		}
		@media (max-width: $sw-xx-max) {
			font-size: 2.4rem;
			line-height: 3rem;
			margin-bottom: 2rem;
		}
	}
}

.reg-aside {
	&__block {
		margin-bottom: 2.2rem;
		width: 100%;
	}
	&__content {
		background-color: #fff;
		padding: 2.1rem 3.2rem 3.8rem 3.2rem;
		@media (max-width: $sw-xx-max) {
			padding: 2rem 2.5rem 3rem 2.5rem;
		}
	}
	&__footer {
		background-color: #000;
		padding: 3rem 3.2rem;
		color: #fff;
		@media (max-width: $sw-xx-max) {
			padding: 2rem 2.5rem;
		}
		&-line {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 6px;
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
		&-total {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-top: 2.6rem;
			text-transform: uppercase;
			font-size: 2rem;
			line-height: 2.2rem;
			font-weight: 900;
			@media (max-width: $sw-xx-max) {
				font-size: 1.8rem;
			}
			.total-price {
				font-weight: 700;
				font-size: 2rem;
				line-height: 3rem;
				@media (max-width: $sw-xx-max) {
					font-size: 2.2rem;
				}
			}
		}
		&-side {
			min-width: 11rem;
			margin-right: 2.8rem;
		}
	}
	&__title {
		text-transform: uppercase;
		margin-bottom: 2.6rem;
		margin-top: 0;
		@media (max-width: $sw-xx-max) {
			font-size: 2.2re;
		}
	}
	&__order {
		&-block {
			& + .reg-aside__order-block {
				margin-top: 3rem;
			}
		}
		&-title {
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 2px;
			@media (max-width: $sw-xx-max) {
				font-size: 1.5rem;
			}
		}
		&-period {
			@extend .small;
			color: rgba(0,0,0,.4);
			font-weight: 500;
			margin-bottom: 0;
			@media (max-width: $sw-xx-max) {
				line-height: 2rem;
			}
		}
		&-price {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 2.4rem;
			line-height: 3rem;
			margin-bottom: 0;
			@media (max-width: $sw-xx-max) {
				font-size: 2.2rem;
				line-height: 2.8rem;
			}
			.price-multiplier {
				color: rgba(0, 0, 0, 0.3);
				padding-left: 3px;
				padding-right: 3px;
				padding-bottom: 4px;
				font-size: 1.7rem;
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
				@media (max-width: $sw-xx-max) {
					font-size: 1.6rem;
				}
			}
		}
	}
}

.reg-aside__promocode{
	position: relative;
	margin-top: 4.2rem;
}

.reg-aside__promocode-title{
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 1.8rem;
	margin-bottom: 1.8rem;
}

.reg-aside__promocode-form{
	position: relative;
	input{
		padding-right: 12rem;
	}
	button{
		top: 0;
		right: 0;
		position: absolute;
	}
}