.faq {
    &-section {
        padding-bottom: 10rem;
        .container {
            @media (max-width: $sw-sm-max) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &-tabs {
        width: 41.1rem;
        @media (max-width: $sw-md-max) {
            width: 37rem;
        }
        @media (max-width: $sw-sm-max) {
            display: none;
        }
        &-content {
            &__wrap {
                width: 49.6rem;
                @media (max-width: $sw-md-max) {
                    width: 48rem;
                }
                @media (max-width: $sw-sm-max) {
                    width: 100%;
                }
                .tab-item {
                    display: none;
                    @media (max-width: $sw-sm-max) {
                        display: flex;
                    }
                }
            }
        }
    }
    &-tab-box {
        .tab-content{
            display: none;
        }
        &.active {
            .tab-content {
                display: block;
            }
        }
    }
}

.tab {
    &-item {
        width: 100%;
        min-height: 10.8rem;
        transition: background-color .3s ease, opacity .3s ease;
        background-color: $wildSand;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 3.2rem;
        margin-bottom: 2px;
        cursor: pointer;
        opacity: 1;
        position: relative;
        overflow: hidden;
        @media (max-width: $sw-md-max) {
            min-height: 10rem;
        }
        @media (max-width: $sw-sm-max) {
            padding: 0 2.4rem 1.9rem 2.4rem;
            border-bottom: 1px solid #787878;
            min-height: auto;
            background-color: transparent;
            margin-bottom: 4rem;
            align-items: flex-start;
            justify-content: space-between;
        }
        &:hover {
            @media (min-width: $sw-md-min) {
                opacity: 0.7;
            }
        }
        &.active {
            background-color: transparent;
        }
        .icon-arrow {
            flex-shrink: 0;
            margin-left: 3rem;
            transform: rotate(90deg);
            font-size: 1.6rem;
            fill: $orange;
            margin-top: 5px;
        }
    }
    &-inner {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 0;
    }
    &-content {
        width: 100%;
        // display: none;
        &-title {
            line-height: 4rem;
            font-weight: 700;
            margin-top: 1.8rem;
            margin-bottom: 3rem;
        }
        &-block {
            margin-bottom: 10.4rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 5rem;
                padding: 0 2.4rem;
            }
        }
    }
}