.section-speakers{
	//margin-top: 8rem;
	margin-bottom: 8rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 0;
	}
	.conferences-detail__description{
		padding-top: 5.2rem;
		@media ( max-width: $sw-sm-max) {
			padding-top: 0;
		}
		@media ( max-width: $sw-xs-max) {}
	}
	.conferences-detail__aside{
		margin-top: 0;
	}
}

.speakers__list{
	display: flex;
	flex-wrap: wrap;
}

.speakers__item{
	width: 33.33333%;
	padding-right: 1.6rem;
	@media ( max-width: $sw-xs-max) {
		width: 50%;
	}
	@media ( max-width: $sw-xx-max) {
		width: 100%;
	}
	@media ( min-width: $sw-sm-min) {
		&:nth-child(n+4){
			margin-top: 7rem;
		}
	}
	@media ( min-width: $sw-xx-min) and ( max-width: $sw-xs-max) {
		&:nth-child(n+3){
			margin-top: 7rem;
		}
	}
	@media ( max-width: $sw-xx-max) {
		&:nth-child(n+2){
			margin-top: 8rem;
		}
	}
}

.section-speakers-question{
	margin-top: 17.8rem;
	margin-bottom: 13.6rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 14.4rem;
		margin-bottom: 11rem;
	}
}

.speakers-question__content{
	display: flex;
	padding-right: 17%;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-sm-max) {
		padding-right: 0;
	}
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.speakers-question__detail{
	width: 45%;
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}
}

.speakers-question__detail-title{
	font-weight: 900;
	font-size: 3.2rem;
	line-height: 3.4rem;
	@media ( max-width: $sw-xs-max) {
		font-size: 2.4rem;
		line-height: 2.8rem;
	}
}

.speakers-question__detail-caption{
	font-size: 2rem;
	margin-top: 2.2rem;
	line-height: 2.8rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 4rem;
	}
}

.speakers-question__form{
	width: 39%;
	padding-top: 1rem;
	@media ( max-width: $sw-sm-max) {
		width: 42%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		padding-top: 0;
		margin-top: 5.5rem;
	}
}