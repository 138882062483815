.news-page {
	&__wrapper {
			position: relative;
			z-index: 1;
			padding: 6rem 0;
			@media (max-width: $sw-lg-max) {
					padding: 8rem 0 6rem 0;
			}
			@media (max-width: $sw-xs-max) {
					padding: 1.4rem 0 6.5rem 0;
			}
	}
	&__title {
			text-transform: uppercase;
			font-size: 5.6rem;
			line-height: 6.4rem;
			margin-bottom: 0;
			@media (max-width: $sw-md-max) {
					font-size: 5rem;
					line-height: 5.8rem;
			}
			@media (max-width: $sw-sm-max) {
					font-size: 4.8rem;
					line-height: 5.2rem;
			}
			&-wrap {
					display: flex;
					align-items: center;
					margin-bottom: 2.1rem;
					@media (max-width: $sw-md-max) {
							margin-bottom: 2rem;
					}
					@media (max-width: $sw-sm-max) {
							margin-bottom: 1rem;
					}
					.link {
							text-transform: uppercase;
							margin-left: 3.2rem;
							@media (max-width: $sw-xs-max) {
									display: none;
							}
					}
			}
	}
	&__subtitle {
			margin-bottom: 3.8rem;
			@media (max-width: $sw-md-max) {
					font-size: 2.2rem;
			}
			@media (max-width: $sw-sm-max) {
					font-size: 2rem;
			}
			@media (max-width: $sw-xs-max) {
					margin-bottom: 4.8rem;
					margin-top: 0;
					line-height: 2.3rem;
			}
	}
	&__filters {
			&-wrapper {
					@media (max-width: $sw-xs-max) {
							z-index: 90;
							display: flex;
							padding: 5px 0 0 2rem;
							overflow-y: hidden;
							overflow-x: scroll;
							position: fixed;
							bottom: 65px;
							width: 100%;
							left: 0;
					}
					&:empty {
							background-color: transparent;
					}
			}
			&-top {
					display: flex;
					align-items: center;
					margin-bottom: 1.8rem;
					@media (max-width: $sw-xs-max) {
							margin-bottom: 4.8rem;
					}
					.link {
							flex-shrink: 0;
							text-transform: uppercase;
							margin-left: 2rem;
							display: none;
							@media (max-width: $sw-xs-max) {
									display: inline-flex;
							}
					}
			}
			&-trigger {
					width: 24rem;
					height: 4.8rem;
					background-color: #000000;
					color: $white;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					padding: 0 1.6rem;
					text-transform: uppercase;
					font-size: 2rem;
					font-weight: 900;
					line-height: 2.2rem;
					opacity: 1;
					margin-right: 1.6rem;
					transition: opacity $transition_duration ease;
					@media (max-width: $sw-sm-max) {
							height: 4.2rem;
							width: 22rem;
							font-size: 1.7rem;
					}
					@media (max-width: $sw-xs-max) {
							height: 5.5rem;
							width: 100%;
							position: fixed;
							bottom: 0;
							left: 0;
							z-index: 5;
					}
					&:hover {
							@media (min-width: $sw-md-min) {
									opacity: 0.8;
							}
					}
					i {
							width: 1.6rem;
							height: 2px;
							margin-right: 1.8rem;
							display: block;
							background-color: $white;
							position: relative;
							transition: background-color $transition_duration ease;
							&:after, &:before {
									content: '';
									display: block;
									width: 100%;
									height: 100%;
									position: absolute;
									left: 0;
									background-color: inherit;
							}
							&:before {
									top: -6px;
							}
							&:after {
									bottom: -6px;
							}
					}
			}
			&-form {
					display: flex;
					align-items: flex-end;
					@media (max-width: $sw-sm-max) {
							width: calc(100% - 23.6rem);
					}
					@media (max-width: $sw-xs-max) {
							width: 100%;
					}
			}
			&-search {
					width: 38rem;
					height: 2.5rem;
					margin-right: 1rem;
					border: none;
					border-bottom: 1px solid #787878;
					box-shadow: none;
					padding: 0;
					background-color: transparent;
					@media (max-width: $sw-sm-max) {
							width: 100%;
					}
					&:focus {
							box-shadow: none;
					}
					@include placeholder {
							font-size: 1.6rem;
							line-height: 2.2rem;
							color: #787878;
					}
			}
			&-submit {
					display: flex;
					align-items: center;
					.icon-search {
							transform: rotate(70deg);
							font-size: 1.8rem;
					}
			}
			&-clear {
					margin-left: 1.6rem;
					text-transform: uppercase;
					color: $black;
					font-size: 1.6rem;
					font-weight: 900;
					height: 40px;
					display: inline-flex;
					align-items: center;
					@media (max-width: $sw-sm-max) {
							font-size: 1.4rem;
					}
					@media (max-width: $sw-xs-max) {
							flex-shrink: 0;
							padding-right: 2rem;
					}
			}
	}
	&__filter {
			&-item {
					padding: 0 1.6rem 0 1rem;
					height: 4rem;
					vertical-align: top;
					display: inline-flex;
					align-items: center;
					background-color: $black;
					margin-right: 4px;
					margin-bottom: 4px;
					@media (max-width: $sw-sm-max) {
							height: 3.3rem;
					}
					@media (max-width: $sw-xs-max) {
							flex-shrink: 0;
					}
			}
			&-name {
					text-transform: uppercase;
					font-size: 1.3rem;
					line-height: 1.5rem;
					color: $white;
					display: block;
					margin-right: 1.2rem;
			}
			&-remove {
					width: 8px;
					height: 8px;
					position: relative;
					&:after, &:before {
							content: '';
							display: block;
							width: 2px;
							height: 100%;
							transform: translate(-50%,-50%);
							left: 50%;
							top: 50%;
							background-color: $white;
							position: absolute;
					}
					&:before {
							transform: translate(-50%,-50%) rotate(45deg);
					}
					&:after {
							transform: translate(-50%,-50%) rotate(-45deg);
					}
			}
	}
}

.news {
	&__wrapper {
			margin-top: 2.3rem;
			@media (max-width: $sw-sm-max) {
					margin-top: 4rem;
			}
			.top-block-container {
					@media (max-width: $sw-xs-max) {
							padding-left: 0;
							padding-right: 0;
					}
			}
	}
	&__top {
			&-block {
					height: auto;
					display: flex;
					flex-wrap: wrap;
					&:not(:last-child) {
							margin-bottom: 45px;
					}
					&-title {
							@media (max-width: $sw-sm-max) {
									padding-left: 2.4rem;
									padding-right: 2.4rem;
							}
							h3 {
									font-size: 23px;
									text-transform: uppercase;
							}
					}
					@media (max-width: $sw-sm-max) {
							flex-wrap: wrap;
							height: auto;
					}
					.news-box__time {
							width: 100%;
							margin-bottom: 0;
					}
					.news-box__name {
							margin-bottom: 0;
							margin-top: 0;
							width: 100%;
							margin-bottom: 5px;
							color:#000;
							text-decoration: none;
					}
					.news-box__detail {
							margin-bottom: 8px;
							font-size: 1.6rem;
							margin-top: 0;
					}
					.news-box__tags {
							@media (max-width: $sw-xs-max) {
									margin-top: 4.8rem;
							}
					}
					.news-box__label {
							@media (max-width: $sw-xs-max) {
									height: 4rem;
							}
					}
					&-left{
						width:100%;
					}
					&-body {
						width: 100%;
						display: flex;
						&-desc {
															margin-top: 3.6rem;
															@media (max-width: $sw-sm-max) {
																	padding-left: 2.4rem;
																	padding-right: 2.4rem;
																	margin-top: 2.6rem;
															}
							.news-box__name {
								font-size: 24px;
								    color: #000;
    text-decoration: none;
							}
							.news-box__time {
								margin-top: 19px;
						}
						}
						@media (max-width: $sw-sm-max) {
							flex-direction: column;
						}
					}
			}
			&-video {
					width: calc(100% - 342px);
		            @media (max-width: $sw-sm-max) {
		                width: 100%;
		                height: 37.5rem;
		            }
		            .video-content__inner {
		                width: 100%;
		                height: 100%;
		            }
		            .icon {
		                &-play, &-pause-path, &-big-play, &-pause {
		                    font-size: 6.6rem !important;
		                }
		                &-play {
		                    stroke: #fff;
		                    fill: #fff;
		                }
		            }

					/* width: 666px;
					height: 340px;
					flex-shrink: 0;
					position: relative;
					.news-box__tags {
							position: absolute;
							bottom: 15px;
							left: 15px;
							z-index: 2;
							a {
									background-color: #E2E2E2;
									z-index: 9;
							}
					}
					@media (max-width: $sw-lg-max) {
							width: 100%;
					}
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: 37.5rem;
					}
					.video-content__inner {
							width: 100%;
							height: 100%;
					}
					.icon {
							&-play, &-pause-path, &-big-play, &-pause {
									font-size: 6.6rem !important;
							}
							&-play {
									stroke: #fff;
									fill: #fff;
							}
					}
					.news-box__name {
						padding-top: 36px;
						font-size: 24px;
					} */
			}
			&-info {
					/* width: 328px;
					margin-left: 16px;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					@media (max-width: $sw-sm-max) {
							width: 100%;
							height: auto;
							margin-left: 0;
							margin-top: 20px;
					}
					.link {
							text-transform: uppercase;
					}
					.link_arrow {
							margin-top: 1rem;
					}
					.news-box {
							&__details {
									background-color: #F5F5F5;
									margin-bottom: 10px;
									@media (max-width: $sw-sm-max) {
											margin-bottom: 6px;
											width: 100%;
									}
							}
							&__detail {
									@media (max-width: $sw-sm-max) {
											margin-bottom: 2rem;
									}
							}
					} */
					width: 342px;
		            background-color: #F5F5F5;
		            @media (max-width: $sw-sm-max) {
		                width: 100%;
		                height: auto;
		            }
		            .link {
		                text-transform: uppercase;
		            }
		            .news-box {
		                &__details {
		                    height: calc(100% - 4.8rem);
		                    background-color: #F5F5F5;
		                    width:100%;
		                    @media (max-width: $sw-sm-max) {
		                        margin-bottom: 6px;
		                    }
		                }
		                &__detail {
		                    @media (max-width: $sw-sm-max) {
		                        margin-bottom: 2rem;
		                    }
		                }
		            }
			}
	}

	&__boxes {
			&-wrapper {
					margin-right: -1.6rem;
					display: flex;
					flex-wrap: wrap;
					margin-top: 3.6rem;
					@media (max-width: $sw-sm-max) {
							justify-content: space-between;
					}
					@media (max-width: $sw-xs-max) {
							margin-bottom: 1.1rem;
					}
					.news-box {
							width: 32.5rem;
							margin-right: 1.6rem;
							margin-bottom: 1.6rem;
							@media (max-width: $sw-md-max) {
									width: 30rem;
							}
							@media (max-width: $sw-sm-max) {
									width: 47%;
							}
							@media (max-width: $sw-xs-max) {
									width: 100%;
							}
							&__content {
									@media (max-width: $sw-xs-max) {
											max-width: 33rem;
											margin: 0 auto;
									}
							}
					}
			}
	}
	&__footer {
			&-wrapper {
					margin-top: 4.2rem;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					flex-wrap: wrap;
					@media (max-width: $sw-xs-max) {
							margin-top: 1rem;
					}
					.link {
							text-transform: uppercase;
					}
			}
	}
}
.industry_news{
	.news__top-info{
		width: 328px;
		margin-left: 16px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		background: none;
		@media (max-width: $sw-sm-max) {
				width: 100%;
				height: auto;
				margin-left: 0;
				margin-top: 20px;
		}
		.link {
				text-transform: uppercase;
		}
		.link_arrow {
				margin-top: 1rem;
		}
		.news-box {
				&__details {
						background-color: #F5F5F5;
						margin-bottom: 10px;
						@media (max-width: $sw-sm-max) {
								margin-bottom: 6px;
								width: 100%;
						}
				}
				&__detail {
						@media (max-width: $sw-sm-max) {
								margin-bottom: 2rem;
						}
				}
		}
	}
	.news__top-video{
		width: 666px;
		height: 340px;
		flex-shrink: 0;
		position: relative;
		.news-box__tags {
				position: absolute;
				bottom: 15px;
				left: 15px;
				z-index: 2;
				a {
						background-color: #E2E2E2;
						z-index: 9;
				}
		}
		@media (max-width: $sw-lg-max) {
				width: 100%;
		}
		@media (max-width: $sw-sm-max) {
				width: 100%;
				height: 37.5rem;
		}
		.video-content__inner {
				width: 100%;
				height: 100%;
		}
		.icon {
				&-play, &-pause-path, &-big-play, &-pause {
						font-size: 6.6rem !important;
				}
				&-play {
						stroke: #fff;
						fill: #fff;
				}
		}
		.news-box__name {
			padding-top: 36px;
			font-size: 24px;
		}
	}
} 

.pagination__wrap, .wp-pagenavi {
	
	display: flex;
	align-items: center;
	@media (max-width: $sw-xs-max) {
			margin-bottom: 2rem;
	}
	@media (max-width: $sw-xx-max) {
			width: 100%;
	}
	
	&__item, a, span {
			font-size: 1.6rem;
			font-weight: 900;
			color: $black;
			text-decoration: none;
			position: relative;
			@extend .no-decoration;
			display: flex;
			align-items: center;
			opacity: 1;
			transition: opacity $transition_duration ease;
			@media (max-width: $sw-xs-max) {
					font-size: 2.2rem;
			}
			&:hover {
					@media (min-width: $sw-md-min) {
							opacity: 0.7;
					}
			}
			& + .pagination__item, + a, + span {
					margin-left: 1.5rem;
					@media (max-width: $sw-xs-max) {
							margin-left: 4.1rem;
					}
					&:after {
							content: '.';
							display: block;
							font-weight: 900;
							color: $black;
							position: absolute;
							pointer-events: none;
							cursor: default;
							top: 7px;
							left: -1rem;
							transform: translateY(-50%);
							@media (max-width: $sw-xs-max) {
									top: 1rem;
									font-size: 2.7rem;
									left: -2.3rem;
							}
					}
			}
	}
	&__divider {
			font-weight: 900;
			color: $black;
			font-size: 1.6rem;
			margin: 0 5px;
			position: relative;
			width: 18px;
			height: 22px;
			@media (max-width: $sw-xs-max) {
					width: 22px;
					margin: 0 17px;
			}
			&:after {
					content: '...';
					display: block;
					font-size: 1.6rem;
					font-weight: 900;
					color: $black;
					position: absolute;
					top: 7px;
					left: 50%;
					transform: translate(-50%, -50%);
					@media (max-width: $sw-xs-max) {
							top: 7px;
							font-size: 2.5rem;
					}
			}
	}
}

.video-content {
	width: 100%;
	height: 100%;
	position: relative;
	&:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	opacity: 1;
	visibility: visible;
	transition: opacity .3s ease, visibility .3s ease;
	background-color: rgba(0,0,0, 0.3);
}
	&.now-playing {
			&:after {
					opacity: 0;
					visibility: hidden;
			}
			.cnt-play {
					opacity: 0;
					visibility: hidden;
			}
			&:hover {
					.cnt-pause, .video-content__full-screen {
							opacity: 1;
							visibility: visible;
					}
			}
			.video-content__seek-bar {
					opacity: 1;
					visibility: visible;
			}
			& + .news-box__tags {
					opacity: 0;
					visibility: hidden;
			}
	}
	&__inner {
			width: 100%;
			height: 100%;
	}
	&__play {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 1;
			transition: opacity .3s ease;
			z-index: 2;
			&:hover {
					.icon-play {
							opacity: 0 !important;
					}
					.icon-big-play {
							opacity: 1 !important;
					}
			}
			.cnt-play, .cnt-pause {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					transition: opacity .3s ease, visibility .3s ease;
					.svg-icon {
							font-size: 3.9rem;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%,-50%);
							transition: opacity .3s ease;
					}
			}
			.cnt-pause {
					opacity: 0;
					visibility: hidden;
					.icon-pause-path {
							stroke: #fff;
							fill: #fff;
					}
					.icon-pause {
							opacity: 0;
							fill: #F2A900;
							stroke: #F2A900;
							color: #fff;
					}
					&:hover {
							.icon-pause-path {
									opacity: 0 !important;
							}
							.icon-pause {
									opacity: 1 !important;
									fill: #F2A900;
									color: #fff;
									stroke: #F2A900;
							}
							.pause-circle {
									stroke: none;
							}
					}
			}
			.cnt-play {
					opacity: 1;
					visibility: visible;
					.icon-big-play {
							opacity: 0;
							fill: #F2A900;
							stroke: #F2A900;
							color: #fff;
					}
					.icon-play {
							stroke: #fff;
							fill: #fff;
					}
			}
	}
	&__full-screen {
			position: absolute;
			right: 9px;
			top: 9px;
			width: 3.8rem;
			height: 3.8rem;
			z-index: 3;
			border-radius: 50%;
			background-color: transparent;
			opacity: 0;
			visibility: hidden;
			transition: background-color .3s ease, opacity .3s ease, visibility .3s ease;
			&:hover {
					background-color: #F2A900;
			}
			.icon-fullscreen {
					color: #fff;
					font-size: 1.7rem;
			}
	}
	&__seek-bar {
			position: absolute;
			width: calc(100% - 4.8rem);
			height: 4px;
			background-color: #f5f5f5;
			bottom: 24px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;
			cursor: pointer;
			visibility: hidden;
			opacity: 0;
			transition: opacity .3s ease, visibility .3s ease;
			span {
					display: block;
					width: 0;
					height: 100%;
					transition: width .1s ease;
					background-color: #F2A900;
			}
	}
	&__controls {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			background-color: rgba(0,0,0,.1);
			&:hover {
					.video-content__play {
							opacity: 1;
					}
					.video-content__full-screen {
							opacity: 1;
					}
			}
			&.video-paused {
					.video-content {
							&__play {
									opacity: 1;
							}
							&__seek-bar {
									opacity: 1;
							}
							&__full-screen {
									opacity: 1;
							}
					}
			}
	}
	&__close {
			width: 3rem;
			height: 3rem;
			position: absolute;
			top: -3rem;
			right: -3rem;
			z-index: 1;
			@media (max-width: $sw-xs-max) {
					position: relative;
					right: auto;
					top: auto;
			}
			&:after, &:before {
					content: '';
					display: block;
					height: 100%;
					width: 2px;
					background-color: $white;
					position: absolute;
					left: 50%;
					top: 50%;
			}
			&:before {
					transform: translate(-50%,-50%) rotate(-45deg);
			}
			&:after {
					transform: translate(-50%,-50%) rotate(45deg);
			}
	}
}
.news__top-block-title{
	width: 100%;
}



