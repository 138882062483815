.ds-project {
    &__section {
        transition: background-color .3s ease;
        padding-top: 6.8rem;
        padding-bottom: 4.3rem;
        @media ( max-width: $sw-sm-max) {
            padding-top: 6rem;
            padding-bottom: 4rem;
        }
    }
    &__wrapper {
        overflow: hidden;
        &.green {
            background-color: $shamrock;
        }
    }
    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        width: 66%;
        border-radius: 0 500px 500px 0 / 0 1000px 1000px 0;
        padding: 6rem 1rem 8rem 5.5rem;
        margin-top: -2rem;
        margin-bottom: -2rem;
        position: relative;
        overflow: hidden;
        @media (max-width: $sw-md-max) {
            width: 80%;
        }
        @media ( max-width: $sw-sm-max) {
            width: 85%;
            padding: 5rem 1rem 6rem 4rem;
        }
        @media ( max-width: $sw-xs-max) {
            width: 100%;
            border-radius: 0;
            margin-top: 0;
            margin-bottom: 0;
            padding: 3rem 3rem 4rem 3rem;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,.3);
            border-radius: inherit;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
    }
    &__subtitle {
        max-width: 27rem;
        margin-bottom: 1.9rem;
        color: #fff;
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
    &__title {
        margin-bottom: 1.1rem;
        margin-top: 0;
        color: #fff;
        @media ( max-width: $sw-md-max) {
			font-size: 4.3rem;
			line-height: 4.8rem;
		}
		@media ( max-width: $sw-sm-max) {
			font-size: 3.8rem;
			line-height: 4.4rem;
		}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
    }
    &__time {
        margin-bottom: 4.7rem;
        color: #fff;
    }
}