.section-title{
	display: flex;
	align-items: flex-start;
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {}
	a{
		font-size: 1.6rem;
		line-height: 1.6rem;
	}

	&_inline{
		align-items: center;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {
			align-items: flex-start;
		}
		@media ( max-width: $sw-xs-max) {}
		.section-title__main{
			width: auto;
			margin-right: 1.6rem;
		}
		.section-title__minor{
			width: auto;
			flex-shrink: 0;
			white-space: nowrap;
		}
	}

	&_down{
		align-items: flex-end;
		justify-content: space-between;
		@media ( max-width: $sw-sm-max) {
			align-items: flex-start;
		}

		.section-title__main{
			width: 65%;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {
				width: 100%;
			}
			@media ( max-width: $sw-xs-max) {}
		}
		.section-title__minor{
			width: 32%;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {
				width: 100%;
			}
			@media ( max-width: $sw-xs-max) {}
		}
	}
}

.section-title__main{
	width: 70%;
	z-index: 1;
	position: relative;
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {}
	h2{
		margin: 0;
		text-transform: uppercase;
		font-size: 4.8rem;
		line-height: 5.2rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {
			font-size: 4.3rem;
			line-height: 4.8rem;
		}
		@media ( max-width: $sw-sm-max) {
			font-size: 3.8rem;
			line-height: 4.4rem;
		}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
		& + a{
			margin-top: 1.9rem;
		}
	}
	h5{
		margin-bottom: 0;
		@media ( max-width: $sw-xs-max) {
			margin-top: 1.7rem;
		}
	}
}

.section-title__minor{
	width: 30%;
	z-index: 1;
	position: relative;
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		margin-top: 3.2rem;
	}
	h3{
		margin: 0;
		font-size: 2.4rem;
		padding-right: 10%;
		line-height: 2.8rem;
		& + a{
			margin-top: 2.9rem;
			@media ( max-width: $sw-xs-max) {
				margin-top: 2.2rem;
			}
		}
	}
}