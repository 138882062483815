.news-inner {
	&__top {
			min-height: 40.7rem;
			padding: 6.1rem 8.5rem;
			position: relative;
			margin-bottom: 2.5rem;
			background-size: cover;
			@media (max-width: $sw-md-max) {
					padding: 5rem 6rem;
			}
			@media (max-width: $sw-sm-max) {
					padding: 4rem;
					min-height: 37rem;
			}
			@media (max-width: $sw-xs-max) {
					margin-bottom: 6rem;
					margin-top: 5rem;
					min-height: 30rem;
			}
			&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					position: absolute;
					background-color: rgba(0,0,0,.38);
					z-index: 0;
			}
			&-mobile {
					display: none;
					@media (max-width: $sw-xs-max) {
							display: block;
					}
			}
			& > .news-inner__title, & > .news-inner__time {
					@media (max-width: $sw-xs-max) {
							display: none;
					}
			}
			.news-box__label {
					display: inline-flex;
					width: auto;
					position: relative;
					z-index: 1;
					height: 33px;
					padding-left: 10px;
					padding-right: 10px;
					@media (max-width: $sw-xs-max) {
							height: 4rem;
							position: absolute;
							left: 0;
							bottom: -4rem;
							width: 100%;
					}
			}
	}
	&__navigation {
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 1.8rem;
			margin-left: -2.3rem;
			@media (max-width: $sw-sm-max) {
					margin-bottom: 1.7rem;
			}
			@media (max-width: $sw-xs-max) {
					margin-bottom: 0;
					position: absolute;
					left: 0;
					width: 100%;
					top: -5rem;
					justify-content: space-between;
					height: 5rem;
					padding: 0 2rem;
					margin-left: 0;
			}
			.link {
					text-transform: uppercase;
					font-weight: 900;
					font-size: 1.6rem;
					@media (max-width: $sw-xs-max) {
							color: #000;
					}
					.icon-arrow {
							@media (max-width: $sw-xs-max) {
									fill: #000;
							}
					}
					& + .link {
							margin-left: 3rem;
							position: relative;
							@media (max-width: $sw-xs-max) {
									margin-left: 0;
							}
							&:before {
									content: '';
									display: block;
									position: absolute;
									left: -1.5rem;
									top: 50%;
									transform: translateY(-50%);
									background-color: #f5f5f5;
									width: 1px;
									height: 1.2rem;
									cursor: default;
									pointer-events: none;
									@media (max-width: $sw-xs-max) {
											display: none;
									}
							}
					}
			}
	}
	&__title {
			position: relative;
			z-index: 1;
			color: $white;
			font-weight: 700;
			margin-bottom: 2.1rem;
			@media (max-width: $sw-md-max) {
					font-size: 4.4rem;
					margin-bottom: 2rem;
			}
			@media (max-width: $sw-sm-max) {
					font-size: 4rem;
			}
			@media (max-width: $sw-xs-max) {
					color: #000;
					font-weight: 700;
					font-size: 3rem;
					line-height: 3.6rem;
			}
	}
	&__time {
			color: $white;
			position: relative;
			z-index: 1;
			overflow: hidden;
			font-weight: 500;
			font-size: 1.6rem;
			white-space: nowrap;
			line-height: 1.6rem;
			margin-bottom: 2.2rem;
			text-overflow: ellipsis;
			@media (max-width: $sw-xs-max) {
					color: #929292;
			}
	}
	&__content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			@media (max-width: $sw-sm-max) {
					flex-wrap: wrap;
			}
	}
	&__footer {
			margin-top: 4.7rem;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			@media (max-width: $sw-md-max) {
					margin-top: 9rem;
			}
			@media (max-width: $sw-sm-max) {
					margin-top: 7rem;
					margin-bottom: 6rem;
			}
			@media (max-width: $sw-xs-max) {
					flex-wrap: wrap;
					margin-top: 5rem;
			}
			p {
					font-size: 2.4rem;
					line-height: 2.8rem;
					font-weight: 900;
					text-transform: uppercase;
					width: 100%;
					max-width: 420px;
					@media (max-width: $sw-md-max) {
							font-size: 2.2rem;
							max-width: 380px;
					}
					@media (max-width: $sw-sm-max) {
							font-size: 2rem;
					}
					@media (max-width: $sw-xs-max) {
							max-width: 100%;
					}
			}  
	}
	&__info {
			width: 66.6rem;
			@media (max-width: $sw-md-max) {
					width: 60rem;
			}
			@media (max-width: $sw-sm-max) {
					width: 100%;
			}
			.text-main {
					margin-bottom: 7rem;
					margin-bottom: 2.6rem;
					@media (max-width: $sw-sm-max) {
							font-size: 2.2rem;
							margin-bottom: 4rem;
					}
			}
	}
	&__aside {
			width: 32.8rem;
			padding-top: 5px;
			@media (max-width: $sw-sm-max) {
					width: 100%;
			}
			&-text {
					font-weight: 500;
			}
			&-title {
					margin-bottom: 5rem;
			}
			.conference-nav-box {
					flex-wrap: wrap;
					margin-bottom: 6.5rem;
					@media (max-width: $sw-sm-max) {
							margin-bottom: 4rem;
					}
					&__view {
							margin-bottom: 1rem;
							margin-right: 0;
					}
					.link {
							width: 100%;
							margin-top: 2.5rem;
					}
					&__detail {
							@media (max-width: $sw-sm-max) {
									width: 100%;
							}
					}
			}
			&-form {
				background: $wildSand;
				padding: 22px 24px;
				.form-group-check:not(:first-child) {
					margin-top: 1rem;
			}
				.form-box__content {
					padding-top: 3px;
				}
				.form-box__title {
					font-size: 2rem;
					text-transform: inherit;
				}
				.form-group {
					input {
						background-color: transparent;
					}
				}
				.form-box__content--inputs {
					padding-top: 18px;
					padding-right: 0;
					.form-group:not(:first-child) {
						margin-top: 2.1rem;
				}
				}
				.form-box_submit {
					button {
						width: 100%;
						font-size: 2rem;
					}
				}
				.form-input_phone {
					margin-top: 15px !important;
				}
			}
			&-banner {
				position: relative;
				height: 548px;
				width: 328px;
				margin-top: 56px;
				overflow: hidden;
				&:after, &:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					z-index: -1;
				}
				&:after {
					width: 116px;
					background-color: #01bcef;
					left: 0;
				}
				&:before {
					width: 212px;
					background-color: #0068af;
					right: 0;
				}
				&-inner {
					z-index: 999;
				}
				&-logo {
					padding-top: 65px;
					padding-right: 5px;
					margin-left: auto;
					width: 100%;
					text-align: right;
					@media (max-width: $sw-xx-max) {
						text-align: center;
				}
					.icon-logo {
						color: #fff;
						width: 68px;
						height: 28px;
					}
				}
				&-circle {
					height: 230px;
					width: 230px;
					border-radius: 50%;
					border: 1px solid white;
					padding-top: 38px;
					padding-left: 65px;
					margin-top: 35px;
					margin-left: -40px;
					&-2 {
						display: flex;
						flex-direction: column;
						b {
							text-transform: uppercase;
							font-size: 27px;
							line-height: 29px;
							font-weight: 900;
						}
					}
					&-1 {
						display: flex;
						padding-left: 3px;
						align-items: center;
						font-weight: bold;
						b {
							font-size: 24px;
						}
						&-r {
							display: flex;
							flex-direction: column;
							padding-left: 12px;
							padding-top: 3px;
							text-transform: uppercase;
							span {
								font-size: 10px;
							}
						}
					}
					&-3 {
						display: flex;
						flex-direction: column;
						span {
							font-size: 9px;
							font-weight: bold;
							letter-spacing: 1px;
							text-transform: uppercase;
						}
					}
				}
				&-text {
					padding-left: 28px;
					padding-top: 30px;
					max-width: 185px;
					b {
						text-transform: uppercase;
						font-size: 10px;
						line-height: 14px;
						display: flex;
					}
				}
			}
	}
	&__share {
			margin-right: 3.2rem;
			span {
					display: block;
					width: 100%;
					text-transform: uppercase;
					font-weight: 900;
					font-size: 1.6rem;
					line-height: 1.8rem;
			}
			.nav-box__social {
					margin-top: 7px;
			}
	}
	&__person {
			&-name {
					font-size: 1.6rem;
					line-height: 1.8rem;
					font-weight: 900;
					margin-bottom: 5px;
					text-transform: uppercase;
			}
			&-position {
					font-size: 1.6rem;
					line-height: 2.2rem;
			}
	}
}


.invested-project {
	&__box {
			margin-bottom: 4.5rem;
			background-color: $wildSand;
			padding: 3.2rem 3.2rem 1.7rem 3.2rem;
			.invested-project__blockquote {
					&:after, &:before {
							background-color: #F5F5F5;
					}
			}
			& + .news-inner__footer {
					margin-top: 12.2rem;
			}
	}
	&__header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			margin-bottom: 2.5rem;
			@media (max-width: $sw-xs-max) {
					flex-wrap: wrap;
			}
			& + .invested-project__blockquote {
					margin-top: 4.7rem;
			}
	}
	&__logo {
			width: 12.2rem;
			height: 12.2rem;
			margin-right: 1.6rem;
			flex-shrink: 0;
			@media (max-width: $sw-xs-max) {
					margin-bottom: 2.5rem;
			}
	}
	&__title {
			font-weight: 700;
			font-size: 3.2rem;
			line-height: 4rem;
			margin-bottom: 0;
			@media (max-width: $sw-md-max) {
					font-size: 3rem;
					line-height: 3.8rem;
			}
			@media (max-width: $sw-sm-max) {
					font-size: 2.6rem;
					line-height: 3.4rem;
			}
			@media (max-width: $sw-xs-max) {
					width: 100%;
					font-size: 2.2rem;
					line-height: 3rem;
			}
	}
	&__text {
			margin-bottom: 2.8rem;
			@media (max-width: $sw-xs-max) {
					font-size: 1.8rem;
					line-height: 2.6rem;
					margin-bottom: 2.5rem;
			}
	}
	&__blockquote {
			padding: 2.5rem 0 3.1rem 0;
			margin-bottom: 3.2rem;
			border-top: 2px solid #000;
			border-bottom: 2px solid #000;
			position: relative;
			margin-top: 5.2rem;
			@media (max-width: $sw-sm-max) {
					padding: 2rem 0;
			}
			&:after, &:before {
					display: block;
					font-weight: 900;
					content: '“';
					position: absolute;
					font-size: 3.2rem;
					width: 3.4rem;
					height: 3.4rem;
					background-color: #fff;
			}
			&:after {
					bottom: -18px;
					right: 0;
					top: auto;
					left: auto;
					text-align: right;
			}
			&:before {
					top: -18px;
					left: 0;
					text-align: left;
			}
			p {
					margin-bottom: 2.2rem;
			}
	}
}
b, strong{
	font-size: 2rem;
    line-height: 2.8rem;
}
.text-main{
	h1, h2, h3, h4, h5, h6{
		    font-weight: 500;
	}
}

blockquote {
			padding: 2.5rem 0 3.1rem 0;
			margin-bottom: 3.2rem;
			border-top: 2px solid #000;
			border-bottom: 2px solid #000;
			position: relative;
			margin-top: 5.2rem;
			@media (max-width: $sw-sm-max) {
					padding: 2rem 0;
			}
			&:after, &:before {
					display: block;
					font-weight: 900;
					content: '“';
					position: absolute;
					font-size: 3.2rem;
					width: 3.4rem;
					height: 3.4rem;
					background-color: #fff;
			}
			&:after {
					bottom: -18px;
					right: 0;
					top: auto;
					left: auto;
					text-align: right;
			}
			&:before {
					top: -18px;
					left: 0;
					text-align: left;
			}
			p {
					margin-bottom: 2.2rem;
			}
	}

.news-inner, .video-inner {
	&__wrapper {
			padding: 6.3rem 0 4rem 0;
			@media (max-width: $sw-lg-max) {
					padding: 11rem 0 4rem 0;
			}
			@media (max-width: $sw-sm-max) {
					padding: 11rem 0 0 0;
			}
			@media (max-width: $sw-xs-max) {
					padding: 0;
			}
			.tags {
					margin-bottom: 7.7rem;
					.tag {
							@media (max-width: $sw-xs-max) {
									margin-bottom: 7px;
							}
					}
			}
			.inner-top-container {
					@media (max-width: $sw-xs-max) {
							padding-left: 0;
							padding-right: 0;
					}
			}
	}
}