.section-takeways{
	margin-top: 7.6rem;
	margin-bottom: 7.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 5.2rem;
	}
}

.takeways__content{

}

.takeways__content-header{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {}
}

.takeways__content-header-text{
	width: 33%;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	position: relative;
	padding-top: 6.7rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {}
	&:after{
		top: 0;
		left: 0;
		content: '';
		width: 2.4rem;
		height: 2.4rem;
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: $orange;
	}
}

.takeways__content-header-caption{
	width: 57.7%;
	font-size: 2rem;
	padding-right: 8%;
	line-height: 2.8rem;
	padding-top: 6.7rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {
		padding-right: 0;
	}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		padding-top: 0;
		margin-top: 4.5rem;
	}
	@media ( max-width: $sw-xs-max) {}
}

.takeways__content-charts{
	/* margin-top: 12.1rem; */
	@media ( max-width: $sw-xs-max) {
		/* margin-top: 10.2rem; */
	}
}

.takeways__content-charts-nav{
	width: 49%;
	margin-bottom: 6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 65%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		margin-bottom: 10.3rem;
		ul{
			overflow-x: auto;
		}
	}
}

.takeways__content-charts-content{
	margin-bottom: 6rem;
}

.takeways__content-charts-wrapper{

}

.takeways-chart{
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-left: -2.4rem;
		width: calc(100% + 4.8rem);
	}
}

.takeways-chart__items{
	display: flex;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		overflow-x: auto;
	}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
	}
}

.takeways-chart__item{
	width: 100%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		min-width: 15.6rem;
	}
	@media ( max-width: $sw-xs-max) {}
	>i{
		width: 100%;
		display: flex;
		height: 24.3rem;
		position: relative;
		align-items: flex-end;
		margin-bottom: 1.3rem;
		border-bottom: .2rem solid $alto;
		i{
			width: .2rem;
			display: block;
			background-color: $black;
		}
	}
	b{
		display: block;
		font-weight: 900;
		font-size: 1.6rem;
		line-height: 1.8rem;
	}
	span{
		display: block;
		font-size: 1.6rem;
		margin-top: .7rem;
		line-height: 2.2rem;
		padding-right: 1.6rem;
	}
	&.first{
		>i{
			margin-bottom: .6rem;
			i{
				width: .9rem;
				background-color: $orange;
			}
		}
		b{
			font-size: 2.4rem;
			line-height: 2.8rem;
		}
		span{
			margin-top: .4rem;
		}
	}
	&.second{
		>i{
			margin-bottom: .6rem;
			&:after{

			}
		}
		b{
			font-size: 2.4rem;
			line-height: 2.8rem;
		}
		span{
			margin-top: .4rem;
		}
	}
}














.takeways__content-answer{
	margin-top: 10.9rem;
	margin-bottom: 10.9rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 13.9rem;
	}
}

.takeways__content-answer-header{
	margin-bottom: 5.1rem;
}

.takeways__content-answer-content{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.takeways__content-answer-nav{
	width: 40.7%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {
		width: 45%;
	}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}
	ul{
		li{
			& + li{
				margin-top: .2rem;
			}
			a{
				color: $black;
				display: flex;
				font-size: 2rem;
				font-weight: 700;
				min-height: 8rem;
				position: relative;
				align-items: center;
				line-height: 2.6rem;
				padding-top: 1.6rem;
				padding-left: 4.8rem;
				padding-right: 3.2rem;
				text-decoration: none;
				padding-bottom: 1.6rem;
				background-color: $wildSand;
				span{
					display: block;
				}
				&:after{
					top: 50%;
					content: '';
					left: 2.4rem;
					width: .8rem;
					height: .8rem;
					display: block;
					position: absolute;
					margin-top: -.4rem;
					border-radius: 50%;
					background-color: $alto;
					transition: all 0.3s ease;
				}
				&.active{
					&:after{
						background-color: $orange;
					}
				}
			}
		}
	}
}

.takeways__content-answer-list{
	width: 49.2%;
	padding-right: 8%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {
		width: 48%;
		padding-right: 0;
	}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		margin-top: 6.4rem;
	}
}

.takeways__content-answer-item{
	position: relative;
	padding-top: 4.8rem;
	&:after{
		left: 0;
		top: .4rem;
		content: '';
		width: 2.4rem;
		height: 2.4rem;
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: $orange;
	}
}

.takeways__content-answer-item-title{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 2.4rem;
}

.takeways__content-answer-item-content{

}
