.section-post-main{
	color: $white;
	overflow: hidden;
	min-height: 100vh;
	background-color: $orange;
	.section-conference-header{
		padding-bottom: 0;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {
			padding-top: 3.6rem;
		}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
	}
	.conference-header__buttons{
		margin-bottom: 0;
	}
}

.section-post-main-content{
	margin-top: 8vh;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		margin-top: 12vh;
		padding-bottom: 8.6rem;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 1.5rem;
	}
}

.post-main__content-header{
	.section-title{
		.section-title__main{
			h2{
				font-size: 5.6rem;
				line-height: 6.4rem;
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					font-size: 3.2rem;
					line-height: 3.4rem;
				}
			}
		}
	}
}

.post-main__content-body{
	display: flex;
	margin-top: 4.1rem;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 4.6rem;
	}
}

.post-main__content-values{
	width: 51%;
	display: flex;
	flex-wrap: wrap;
	padding-top: 1.6rem;
	align-items: stretch;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		padding-top: 0;
	}
	@media ( max-width: $sw-xs-max) {}
}

.post-main__content-value{
	width: 33.33333%;
	padding-right: 1.6rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: 600px) {
		width: 50%;
		padding-right: 1rem;
	}
	@media ( max-width: 360px) {
		width: 100%;
	}
	@media ( min-width: 601px) {
		&:nth-child(n+4){
			margin-top: 3.7rem;
		}
	}
	@media ( min-width: 361px) and ( max-width: 600px) {
		&:nth-child(n+3){
			margin-top: 3.7rem;
		}
	}
	@media ( max-width: 360px) {
		&:nth-child(n+2){
			margin-top: 3.5rem;
		}
	}
	b{
		display: block;
		font-weight: 900;
		font-size: 4.8rem;
		line-height: 5rem;
	}
	span{
		display: block;
		font-weight: 500;
		font-size: 1.6rem;
		margin-top: .7rem;
		line-height: 2.2rem;
	}
}

.post-main__content-view{
	width: 41%;
	display: flex;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		margin-top: 3.9rem;
		justify-content: center;
	}
	@media ( max-width: $sw-xs-max) {}
}

.post-main__content-view-content{
	position: relative;
	picture{
		width: 24rem;
		height: 24rem;
		display: block;
		overflow: hidden;
		border-radius: 50%;
		img{

		}
	}
}

.post-main__content-view-action{
	top: 50%;
	left: 100%;
	position: absolute;
	white-space: nowrap;
	margin-top: -2.4rem;
	margin-left: -2.4rem;
	@media ( max-width: $sw-xs-max) {
		left: 50%;
	}
	a{
		i{
			@media ( max-width: $sw-xs-max) {
				margin-right: 0;
			}
		}
		span{
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				display: none;
			}
		}
	}
}