.section-about-main-screen{
	height: 100vh;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		height: auto;
	}
}

.about-main-screen__wrapper{
    padding-top: 29vh;
    @media ( max-width: $sw-lg-max) {}
    @media ( max-width: $sw-md-max) {}
    @media ( max-width: $sw-sm-max) {
    	padding-top: 32vh;
    }
    @media ( max-width: $sw-xs-max) {
    	padding-top: 3.8rem;
    }
}

.about-main-screen__content{
	position: relative;
    padding-right: 35rem;
    @media ( max-width: $sw-lg-max) {}
    @media ( max-width: $sw-md-max) {
    	padding-right: 28rem;
    }
    @media ( max-width: $sw-sm-max) {
    	padding-right: 8rem;
    }
    @media ( max-width: $sw-xs-max) {
    	display: flex;
    	padding-right: 0;
    	flex-direction: column;
    }
}

.about-main-screen__title{
	z-index: 2;
	position: relative;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 1;
	}
	h2{
		font-size: 4.8rem;
		line-height: 5.2rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
	}
	span{
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			display: block;
		}
	}
}

.about-main-screen__caption{
	z-index: 2;
	font-weight: 500;
	margin-top: 2rem;
	font-size: 2.4rem;
	line-height: 3rem;
	position: relative;
	@media ( max-width: $sw-xs-max) {
		order: 2;
		margin-top: 2.9rem;
	}
}

.about-main-screen__view{
	top: -54%;
	left: 23%;
	width: 53.6rem;
	height: 53.6rem;
	position: absolute;
	font-size: .536rem;
	transform-style: preserve-3d;
	transform: rotateX(75deg) rotateY(-30deg);
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 0;
		top: auto;
		left: auto;
		width: 21.2rem;
		height: 21.2rem;
		margin-left: auto;
		position: relative;
		margin-right: auto;
		margin-bottom: 6.6rem;
	}
}


.about-main-screen__planet{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	position: absolute;
	border-radius: 50%;
	background-color: $white;
	transform: rotateX(-75deg);
	border: .3rem solid $cerulean;
}

.about-main-screen__moon{
	z-index: 1;
	top: -13rem;
	left: -13rem;
	right: -13rem;
	bottom: -13rem;
	position: absolute;
	border-radius: 50%;
	animation-duration: 5s;
	animation-name: moonAnimate;
	transform-style: preserve-3d;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	@media ( max-width: $sw-xs-max) {
		top: -6rem;
		left: -6rem;
		right: -6rem;
		bottom: -6rem;
	}
	@media ( max-width: 360px) {
		top: -5rem;
		left: -5rem;
		right: -5rem;
		bottom: -5rem;
	}
}

.about-main-screen__moon-position{
	top: 0;
	left: 50%;
	position: absolute;
	animation-name: invert;
	transform-style: preserve-3d;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	i{
		top: 0;
		left: 50%;
		width: 3.4rem;
		height: 3.4rem;
		display: block;
		position: absolute;
		border-radius: 50%;
		margin-top: -1.7rem;
		margin-left: -1.7rem;
		animation-name: invert;
		animation-duration: 5s;
		background-color: $cerulean;
		transform-style: preserve-3d;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			width: 2.4rem;
			height: 2.4rem;
			margin-top: -1.2rem;
			margin-left: -1.2rem;
		}
	}
}

@keyframes invert {
	0% {
		transform: rotateX(-90deg) rotateY(-360deg) ;
	}
	100% {
		transform: rotateX(-90deg) rotateY(0deg) ;
	}
}

@keyframes moonAnimate {
	0%{
		transform: rotateZ(0);
	}
	100%{
		transform: rotateZ(360deg);
	}
}