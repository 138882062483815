.vacancy-adv {
    background-color: #F5F5F5;
    padding: 5rem 0 6rem 0;
    &__top {
        width: 100%;
        max-width: 64rem;
        margin-bottom: 3.1rem;
        .section-title {
            margin-bottom: 1.5rem;
        }
        h2 {
            text-transform: uppercase;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        margin-bottom: 1.5rem;
        @media (max-width: 991px) {
            margin-left: -1rem;
            margin-right: -1rem;
        }
        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__block {
        background-color: #ffffff;
        padding: 3.4rem 2.5rem 3rem 2.5rem;
        height: 100%;
        @media (max-width: 767px) {
            height: auto;
            max-width: 31.3rem;
            padding: 2.5rem 2rem;
        }
        &-wrap {
            width: calc(100% / 3);
            padding: 0 1.5rem;
            margin-bottom: 1.5rem;
            min-height: 37.1rem;
            @media (max-width: 991px) {
                width: 50%;
                padding: 0 1rem;
                margin-bottom: 2rem;
            }
            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: center;
                min-height: auto;
            }
        }
        &__icon {
            margin-bottom: 3rem;
            svg {
                width: auto;
                height: 8.6rem;
            }
        }
        &__title {
            margin-bottom: 1.5rem;
            font-size: 2rem;
            line-height: 1.26;
            font-weight: 900;
        }
        &__text {
            font-size: 1.6rem;
            line-height: 1.7;
            margin-bottom: 0;
        }
    }
    &__insta {
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            flex-wrap: wrap;
        }
        &__block {
            position: relative;
            height: 33rem;
            flex-shrink: 0;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @media (max-width: 991px) {
                height: 28rem;
            }
            @media (max-width: 767px) {
                height: 23rem;
            }
            & + .vacancy-adv__insta__block {
                margin-left: 1rem;
                @media (max-width: 991px) {
                    margin-left: 0;
                }
            }
            &.block_sm {
                width: 25.2rem;
                @media (max-width: 991px) {
                    display: none;
                }
            }
            &.block_lg {
                width: calc(100% - 52.4rem);
                @media (max-width: 991px) {
                    width: 100%;
                }
            }
            &__info {
                position: absolute;
                left: 2rem;
                bottom: 2.6rem;
                a {
                    text-decoration: none;
                    &:hover {
                        @media (min-width: 992px) {
                            svg {
                                transform: translateX(3px);
                            }
                        }
                    }
                    span {
                        color: #ffffff;
                        font-size: 2rem;
                        line-height: 1.1;
                        display: inline-block;
                        text-transform: uppercase;
                        font-weight: 900;
                    }
                    svg {
                        width: 1.15em;
                        font-size: 1.9rem;
                        display: inline-block;
                        vertical-align: text-bottom;
                        margin-left: 7px;
                        transform: none;
                        transition: transform .3s ease;
                    }
                }
            }
        }
    }
}