.reg-failed {
    &__section {
        padding-bottom: 14rem;
        background: $wildSand;
        @media (max-width: $sw-lg-max) {
            padding-bottom: 12rem;
        }
        @media (max-width: $sw-md-max) {
            padding-bottom: 9rem;
        }
        @media (max-width: $sw-sm-max) {
            padding-bottom: 6rem;
            padding-top: 13rem;
        }
        @media (max-width: $sw-xs-max) {
            padding-top: 3rem;
        }
        .section-conference-header {
            @media (max-width: $sw-sm-max) {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
    &__icon {
        margin-bottom: 2.3rem;
        .icon-failed {
            font-size: 7.2rem;
            color: #000;
        }
    }
    &__content {
        display: flex;
        align-items: flex-start;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &__main {
        width: calc(100% - 37rem);
        padding-right: 5rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 8.4rem;
        }
        .link {
            text-transform: uppercase;
            margin-left: -2rem;
            @media (max-width: $sw-sm-max) {
                margin-left: 0;
            }
        }
    }
    &__info {
        width: 37rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
        }
        .people-box__details {
            @media (max-width: $sw-sm-max) {
                min-height: 12rem;
                padding-top: 1rem;
                padding-left: 14.4rem;
            }
            @media (max-width: $sw-xs-max) {
                padding-left: 11.7rem;
            }
        }
        .people-box__pic {
            @media (max-width: $sw-sm-max) {
                position: absolute;
                left: 0;
                top: 0;
                width: 12rem;
                height: 12rem;
            }
            @media (max-width: $sw-xs-max) {
                width: 9.3rem;
                height: 9.3rem;
            }
        }
    }
    &__title {
        margin-top: 0;
        margin-bottom: 3rem;
        text-transform: uppercase;
        @media ( max-width: $sw-md-max) {
			font-size: 4.3rem;
			line-height: 4.8rem;
		}
		@media ( max-width: $sw-sm-max) {
			font-size: 3.8rem;
			line-height: 4.4rem;
		}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
    }
    &__text {
        @extend .big;
        font-weight: 500;
    }
}