.find-out {
    &__section {
        background-color: #fff;
        padding-bottom: 10.8rem;
        padding-top: 8rem;
        @media (max-width: $sw-xs-max) {
            padding-bottom: 9.6rem;
        }
    }
    &__content {
        display: flex;
        align-items: flex-start;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
        .form-group {
            margin-bottom: 2.8rem;
            margin-top: 0;
            &:last-child {
                margin-top: 4rem;
                margin-bottom: 1.5rem;
            }
        }
        .form-input input, .form-input textarea {
            padding-bottom: .7rem;
        }
        textarea {
            height: 12.5rem;
            padding-top: 0;
            min-height: auto;
        }
        .section-title {
            margin-bottom: 1.7rem;
            @media (max-width: $sw-xs-max) {
                margin-bottom: 3.2rem;
            }
        }
    }
    &__info-side {
        margin-right: 10.7rem;
        width: 40.3rem;
        @media (max-width: $sw-sm-max) {
            margin-right: 0;
            width: 100%;
        }
    }
    &__form-side {
        width: 32.2rem;
        margin-top: 8px;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            max-width: 400px;
        }
        @media (max-width: $sw-xs-max) {
            margin-top: 5.6rem;
        }
        .btn_submit {
            text-transform: uppercase;
        }
        .form-file-name {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
        .form-box_submit {
            @media (max-width: $sw-xs-max) {
                margin-top: 0;
            }
        }
    }
    &__text {
        font-size: 2rem;
        font-size: 2.8rem;
        margin-bottom: 2.2rem;
    }
}