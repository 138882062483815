.cta {
	&__section {
			position: relative;
			padding-bottom: 9.8rem;
			@media (max-width: $sw-sm-max) {
					padding-bottom: 8rem;
			}
			&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,.3);
					position: absolute;
					left: 0;
					top: 0;
					z-index: 0;
			}
			.container {
					position: relative;
					z-index: 1;
			}
			.link {
					text-transform: uppercase;
					    margin-top: 1.7rem;
			}
			&.cta__section-simple {
					padding-top: 7rem;
					position: relative;
					@media (max-width: $sw-sm-max) {
							padding-top: 5rem;
					}
			}
	}
	&__image {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
	}
	&__partner-logo {
			width: 6.9rem;
			height: 7.8rem;
			margin-bottom: 2.2rem;
	}
	&__title {
			//margin-bottom: 1.7rem;
			color: #fff;
			text-transform: uppercase;
			max-width: 60rem;
			@media (max-width: $sw-sm-max) {
					max-width: 100%;
			}
			p{
				margin-bottom:0;
			}
	}
	&__subtitle {
			margin-top: 10px;
			font-weight: 900;
			font-size: 1.6rem;
			line-height: 1.6rem;
			text-transform: uppercase;
	}
	&__date {
			font-size: 1.6rem;
			line-height: 2.2rem;
			color: #fff;
			margin-bottom: 2.2rem;
	}
}

.cta-update {
	&__section {
			background-color: #000;
			padding: 5rem 0;
			@media (max-width: $sw-xx-max) {
					width: calc(100% - 4.8rem);
					margin: 0 auto;
					padding: 3.7rem 0;
			}
			.container {
					@media (max-width: $sw-sm-max) {
							padding-left: 2.5rem;
							padding-right: 2.5rem;
					}
			}
			&.cta-section_red {
					background-color: #DB3539;
			}
			&.cta-section_info {
					.cta-update__content {
							flex-wrap: wrap;
					}
					.cta__title {
							width: 100%;
							color: #000;
					}
			}
	}
	&__content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media (max-width: $sw-sm-max) {
					flex-wrap: wrap;
			}
			.link {
					margin-left: 2.5rem;
					@media (max-width: $sw-sm-max) {
							margin-left: 0;
					}
			}
			.cta__title {
					max-width: 100%;
					@media (max-width: $sw-sm-max) {
							//margin-bottom: 7.9rem;
							width: 100%;
					}
			}
	}
}
.cta__title {
    color: #fff;
    text-transform: uppercase;
    max-width: 60rem;
    margin-top: 1rem;
    font-size: 3.2rem;
    line-height: 3.4rem;
    font-weight: 900;
}