.ju {
    &-section {
        height: 100%;
    }
    &__top-block {
        background-color: #009BD7;
        color: #fff;
    }
    &__image-block {
        min-height: 69vh;
        width: calc(100% + 200px);
        margin-left: -100px;
        border-radius: 0 0 990px 990px / 0 0 495px 495px;
        overflow: visible !important;
        display: flex;
        align-items: flex-end;
        padding: 15rem 0 0 0;
        position: relative;
        @media (max-width: $sw-lg-max) {
            padding: 13rem 0 0 0;
            min-height: 70vh;
            align-items: center;
            border-radius: 0 0 1200px 1200px / 0 0 450px 450px;
        }
        @media (max-width: $sw-md-max) {
            padding: 7rem 0 0 0;
        }
        @media (max-width: $sw-sm-max) {
            padding: 10rem 0 0 0;
            border-radius: 0 0 1140px 1140px / 0 0 570px 570px;
            min-height: 49.5rem;
        }
        .container {
            @media (max-width: $sw-sm-max) {
                max-width: calc(100% - 240px);
            }
            @media (max-width: $sw-xs-max) {
                max-width: calc(100% - 200px);
            }
        }
        img {
            border-radius: inherit;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background-color: rgba(0,0,0,.3);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .link {
            z-index: 3;
            position: relative;
            @media (max-width: $sw-lg-max) {
                position: absolute;
                left: 50%;
                bottom: -2rem;
                transform: translateX(-50%);
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
            span {
                @media (max-width: $sw-lg-max) {
                    width: 100%;
                    order: 0;
                    margin-bottom: 1.3rem;
                }
            }
            &-svg-wrap {
                @media (max-width: $sw-lg-max) {
                    order: 1;
                }
            }
            &:hover {
                @media (min-width: $sw-md-min) {
                    svg {
                        animation: none;
                        transform: scale(0.9) rotateZ(90deg);
                    }
                    .link-svg-wrap {
                        transform: scale(1.1);
                    }
                }
            }
            svg {
                fill: #009BD7;
            }
        }
        .link-svg-wrap {
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.2rem;
            transform: scale(1);
            transition: transform .4s ease;
        }
    }
    &__image-text {
        display: block;
        width: 100%;
        margin-bottom: 5rem;
        @media (max-width: 1700px) {
            margin-bottom: 6rem;
        }
        @media (max-width: 1550px) {
            margin-bottom: 7.2rem;
        }
        @media (max-width: $sw-lg-max) {
            margin-bottom: 13rem;
        }
        .ju__title {
            position: relative;
            z-index: 3;
            @media (max-width: $sw-xs-max) {
                font-size: 4.8rem;
                line-height: 5rem;
            }
        }
        .big {
            margin-bottom: 5.3rem;
            position: relative;
            z-index: 3;
        }
    }
    &__title {
        font-size: 5.6rem;
        text-transform: uppercase;
    }
    &__text-block {
        margin-top: 17rem;
        padding-bottom: 8.3rem;
        @media (max-width: $sw-lg-max) {
            margin-top: 10rem;
            padding-bottom: 7rem;
        }
        @media (max-width: $sw-sm-max) {
            margin-top: 8.5rem;
            padding-bottom: 6.9rem;
        }
        .section-title {
            margin-bottom: 2rem;
            @media (max-width: $sw-xs-max) {
                margin-bottom: 2.9rem;
            }
        }
    }
}