.filters {
    &__menu-wrapper {
        position: fixed;
        height: 100vh;
        width: calc(100vw - 22.8rem);
        top: 0;
        left: 22.8rem;
        background-color: rgba(0,0,0,.95);
        z-index: 13;
        display: none;
        @media (max-width: $sw-lg-max) {
            width: 100%;
            left: 0;
            height: calc(100% - 7.4rem);
            top: 7.4rem;
        }
    }
    &__form {
        @media (max-width: $sw-sm-max) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 3.2rem;
        margin-bottom: 2rem;
        width: 100%;        
        @media (max-width: $sw-md-max) {
            margin-bottom: 0;
            padding: 3.2rem 3.2rem 0 3.2rem;
        }
        @media (max-width: $sw-xs-max) {
            height: 6.7rem;
            justify-content: center;
            padding: 0;
            order: 1;
            background-color: #000;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
        }
        &.active {
            .filters {
                &__clear {
                    opacity: 1;
                    transition: opacity .3s ease 1.3s, color .3s ease;
                    @media (max-width: $sw-xs-max) {
                        transition: opacity .3s ease;
                    }
                }
                &__submit {
                    opacity: 1;
                    transition: opacity .3s ease 1.3s, color .3s ease;
                    @media (max-width: $sw-xs-max) {
                        transition: opacity .3s ease, color .3s ease;
                    }
                }
                &__close {
                    opacity: 1;
                    transition: opacity .3s ease 1.3s;
                }
            }
        }
    }
    &__clear, &__submit {
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 900;
        cursor: pointer;
    }
    &__clear {
        color: $white;
        margin-right: 19px;
        opacity: 0;
        transition: opacity .3s ease 1.1s, color .3s ease;
        @media (max-width: $sw-xs-max) {
            transition: opacity .3s ease 1.4s, color .3s ease;
        }
        &:hover {
            @media (min-width: $sw-md-min) {
                color: rgba(255,255,255,.8);
            }
        }
    }
    &__submit {
        color: #009BD7;
        margin-right: 24px;
        opacity: 0;
        transition: opacity .3s ease .2s, color .3s ease;
        @media (max-width: $sw-xs-max) {
            transition: opacity .3s ease 1.4s, color .3s ease;
        }
        &:hover {
            @media (min-width: $sw-md-min) {
                color: darken(#009BD7, 7%);
            }
        }
    }
    &__close {
        width: 4.7rem;
        height: 4.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        cursor: pointer;
        transition: opacity .3s ease .2s;
        @media (max-width: $sw-xs-max) {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }
        @media (max-width: $sw-xx-max) {
            top: 0;
            right: 0;
        }
        &:hover {
            @media (min-width: $sw-md-min) {
                i {
                    &:after, &:before {
                        background-color: rgba(255,255,255,.8);
                    }
                }
            }
        }
        i {
            width: 1.6rem;
            height: 2px;
            display: block;
            background-color: transparent;
            position: relative;
            &:after, &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                transition: background-color .3s ease;
            }
        }
        i {
            &:after, &:before {
                background-color: $white;
            }
            &:before {
                top: 0;
                transform: rotate(45deg);
            }
            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
    &__content {
        padding: 0 10.2rem 5rem 10.2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        overflow-y: auto;
        @media (max-width: $sw-md-max) {
            padding: 0 5rem 4rem 5rem;
            flex-wrap: wrap;
        }
        @media (max-width: $sw-sm-max) {
            width: 100%;
            height: calc(100vh - 14.1rem);
            overflow-y: auto;
        }
        @media (max-width: $sw-xs-max) {
            padding: 0 3rem 4rem 3rem;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        width: 100%;
        &-wrap {
            @media (max-width: $sw-md-max) {
                margin-top: 5rem;
            }
            &.active {
                .filters__list-title {
                    opacity: 1;
                    transform: translateY(0px);
                    transition: transform .3s ease-in-out .3s, opacity .4s ease-in-out .3s;   
                }
                .filters__list-item {
                    &:after {
                        width: 100%;
                        transition: width .3s ease-in-out .6s;
                    }
                }
                .filters__list-name {
                    opacity: 1;
                    transform: translateY(0px);
                    transition: transform .3s ease-in-out .9s, opacity .4s ease-in-out .9s;
                }
                .filters__list-label .icon-check {
                    transform: translateY(0px);
                    transition: transform .3s ease-in-out .9s, opacity .3s ease-in-out;
                }
            }
            &.list-conferences {
                width: 327px;
                @media (max-width: $sw-md-max) {
                    width: 100%;
                }
            }
            &.list-types {
                width: 242px;
                @media (max-width: $sw-md-max) {
                    width: 100%;
                }
            }
            &.list-fields {
                width: 242px;
                @media (max-width: $sw-md-max) {
                    width: 100%;
                }
            }
        }
        &-title {
            text-transform: uppercase;
            color: $white;
            margin-bottom: 3.2rem;
            opacity: 0;
            transform: translateY(15px);
            transition: transform .3s ease-in-out .5s, opacity .4s ease-in-out .5s;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 1.5rem;
            }
        }
        &-item {
            position: relative;
            width: 100%;
            overflow: hidden;
            opacity: 1;
            &:after {
                content: '';
                display: block;
                width: 0;
                height: 1px;
                background-color: rgba(255,255,255,.3);
                transition: width .3s ease-in-out .8s;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
        &-name {
            text-transform: uppercase;
            font-size: 1.3rem;
            line-height: 1.5rem;
            color: $white;
            display: block;
            margin-right: 10px;
            opacity: 0;
            transform: translateY(calc(100% + 19px));
            transition: transform .3s ease-in-out 1.1s, opacity .4s ease-in-out 1.1s;
        }
        &-label {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 17px;
            padding-top: 15px;
            margin-bottom: 0;
            cursor: pointer;
            &:hover {
                @media (min-width: $sw-md-min) {
                    .icon-check {
                        opacity: 1;
                    }
                }
            }
            input {
                width: 0;
                height: 0;
                position: absolute;
                left: -9990em;
                top: -9999em;
                opacity: 0;
                visibility: hidden;
                &:checked {
                    & + .icon-check {
                        opacity: 1;
                    }
                }
            }
            .icon-check {
                font-size: 1.2rem;
                margin-left: auto;
                display: block;
                color: $white;
                opacity: 0;
                flex-shrink: 0;
                transform: translateY(calc(100% + 30px));
                transition: transform .3s ease-in-out 1.1s, opacity .3s ease-in-out 1.1s;
            }
        }
    }
}