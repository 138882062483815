.section-error{
	color: $white;
	min-height: 100vh;
	padding-top: 4rem;
	padding-bottom: 22rem;
	background-color: $black;
	@media ( max-width: $sw-md-max) {
		padding-bottom: 18rem;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: -7.2rem;
		padding-top: 5.9rem;
	}
}

.section-error__header{
	padding-left: 5.5rem;
	padding-right: 5.5rem;
	@media ( max-width: $sw-md-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
	@media ( max-width: $sw-xs-max) {
		padding-left: 5.6rem;
		padding-right: 5.6rem;
	}
}

.section-error__brand{
	a{
		display: inline-block;
		svg{
			color: $white;
			font-size: 14.9rem;
		}
	}
}

.section-error__body{
	margin-top: 8rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 4.5rem;
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}
}

.container{

}

.section-error__name{
	display: flex;
	font-size: 1.6rem;
	align-items: center;
	b{
		display: block;
		font-weight: 900;
		font-size: 3.2rem;
		& + span{
			margin-left: .7rem;
		}
	}
	span{
		display: block;
		font-weight: 500;
	}
}

.section-error__caption{
	max-width: 40rem;
	margin-top: 1rem;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
}

.section-error__action{
	margin-top: 2.3rem;
	margin-left: -2.4rem;
	@media ( max-width: $sw-md-max) {
		margin-left: 0;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 2.9rem;
		margin-left: -2.4rem;
	}
}

.section-error__events{
	margin-top: 12.6rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 9.5rem;
	}
}

.section-error__events-title{
	font-weight: 900;
	font-size: 3.2rem;
	line-height: 3.4rem;
	margin-bottom: 7.5rem;
	text-transform: uppercase;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		font-size: 2.4rem;
		line-height: 2.8rem;
		margin-bottom: 6.1rem;
	}
}

.section-error__events-list{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	margin-left: -6.5rem;
	margin-right: -6.5rem;
	@media ( max-width: $sw-md-max) {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {}
}

.section-error__events-item{
	width: 33.33333%;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 50%;
	}
	@media ( max-width: 678px) {
		width: 100%;
	}

	
	@media ( min-width: $sw-md-min) {
		&:nth-child(n+4){
			margin-top: 6.8rem;
		}
	}
	@media ( min-width: 679px) and ( max-width: $sw-sm-max) {
		&:nth-child(n+3){
			margin-top: 8.4rem;
		}
	}
	@media ( max-width: 678px) {
		&:nth-child(n+2){
			margin-top: 7rem;
		}
	}
}

.conference-nav-box{

}