@mixin delay-increment{
    @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          transition: transform .3s ease #{0.1 + $i/20}s, opacity .3s ease #{0.1 + $i/20}s;
        }
    }
}

.announcement {
    &-section {
        padding: 8rem 0 13.2rem 0;
        @media (max-width:1439px) {
            padding: 12rem 0 13.2rem 0;
        }
        @media (max-width:1199px) {
            padding: 12rem 0 11rem 0;
        }
        @media (max-width:991px) {
            padding: 12rem 0 9rem 0;
        }
        @media (max-width:767px) {
            padding: 5rem 0 9rem 0;
        }
    }
    &__top {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 3.1rem;
        @media (max-width:991px) {
            flex-wrap: wrap;
            margin-bottom: 4rem;
        }
        .section-title {
            margin-bottom: 1.5rem;
        }
        h2 {
            text-transform: uppercase;
        }
        &-main {
            width: 100%;
            max-width: 62.8rem;
            margin-right: 58px;
            @media (max-width:1199px) {
                max-width: 55rem;
            }
            @media (max-width:991px) {
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }
            p {
                font-weight: 500;
            }
        }
        &-info {
            margin-top: 26px;
            @media (max-width:991px) {
                width: 100%;
                margin-top: 0;
            }
            .top-info {
                &__date {
                    color: #009BD7;
                    display: block;
                    width: 100%;
                    margin-bottom: 16px;
                    font-weight: 700;
                    font-size: 18px;
                    @media (max-width:991px) {
                        margin-bottom: 13px;
                    }
                }
                &__count {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -47px;
                    @media (max-width:991px) {
                        margin-right: -35px;
                    }
                    &__item {
                        margin-right: 47px;
                        max-width: 120px;
                        margin-bottom: 15px;
                        @media (max-width:991px) {
                            margin-right: 35px;
                        }
                    }
                    &__num {
                        font-size: 48px;
                        line-height: 1.1;
                        font-weight: 900;
                        color: #000000;
                        margin-bottom: 12px;
                        display: block;
                        @media (max-width:991px) {
                            font-size: 42px;
                            margin-bottom: 10px;
                        }
                    }
                    &__name {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 1.25;
                        color: #000000;
                        text-transform: uppercase;
                        display: block;
                        @media (max-width:991px) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        @media (max-width:1199px) {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }
    &__block {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40rem;
        @media (max-width:767px) {
            max-width: 34.5rem;
        }
        &:hover {
            .announcement__block__inner {
                opacity: 1;
                visibility: visible;
                transition: opacity .3s ease, visibility .3s ease;
            }
            .announcement__block__title {
                opacity: 1;
            }
            .announcement__block__list li{
                opacity: 1;
                transform: none;
                @include delay-increment;
            }
            .announcement__block__link {
                opacity: 1;
                transform: none;
                transition: transform .3s ease .5s, opacity .3s ease .5s;
            }
        }
        &-wrap {
            width: calc(100% / 3);
            padding: 0 1.5rem;
            margin-bottom: 3rem;
            @media (max-width:1199px) {
                padding: 0 1rem;
                margin-bottom: 2rem;
            }
            @media (max-width:991px) {
                width: 50%;
            }
            @media (max-width:767px) {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
        &__inner {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.8);
            opacity: 0;
            visibility: hidden;
            padding: 3rem 2.4rem 6rem 2.4rem;
            transition: opacity .3s ease, visibility .3s ease;
        }
        &__title {
            margin-top: 0;
            margin-bottom: 2.4rem;
            color: #ffffff;
            padding-left: 7px;
            opacity: 0;
            transition: transform .3s ease, opacity .3s ease;
        }
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                position: relative;
                padding-left: 1.3rem;
                color: #ffffff;
                font-size: 1.6rem;
                line-height: 1.25;
                opacity: 0;
                transform: translateY(20px);
                transition: transform .3s ease, opacity .3s ease;
                &:before {
                    content: '';
                    display: block;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    position: absolute;
                    left: 1px;
                    top: 7px;
                }
            }
        }
        &__link {
            position: absolute;
            right: 1.8rem;
            bottom: 3.3rem;
            opacity: 0;
            transform: translateY(20px);
            transition: transform .3s ease, opacity .3s ease;
        }
    }
}

html.announcement-modal {
    .p-wrapp {
        @media (min-width: 768px) {
            filter: blur(1.5px);
        }
    }
    .modal-backdrop.show {
        @media (min-width: 768px) {
            opacity: .2;
        }
    }
}
.modal-announcement {
    .modal-content {
        min-height: auto;
        padding: 5rem 9rem 6rem 9rem;
        @media (max-width:767px) {
            padding: 4rem 5rem 5rem 5rem;
            min-height: 100vh;
        }
    }
    .modal-dialog {
        max-width: 47rem;
    }
    .modal-body {
        padding-left: 0;
        padding-right: 0;
    }
    .modal-title {
        font-weight: 800;
        color: #47D597;
        font-size: 2.8rem;
        line-height: 1.55;
        margin-bottom: 3rem;
    }
}
.btn_dark-green {
    background-color: #47D597;
}
.btn_submit.btn_dark-green {
    &:after{
        color: #47D597;
    }
}