.svg {}
.icon {
    &-search {
        width: 0.9649122807017544em;
    }
    &-check {
        width: 1.3em;
    }
    &-chat {
        width: 1.144927536231884em;
    }
    &-person {
        width: 0.9156193895870736em;
    }
    &-chevron-sm {
        width: 1.8em;
    }
}