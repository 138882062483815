.conference-box {
	width: 100%;
	height: 50rem;
	display: block;
	position: relative;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		height: 100%;
	}
	@media ( max-width: 678px) {
		height: auto;
	}
	&:hover {
		@media (min-width: $sw-md-min) {
			.conference-box__content {
				&:before {
					transform: translateX(0);
				}
			}
		}
	}
	&:nth-child(3n + 3) {
		margin-right: 0;
	}
	&.active{
		.conference-box__form{
			display: block;
		}
	}
	&.orange {
		.conference-box__content {
			background-color: #FC6621;
			&:before {
				background-color: #8a9297;
			}
		}
	}
	&.blue {
		.conference-box__content {
			background-color: #107BD2;
			&:before {
				background-color: #41b6e6;
			}
		}
	}
	&.green {
		.conference-box__content {
			background-color: #70CC61;
			&:before {
				background-color: #ece81a;
			}
		}
	}
	&.light-green {
		.conference-box__content {
			background-color: #48D597;
			&:before {
				background-color: #ee2737;
			}
		}
	}
	&.light-violet {
		.conference-box__content {
			background-color: #8787D5;
			&:before {
				background-color: #8bd3e6;
			}
		}
	}
	&.light-orange {
		.conference-box__content {
			background-color: #F2A900;
			&:before {
				background-color: #7474c1;
			}
		}
	}
	&.violet {
		.conference-box__content {
			background-color: #8F60E2;
			&:before {
				background-color: #8a9297;
			}
		}
	}
	&.light-blue {
		.conference-box__content {
			background-color: #69B3E7;
			&:before {
				background-color: #97d700;
			}
		}
	}
	&.wish-box {
		.conference-box__content {
			background-color: $white;
		}
		.conference-box__info {
			margin-bottom: 6.4rem;
			font-weight: 400;
		}
	}
	&__wrapp {
		font-size: 0;
		width: 100.7rem;
		margin-right: -1.6rem;
	}
	&__open, &__close {
		top: 0;
		right: 0;
		width: 4.8rem;
		display: flex;
		height: 4.8rem;
		position: absolute;
		align-items: center;
		justify-content: center;
		background-color: $black;
		@media ( max-width: $sw-xs-max) {
			display: none;
		}
		i {
			width: 1.6rem;
			height: 2px;
			display: block;
			background-color: $white;
			position: relative;
			transition: background-color $transition_duration ease;
			&:after, &:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
			}
		}
	}
	&__open {
		i {
			&:after, &:before {
				left: 0;
				background-color: inherit;
			}
			&:before {
				top: -6px;
			}
			&:after {
				bottom: -6px;
			}
		}
	}
	&__close {
		transform: scale(0);
		opacity: 0;
		transition: transform .3s ease .3s, opacity .4s ease;
		&.active {
			opacity: 1;
			transform: scale(1);
			transition: transform .1s ease;
		}
		i {
			background-color: transparent;
			&:after, &:before {
				background-color: $white;
			}
			&:before {
				top: 0;
				transform: rotate(45deg);
			}
			&:after {
				bottom: 0;
				transform: rotate(-45deg);
			}
		}
	}
	&__content {
		overflow: hidden;
		position: relative;
		height: calc(100% - 5.6rem);
		padding: 4rem 3.2rem 3.2rem 3.2rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			height: 100%;
			padding: 2.4rem;
			min-height: 31rem;
			padding-bottom: 10.4rem;
		}
		&:before {
			top: 0;
			left: 0;
			content: '';
			height: 100%;
			display: block;
			width: 11.6rem;
			position: absolute;
			transform: translateX(-11.6rem);
			transition: transform .3s ease-in-out;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				width: 6.9rem;
				transform: translateX(0);
			}
		}
	}

	&__num-wrapp{
		z-index: 1;
		color: $black;
		display: flex;
		position: relative;
		align-items: center;
		margin-bottom: .8rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			margin-bottom: 1.4rem;
		}
		h3{
			font-size: 3.2rem;
			line-height: 3rem;
		}
		span{
			font-weight: 700;
			font-size: 1.2rem;
			margin-top: .4rem;
			margin-left: .8rem;
			line-height: 1.3rem;
			text-transform: uppercase;
		}
	}
	&__title{
		z-index: 1;
		position: relative;
		text-transform: uppercase;
		h3{
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {}
		}
	}
	&__info{
		z-index: 1;
		color: $thunder;
		font-weight: 500;
		font-size: 1.6rem;
		position: relative;
		margin-top: 1.7rem;
		line-height: 1.8rem;
	}
	&__footer{
		display: flex;
		align-items: center;
		background-color: $black;
		justify-content: space-between;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			left: 2.4rem;
			right: 2.4rem;
			bottom: 2.4rem;
			position: absolute;
			justify-content: flex-start;
			background-color: transparent;
		}
		a{
			width: 100%;
			color: $white;
			height: 5.6rem;
			font-weight: 900;
			font-size: 1.6rem;
			position: relative;
			text-align: center;
			line-height: 5.6rem;
			text-decoration: none;
			text-transform: uppercase;
			@media ( max-width: $sw-xs-max) {
				width: auto;
				color: $black;
				height: 3.2rem;
				line-height: 3.2rem;
			}
			& + a {
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					margin-left: 3.2rem;
				}
				&:before {
					width: 2px;
					top: 2.1rem;
					content: '';
					left: -.1rem;
					bottom: 1.8rem;
					display: block;
					position: absolute;
					pointer-events: none;
					background-color: $white;
					@media ( max-width: $sw-lg-max) {}
					@media ( max-width: $sw-md-max) {}
					@media ( max-width: $sw-sm-max) {}
					@media ( max-width: $sw-xs-max) {
						top: .8rem;
						width: 1px;
						left: -1.6rem;
						bottom: 0.7rem;
						background-color: $black;
					}
				}
			}
		}
	}
	&__submit {
		width: 100%;
		height: 5.6rem;
		background-color: rgba(#231F20, 0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		color: #fff;
		font-weight: 900;
		font-size: 1.6rem;
		line-height: 1.6rem;
	}
	&__form{
		&-title {
			font-size: 1.6rem;
			line-height: 2.2rem;
			color: #787878;
		}
	}
	&__text {
		margin-top: 1rem;
		resize: none;
		border: none;
		border-bottom: 1px solid #787878;
		transition: border-color $transition_duration ease, box-shadow $transition_duration ease;
		&:focus {
			border-color: transparent;
		}
	}
	&__menu {
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: none;
		position: absolute;
		background-color: $black;
		&-inner {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: flex-end;
		}
	}
	&__list {
		width: 100%;
		padding: 0 3.2rem;
		margin: 5rem 0 0 0;
		&:hover {
			@media (min-width: $sw-md-min) {
				.conference-box__list-item {
					opacity: 0.5;
				}
			}
		}
		&.active {
			.conference-box__list-item {
				width: 100%;
				transition: opacity $transition_duration ease, width .25s ease-in-out .1s;
				a {
					transform: translateY(0);
					opacity: 1;
					transition: transform .35s ease-in-out .3s, opacity .5s ease-in-out .3s;
				}
			}
		}
		&-item {
			font-size: 1.6rem;
			font-weight: 500;
			color: $white;
			letter-spacing: 0.5px;
			display: block;
			padding: 0 0 10px 0;
			margin-bottom: 5px;
			position: relative;
			opacity: 1;
			width: 0;
			overflow: hidden;
			transition: opacity $transition_duration ease, width .35s ease-in-out;
			&:hover {
				@media (min-width: $sw-md-min) {
					opacity: 1 !important;
				}
			}
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				left: 0;
				bottom: 0;
				background-color: $white;
			}
			a {
				text-decoration: none;
				color: #fff;
				white-space: nowrap;
				display: block;
				opacity: 0;
				transition: transform .4s ease-in-out, opacity .25s ease-in-out;
				transform: translateY(calc(100% + 15px));
			}
		}
	}
}


.conference-box__form{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: none;
	position: absolute;
	background-color: $white;
	@media ( max-width: $sw-md-max) {
		position: static;
	}
	@media ( max-width: $sw-sm-max) {
		position: static;
	}
	@media ( max-width: $sw-xs-max) {
		position: static;
	}
	.conference-box__content{
		background-color: $white !important;
	}
}

.conference-box__form-content{
	height: 100%;
	display: flex;
	padding: 3.2rem;
	padding-bottom: 0;
	padding-top: 4rem;
	flex-direction: column;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.conference-box__form-content-title{
	flex-shrink: 0;
	overflow: hidden;
	max-height: 8.4rem;
}

.conference-box__form-content-caption{
	height: 8.8rem;
	flex-shrink: 0;
	overflow: hidden;
	font-size: 1.6rem;
	margin-top: 1.1rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		height: 4.4rem;
	}
}

.conference-box__form-content-fields{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	textarea{
		min-height: 5.8rem;
		@media ( max-width: $sw-xs-max) {
			min-height: 8.2rem;
		}
	}

	.form-group{
		label.invalid{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		& + .form-group_submit{
			@media ( max-width: $sw-xs-max) {
				margin-top: 2rem;
			}
		}
	}
	.form-group_submit{
		margin-left: -3.2rem;
		margin-right: -3.2rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.conference-box__footer{

}