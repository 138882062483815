.partner-boxes {
    &__section {
        background-color: $wildSand;
        padding: 9rem 0 9rem 0;
        @media (max-width: $sw-md-max) {
            padding: 7rem 0 10rem 0;
        }
        @media (max-width: $sw-sm-max) {
            padding: 5.5rem 0 5rem 0;
        }
        &.our-partners {
            .partner-boxes__wrapper {
                margin-bottom: -6.3rem;
                @media (max-width: $sw-md-max) {
                    margin-bottom: -4.5rem;
                }
                @media (max-width: $sw-sm-max) {
                    margin-bottom: 6rem;
                }
            }
            .partner-boxes__aside {
                margin-bottom: 7rem;
                @media (max-width: $sw-md-max) {
                    margin-bottom: 9rem;
                }
                @media (max-width: $sw-sm-max) {
                    margin-bottom: 0;
                }
            }
            .platform-box {
                @media (max-width: $sw-sm-max) {
                    padding: 1.6rem 1.6rem 4rem 1.6rem;
                }
            }
            .partner-boxes__item {
                @media (max-width: $sw-xx-max) {
                    opacity: 0.5;
                }
                &.slick-current {
                    @media (max-width: $sw-xx-max) {
                        opacity: 1;
                    }
                }
            }
        }
        &.media-partners {
            .partner-boxes__aside {
                margin-bottom: 5.6rem;
            }
            .partner-boxes__wrapper {
                @media (max-width: $sw-sm-max) {
                    margin-bottom: 3rem;
                }
            }
        }
        .section-title {
            margin-bottom: 5rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 4.2rem;
            }
            &__minor {
                display: none;
                @media (max-width: $sw-sm-max) {
                    margin-top: 1.5rem;
                    display: block;
                }
            }   
        }
    }
    &__content {
        display: flex;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &__wrapper {
        width: calc(100% - 32.6rem);
        display: flex;
        flex-wrap: wrap;
        @media (max-width: $sw-md-max) {
            width: calc(100% - 29rem);
        }
        @media (max-width: $sw-sm-max) {
            width: 100%;
        }
        .platform-box__case {
            background-color: $orange;
            @media (max-width: $sw-sm-max) {
                transform: none;
                background-color: transparent;
            }
            &-btn {
                text-transform: uppercase;
                color: #000;
                svg {
                    fill: #000;
                }
            }
        }
        .platform-box__type {
            @extend .small;
            margin-top: 8px;
            margin-bottom: 4.7rem;
            @media (max-width: $sw-md-max) {
                margin-bottom: 2.7rem;
            }
        }
        .platform-box {
            &.all-partners {
                display: inline-flex;
                align-items: flex-end;
                justify-content: flex-start;
                text-transform: uppercase;
                @media (max-width: $sw-md-max) {
                    padding: 1.2rem;
                    height: 14.4rem;
                    width: 14.4rem;
                }
                @media (max-width: $sw-sm-max) {
                    display: none;
                }
            }
        }
        .partner-boxes__block {
            @media (max-width: $sw-sm-max) {
                width: 100vw;
            }
        }
    }
    &__item {
        margin-bottom: 1.6rem;
        margin-right: 1.6rem;
        width: 15.4rem;
        opacity: 1;
        transition: opacity .3s ease;
        display: inline-block;
        vertical-align: top;
        @media (max-width: $sw-md-max) {
            width: 14.4rem;
        }
        @media (max-width: $sw-sm-max) {
            width: 21rem;
            margin-bottom: 0;
        }
        .platform-box {
            width: 100%;
            @media (max-width: $sw-md-max) {
                height: 14.4rem;
            }
            @media (max-width: $sw-sm-max) {
                height: 21rem;
            }
        }
    }
    &__aside {
        width: 32.6rem;
        margin-bottom: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: $sw-md-max) {
            width: 29rem;
        }
        @media (max-width: $sw-sm-max) {
            width: 100%;
            background-color: transparent;
        }
        .people-box__name {
            min-height: auto;
            @media ( max-width: $sw-sm-max) {
                margin-top: 0;
                min-height: auto;
            }
        }
        .people-box__details{
            @media ( max-width: $sw-sm-max) {
                padding-top: 1.7rem;
                padding-left: 11.6rem;
            }
        }
        .people-box__position{
            @media ( max-width: $sw-sm-max) {
                min-height: auto;
                margin-bottom: 2.2rem;
            }
        }
        .people-box__pic{
            @media ( max-width: $sw-sm-max) {
                position: absolute;
                top: 0;
                left: 0;
                width: 9.2rem;
                height: 9.2rem;
            }
        }
        .link {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
        &-title {
            text-transform: uppercase;
        }
        &-content {
            width: 100%;
            height: 100%;
            background: #fff;
            padding: 4rem;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            @media (max-width: $sw-md-max) {
                padding: 3rem;
            }
            @media (max-width: $sw-sm-max) {
                padding: 0;
                background-color: transparent;
            }
            &.aside-gold-partner {
                @media (max-width: $sw-sm-max) {
                    background-color: #fff;
                    padding: 4rem;
                }
            }
        }
        &-footer {
            padding-top: 4.7rem;
            margin-top: auto;
            .partner-aside__text {
                margin-bottom: 1rem;
            }
            .link {
                text-transform: uppercase;
            }
        }
        &-form {
            margin-top: 3.1rem;
            @media (max-width: $sw-sm-max) {
                width: 100%;
                max-width: 46.5rem;
                margin: 3rem auto 0 auto;
            }
            .form-box_submit {
                width: 100%;
                @media (max-width: $sw-xs-max) {
                    margin-top: 0;
                }
                .btn {
                    width: 100%;
                    text-transform: uppercase;
                }
            }
            .form-group {
                margin-bottom: 2rem;
                margin-top: 0;
                input {
                    padding-bottom: .7rem;
                    background-color: transparent;
                }
            }
        }
    }
}

.partner-aside {
    &__text {
        @extend .big;
        font-weight: 500;
    }
}