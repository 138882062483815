.registration-step {
    &__section {
        background-color: $wildSand;
        padding-bottom: 5rem;
        @media (max-width: $sw-xs-max) {
            padding-bottom: 2rem;
        }
        .step {
            &__wrapper {
                background-color: #fff;
                padding: 2.4rem 1.6rem;
                @media (max-width: $sw-md-max) {
                    padding: 2rem 1rem;
                }
                @media (max-width: $sw-xs-max) {
                    background-color: transparent;
                    padding: 0 0 2rem 0;
                    margin-left: -1.6rem;
                    margin-right: -1.6rem;
                }
            }
            &__boxes {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__box {
                width: 100%;
                text-align: right;
                position: relative;
                white-space: nowrap;
                &:after{
                    position: absolute;
                    left: 0;
                    display: block;
                    width: 100%;
                    min-width: 15px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 2px;
                    content: '';
                    background-color: rgba(0,0,0,.3);
                }
                span{
                    background-color: #fff;
                    padding-left: 1.6rem;
                    padding-right: 1.6rem;
                    color: rgba(0,0,0,.3);
                    font-size: 1.6rem;
                    font-weight: 900;
                    text-transform: uppercase;
                    z-index: 1;
                    position: relative;
                    @media (max-width: $sw-xs-max) {
                        background: $wildSand;
                    }
                    @media (max-width: $sw-xx-max) {
                        font-size: 1.5rem;
                        padding-left: 9px;
                        padding-right: 9px;
                    }
                }
                &:first-child{
                    width: auto;
                    &:after {
                        display: none;
                    }
                }
                &.active {
                    span {
                        color: $orange;
                    }
                }
                &.done {
                    span {
                        color: #000;
                    }
                    & + .step__box:after {
                        background-color: #000;
                    }
                }
            }
        }
    }
}

.step__boxes{
    
}

.step__box{
    
}