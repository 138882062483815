.section-about-team{
	padding-top: 5.8rem;
	padding-bottom: 14.7rem;
	background-color: $wildSand;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-bottom: 0;
	}
	.section-title{
		max-width: 80%;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {
			max-width: 90%;
		}
		@media ( max-width: $sw-xs-max) {
			max-width: 100%;
		}
	}
}

.about-team__content{
	margin-top: 7.9rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 6rem;
	}
}

.about-team__list{
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.6rem;
	align-items: flex-start;
	@media ( max-width: 699px) {
		max-width: 40rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.about-team__item{
	width: 25%;
	padding-left: 1.6rem;

	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 33.33333%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 50%;
	}
	@media ( max-width: 699px) {
		width: 100%;
		padding-left: 0;
	}

	@media ( min-width: $sw-md-min) {
		&:nth-child(n+5){
			margin-top: 8.4rem;
		}
	}
	@media ( min-width: $sw-sm-min) and ( max-width: $sw-sm-max) {
		&:nth-child(n+4){
			margin-top: 8.4rem;
		}
	}
	@media ( min-width: 700px) and ( max-width: $sw-xs-max) {
		&:nth-child(n+3){
			margin-top: 5.6rem;
		}
	}
	@media ( max-width: 699px) {
		&:nth-child(n+2){
			margin-top: 5rem;
		}
	}
}

.about-team-box{
	position: relative;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		display: flex;
		min-height: 11.7rem;
		padding-top: 3.9rem;
		padding-left: 14.6rem;
		flex-direction: column;
	}
}

.about-team-box__view{
	display: block;
	width: 15.4rem;
	height: 15.4rem;
	overflow: hidden;
	border-radius: 50%;
	margin-bottom: 1.9rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		top: 0;
		left: 0;
		width: 11.7rem;
		height: 11.7rem;
		position: absolute;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
		img{
			border-radius: 50%;
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
		}
	}
}

.about-team-box__name{
	font-size: 1.6rem;
	line-height: 1.8rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 1;
	}
}

.about-team-box__position{
	font-size: 1.6rem;
	margin-top: .2rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 2;
		margin-top: .4rem;
	}
}

.about-team-box__social{
	margin-top: .7rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 0;
		margin-top: 0;
		margin-bottom: .9rem;
	}
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					margin-right: 1.3rem;
				}
			}
			a{
				display: block;
				position: relative;
				svg{
					font-size: 2.4rem;
					@media ( max-width: $sw-xs-max) {
						font-size: 4rem;
					}
				}
				&.blue{
					svg{
						fill: $cerule;
					}
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-.4rem);
					}
				}
			}
		}
	}
}




.about-team__official-info{
	display: flex;
	margin-top: 17rem;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		margin-top: 12rem;
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 6rem;
	}
}

.about-team__official-info-title{
	width: 47%;
	padding-top: 3.5rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {}
}

.about-team__official-info-title-name{
	font-weight: 900;
	font-size: 4.8rem;
	line-height: 5.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		font-size: 3.2rem;
		line-height: 3.4rem;
	}
}

.about-team__official-info-title-action{
	margin-top: 2.5rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 2.2rem;
	}
}

.about-team__official-info-detail{
	width: 49.3%;
	padding: 5.7rem;
	padding-top: 4.6rem;
	padding-bottom: 8rem;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		margin-top: 6.4rem;
	}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		margin-left: -2.4rem;
		padding-right: 2.4rem;
		width: calc(100% + 4.8rem);
	}
}

.about-team__official-info-detail-title{
	font-weight: 900;
	font-size: 2.4rem;
	line-height: 2.8rem;
	margin-bottom: 3.3rem;
}

.about-team__official-info-detail-content{
	font-size: 1.6rem;
	line-height: 2.2rem;
	    white-space: pre-line;
}