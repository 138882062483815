.news-top {
	&__navigation {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 3rem;
			margin-left: -2.3rem;
			@media (max-width: $sw-xs-max) {
					margin-left: 0;
					justify-content: space-between;
			}
			.link {
					text-transform: uppercase;
					font-weight: 900;
					font-size: 1.6rem;
					.icon-arrow {
							@media (max-width: $sw-xs-max) {
									fill: #000;
							}
					}
					& + .link {
							margin-left: 3rem;
							position: relative;
							@media (max-width: $sw-xs-max) {
									margin-left: 0;
							}
							&:before {
									content: '';
									display: block;
									position: absolute;
									left: -1.5rem;
									top: 50%;
									transform: translateY(-50%);
									background-color: #787878;
									width: 1px;
									height: 1.2rem;
									cursor: default;
									pointer-events: none;
									@media (max-width: $sw-xs-max) {
											display: none;
									}
							}
					}
			}
	}
}
.news__video {
	&-block {
			height: auto;
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			@media (max-width: $sw-sm-max) {
					flex-wrap: wrap;
			}
			&-title {
					margin-bottom: 2.5rem;
			}
			.tags {
					@media (max-width: $sw-xs-max) {
							margin-bottom: 3rem;
					}
			}
			.news__top {
					&-video {
							width: calc(100% - 25.7rem);
							height: auto;
							@media (max-width: $sw-sm-max) {
									width: 100%;
							}
					}
					&-info {
							width: 252px;
							padding-left: 1.7rem;
							justify-content: flex-start;
							background-color: transparent;
							@media (max-width: $sw-sm-max) {
									width: 100%;
									padding-left: 0;
									margin-bottom: 7rem;
							}
							@media (max-width: $sw-xs-max) {
									padding-left: 1.5rem;
									padding-right: 1.5rem;
							}
					}
			}
			.video-content {
					height: 45rem;
					margin-bottom: 2.3rem;
					@media (max-width: $sw-sm-max) {
							height: 40rem;
							margin-bottom: 0;
					}
					@media (max-width: $sw-xs-max) {
							height: 23rem;
					}
			}
			.video__info {
					&-title  {
							margin-bottom: 1.5rem;
							margin-top: 3px;
							width: 100%;
					}
					&-photo {
							border-radius: 50%;
							width: 6.9rem;
							height: 6.9rem;
							img {
									border-radius: 50%;
							}
					}
					&-name {
							text-transform: uppercase;
							margin-bottom: 3px;
							width: 100%;
					}
					&-position {
							font-size: 1.6rem;
							line-height: 2.2rem;
							color: #000;
							margin-bottom: 3px;
							width: 100%;
					} 
					&-company {
							margin-top: 0;
							line-height: 2.4rem;
							width: 100%;
					}
			}
	}
	&-info {
			&-wrap {
					@media (max-width: $sw-sm-max) {
							display: flex;
							flex-wrap: wrap;
					}
					.news-box__label {
							display: inline-flex;
							height: 3.2rem;
							padding-left: 1rem;
							padding-right: 1rem;
							margin-bottom: 2.5rem;
							@media (max-width: $sw-sm-max) {
									order: 0;
									display: flex;
									width: 100%;
									margin-bottom: 1rem;
							}
					}
					.news-inner__time {
							color: #929292;
							@media (max-width: $sw-sm-max) {
									order: 2;
							}
							@media (max-width: $sw-xs-max) {
									padding-left: 1.5rem;
									padding-right: 1.5rem;
							}
					}
					.tags {
							@media (max-width: $sw-sm-max) {
									order: 3;
									width: 100%;
							}
							@media (max-width: $sw-xs-max) {
									padding-left: 1.5rem;
									padding-right: 1.5rem;
							}
					}
			}
	}
	&-name {
			font-weight: 700;
			margin-bottom: 1.5rem;
			@media (max-width: $sw-lg-max) {
					font-size: 4.2rem;
			}
			@media (max-width: $sw-sm-max) {
					font-size: 3.8rem;
					line-height: 4.3rem;
					order: 1;
					margin-bottom: 2rem;
			}
			@media (max-width: $sw-xs-max) {
					font-size: 3.1rem;
					line-height: 3.6rem;
					padding-left: 1.5rem;
					padding-right: 1.5rem;
			}
	}
}
.video-inner__wrapper {
	@media (max-width: $sw-xs-max) {
			padding-top: 3rem;
	}
	.top-block-container {
			@media (max-width: $sw-xs-max) {
					padding-left: 0;
					padding-right: 0;
			}
	}
}