.section-question-people{
	padding-top: 6.9rem;
	padding-bottom: 12rem;
	background-color: $wildSand;
	@media ( max-width: $sw-sm-max) {
		padding-bottom: 9rem;
	}
	@media ( max-width: $sw-xs-max) {
		padding-top: 4.3rem;
	}
}

.question-people__items{
	display: flex;
	flex-wrap: wrap;
	margin-left: -2%;
	align-items: flex-start;
	@media ( max-width: $sw-sm-max) {
		margin-left: -10%;
	}
	@media ( max-width: $sw-xs-max) {
		margin-left: -5%;
	}
	@media ( max-width: $sw-xx-max) {
		margin-left: 0;
	}
}

.question-people__item{
	width: 23%;
	margin-left: 2%;
	padding-right: 3.2rem;
	@media ( max-width: $sw-sm-max) {
		width: 40%;
		margin-left: 10%;
		padding-right: 0;
	}
	@media ( max-width: $sw-xs-max) {
		width: 45%;
		margin-left: 5%;
	}
	@media ( max-width: $sw-xx-max) {
		width: 100%;
		margin-left: 0;
	}
	@media ( min-width: $sw-md-min) {
		&:nth-child(n+5){
			margin-top: 3.6rem;
		}		
	}
	@media ( min-width: $sw-xs-min) and ( max-width: $sw-sm-max) {
		&:nth-child(n+3){
			margin-top: 5.2rem;
		}		
	}
	@media ( max-width: $sw-xx-max) {
		&:nth-child(n+2){
			margin-top: 7.4rem;
		}
	}
}

.people-box{
	width: 100%;
	font-size: 1.6rem;
	line-height: 2.2rem;
}

.people-box__position{
	font-weight: 900;
	font-size: 2.4rem;
	min-height: 8.4rem;
	line-height: 2.8rem;
	margin-bottom: .4rem;
	text-transform: uppercase;
	@media ( max-width: $sw-xx-max) {
		min-height: auto;
		margin-bottom: 2.2rem;
	}
}

.people-box__details{
	position: relative;
	@media ( max-width: $sw-xx-max) {
		padding-top: 1.7rem;
		padding-left: 9.2rem;
	}
}

.people-box__pic{
	width: 6.8rem;
	height: 6.8rem;
	overflow: hidden;
	border-radius: 50%;
	@media ( max-width: $sw-xx-max) {
		position: absolute;
		top: 0;
		left: 0;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
	}
}

.people-box__name{
	font-weight: 900;
	font-size: 1.6rem;
	min-height: 3.6rem;
	margin-top: 1.7rem;
	line-height: 1.8rem;
	text-transform: uppercase;
	@media ( max-width: $sw-xx-max) {
		margin-top: 0;
		min-height: auto;
	}
}

.people-box__sub-position{
	margin-top: .4rem;
}

.people-box__contacts{
	/* margin-top: 1.8rem; */
	@media ( max-width: $sw-xs-max) {
		margin-top: .9rem;
	}
}