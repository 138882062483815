	.nav-box{
	@media ( max-width: $sw-lg-max) {
		box-shadow: 0 2px 16px 0 rgba(0,0,0,0.15);
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {}
	&:after{
		top: 0;
		right: 0;
		bottom: 0;
		content: '';
		width: .1rem;
		display: block;
		position: absolute;
		background-color: $mercury;
		@media ( max-width: $sw-lg-max) {
			right: -.1rem;
		}
		@media ( max-width: $sw-xs-max) {
			display: none;
		}
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}

	&.nav-box-simple {
		.nav-box__content {
			@media ( min-width: $sw-xl-min) {
				padding-top: 0;
			}
		}
		.nav-box__header {
			@media ( min-width: $sw-xl-min) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.nav-box__detail {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
			padding-top: 2.4rem;
			padding-bottom: 3.2rem;
			@media ( max-width: $sw-lg-max) {
				display: none;
			}
		}
		.nav-box__brand {
			@media ( min-width: $sw-xl-min) {
				display: none;
			}
		}
		.nav-box__body {
			@media ( min-width: $sw-xl-min) {
				padding-top: 4rem;
			}
		}
	}

	.nav-submenu-active & {
		box-shadow: none;
	}



	&_conference{
		@media ( max-width: $sw-lg-max) {
			width: auto;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			width: 100%;
			&:after{
				top: 5.5rem;
			}
		}
		.nav-box__toggle{
			margin-right: 2.9rem;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				order: 0;
			}
			button{
				color: $orange;
			}
			.nav-box__toggle-view{
				i{
					background-color: $orange;
				}
			}
		}
		.nav-box__header{
			padding-left: 0;
			padding-right: 0;
			@media ( max-width: $sw-lg-max) {
				padding-left: 3.2rem;
				padding-right: 3.2rem;
			}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				padding-right: 0;
				padding-left: 2.4rem;
			}
		}
		.nav-box__body{
			padding-top: 3.2rem;
			@media ( max-width: $sw-lg-max) {
				padding-top: 6.4rem;
			}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {}
		}
		.nav-box__content{
			padding-top: 0;
			@media ( max-width: $sw-lg-max) {
				padding-top: 2.4rem;
				padding-bottom: 2.4rem;
			}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		.nav-box__global{
			nav{
				ul{
					li{
						&:not(:last-child){
							margin-bottom: 2rem;
						}
						a{
							&.lang{
								&.active{
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}
}




.nav-box__conference{
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		display: none;
	}
	.conference-nav-box{
		padding-top: 3.7rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		padding-bottom: 4.1rem;
		background-color: $silverApprox;
		@media ( max-width: $sw-lg-max) {
			padding: 0;
			background-color: transparent;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
		&.red {
			background-color: $alizarinCrimson;
			@media ( max-width: $sw-lg-max) {
				background-color: transparent;
			}
		}
	}
	.conference-nav-box__view{
		display: none;
		margin-right: .8rem;
		@media ( max-width: $sw-lg-max) {
			display: block;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
	}
	.conference-nav-box__detail{

	}
	.conference-nav-box__title{
		margin-bottom: .7rem;
		@media ( max-width: $sw-lg-max) {
			display: none;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
		i{
			font-size: 2rem;
			line-height: 2.2rem;
		}
		b{
			margin-left: .7rem;
		}
	}
	.conference-nav-box__name{
		font-size: 2rem;
		line-height: 2.2rem;
		@media ( max-width: $sw-lg-max) {
			display: flex;
			height: 3.6rem;
			max-width: 21rem;
			//overflow: hidden;
			font-size: 1.6rem;
			line-height: 1.8rem;
			align-items: center;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
	}
	.conference-nav-box__time{
		margin-top: .5rem;
		@media ( max-width: $sw-lg-max) {
			display: none;
		}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {}
	}
}

.nav-box__registration{
	display: none;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		display: block;
	}
}

.nav-box__content{
	height: 100%;
	display: flex;
	padding-top: 3.5rem;
	padding-bottom: 3.2rem;
	flex-direction: column;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {
		padding-top: 1.8rem;
		padding-bottom: 1.8rem;
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}
	&.active {
		overflow: hidden;
		overflow-y: auto;
		.nav-box__footer {
			margin-bottom: 10px;
		}
		.nav-box__news {
			margin-left: 10px;
			@media ( max-width: $sw-xs-max) {
				margin-left: 0;
			}
		}
	}
}

@media ( min-width: $sw-md-min) {
	body:not(.nav-submenu-active){
		.nav-box__content:hover{
			.nav-box__footer{
				// opacity: .3;
			}
			nav{
				>ul{
					>li{
						// opacity: .3;
					}
				}
			}
		}
	}
}

.nav-box__header{
	flex-shrink: 0;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-lg-max) {
		display: flex;
		align-items: center;
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}
	@media ( max-width: $sw-md-max) {
	}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2rem;
		//position: relative;
		padding-right: 2rem;
		justify-content: space-between;
		&:after {
			content: '';
			display: block;
			left: 0;
			right: 0;
			width: auto;
			//top: 7.1rem;
			height: .1rem;
			bottom: 0;
			width: 100%;
			z-index: -1;
			position: absolute;
			background-color: #E2E2E2;
		}
	}
}

.nav-box__toggle{
	display: none;
	margin-right: 1rem;
	@media ( max-width: $sw-lg-max) {
		display: block;
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 1;
	}
	button{
		display: flex;
		color: $cerulean;
		font-weight: 900;
		font-size: 1.6rem;
		line-height: 1.4rem;
		align-items: stretch;
	}
	.nav-box__toggle-view{
		width: 1.8rem;
		height: 1.4rem;
		position: relative;
		background-color: transparent;
		i{
			left: 0;
			width: 1.8rem;
			height: .2rem;
			display: block;
			position: absolute;
			background-color: $cerulean;
			&:nth-child(1){
				top: 0rem;
			}
			&:nth-child(2){
				top: .6rem;

			}
			&:nth-child(3){
				top: 1.2rem;
			}
		}
	}
	.nav-box__toggle-caption{
		width: 8.6rem;
		overflow: hidden;
		position: relative;
		margin-left: .8rem;
		span{
			left: 0;
			position: absolute;
			line-height: 1.4rem;
			text-transform: uppercase;
			&.open{
				top: 0;
			}
			&.close{
				top: 100%;
				z-index: 1;
			}
		}
	}
}

.nav-box__body{
	height: 100%;
	display: flex;
	padding-top: 8.9rem;
	flex-direction: column;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {
		display: none;
		.nav-mobile-active &{
			display: flex;
		}
		.nav-mobile-deactive &{
			display: flex;
		}
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 7.2rem;
		padding-bottom: 2rem;
	}
}

.nav-box__main{
	height: 100%;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 5.4rem;
		padding-right: 5.4rem;
		    max-height: 34rem;
    overflow: auto;
    overflow-x: hidden;
	}
	@media ( max-width: 360px) {
		padding-left: 3.2rem;
		padding-right: 3.2rem;

	}
	&-news {
		display: flex;
		height: auto;
		.nav-box__global {
			transform: translateX(-230px);
			opacity: 0;
			visibility: hidden;
			@media (max-width: $sw-lg-max) {
				transform: translateX(-280px);
			}
			@media (max-width: $sw-xs-max) {
				transform: translateX(-450px);
			}
		}
		.nav-box__news {
			max-width: 245px;
			opacity: 1;
			visibility: visible;
			transform: translateX(-180px);
			/* max-width: 100%; */
			@media (max-width: $sw-lg-max) {
				transform: translateX(-245px);
			}
			@media (max-width: $sw-xs-max) {
				transform: translateX(-100%);
			}
			.icon-arrow {
				transform: rotateZ(180deg);
			}
			.news__sublist, .news__sublist-list {
				list-style: none;
			}
		}
		&.active {
			.nav-box__global {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
			.nav-box__news {
				transform: translateX(50px);
				opacity: 0;
				
				visibility: hidden;
			}
		}
	}
}

.nav-box__global {
	flex-shrink: 0;
	width: 100%;
	opacity: 1;
	visibility: visible;
	transform: translateX(0);
	transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .4s ease-in-out; 
}

.nav-box__news {
	flex-shrink: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: opacity .4s ease-in-out, visibility .4s ease-in-out, transform .4s ease-in-out; 
	transform: translateX(50px);
	.nav-list-news {
		height: 470px;
		overflow: hidden;
		transition: all .6s ease-in-out;
		@media ( max-width: $sw-lg-max) {
			height: 460px;
		}
		@media ( max-width: $sw-xs-max) {
			height: 420px;
		}
		&.active {
			// max-height: 5000px;
			height: auto;
			overflow: visible;
		}
	}
	.news__sublist {

		padding: 18px 0 0 14px;
		&-title > span {
			font-size: 1.6rem;
			text-transform: uppercase;
			display: inline-block;
			margin-bottom: 10px;
		}
		&-list {
			padding: 0 0 0 14px;
			max-height: 150px;
		overflow: auto;
			li {
				margin-bottom: 8px !important;
				a {
					font-size: 1.4rem;
				}
				&.active{
					a{
						color:#009BDF;
					}
				}
			}

		}
	}
	.show-more {
		&-btn {
			display: flex;
			align-items: center;
			margin-left: 28px;
			color: #828282;
			font-size: 14px;
			margin-top: 10px;
			.icon-chevron-sm {
				font-size: 5px;
				fill: #828282;
				margin-left: 5px;
				margin-top: 2px;
				transform: rotate(0deg);
				transition: transform .3s ease;
			}
			&.active {
				.icon-chevron-sm {
					transform: rotate(-180deg);
				}
				.show-more-text {
					display: none;
				}
				.show-less-text {
					display: block;
				}
			}
		}
	}
	.show-less-text {
		display: none;
	}
}

.nav-box__footer{
	display: flex;
	flex-shrink: 0;
	/* margin-top: 6.4rem; */
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	flex-direction: column;
	transition: all 0.3s ease;
	@media ( min-width: $sw-md-min) {
		&:hover{
			opacity: 1 !important;
		}
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 5.4rem;
		// margin-top: 14rem;
		padding-left: 5.4rem;
		padding-right: 5.4rem;
	}
	@media ( max-width: 360px) {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}
	&.footer-green {
		.nav-box__conference-social-title {
			color: #72D19C;
		}
		.nav-box__conference-social-list ul li a svg {
			fill: #009BD7;
		}
	}
}

.nav-box__brand{
	// width: 100%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		order: 0;
	}
	a{
		padding-left: .4rem;
		padding-right: .4rem;
		display: inline-block;
		color: #009BDF;
		svg{
			font-size: 15rem;
			@media ( max-width: $sw-lg-max) {
				font-size: 12.2rem;
			}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {}
		}
	}
}

.nav-box__conference-social{

}

.nav-box__conference-social-title{
	color: $orange;
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin-bottom: 1.2rem;
	text-transform: uppercase;
}

.nav-box__conference-social-list{
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
			}
			a{
				display: block;
				svg{
					fill: $orange;
					font-size: 2.4rem;
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-.4rem);
					}
				}
			}
		}
	}
}

.nav-box__conference-copywriting{
	margin-top: 2.4rem;
}

.nav-box__global, .nav-box__news{
	nav{
		ul{
			li {
				color: $thunder;
				font-size: 1.6rem;
				line-height: 1.6rem;
				transition: all 0.3s ease;
				@media ( max-width: $sw-xs-max) {
					font-size: 2.4rem;
					line-height: 2.4rem;
				}
				&:not(:last-child){
					margin-bottom: 2.2rem;
					@media ( max-width: $sw-xs-max) {
						margin-bottom: 2.3rem;
					}
				}
				a{
					display: flex;
					color: $thunder;
					align-items: center;
					text-decoration: none;
					justify-content: space-between;
					@media ( max-width: $sw-xs-max) {
						justify-content: flex-start;
					}
					svg{
						display: block;
						font-size: 1.6rem;
						transition: all 0.375s ease;
						@media ( max-width: $sw-xs-max) {
							margin-top: .5rem;
							margin-left: 2.6rem;
						}
					}
					&.lang{
						opacity: .3;
						font-weight: 500;
						&.active{
							opacity: 1;
							color: $cerule;
						}
					}
					&:hover{
						span{
							color: #231F20;
							opacity: .3;
						}
					}
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						opacity: 1 !important;
					}
				}
				&.nav-list {
					.nav-submenu-active &{
						a {
							.icon-arrow{
								transform: rotateZ(180deg);
							}
						}
					}
				}
			}
		}
	}
}

.nav-box__link-line{
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		justify-content: flex-start;
	}
	&.link-line-green {
		a.lang.active {
			color: #72D19C !important;
		}
	}
	.nav-box__link-group{
		display: flex;
		align-items: center;
		&:first-child{
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				margin-right: 4.5rem;
			}
		}
		a{
			&:not(:last-child){
				margin-right: 1.1rem;
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					margin-right: 2rem;
				}
			}
		}
	}
}




.nav-list-box__content{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 110.8rem;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

.nav-list-box__content-action{
	display: none;
	margin-bottom: 1rem;
	@media ( max-width: $sw-xs-max) {
		display: block;
	}
}

.nav-list-box__content-title{
	display: none;
	font-weight: 900;
	font-size: 3.2rem;
	line-height: 3.4rem;
	padding-left: 2.5rem;
	margin-bottom: 5.3rem;
	@media ( max-width: $sw-xs-max) {
		display: block;
	}
	@media ( max-width: 360px) {
		padding-left: 0;
	}
}

.nav-list-box__content-items{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.nav-list-box__content-item{
	width: 33.33333%;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	@media ( max-width: 1280px) {
		width: 50%;
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.6rem;
		padding-right: 2.6rem;
	}
	@media ( max-width: 360px) {
		padding-left: 0;
		padding-right: 0;
	}

	@media (min-width: 1281px) {
		&:nth-child(n+4){
			margin-top: 8.4rem;
		}
	}

	@media ( min-width: $sw-md-min) and (max-width: 1280px) {
		&:nth-child(n+3){
			margin-top: 8.4rem;
		}
	}
	@media ( max-width: $sw-sm-max) {
		&:nth-child(n+2){
			margin-top: 6.4rem;
		}
	}

}



.conference-nav-box{
	display: flex;
	align-items: flex-start;
}

.conference-nav-box__view{
	width: 3.1rem;
	height: 3.1rem;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	border-radius: 50%;
	margin-right: 2.4rem;
	i{
		display: block;
		position: absolute;
		&.main{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
		}
		&.minor{
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
			width: 1.1rem;
		}
	}
}

.conference-nav-box__detail{

}

.conference-nav-box__title{
	display: flex;
	align-items: center;
	margin-bottom: .4rem;
	i{
		font-weight: 900;
		font-size: 2.4rem;
		font-style: normal;
		line-height: 2.8rem;
	}
	b{
		font-size: 1rem;
		margin-left: .5rem;
		line-height: 1.1rem;
		text-transform: uppercase;
	}
}

.conference-nav-box__name{
	font-weight: 900;
	font-size: 2.4rem;
	line-height: 2.8rem;
}

.conference-nav-box__time{
	font-weight: 500;
	font-size: 1.6rem;
	margin-top: .9rem;
	line-height: 2.2rem;
}





.nav-box__copywriting{
	color: $boulder;
	font-size: 1.4rem;
	line-height: 1.6rem;
}

.nav-box__social{
	margin-top: 1.6rem;
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
				@media ( max-width: $sw-xs-max) {
					margin-right: 1.5rem;
				}
			}
			a{
				display: block;
				svg{
					fill: $cerule;
					font-size: 2.4rem;
					@media ( max-width: $sw-lg-max) {}
					@media ( max-width: $sw-md-max) {}
					@media ( max-width: $sw-sm-max) {}
					@media ( max-width: $sw-xs-max) {
						font-size: 4rem;
					}
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-.4rem);
					}
				}
			}
		}
	}
}