.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: top;
	display: inline-block;
}
.icon {
	width: 1em;
	height: 1em;
	stroke-width: 0;
	fill: currentColor;
	stroke: currentColor;
	display: inline-block;
	&.icon-quote-rus{
		height: 0.7111111111111111em;
	}
	&.icon-quote-eng{
		height: 0.6274509803921569em;
	}
	&-logo{
		height: 0.3950617283950617em;
	}
}