.section-main-screen{
	height: 100vh;
	overflow: hidden;
	background-color: $cerulean;
}

.main-screen__wrapper{
	padding-top: 32vh;
	@media ( max-width: $sw-lg-max) {
		padding-top: 28vh;
	}
	@media ( max-width: $sw-sm-max) {
		padding-top: 36vh;
	}
	@media ( max-width: $sw-xs-max) {
		padding-top: 24vh;
	}
}

.main-screen__content{
	position: relative;
	padding-right: 22rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		padding-right: 18rem;
	}
	@media ( max-width: $sw-xs-max) {
		padding-right: 0;
	}
}

.main-screen__title.h-reset.section-title{
	z-index: 1;
	height: 25.6rem;
	position: relative;
	align-items: center;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		height: 17.6rem;
	}
	@media ( max-width: $sw-xs-max) {
		height: auto;
	}
	h2{
		font-size: 5.6rem;
		line-height: 6.4rem;
		@media ( max-width: $sw-sm-max) {
			font-size: 4.2rem;
			line-height: 4.4rem;
		}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
		@media ( max-width: 360px) {
			font-size: 3rem;
			line-height: 3.2rem;
		}
	}
}

.main-screen__view{
	top: 50%;
	left: 50%;
	width: 100rem;
	height: 100rem;
	display: block;
	position: absolute;
	border-radius: 50%;
	border: .3rem solid $white;
	transform: translate(-68%, -60%) rotateZ(39deg);
	@media ( max-width: $sw-sm-max) {
		width: 80rem;
		height: 80rem;
		transform: translate(-65%, -52%) rotateZ(30deg);
	}
	@media ( max-width: $sw-xs-max) {
		width: 60.1rem;
		height: 60.1rem;
		transform: translate(-75%, -55%) rotateZ(35deg);
	}
	i{
		top: 50%;
		left: 50%;
		height: 1px;
		width: 50rem;
		display: block;
		position: absolute;
		transform-origin: 0 0;
		background-color: transparent;
		@media ( max-width: $sw-sm-max) {
			width: 40rem;
		}
		@media ( max-width: $sw-xs-max) {
			width: 30.05rem;
		}
		&:after{
			top: -1rem;
			z-index: 1;
			content: '';
			width: 2rem;
			height: 2rem;
			right: -1rem;
			border-radius: 50%;
			position: absolute;
			background-color: $white;
		}
	}
}

.main-screen__more{
	top: 50%;
	left: 50%;
	z-index: 1;
	width: 100rem;
	display: flex;
	height: 100rem;
	position: absolute;
	border-radius: 50%;
	align-items: center;
    justify-content: flex-end;
	border: .3rem solid transparent;
	transform: translate(-68%, -60%) rotateZ(39deg);
	@media ( max-width: $sw-sm-max) {
		width: 80rem;
		height: 80rem;
		transform: translate(-65%, -52%) rotateZ(30deg);
	}
	@media ( max-width: $sw-xs-max) {
		width: 60.1rem;
		height: 60.1rem;
		transform: translate(-75%, -55%) rotateZ(35deg);
	}
	a{
		position: relative;
		margin-top: -2.6rem;
		margin-right: -2.6rem;
		transform: rotateZ(-39deg);
		transform-origin: 100% 50%;
		@media ( max-width: $sw-sm-max) {
			transform: rotateZ(-30deg);
		}
		@media ( max-width: $sw-xs-max) {
			transform: rotateZ(-35deg);
		}
		i{
			width: 5.2rem;
			height: 5.2rem;
			position: relative;
			border-radius: 50%;
			margin-left: 1.8rem;
			background-color: $white;
			svg{
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				fill: $cerulean;
				position: absolute;
			}
		}
	}
}