.section-profile {
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        margin-right: -3rem;
        margin-bottom: 2rem;
        @media (max-width: $sw-md-max) {
            margin-top: 2.5rem;
            margin-bottom: 1.5rem;
            margin-right: -2.5rem;
        }
        @media (max-width: $sw-xs-max) {
            margin-right: 0;
        }
    }
    &__block {
        background-color: #fff;
        padding: 23px 25px;
        /* min-height: 390px; */
        width: calc((100% - 90px) / 3);
        margin-right: 3rem;
        /* margin-bottom: 1.5rem; */
        @media (max-width: $sw-md-max) {
            margin-right: 2.5rem;
            min-height: 350px;
            width: calc((100% - 75px) / 3);
        }
        @media (max-width: $sw-sm-max) {
            width: calc((100% - 50px) / 2);
            min-height: auto;
            padding: 20px;
        }
        @media (max-width: $sw-xs-max) {
            padding: 15px 0 10px 0;
            width: 100%;
            margin-right: 0;
        }
        &.block_sm {
            min-height: auto;
            padding: 55px 25px 70px 25px;
        }
        &.profile__block-read-more {
            background-color: #98CB4E;
            padding: 55px 25px 23px 25px;
            .link {
                margin-top: 3.8rem;
            }
        }
        .conf-dot {
            margin-bottom: 1.5rem;
            width: 16px;
            height: 16px;
            @media (max-width: $sw-xs-max) {
                width: 12px;
                height: 12px;
            }
        }
    }
    &__title {
        p {
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.38;
            margin-bottom: 15px;
            @media (max-width: $sw-md-max) {
                font-size: 1.9rem;
            }
        }
    }
    &__text {
        p {
            font-size: 1.6rem;
            line-height: 1.72;
            margin-bottom: 2rem;
        }
    }
    &__img {
        width: 100%;
        height: 17.8rem;
        margin-bottom: 1.5rem;
    }
}