.footer{
	padding-top: 6.6rem;
	padding-left: 22.8rem;
	padding-bottom: 11.5rem;
	background-color: $cerulean;
	@media ( max-width: $sw-lg-max) {
		padding-left: 0;
	}
	@media ( max-width: $sw-xs-max) {
		padding-top: 3.7rem;
		padding-bottom: 6.6rem;
	}

	&_conference{
		padding-top: 4.8rem;
		padding-bottom: 7.6rem;
		background-color: $wildSand;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			padding-top: 8.8rem;
			padding-bottom: 7.8rem;
		}
		.footer__wrapper{
			flex-direction: column;
		}
	}
}

.footer__wrapper{
	display: flex;
	align-content: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.footer__subscribe{
	max-width: 30rem;
	@media ( max-width: $sw-xs-max) {
		max-width: 100%;
	}
}

.footer__subscribe-title{
	color: $white;
	font-size: 2rem;
	font-weight: 900;
	line-height: 2.2rem;
	text-transform: uppercase;
}

.footer__subscribe-action{
	margin-top: 3rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 1.9rem;
	}
}

.footer__nav{
	font-size: 1.6rem;
	min-width: 32.7rem;
	padding-top: .8rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-sm-max) {
		min-width: auto;
	}
	@media ( max-width: $sw-xs-max) {
		padding: 0;
		margin-top: 5.3rem;
	}
	ul{
		li{
			&:not(:last-child){
				margin-bottom: .9rem;
				@media ( max-width: $sw-xs-max) {
					margin-bottom: .5rem;
				}
			}
			&.copywriting{
				display: none;
				@media ( max-width: $sw-xs-max) {
					display: block;
					margin-top: 3.8rem;
					margin-bottom: 1.1rem;
				}
			}
		}
	}
}

.footer__social{
	display: none;
	margin-top: 7rem;
	@media ( max-width: $sw-xs-max) {
		display: block;
	}
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: 1.3rem;
			}
			a{
				display: block;
				svg{
					fill: $white;
					font-size: 4rem;
				}
			}
		}
	}
}













.footer__wrapper-about-line{
	display: flex;
	align-items: flex-start;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {}
}

.footer__reporter{
	width: 34%;
	font-size: 1.6rem;
	position: relative;
	line-height: 2.2rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		padding-right: 0;
		min-height: 12rem;
		padding-top: 1rem;
		padding-left: 14.4rem;
	}
	@media ( max-width: $sw-xs-max) {
		padding-top: 2rem;
		padding-left: 11.7rem;
	}
}

.footer__reporter-pic{
	display: block;
	width: 15.4rem;
	height: 15.4rem;
	overflow: hidden;
	border-radius: 50%;
	margin-bottom: 1.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		top: 0;
		left: 0;
		margin: 0;
		width: 12rem;
		height: 12rem;
		position: absolute;
	}
	@media ( max-width: $sw-xs-max) {
		width: 9.3rem;
		height: 9.3rem;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
	}
}

.footer__reporter-name{
	line-height: 1.8rem;
	text-transform: uppercase;
}

.footer__reporter-quote{
	margin-top: .4rem;
	padding-right: 38%;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-right: 15%;
	}
}

.footer__reporter-contacts{
	padding-top: .3rem;
	padding-left: .3rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 0;
	}
	ul{
		li{
			a{

			}
		}
	}
}


.footer__conference-additional{
	width: 66%;
	padding-top: 2.7rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
		padding-top: 0;
		margin-top: 4.6rem;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 6.6rem;
	}
}

.footer__conference-options{
	display: flex;
	align-items: flex-start;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.footer__conference-option{
	width: 50%;
	font-size: 1.6rem;
	position: relative;
	line-height: 2.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		min-height: 7.2rem;
		padding-top: 0.8rem;
		padding-left: 11.7rem;
		&:not(:last-child){
			margin-bottom: 5.5rem;
		}
	}
}

.footer__conference-option-icon{
	margin-bottom: 1.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		top: 0;
		left: 0;
		margin-bottom: 0;
		position: absolute;
	}
	svg{
		font-size: 7.2rem;
	}
}

.footer__conference-option-name{

}

.footer__conference-option-action{
	margin-top: .5rem;
}


.footer__conference-social{
	font-size: 1.6rem;
	line-height: 2.2rem;
	margin-top: 5.5rem;
}

.footer__conference-social-title{
	margin-bottom: .6rem;
}

.footer__conference-social-list{
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
			}
			a{
				display: block;
				position: relative;
				svg{
					font-size: 2.8rem;
					
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-.4rem);
					}
				}
			}
		}
	}
}

.footer__wrapper-nav-line{
	display: flex;
	font-size: 1.6rem;
	margin-top: 4.9rem;
	line-height: 2.2rem;
	align-items: flex-end;
	@media ( max-width: $sw-xs-max) {
		margin-top: 8.7rem;
		flex-direction: column;
	}
}

.footer__conference-nav{
	width: 34%;
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}
	ul{
		li{
			&:not(:last-child){
				margin-bottom: .5rem;
			}
		}
	}
}

.footer__conference-manufacturer{
	width: 66%;
	color: #000000;
	a{
		color: #000000;
		text-decoration: none;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		margin-top: 2.5rem;
	}
}