.hotel-carousel {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    @media (max-width: $sw-sm-max) {
        height: auto;
    }
    &__section {
        background-color: #000000;
        padding: 21rem 0 6rem 0;
        position: relative;
        overflow: hidden;
        @media (max-width: $sw-sm-max) {
            padding: 5.3rem 0 0 0;
        }
        .container {
            @media (max-width: $sw-sm-max) {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__info {
        max-width: 52rem;
        color: #fff;
        position: relative;
        z-index: 3;
        @media (max-width: $sw-sm-max) {
            max-width: 73.8rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin: 0 auto;
        }
        @media (max-width: $sw-xs-max) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }
        .info {
            &-icon {
                width: 100%;
                margin-bottom: 2rem;
                svg {
                    font-size: 7.1rem;
                }
            }
            &-title {
                width: 100%;
                margin-bottom: 1rem;
            }
            &-subtitle {
                width: 100%;
                text-transform: uppercase;
                margin-bottom: .5rem;
            }
            &-addredd {
                width: 100%;
                margin-bottom: 4rem;
            }
        }
    }
    &__wrapper {
        width: 67rem;
        height: 67rem;
        border-radius: 50%;
        position: absolute;
        top: -19rem;
        right: -10rem;
        z-index: 2;
        @media (max-width: $sw-sm-max) {
            position: relative;
            border-radius: 0;
            top: auto;
            right: auto;
            width: 100%;
            height: auto;
            margin-top: 7rem;
            background-color: $wildSand;
        }
    }
    &__item {
        width: 67rem;
        height: 67rem !important;
        border-radius: 50%;
        overflow: hidden;
        @media (max-width: $sw-sm-max) {
            border-radius: 0;
            width: 37.6rem;
            height: 37.6rem !important;
        }
        img {
            border-radius: inherit;
        }
    }
    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3.2rem;
    }
    &__action {
        opacity: 1;
        transition: opacity .3s ease;
        @media (max-width: $sw-sm-max) {
            display: none;
        }
        button {
            display: flex;
            align-items: center;
        }
        &.slick-disabled {
            opacity: 0.5;
        }
        &.hotel-prev {
            transform: rotate(180deg);
            margin-right: 3.3rem;
        }
        &.hotel-next {
            margin-left: 3.3rem;
        }
        .icon-arrow {
            fill: #fff;
            font-size: 1.6rem;
        }
    }
    &__dots {
        li.slick-active button {
            background-color: $orange;
        }
    }
}