.conference-hero {
	width: 100%;
	min-height: 100vh;
	position: relative;
	transition: background-color .3s ease;
	padding-top: 7rem;
	padding-bottom: 7rem;
	&:after {
			content: '';
			width: 24%;
			height: 100%;
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			transition: background-color .3s ease;
			display: none;
	}
	@media (max-width: $sw-lg-max) {
			padding-top: 12rem;
	}
	@media ( max-width: $sw-sm-max) {
			&:after {
					display: block;
			}
	}
	@media (max-width: $sw-xs-max) {
			padding-top: 3rem;
	}
	&.orange {
			background-color: $orange;
			&:after {
					background-color: #bababa;
			}
	}
	&.green {
			background-color: #98CB4E;
			&:after {
					background-color: #bababa;
			}
	}
	&__links {
			position: absolute;
			top: 3rem;
			right: 3rem;
			z-index: 2;
			@media (max-width: $sw-sm-max) {
					display: none;
			}
			.link {
					text-transform: uppercase;
					& + .link {
							margin-left: 8px;
					}
			}
	}
	&__logo {
			margin-bottom: 6rem;
			position: relative;
			display: inline-block;
			z-index: 2;
			@media (max-width: $sw-xs-max) {
					margin-bottom: 7.5rem;
			}
			@media (max-width: $sw-xx-max) {
					margin-bottom: 4rem;
			}
			.icon-logo {
					font-size: 15rem;
					color: #fff;
			}
	}
	&__info {
			max-width: 76.5rem;
			color: #fff;
			transition: color .3s ease;
			display: flex;
			flex-wrap: wrap;
			@media (max-width: $sw-lg-max) {
					max-width: 70rem;
			}
			@media ( max-width: $sw-md-max) {
					max-width: 46rem;
			}
			@media (max-width: $sw-sm-max) {
					max-width: 100%;
			}
			.hero-title {
					text-transform: uppercase;
					font-size: 5.2rem;
					line-height: 6.4rem;
					margin-top: 0;
					margin-bottom: 4rem;
					position: relative;
					z-index: 2;
					@media ( max-width: $sw-md-max) {
							font-size: 4.3rem;
							line-height: 4.8rem;
					}
					@media ( max-width: $sw-sm-max) {
							font-size: 3.8rem;
							line-height: 4.4rem;
							margin-top: 38rem;
							color: #000;
					}
					@media (max-width: $sw-xs-max) {
							font-size: 3.2rem;
							line-height: 3.4rem;
							margin-top: 43rem;
					}
			}
			.conference-nav-box {
					z-index: 2;
					width: auto;
					@media (max-width: $sw-sm-max) {
							position: absolute;
							top: 21rem;
							flex-wrap: wrap;
							max-width: 36rem;
					}
					@media (max-width: $sw-xs-max) {
							top: 14rem;
					}
					.link {
							display: none;
							margin-top: 2rem;
							text-transform: uppercase;
							@media (max-width: $sw-sm-max) {
									display: inline-flex;
							}
					}
			}
			.leaders-box__action {
					display: none;
					margin-top: 0;
					z-index: 1;
					@media (max-width: $sw-sm-max) {
							display: block;
					}
					a.link_icon-descript i {
							width: 4.8rem;
							height: 4.8rem;
							.icon-big-play {
									fill: #000;
									stroke: #000;
									color: $orange;
									font-size: 4.8rem;
							}
					}
			}
			.conference-nav-box__detail {
					max-width: 36rem;
					@media (max-width: $sw-sm-max) {
							max-width: 100%;
					}
			}
			.conference-nav-box__view {
					display: none;
					@media (max-width: $sw-sm-max) {
							display: block;
							margin-bottom: 1.5rem;
					}
			}
			.conference-nav-box__time {
					margin-top: 2.4rem;
					font-size: 2rem;
					line-height: 2.2rem;
					text-transform: uppercase;
					font-weight: 900;
			}
	}
	&__image {
			border-radius: inherit;
			width: 100%;
			height: 100%;
			img {
					border-radius: inherit;
					-webkit-filter: grayscale(100%);
    				filter: grayscale(100%);
			}
			&-wrap {
					width: 99.7rem;
					height: 99.7rem;
					border-radius: 50%;
					position: absolute;
					top: -21.2rem;
					right: -21.2rem;
					z-index: 1;
					@media (max-width: $sw-lg-max) {
							top: -26.2rem;
							right: -26.2rem;
					}
					@media (max-width: $sw-md-max) {
							top: -30rem;
							right: -35rem;
					}
					@media (max-width: $sw-sm-max) {
							top: -51rem;
							right: -5rem;
							width: 110rem;
							height: 110rem;
					}
					@media (max-width: $sw-xs-max) {
							width: 105rem;
							height: 105rem; 
					}
					@media (max-width: $sw-xx-max) {
							top: -41.2rem;
							right: -12rem;
							width: 95rem;
							height: 95rem;
					}
					&:after {
							content: '';
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							border-radius: inherit;
							background-color: rgba(0,0,0,.4);
					}
					.icon-big-play {
							font-size: 4.8rem;
							fill: #fff;
							stroke: #fff;
							color: $orange;
					}
					.link_icon-descript {
							color: #fff;
							span {
									margin-left: 1.6rem;
							}
							&:hover {
									@media (min-width: $sw-md-min) {
											.icon-big-play {
													font-size: 5.2rem;
											}
									}
							}
					}
					.leaders-box__action {
							position: absolute;
							top: 697px;
							left: 46px;
							width: calc(99.7rem / 2);
							z-index: 20;
							@media (max-width: $sw-sm-max) {
									display: none;
							}
					}
			}
	}
}