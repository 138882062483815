.partnering {
    &__top-block {
        min-height: 69rem;
        width: calc(100% + 200px);
        margin-left: -100px;
        border-radius: 0 0 990px 990px / 0 0 495px 495px;
        overflow: visible !important;
        display: flex;
        padding: 8rem 0 0 0;
        position: relative;
        @media (max-width: $sw-lg-max) {
            padding: 13rem 0 0 0;
            min-height: 70vh;
            align-items: center;
            border-radius: 0 0 1200px 1200px / 0 0 450px 450px;
        }
        @media (max-width: $sw-md-max) {
            padding: 7rem 0 0 0;
        }
        @media (max-width: $sw-sm-max) {
            border-radius: 0 0 1140px 1140px / 0 0 570px 570px;
            min-height: 49.5rem;
        }
        .container {
            @media (max-width: $sw-sm-max) {
                max-width: calc(100% - 240px);
            }
            @media (max-width: $sw-xs-max) {
                max-width: calc(100% - 200px);
            }
        }
        img {
            border-radius: inherit;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background-color: rgba(0,0,0,.3);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
    }
    &__top-text {
        display: block;
        width: 100%;
        margin-bottom: 5rem;
        @media (max-width: 1700px) {
            margin-bottom: 6rem;
        }
        @media (max-width: 1550px) {
            margin-bottom: 7.2rem;
        }
        @media (max-width: $sw-lg-max) {
            margin-bottom: 13rem;
        }
        .big {
            margin-bottom: 5.3rem;
            position: relative;
            z-index: 3;
            color: #fff;
        }
    }
    &__title {
        font-size: 5.6rem;
        line-height: 6.4rem;
        text-transform: uppercase;
        position: relative;
        color: #fff;
        z-index: 3;
        margin-bottom: 3.3rem;
        @media (max-width: $sw-xs-max) {
            font-size: 4rem;
            line-height: 5rem;
        }
    }
    &__info {
        &-wrap {
            padding: 6rem 0 8rem 0;
            .partnering__info-contact {
                .people-box__details {
                    padding-left: 0;
                }
                .people-box__name {
                    min-height: auto;
                }
                .people-box__pic {
                    @media (max-width: $sw-xs-max) {
                        position: relative;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        &-content {
            display: flex;
            align-items: flex-start;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        &-box {
            width: calc((100% - 32rem) / 2);
            padding-right: 2.5rem;
            @media (max-width: $sw-sm-max) {
                width: 47%;
                padding-right: 0;
                margin-bottom: 3rem;
            }
            @media (max-width: $sw-xs-max) {
                width: 100%;
            }
        }
        &-contact {
            width: 24rem;
            margin-left: 8rem;
            padding-top: 9.5rem;
            @media (max-width: $sw-sm-max) {
                width: 100%;
                margin-left: 0;
                padding-top: 0;
            }
            .big {
                font-weight: 500;
                margin-bottom: 1.5rem;
            }
            
        }
    }
    &__view {
        &-section {
            padding-top: 9.5rem;
            background-color: #fff;
            @media (max-width: $sw-xs-max) {
                padding-top: 7rem;
            }
            &.section-dark {
                background-color: #F5F5F5;
            }
            .section-title {
                margin-bottom: 7.5rem;
                @media (max-width: $sw-xs-max) {
                    margin-bottom: 6rem;
                }
                & + .section-subtitle {
                    margin-top: -5.5rem;
                    @media (max-width: $sw-xs-max) {
                        margin-top: -4rem;
                    }
                }
            }
            .section-subtitle {
                max-width: 50rem;
                margin-bottom: 7rem;
            }
        }
        &-box {
            width: 50%;
            min-height: 49.3rem;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            vertical-align: top;
            @media (max-width: $sw-md-max) {
                min-height: 46rem;
            }
            @media (max-width: $sw-sm-max) {
                width: 100%;
                min-height: 40rem;
            }
            @media (max-width: $sw-xs-max) {
                min-height: 35rem;
            }
            &.text-box {
                padding: 1rem 15rem 1rem 10rem;
                @media (max-width: $sw-md-max) {
                    padding: 1rem 6rem;
                }
                @media (max-width: $sw-sm-max) {
                    order: 0;
                    min-height: auto;
                    padding: 5rem;
                    text-align: center;
                }
            }
            &.image-box {
                @media (max-width: $sw-sm-max) {
                    order: 1;
                }
            }
            &.dark {
                background-color: #000;
                color: #fff;
            }
            &.silver {
                background-color: #F5F5F5;
                color: #000;
            }
            &.blue {
                background-color: #009BD7;
                color: #000;
            }
            &-wrap {
                display: flex;
                flex-wrap: wrap;
            }
            .view-box-title {
                width: 100%;
                margin-bottom: 2rem;
                margin-top: 0;
            }
            .view-box-text {
                margin-bottom: 0;
                & + .view-box-text {
                    margin-top: 2.8rem;
                }
            }
        }
    }
    &__contact {
        &-section {
            padding: 10.7rem 0 15.5rem 0;
            @media (max-width: $sw-lg-max) {
                padding: 10rem 0;
            }
            @media (max-width: $sw-sm-max) {
                padding: 9rem 0;
            }
            @media (max-width: $sw-xs-max) {
                padding: 7rem 0;
            }
            .section-title {
                padding-right: 3.2rem;
                @media (max-width: $sw-sm-max) {
                    padding-right: 0;
                    margin-bottom: 6rem;
                }
                @media (max-width: $sw-xs-max) {
                    margin-bottom: 4.5rem;
                }
            }
            .partnering__info-contact {
                width: 41.4rem;
                margin-left: 0;
                padding-top: 0;
                flex-shrink: 0;
                @media (max-width: $sw-sm-max) {
                    width: 100%;
                }
            }
        }
        &-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
            }
        }
    }
}

.info-box {
    &__icon {
        min-height: 7.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2.5rem;
        .icon-chat {
            font-size: 7.2rem;
        }
        .icon-sun {
            font-size: 6.6rem;
        }
    }
    &__title {
        text-transform: uppercase;
        margin-bottom: 3rem;
    }
}