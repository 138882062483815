.vacancies {
    &-section {
        background-color: #fff;
        padding: 6.5rem 0 0 0;
        @media (max-width: $sw-xs-max) {
            padding: 4.7rem 0 0 0;
        }
        .section-title {
            @media (max-width: $sw-xs-max) {
                display: none;
            }
        }
    }
    &-content {
        margin-top: 11.2rem;
        @media (max-width: $sw-xs-max) {
            margin-top: 0;
        }
        .section-title {
            margin-bottom: 4.6rem;
            @media (max-width: $sw-xs-max) {
                display: flex;
                margin-bottom: 3rem;
            }
        }
    }
    &-block {
        min-height: 17rem;
        padding: 2.8rem 3.2rem 3.4rem 3.2rem;
        background-color: $wildSand;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4px;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
            margin-bottom: 1.6rem;
            padding: 2.4rem;
        }
        &__main-side, &__info-side {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            /* justify-content: space-between; */
            @media (max-width: $sw-sm-max) {
                display: block;
            }
        }
        &__main-side {
            width: 41%;
            @media (max-width: $sw-sm-max) {
                width: 100%;
            }
        }
        &__info-side {
            width: 59%;
            padding-left: 1rem;
            @media (max-width: $sw-sm-max) {
                width: 100%;
                padding-left: 0;
            }
        }
        &__title {
            margin-bottom: 1.1rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 1rem;
            }
            h4 {
                margin-top: 0;
                margin-bottom: 0;
                @media (max-width: $sw-md-max) {
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                }
                @media (max-width: $sw-xs-max) {
                    font-size: 2rem;
                    line-height: 2.2rem;
                }
            }
        }
        &__descr {
            margin-bottom: 3.1rem;
            @media (max-width: $sw-sm-max) {
                margin-bottom: 3.9rem;
            }
            .small {
                margin-bottom: 0;
            }
        }
        &__location {
            @media (max-width: $sw-sm-max) {
                margin-bottom: 2.7rem;
            }
            .small {
                margin-bottom: 0;
            }
        }
        &__action {
            display: flex;
            align-items: center;
            @media (max-width: $sw-sm-max) {
                justify-content: space-between;
            }
            &.active {
                .link-get-info {
                    svg {
                        transform: rotate(270deg);
                        animation: none;
                    }
                }
                .link_form {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .link-get-info {
                margin-right: 4.4rem;
                @media (max-width: $sw-sm-max) {
                    margin-right: 3rem;
                }
            }
            .link_form {
                transition: opacity .3s ease, visibility .3s ease;
                opacity: 1;
                visibility: visible;
            }
        }
        &__dropdown {
            padding: 4.3rem 8.5rem 5.6rem 8.5rem;
            background-color: $wildSand;
            margin-bottom: 3px;
            margin-top: -2px;
            display: none;
            @media (max-width: $sw-md-max) {
                padding: 3.5rem 6rem;
            }
            @media (max-width: $sw-sm-max) {
                margin-top: -1.6rem;
                margin-bottom: 1.6rem;
                padding: 2.4rem;
            }
            @media (max-width: $sw-xs-max) {
                padding: 3.2rem 2.4rem 4.7rem 2.4rem;
            }
            &.active {
                .vacancies-block__dropdown-content {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity .3s ease-in-out .3s, transform .3s ease-in-out .3s;
                }
            }
            &-content {
                opacity: 0;
                transform: translateY(3rem);
                transition: opacity .3s ease-in-out, transform .3s ease-in-out;
            }
            .vacancies-block__side {
                margin-bottom: 4.4rem;
                @media (max-width: $sw-xs-max) {
                    margin-bottom: 5.4rem;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .dropdown-side {
                width: 350px;
                @media (max-width: $sw-sm-max) {
                    width: 100%;
                    margin-bottom: 4rem;
                }
                &:last-child {
                    @media (max-width: $sw-sm-max) {
                        margin-bottom: 0;
                    }
                }
                &:nth-child(odd) {
                    margin-right: 7.5rem;
                    @media (max-width: $sw-sm-max) {
                        margin-right: 0;
                    }
                }
                &__wrap {
                    display: flex;
                    align-items: flex-start;
                    @media (max-width: $sw-sm-max) {
                        flex-wrap: wrap;
                    }
                }
                &__title {
                    text-transform: uppercase;
                    h1,h2,h3,h4,h5,h6 {
                        margin-top: 0;
                        margin-bottom: 1.1rem;
                    }
                }
                &__list {
                    list-style: none;
                    margin: 2.1rem 0 0 0;
                    padding: 0;
                    li {
                        padding-left: 2.4rem;
                        position: relative;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                        &:before {
                            content: '';
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: $cerulean;
                            position: absolute;
                            left: 0;
                            top: 5px;
                        }
                        & + li {
                            margin-top: 2.2rem;
                        }
                    }
                }
            }
        }
    }
}