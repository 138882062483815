.section-question-detail{
	margin-top: 8.1rem;
	margin-bottom: 10.5rem;
	@media ( max-width: $sw-lg-max) {
		margin-top: 4.1rem;
		margin-bottom: 8.2rem;
	}
	@media ( max-width: $sw-xs-max) {
		margin-bottom: 5.9rem;
	}
}

.question-detail__content{
	display: flex;
	margin-top: 5.8rem;
	padding-right: 8.8%;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-sm-max) {
		padding-right: 0;
	}
	@media ( max-width: $sw-xs-max) {
		margin-top: 3.5rem;
		flex-direction: column;
	}
}

.question-detail__contacts{
	width: 44.7%;
	@media ( max-width: $sw-sm-max) {
		width: 48%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}
}

.question-detail__contacts-unit{
	width: 100%;
	&:not(:last-child){
		margin-bottom: 2.4rem;
		padding-bottom: 2.4rem;
		border-bottom: 1px solid $boulderSolid;
	}
}

.question-detail__contacts-unit-title{
	font-size: 1.6rem;
	line-height: 2.2rem;
	margin-bottom: .2rem;
}

.question-detail__contacts-unit-content{
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
}

.question-detail__form{
	width: 35.2%;
	padding-top: 2.7rem;
	@media ( max-width: $sw-sm-max) {
		width: 40%;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		padding-top: 0;
		margin-top: 11.1rem;
	}
}

.question-detail__form-title{
	font-weight: 900;
	font-size: 3.2rem;
	line-height: 3.4rem;
	margin-bottom: 2.8rem;
}