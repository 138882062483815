.section-conference-header{
	padding-top: 2.8rem;
	padding-bottom: 5.5rem;
	@media (max-width: $sw-lg-max) {
		padding-top: 12rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 4rem;
	}
	@media (max-width: $sw-xx-max) {
		padding-top: 2rem;
	}
	&.wildsand {
		background-color: $wildSand;
	}
	.section-title{
		max-width: 60rem;
		&__main{
			text-transform: uppercase;
			width: 100%;
		}
		@media (max-width: $sw-sm-max) {
			max-width: 100%;
			margin-bottom: 3rem;
		}
		@media (max-width: $sw-xs-max) {
			max-width: 100%;
			margin-bottom: 3rem;
		}
		h1 {
			margin-bottom: 0;
			font-size: 5.8rem;
			line-height: 6.4rem;
			@media (max-width: $sw-md-max) {
                font-size: 4.8rem;
                line-height: 5rem;
            }
			@media (max-width: $sw-xs-max) {
                font-size: 3.2rem;
                line-height: 3.4rem;
            }
		}
	}
}

.conference-header__buttons{
	display: flex;
	margin-bottom: 5px;
	align-items: center;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	justify-content: flex-end;
	@media (max-width: $sw-lg-max) {
		margin-bottom: 2rem;
	}
	@media (max-width: $sw-sm-max) {
		display: none;
	}
	&.buttons-equal {
		.link {
			min-width: 21rem;
			& + .link {
				margin-left: 0;
			}
		}
		& + .conference-header__title-content {
			margin-top: -2rem;
			@media (max-width: $sw-lg-min) {
				margin-top: 0;
			}
			.conference-header__title {
				margin-bottom: 2rem;
			}
		}
	}
	.link {
		text-transform: uppercase;
		& + .link {
			margin-left: 8px;
		}
	}
}

.conference-header {
	&__text {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media (max-width: $sw-sm-max) {
			flex-wrap: wrap;
		}
	}
	&__promp {
		max-width: 45rem;
		@media (max-width: $sw-sm-max) {
			max-width: 100%;
			margin-bottom: 2rem;
		}
	}
}