.agenda-ss {
    &__section {
        background-color: $wildSand;
        padding: 8rem 0 7rem 0;
        @media (max-width: $sw-sm-max) {
            padding: 6rem 0 4rem 0;
        }
        .section-title {
            margin-bottom: 4.5rem;
        }
    }
    &__box {
        width: 32.6rem;
        min-height: 27.6rem;
        padding: 2.5rem 2rem 2.5rem 2.5rem;
        background-color: #FFFFFF;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        position: relative;
        @media (max-width: $sw-md-max) {
            width: 30.2rem;
            padding: 2.2rem 1.8rem 2.2rem 2.2rem;
            margin-right: 1.2rem;
            margin-bottom: 1.2rem;
        }
        @media (max-width: $sw-sm-max) {
            width: 26rem;
            padding: 2rem 1.7rem;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
        }
        @media (max-width: $sw-xs-max) {
            width: 23.4rem;
        }
        .conf-dot {
            margin-bottom: 1.5rem;
        }
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-right: -1.5rem;
            @media (max-width: $sw-md-max) {
                margin-right: -1.2rem;
            }
            @media (max-width: $sw-sm-max) {
                margin-right: 0;
            }
        }
        &-title {
            min-height: 7rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            line-height: 2.6rem;
            font-weight: 700;
        }
        &-info {
            @extend .agenda-ss__box;
            background-color: $orange;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: $sw-sm-max) {
                width: 32.6rem;
                min-height: 27.6rem;
                padding: 2.5rem 2rem 2.5rem 2.5rem;
                margin: 2.1rem auto 0 auto;
            }
            @media (max-width: $sw-xx-max) {
                width: 100%;
            }
            .box-info {
                &__title {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}