form{
	&.valid-success, &.valid-success-complete{
		.form-input{
			input, textarea{
				&::placeholder{
					opacity: 0;
				}
			}
		}
	}
}

.form-select {
	&.select-hidden {
		display: none;
  		visibility: hidden;
	}
	&-wrap {
		position: relative;
	}
	&_xxl{
		& + .select-trigger{
			height: 3.6rem;
			line-height: 3.6rem;
		}
	}
}
.select-trigger {
	border-bottom: 1px solid #787878;
	height: 2.6rem;
	font-size: 1.6rem;
	position: relative;
	&:after {
		content: '';
		display: block;
		width: 17.5px;
		height: 15.5px;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTUuNSAxNy45IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNS41IDE3Ljk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMjMxRjIwO308L3N0eWxlPjx0aXRsZT5DNjIzNkE0MS03QkU0LTREOEEtOTAyMy1DOEIwMjA2MTRENEE8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz48ZyBpZD0ic3R5bGVndWlkZSI+PGcgaWQ9InN0eWxlZ3VpZGVfeDJGX2ljb25zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDU4LjAwMDAwMCwgLTMwNS4wMDAwMDApIj48ZyBpZD0iYXJyb3ctdmVydGljYWwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQ2Ni4wMDAwMDAsIDMxNC4wMDAwMDApIHJvdGF0ZSgtMjcwLjAwMDAwMCkgdHJhbnNsYXRlKC00NjYuMDAwMDAwLCAtMzE0LjAwMDAwMCkgdHJhbnNsYXRlKDQ1Ny4wMDAwMDAsIDMwNi4wMDAwMDApIj48cGF0aCBpZD0iYXJyb3dfeDJGX2JsYWNrIiBjbGFzcz0ic3QwIiBkPSJNMTQuMSw3LjNMOC43LDEuOWwxLjQtMS40bDYuNCw2LjRsMS40LDEuNEwxMC4yLDE2bC0xLjQtMS40bDUuMy01LjNIMHYtMkgxNC4xeiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.select-options {
	background-color: #fff;
	box-shadow: 0 4px 10px 0 rgba(0,0,0,0.11);
	list-style: none;
	margin: 0;
	padding: 2rem 0.6rem 1.3rem 0.6rem;
	max-height: 32.7rem;
	overflow-y: auto;
	display: none; 
	position: absolute;
	top: 100%;
	right: auto;
	min-width: 100%;
	left: 0;
	z-index: 999;
	@media (max-width: $sw-sm-max) {
		padding: 1.5rem 1.9rem 1.3rem 1.7rem;
	}
	&.active {
		&:after {
			opacity: 1;
			visibility: visible;
		}
	}
	&:after {
		content: '';
		display: block;
		width: calc(100% - 17px);
		position: absolute;
		left: 0;
		bottom: 0;
		height: 19rem;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease, visibility .3s ease;
		background: linear-gradient(180deg, rgba(32, 100, 219, 0) 0%, #fff 95%);
	}
	li {
		cursor: pointer;
		margin-bottom: 2px;
		font-size: 1.6rem;
		line-height: 2.2rem;
		opacity: 1;
		transition: opacity .3s ease;
		&:not(.selected):hover {
			@media (min-width: $sw-md-min) {
				opacity: 0.6;
			}
		}
		span {
			display: inline-block;
			padding: 3px 1rem;
			border-radius: 1.2rem;
		}
		&.selected {
			span {
				background-color: #E2E2E2;
			}
		}
	}
}

.form-group{
	margin-bottom: 0;
	position: relative;
	&:not(:first-child){
		margin-top: 1.2rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			margin-top: 1.1rem;
		}
	}
	&-descr {
		margin-top: 3px;	
	}
	&-title {
		color: $boulder;
		font-size: 1.6rem;
		line-height: 2.2rem;
		margin-bottom: 0.7rem;
	}
	label{
		margin: 0;
		color: #1c1c1c;
		cursor: pointer;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 1.8rem;
		margin-bottom: .6rem;
		&.invalid{
			margin: 0;
			color: red;
			font-weight: 400;
			font-size: 1.4rem;
			margin-top: .5rem;
			line-height: 1.4rem;
		}
	}
	&.button-group{
		margin-top: 30px;
	}
	&.caption-group{
		font-size: 12px;
		margin-top: 16px;
		line-height: 16px;
		text-align: center;
		&_md{
			font-size: 1.4rem;
			line-height: 2rem;
		}
		&_lg{
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
		&_grey{
			opacity: .5;
		}
	}
	.input-file{
		label{
			input{
				display: none;
			}
		}
		&__output{
			display: none;
			color: #70787f;
			font-size: 1.3rem;
			margin-top: .8rem;
			line-height: 1.6rem;
			align-items: flex-start;
			svg{
				fill: #eb50a3;
				flex-shrink: 0;
				font-size: 2.2rem;
				margin-right: 1rem;
			}
			span{
				display: block;
				padding-top: .2rem;
			}
		}
		&.active{
			.input-file__output{
				display: flex;
			}
		}
	}
	& + .form-group_submit{
		margin-top: 2.8rem;
	}
	&.form-file {
		// margin-top: -1rem !important;
		input {
			display: none;
		}
		label {
			display: flex;
			justify-content: space-between;
		}
	}
	&_xl{
		&:not(:first-child){
			margin-top: 2rem;
		}	
	}
	&_xxl{
		&:not(:first-child){
			margin-top: 2.2rem;
		}
		& + .form-group_submit{
			margin-top: 6.8rem;
		}
	}



	&.form-file{
		input{
			display: none;
		}
		label{
			margin: 0;
			>span{
				text-transform: uppercase;
				span{

				}
			}
		}
	}
}

.form-file__caption{
	font-size: 1.4rem;
	margin-top: .9rem;
	line-height: 1.6rem;
}

.form-input{
	input,textarea{
		width: 100%;
		border: none;
		outline: none;
		height: 2.2rem;
		padding-left: 0;
		padding-right: 0;
		border-radius: 0;
		overflow: hidden;
		font-size: 1.6rem;
		text-overflow: ellipsis;
		transition: all 0.3s ease;
		border-bottom: 1px solid $boulder;
		&::-webkit-input-placeholder {
			transition: opacity 0.3s ease;
		}
		&:-moz-placeholder {
			transition: opacity 0.3s ease;
		}
		&::-moz-placeholder {
			transition: opacity 0.3s ease;
		}
		&:-ms-input-placeholder {  
			transition: opacity 0.3s ease;
		}
		&::placeholder{
			color: $boulder;
		}
		&:focus::-webkit-input-placeholder{
			opacity: 0;
		}
		&:focus::-moz-placeholder{
			opacity: 0;
		}
		&:focus:-moz-placeholder{
			opacity: 0;
		}
	}

	textarea{
		padding: 0;
		resize: none;
		padding-top: .6rem;
		min-height: 15.7rem;
		padding-bottom: .6rem;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none; 
		margin: 0; 
	}

	&_blue{

	}

	&.invalid{
		input{
			border-bottom: 1px solid $alizarinCrimson;
		}
	}


	&__value{
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		font-size: 1.6rem;
		position: absolute;
		line-height: 2.2rem;
	}

	&__placeholder{
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		color: $boulder;
		font-size: 1.6rem;
		visibility: hidden;
		position: absolute;
		line-height: 2.2rem;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&_xxl{
		input{
			height: 3.6rem;
		}
		.form-input__value{
			line-height: 3.6rem;
		}
		.form-input__placeholder{
			line-height: 3.6rem;
		}
	}

	&_xl{
		input{
			height: 3.4rem;
		}
		.form-input__value{
			line-height: 3.4rem;
		}
		.form-input__placeholder{
			line-height: 3.4rem;
		}
	}
	&_md{
		input{
			height: 3rem;
		}
		.form-input__value{
			line-height: 3rem;
		}
		.form-input__placeholder{
			line-height: 3rem;
		}
	}
	&_sm{
		input{
			height: 2.8rem;
		}
		.form-input__value{
			line-height: 2.8rem;
		}
		.form-input__placeholder{
			line-height: 2.8rem;
		}
	}

	&_optional{
		position: relative;
		&:after{
			left: 0;
			top: 100%;
			color: $boulder;
			font-size: 1.4rem;
			margin-top: .3rem;
			position: absolute;
			line-height: 1.4rem;
			content: attr(data-optional);
		}
	}
}




.form-box{
	&__title{
		font-weight: 900;
		font-size: 1.6rem;
		line-height: 1.8rem;
		margin-bottom: 1.6rem;
		text-transform: uppercase;
		@media ( max-width: $sw-xs-max) {
			margin-bottom: 1.9rem;
		}
		&_lg{
			margin-bottom: 2.4rem;
		}
	}
	&__content{
		padding-right: 1.8rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			padding-right: 0;
		}
		.row{
			margin-left: -.9rem;
			margin-right: -.9rem;
			.col{
				padding-left: .9rem;
				padding-right: .9rem;
				&:not(:last-child){
					@media ( max-width: $sw-xs-max) {
						margin-bottom: 1.1rem;
					}
				}
				&_xl{
					&:not(:last-child){
						@media ( max-width: $sw-xs-max) {
							margin-bottom: 3.3rem;
						}
					}
				}
			}
			&:not(:last-child){
				margin-bottom: 2.1rem;
			}
			&_xl{
				&:not(:last-child){
					margin-bottom: 3.3rem;
				}
			}
		}
	}
	&:not(:last-child){
		margin-bottom: 4.6rem;
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			margin-bottom: 6.9rem;
		}
	}
	&_submit{
		@media ( max-width: $sw-lg-max) {}
		@media ( max-width: $sw-md-max) {}
		@media ( max-width: $sw-sm-max) {}
		@media ( max-width: $sw-xs-max) {
			margin-top: -2.7rem;
		}
	}
}


.form-check{
	padding: 0;
	label{
		margin: 0;
		cursor: pointer;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	input{
		display: none;
		&:checked + span{
			&:after{
				border-radius: 50%;
				transform: rotateZ(45deg);
				background-color: $cerulean;
			}
		}
	}
	span{
		display: block;
		position: relative;
		padding-left: 2.6rem;
		&:after{
			left: 0;
			top: .2rem;
			content: '';
			width: 1.6rem;
			height: 1.6rem;
			display: block;
			position: absolute;
			background-color: $alto;
			transition: all 0.3s ease;
		}
	}


	input[type="radio"]{
		display: none;
		&:checked + span{
			&:after{
				transform: none;
				border-radius: 50%;
				background-color: $alto;
			}
			&:before{
				opacity: 1;
			}
		}
		& + span{
			&:after{
				border-radius: 50%;
			}
			&:before{
				top: .6rem;
				opacity: 0;
				z-index: 1;
				left: .4rem;
				content: '';
				width: .8rem;
				height: .8rem;
				display: block;
				border-radius: 50%;
				position: absolute;
				background-color: $orange;
				transition: all 0.3s ease;
			}
		}
	}
	&_orange{
		input{
			display: none;
			&:checked + span{
				&:after{
					background-color: $orange;
				}
			}
		}
	}
}