.section-leaders{
	padding-top: 7.5rem;
	padding-bottom: 5.6rem;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-top: 3.2rem;
		padding-bottom: 6.4rem;
	}
	&.section_pink {
		background-color: $wildSand;
	}
	&.conf-trusted {
		.slick-dots li.slick-active button {
			background-color: $orange;
		}
	}
}

.leaders__content{
	margin-top: 7rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-top: 3.7rem;
	}
}

.leaders__carousel{
	position: relative;
}

.leaders__carousel-items{
}

.leaders__carousel-item{
	padding-right: 4.5rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {
		padding-right: 2.4rem;
	}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {}
}

.leaders-box{
	display: flex;
	align-items: flex-start;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		flex-direction: column;
	}
	@media ( max-width: $sw-xs-max) {}
	&_lg {
		.leaders-box__view {
			width: 15.3rem;
			height: 15.3rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.leaders-box__details {
			padding-top: 6rem;
		}
	}
}

.leaders-box__view{
	width: 6.9rem;
	height: 6.9rem;
	flex-shrink: 0;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 1.5rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 9.2rem;
		height: 9.2rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3.5rem;
	}
	@media ( max-width: $sw-xs-max) {
		width: 6.9rem;
		height: 6.9rem;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		img{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			position: absolute;
		}
	}
}

.leaders-box__details{
	padding-top: .7rem;
}

.leaders-box__name{
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 1.8rem;
	text-transform: uppercase;
}

.leaders-box__position{
	font-size: 1.6rem;
	margin-top: .2rem;
	line-height: 2.2rem;
}

.leaders-box__direction{
	font-weight: 900;
	font-size: 2.4rem;
	margin-top: .3rem;
	line-height: 2.8rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		font-size: 2rem;
		line-height: 2.2rem;
	}
}

.leaders-box__quote{
	font-size: 1.6rem;
	margin-top: 1.7rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		font-size: 2rem;
		margin-top: 2rem;
		line-height: 2.8rem;
	}
}

.leaders-box__action{
	margin-top: 2.6rem;
	@media ( max-width: $sw-sm-max) {
		margin-top: 2.9rem;
	}
}

.leaders__carousel-control{
	display: flex;
	margin-top: 7.9rem;
	align-items: center;
	justify-content: center;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		left: 0;
		right: 0;
		margin: 0;
		top: 3.7rem;
		position: absolute;
		justify-content: space-between;
	}
	@media ( max-width: $sw-xs-max) {
		top: 2.5rem;
	}
}

.leaders__carousel-action, .partner__carousel-action{
	button{
		display: block;
		position: relative;
		transition: all 0.3s ease;
		&:after{
			top: 50%;
			left: 50%;
			content: '';
			width: 3.2rem;
			height: 3.2rem;
			display: block;
			position: absolute;
			border-radius: 50%;
			margin-top: -1.6rem;
			margin-left: -1.6rem;
			background-color: transparent;
		}
		@media ( min-width: $sw-md-min) {
			&:hover{
				transform: translateX(.8rem);
			}
		}
		svg{
			display: block;
			font-size: 1.8rem;
		}
		&.prev{
			svg{
				transform: rotateZ(180deg);
			}
			@media ( min-width: $sw-md-min) {
				&:hover{
					transform: translateX(-.8rem);
				}
			}
		}
	}
}

.leaders__carousel-dots{
	margin-left: 3.2rem;
	margin-right: 3.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		display: none;
	}
	@media ( max-width: $sw-xs-max) {}
}

.slick-dots{
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	li{
		&:not(:last-child){
			margin-right: 1.6rem;
		}
		button{
			font-size: 0;
			width: .8rem;
			height: .8rem;
			display: block;
			border-radius: 50%;
			transition: all 0.3s ease;
			background-color: $silver;
		}
		&.slick-active{
			button{
				background-color: $cerulean;
			}
		}
	}
}