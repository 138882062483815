.exhibition-body {
	padding-bottom: 11rem;
	&__include_content{
		.inner_html{
			padding: 5rem;
			@media (max-width: 1199px){
				padding: 5rem 0 0;;
			}
		}
		overflow: hidden;
		max-height: 0;
		-webkit-transition: all 0.3s ease;
	    -moz-transition: all 0.3s ease;
	    -o-transition: all 0.3s ease;
	    -ms-transition: all 0.3s ease;
	    transition: all 0.3s ease;
	    &.active{
	    	max-height: 1000px;

	    }
	}
	&__row {
		display: flex;
		padding-top: 7.5rem;
		@media (max-width: $sw-md-max) {
			flex-direction: column;
			padding-top: 4rem;
		}
		&-ps {
			padding-top: 2.7rem;
		}
	}
	&__links {
		a {
			padding: 0 6.2rem;
		}
	}
	&__subtitle {
		margin-top: 3.8rem;
		@media (max-width: $sw-xs-max) {
			margin-top: 2rem;
		}
		span {
			font-size: 2.4rem;
			line-height: 2.9rem;
			@media (max-width: $sw-xs-max) {
				font-size: 1.8rem;
			}
		}
	}
	&__stand {
		flex-shrink: 0;
		width: 644px;
		@media (max-width: $sw-md-max) {
			width: 100%;
		}
		&-actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 6rem;
			//margin-left: 5rem;
			//margin-right: 1rem;
			@media (max-width: $sw-md-max) {
				margin-left: 0;
			}
		}
		&-subtitle {
			//margin-left: 4rem;
			@media (max-width: $sw-md-max) {
				margin-left: 0;
			}
		}
		&-img {
			margin-top: 5rem;
			@media (max-width: $sw-md-max) {
				margin-top: 2rem;
			}
		}
		&-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			//margin-left: 4rem;
			//margin-right: 7rem;
			@media (max-width: $sw-md-max) {
				margin-left: 0;
				margin-right: 0;
			}
			h3 {
				font-size: 2.4rem;
				text-transform: uppercase;
				line-height: 2.8rem;
				@media (max-width: $sw-xs-max) {
					font-size: 1.8rem;
				}
			}
			span {
				font-size: 2.4rem;
				text-transform: uppercase;
				line-height: 2.8rem;
				font-weight: 900;
				@media (max-width: $sw-xs-max) {
					font-size: 1.8rem;
				}
			}
		}
		&-subtitle {
			span {
				font-size: 1.6rem;
				line-height: 1.9rem;
				
			}
		}
		&-img {
			height: 252px;
			&-inner {
				height: 100%;
			}
		}
	}
	&__list {
		margin-left: 6.2rem;
		@media (max-width: $sw-md-max) {
			margin-left: 0;
			margin-top: 20px;
		}
		&-title {
			h3 {
				font-size: 2.4rem;
				line-height: 2.8rem;
				text-transform: uppercase;
			}
		}
		&-body {
			margin-top: 40px;
			li {
				font-size: 1.6rem;
				margin-bottom: 31px;
				padding-left: 12px;
				position: relative;
				line-height: 2.4rem;
				@media (max-width: $sw-md-max) {
					margin-bottom: 20px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 5px;
					left: -10px;
					width: 10px;
					height: 10px;
					background: #FE5000;
					border-radius: 50%;
				}
			}
		}
	}
	&__pick {
		&-title {
			h3 {
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
		}
		&-subtitle {
			margin-top: 10px;
			span {
				font-size: 1.6rem;
				line-height: 1.9rem;
				font-weight: 500;
			}
		}
		&-img {
			width: 65.6rem;
			height: 47rem;
			margin-top: 2rem;
			@media (max-width: $sw-md-max) {
				width: 100%;
			}
			@media (max-width: $sw-xs-max) {
				height: 30rem;
			}
			&-inner {
				height: 100%;
			}
		}
	}
	&__help {
		margin-left: 4.9rem;
		margin-top: 11rem;
		@media (max-width: $sw-md-max) {
			margin-left: 0;
			margin-top: 3rem;
		}
		&-title {
			h3 {
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
		}
		&-img {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			overflow: hidden;
			&-inner {
				height: 70px;
			}
		}
		&-name {
			margin-top: 23px;
			span {
				font-weight: 800;
				font-size: 1.6rem;
			}
		}
		&-role {
			span {
				font-weight: 400;
				font-size: 1.6rem;
			}
		}
		&-phone {
			margin-top: 20px;
			span {
				font-size: 1.6rem;
			}
			a {
				font-size: 1.6rem;
				color: black;
				text-decoration: none;
			}
		}
		&-email {
			span {
				font-size: 1.6rem;
				font-weight: bold;
				color: black;
			}
			a {
				font-size: 1.6rem;
				font-weight: 400;
				color: $cerulean;
				text-decoration: none;
			}
		}
	}
}
.exhibition-body__stand-actions .active svg{
	fill: #FC6621;
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
}
.exhibition-body__stand-actions .link_arrow-down:hover svg{
	    -webkit-animation-iteration-count: unset;
    animation-iteration-count: unset;
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
    -webkit-animation-fill-mode: unset;
    animation-fill-mode: unset;
    -webkit-animation-name: unset;
    animation-name: unset;
    -webkit-animation-timing-function: unset;
    animation-timing-function: unset;
}