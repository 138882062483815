.reg-price {
    &__section {
        background-color: $wildSand;
        padding-bottom: 9.7rem;
    }
    &__wrapper {
        display: flex;
        align-items: flex-start;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &__content {
        width: calc(100% - 32.4rem);
        padding-right: 1.6rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 6.5rem;
        }
    }
    &__block {
        margin-bottom: 1.6rem;
        .link {
            text-transform: uppercase;
            &.active {
                .icon-arrow {
                    transform: rotate(270deg);
                    fill: $orange;
                    animation: none;
                }
            }
            & + .link {
                margin-left: 3.5rem;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &-content {
            padding: 3rem;
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            @media (max-width: $sw-xs-max) {
                flex-wrap: wrap;
                padding: 3rem 0 0 0;
            }
        }
        &-dropdown {
            background-color: #fff;
            margin-top: 1px;
            .dropdown-link__wrap {
                display: none;
                padding: 1.8rem 3rem;
                border-top: 1px solid #d8d8d8;
                @media (max-width: $sw-xs-max) {
                    display: block;
                }
            }
        }
        &-list {
            padding: 4.6rem 8.5rem 7.4rem 8.5rem;
            display: none;
            @media (max-width: $sw-sm-max) {
                padding: 3rem 3.3rem;
            }
            @media (max-width: $sw-xs-max) {
                padding: 1rem 3.3rem 3rem 3.3rem;
            }
            .price__block-list {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    position: relative;
                    padding-left: 2.4rem;
                    &:before {
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $orange;
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }
                }
            }
        }
        &-table {
            padding: 5px 3rem 2.5rem 3rem;
            display: none;
            @media (max-width: $sw-xs-max) {
                padding: 0 3rem 2.5rem 3rem;
            }
            .table-row {
                padding: 1.2rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: rgba(0,0,0,.5);
                position: relative;
                width: 100%;
                @media (max-width: $sw-xs-max) {
                    flex-wrap: wrap;
                    padding: 2rem 0;
                }
                &.active {
                    color: #000;
                    &:before {
                        content: '';
                        display: none;
                        width: 1.6rem;
                        height: 1.6rem;
                        border-radius: 50%;
                        background-color: $orange;
                        position: absolute;
                        left: -37px;
                    }   
                    .row-name {
                        &:after {
                            content: 'now';
                            display: inline-block;
                            font-size: 1.6rem;
                            color: $orange;
                            font-weight: 500;
                            margin-left: 1.4rem;
                            position: absolute;
                            right: -35px;
                            top: 1px;
                        }
                    } 
                }
                & + .table-row {
                    border-top: 1px solid #D8D8D8;
                }
            }
            .row {
                &-name {
                    min-width: 15rem;
                    font-size: 2rem;
                    line-height: 2.6rem;
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    &.late-price {
                        color: #000;
                    }
                    @media (max-width: $sw-xs-max) {
                        min-width: auto;
                        width: auto;
                        order: 0;
                    }
                }
                &-time {
                    min-width: 10rem;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    @media (max-width: $sw-xs-max) {
                        min-width: auto;
                        width: 100%;
                        order: 2;
                        margin-top: 1rem;
                    }
                }
                &-sum {
                    min-width: 14.5rem;
                    font-size: 2rem;
                    line-height: 2.6rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    @media (max-width: $sw-xs-max) {
                        min-width: auto;
                        order: 1;
                        width: auto;
                    }
                }
            }
        }
        &-info, &-price {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-info {
            width: calc(100% - 16.5rem);
            @media (max-width: $sw-xs-max) {
                width: 100%;
            }
            .block-info__links {
                @media (max-width: $sw-xs-max) {
                    display: none;
                }
            }
        }
        &-price {
            width: 14.5rem;
            @media (max-width: $sw-xs-max) {
                width: 100%;
            }
            .block-info__top {
                @media (max-width: $sw-xs-max) {
                    display: flex;
                    align-items: center;
                }
                @media (max-width: $sw-xx-max) {
                    flex-wrap: wrap;
                }
            }
            .block-info__links {
                @media (max-width: $sw-xs-max) {
                    margin-top: 6rem;
                    padding-bottom: 1.7rem;
                }
            }
        }
        &-title {
            text-transform: uppercase;
            margin-top: 0;
            @media (max-width: $sw-xs-max) {
                font-size: 2rem;
                line-height: 2.2rem;
                margin-bottom: 1.7;
            }
        }
        &-duration {
            margin-bottom: 0;
        }
        &-conf {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.3rem;
            line-height: 1.5rem;
            @media (max-width: $sw-xs-max) {
                margin-bottom: 5px;
            }
            & + .reg-price__block-duration {
                margin-top: 2.2rem;
            }
        }
        .block-info {
            &__top {
                margin-bottom: 2rem;
                @media (max-width: $sw-xs-max) {
                    margin-bottom: 7px;
                    padding: 0 3rem;
                }
            }
            &__links {
                @media (max-width: $sw-xs-max) {
                    padding: 0 3rem;
                    padding-bottom: 1.7rem;
                }
            }
            &__title {
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: $orange;
                font-weight: 500;
                display: block;
                width: 100%;
                @media (max-width: $sw-xs-max) {
                    display: inline-block;
                    width: auto;
                    order: 1;
                    margin: 0 10px 0 0;
                }
            }
            &__sum {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 2rem;
                line-height: 2.6rem;
                @media (max-width: $sw-xs-max) {
                    display: inline-block;
                    width: auto;
                    order: 0;
                    margin-right: 10px;
                }
            }
            &__time {
                font-size: 1.6rem;
                line-height: 2.2rem;
                @media (max-width: $sw-xs-max) {
                    display: inline-block;
                    width: auto;
                    order: 2;
                }
            }
        }
    }
    &__aside {
        width: 32.4rem;
        @media (max-width: $sw-sm-max) {
            width: 100%;
        }
        &-content {
            background-color: #fff;
            margin-bottom: 1.6rem;
            padding: 4rem;
            @media (max-width: $sw-sm-max) {
                padding: 3rem;
            }
            &.aside-conditions {
                padding: 3.3rem 4.2rem .7rem 3rem;
                @media (max-width: $sw-sm-max) {
                    background-color: transparent;
                    padding: 0;
                    margin-bottom: 3.2rem;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            .people-box__details {
                @media (max-width: $sw-xs-max) {
                    padding-left: 0;
                }
            }
            .people-box__pic {
                @media (max-width: $sw-xs-max) {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin-bottom: 1.5rem;
                }
            }
        }
        &-title {
            margin-top: 0;
            margin-bottom: 2.6rem;
            text-transform: uppercase;
        }
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin-bottom: 2.3rem;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $orange;
                    margin-bottom: 7px;
                    @media (max-width: $sw-sm-max) {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
        .people-box__name {
            min-height: auto;
        }
        .people-box__sub-position,
        .people-box__contacts {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }
}