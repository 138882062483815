.section-conferences-detail{
	margin-bottom: 8rem;
}

.conferences-detail__content{
	display: flex;
	align-items: flex-start;
}

.conferences-detail__description{
	width: calc(100% - 25.1rem);
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		width: 100%;
	}
	@media ( max-width: $sw-xs-max) {}
}

.conferences-detail__tab-nav{
	ul{
		display: flex;
		li{
			width: 100%;
			a{
				color: $black;
				display: block;
				font-weight: 900;
				font-size: 2.4rem;
				line-height: 2.8rem;
				padding-top: 1.6rem;
				text-decoration: none;
				padding-bottom: 1.6rem;
				border-bottom: .8rem solid $alto;
				@media ( max-width: $sw-lg-max) {}
				@media ( max-width: $sw-md-max) {}
				@media ( max-width: $sw-sm-max) {}
				@media ( max-width: $sw-xs-max) {
					font-size: 1.5rem;
					line-height: 1.7rem;
					padding-top: 1.1rem;
					padding-bottom: 1.1rem;
				}
				&.active{
					border-bottom: .8rem solid $black;
				}
			}
		}
	}
}

.tab-nav{
	display: flex;
	li{
		width: 100%;
		a{
			color: $black;
			display: block;
			font-weight: 900;
			font-size: 2.4rem;
			line-height: 2.8rem;
			padding-top: 1.6rem;
			text-decoration: none;
			padding-bottom: 1.6rem;
			border-bottom: .8rem solid $alto;
			    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
        margin-right: 1.6rem;
			@media ( max-width: $sw-xs-max) {
				font-size: 2rem;
				line-height: 2.2rem;
				padding-top: 1.1rem;
				padding-bottom: 1.1rem;
			}
			&.active{
				border-bottom: .8rem solid $black;
			}
		}
	}
}

.conferences-detail__tab-content{
	margin-top: .4rem;
	.tab-content{

	}
}


.conferences-timeline{

}

.conferences-timeline-unit{
	font-size: 1.6rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		margin-left: -2.4rem;
		margin-right: -2.4rem;
	}
	&:not(:last-child){
		border-bottom: 1px solid $boulder;
	}

	&.descriptor{
		.conferences-timeline-unit__name{
			width: 79%;
			font-weight: 900;
			font-size: 2.4rem;
			@media ( max-width: $sw-lg-max) {}
			@media ( max-width: $sw-md-max) {}
			@media ( max-width: $sw-sm-max) {}
			@media ( max-width: $sw-xs-max) {
				width: 85%;
				font-size: 2rem;
				line-height: 2.2rem;
			}
		}
	}

	&.active{
		.conferences-timeline-unit__action{
			transform: rotateZ(180deg);
		}
	}
}

.conferences-timeline-unit-header{
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		flex-wrap: wrap;
		padding-top: 4.1rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		padding-bottom: 3.8rem;
	}
}

.conferences-timeline-unit__time{
	width: 11%;
	padding-top: .7rem;
	padding-left: .2rem;
	padding-right: 1rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: .9rem;
	}
}
.conferences-timeline-unit__name{
	width: 69%;
	font-size: 2rem;
	line-height: 2.8rem;
	padding-right: 2.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 70%;
		font-size: 2rem;
	}
}

.conferences-timeline-unit__picture{
	width: 20%;
	position: relative;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 30%;
	}
	picture{
		top: 50%;
		left: .5rem;
		right: .5rem;
		display: block;
		text-align: right;
		position: absolute;
		transform: translateY(-25%);
		img{
			max-height: 6.4rem;
		}
	}
}

.conferences-timeline-unit__action{
	width: 10%;
	text-align: center;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 15%;
	}
}

.conferences-timeline-unit-description{
	display: none;
	padding-left: 11%;
	padding-top: 1.2rem;
	padding-bottom: 5.4rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.conferences-timeline-unit__description {
	padding-left: 11%;
    margin-top: -2rem;
    margin-bottom: 2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.conferences-timeline-unit-description__sub-unit{
	&:not(:first-child){
		margin-top: 5.4rem;
		padding-top: 3.3rem;
		border-top: 1px solid $alto;
	}
}

.conferences-timeline-unit-description__sub-unit-description{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.conferences-timeline-unit-description__sub-unit-small-detail{
	width: 70%;
	padding-right: 1.6rem;
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}
}

.conferences-timeline-unit-description__sub-unit-small-time{
	font-size: 1.6rem;
	position: relative;
	line-height: 2.2rem;
	padding-left: 2.5rem;
	margin-bottom: 1.6rem;
	&:after{
		left: 0;
		top: .5rem;
		width: 1rem;
		content: '';
		height: 1rem;
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: $orange;
	}
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				&:after{
					width: 1px;
					content: '';
					height: 1.6rem;
					margin-left: .8rem;
					margin-right: .8rem;
					display: inline-block;
					background-color: $boulder;
				}
			}
		}
	}
}

.conferences-timeline-unit-description__sub-unit-small-caption{
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.6rem;
}

.conferences-timeline-unit-description__sub-unit-small-view{
	width: 30%;
	text-align: right;
	padding-right: 3.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		text-align: left;
		padding-right: 0;
		margin-top: 2.4rem;
	}
}

.conferences-timeline-unit-description__sub-unit-full-description{
	margin-top: 2.4rem;
}

.conferences-timeline-unit-description__sub-unit-reporters{
	display: flex;
	flex-wrap: wrap;
	margin-top: 3.4rem;
}

.conferences-timeline-unit-description__sub-unit-reporter{
	width: 50%;
	font-weight: 900;
	font-size: 1.6rem;
	line-height: 2.2rem;
	padding-right: 3.2rem;
	text-transform: uppercase;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		padding-right: 0;
	}
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( min-width: $sw-sm-min) {
		&:nth-child(n+3){
			margin-top: 5.3rem;
		}
	}
	@media ( max-width: $sw-xs-max) {
		&:nth-child(n+2){
			margin-top: 5.3rem;
		}
	}
}

.conferences-timeline-unit-description__sub-unit-reporter-name{
	line-height: 1.8rem;
	margin-bottom: .3rem;
}

.conferences-timeline-unit-description__sub-unit-reporter-specialisation{
	font-weight: 400;
	text-transform: none;
}

.conferences-timeline-unit-description__sub-unit-reporter-company{
	font-size: 2rem;
	margin-top: .9rem;
}




.conferences-detail__aside{
	width: 23.9rem;
	margin-top: 5.9rem;
	margin-left: 1.2rem;
	@media ( max-width: $sw-lg-max) {}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {
		display: none;
	}
	@media ( max-width: $sw-xs-max) {}
}

.conferences-detail__aside-item{
	&:not(:last-child){
		margin-bottom: 1.6rem;
	}
}



.special-event-box{
	display: block;
	padding-top: 2.5rem;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	background-color: $wildSand;
}

.special-event-box__title{
	font-size: 1.6rem;
	line-height: 2.2rem;
	margin-bottom: 1.1rem;
}

.special-event-box__name{
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.6rem;
	padding-right: .5rem;
}

.special-event-box__view{
	width: auto;
	height: 23.9rem;
	margin-top: 2.8rem;
	margin-left: -2.4rem;
	margin-right: -2.4rem;
	picture{
		width: 100%;
		height: 100%;
		display: block;
	}
}

.conferences-detail__aside-action{
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

// new new new new new new new new new

.conferences-detail__color-descr {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 24px;
	&__item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		margin-right: 15px;
		@media (max-width: 767px) {
			width: 100%;
			margin-right: 0;
		}
		&.plannary-section {
			color: #000000;
			.conferences-detail__color-descr__circle {
				background-color: #000000;
			}
		}
		&.technical-stream {
			color: #98CB4E;
			.conferences-detail__color-descr__circle {
				background-color: #98CB4E;
			}
		}
		&.strategic-stream {
			color: #009BD7;
			.conferences-detail__color-descr__circle {
				background-color: #009BD7;
			}
		}
	}
	&__circle {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		margin-right: 10px;
		flex-shrink: 0;
	}
	&__name {
		text-transform: uppercase;
		font-weight: 800;
		font-size: 14px;
		line-height: 1;
	}
}

.sub-unit {
	&-list {
		margin-bottom: 30px;
		@media (max-width: 767px) {
			margin-bottom: 40px;
		}
		li {
			/* font-weight: 800; */
			font-size: 16px;
			line-height: 1.38;
			margin-bottom: 7px;
		}
	}
	&-inner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		&-item {
			width: calc((100% - 46px) / 2);
			@media (max-width: 767px) {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			&__icon {
				margin-bottom: 16px;
				img, svg{
					width: 1em;
					font-size: 61px;
				}
			}
			&__title {
				margin-bottom: 10px;
				font-weight: 900;
				font-size: 20px;
				line-height: 1.2;
				text-transform: uppercase;
				span {
					font-weight: 700;
				}
			}
			&__text {
				font-size: 16px;
				line-height: 1.35;
			}
		}
	}
}

.icon {
	&-special-mitting {
		width: 1em;
		font-size: 61px;
	}
	&-meeting-group {
		width: 1em;
		font-size: 61px;
	}
}


.conferences-timeline-unit-double {
	&__wrapper {
		display: flex;
		padding-top: 3rem;
		padding-bottom: 3rem;
		@media (max-width: 767px) {
			flex-wrap: wrap;
			padding-top: 4rem;
			padding-left: 2.4rem;
    		padding-right: 2.4rem;
		}
		.conferences-timeline-unit {
			@media (max-width: 767px) {
				margin-left: 0;
				margin-right: 0;
			}
			&-header {
				padding-top: 0;
					@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__time {
				flex-shrink: 0;
			}
			&__name {
				width: 90%;
				padding-right: 10px;
				font-weight: 900;
				font-size: 2.4rem;
				@media (max-width: 767px) {
					width: 85%;
				}
				span {
					font-weight: 700;
				}
			}
			&__description {
				padding-left: 0;
				@media (max-width: 767px) {
					margin-bottom: 3rem;
				}
			}
			&__trigger-link {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 35px;
			}
			&.technical-stream {
				.conferences-timeline-unit__name {
					color: #98CB4E;
				}
			}
			&.strategic-stream {
				.conferences-timeline-unit__name {
					color: #009BD7;
				}
			}
		}
	}
	&__inner {
		width: 93%;
		@media (max-width: 767px) {
			width: 100%;
		}
		&__triggers {
			width: 100%;
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			@media (max-width: 767px) {
				flex-wrap: wrap;
				width: 100%;
			}
			.conferences-timeline-unit {
				width: calc((100% - 22px) / 2);
				border-bottom: none;
				padding-right: 22px;
				transition: width .4s ease, border-color .3s ease;
				position: relative;
				@media (max-width: 767px) {
					width: 100%;
					padding-right: 0;
				}
				&:first-child {
					&:after {
						content: '';
						display: block;
						width: 2px;
						height: 100%;
						position: absolute;
						right: 0;
						top: 0;
						background-color: #4F4F4F;
						@media (max-width: 767px) {
							right: auto;
							left: 50%;
							top: auto;
							bottom: 0;
							width: 95%;
							height: 1px;
							transform: translateX(-50%);
						}
					}
				}
				&:last-child {
					@media (max-width: 767px) {
						padding-top: 2.5rem;
					}
					.conferences-timeline-unit__description {
						@media (max-width: 767px) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&__content {
			width: 100%;
			display: flex;
			overflow: hidden;
			&-wrap {
				display: none;
			}
			.conferences-timeline-unit {
				width: 100%;
				flex-shrink: 0;
				border: none;
				transition: transform .5s ease, opacity .5s ease;
				&:first-child {
					transform: translateX(-100%);
					opacity: 0;
					&.active {
						opacity: 1;
						transform: translateX(0);
					}
				}
				&:last-child {
					transform: translateX(0);
					opacity: 0;
					&.active {
						opacity: 1;
						transform: translateX(-100%);
					}
				}
				&__name {
					width: 85%;
				}
				&-description__sub-unit {
					border: none;
					margin-top: 0;
					padding-top: 0;
				}
				&.technical-stream {
					.conferences-timeline-unit__name {
						color: #98CB4E;
					}
				}
				&.strategic-stream {
					.conferences-timeline-unit__name {
						color: #009BD7;
					}
				}
			}
		}
	}
}