.section {
    &-videos {
        margin-top: 5rem;
        &__wrap, &-sm__wrap {
            display: flex;
            flex-wrap: wrap;
            margin-top: 3rem;
            margin-bottom: 3rem;
            margin-right: -3rem;
            @media (max-width: $sw-md-max) {
                margin-right: -2.4rem;
            }
            @media (max-width: $sw-sm-max) {
                margin-right: -2.9rem;
            }
            @media (max-width: $sw-xs-max) {
                margin-right: 0;
            }
            .news-box__view {
                width: 31.3rem;
                height: 17.6rem;
                margin-right: 3rem;
                margin-bottom: 3rem;
                @media (max-width: $sw-md-max) {
                    width: 29.5rem;
                    height: 17rem;
                    margin-right: 2.3rem;
                    margin-bottom: 2.3rem;
                }
                @media (max-width: $sw-sm-max) {
                    width: calc(50% - 22px);
                    margin-right: 1.5rem;
                    margin-bottom: 1.5rem;
                }
                @media (max-width: $sw-xs-max) {
                    width: calc(50% - 8px);
                    height: 15rem;
                }
                @media (max-width: $sw-xx-max) {
                    width: 100%;
                    height: 15rem;
                    margin-right: 0;
                }
                &:nth-child(2n) {
                    @media (max-width: $sw-xs-max) {
                        margin-right: 0;
                    }
                }
            }
        }
        &-sm__wrap {
            .news-box__view {
                height: 31.3rem;
                @media (max-width: $sw-xs-max) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}