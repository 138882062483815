.regional-profile {
	padding-top: 4rem;
	@media (max-width: $sw-lg-max) {
		padding-top: 10rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 2rem;
	}
	&__links {
		position: absolute;
		right: 3rem;
		top: 1.7rem;
		@media (max-width: $sw-sm-max) {
			display: none;
		}
		.btn_blue {
			padding: 0 6.2rem;
		}
		.btn_black{
			padding: 0 2.9rem;
		}
	}
	&__title {
		h2 {
			font-size: 5.6rem;
			letter-spacing: 0.4px;
			@media (max-width: $sw-sm-max) {
				font-size: 4rem;
			}
			@media (max-width: $sw-xx-max) {
				font-size: 3rem;
			}
		}
	}
	&__subtitle {
		max-width: 61rem;
		margin-top: 2.4rem;
		@media (max-width: $sw-sm-max) {
			margin-top: 1.3rem;
		}
		span {
			font-size: 2rem;
			font-weight: 500;
			line-height: 2.5rem;
			@media (max-width: $sw-sm-max) {
				font-size: 1.6rem;
			}
		}
	}
	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 1.9rem;
		margin-right: 5.7rem;
		margin-bottom: -46px;
		@media (max-width: $sw-sm-max) {
			margin-right: 0;
		}
	}
	&__item {
		margin-bottom: 6rem;
		width: 45.2%;
		margin-right: 6rem;
		overflow: hidden;
		@media (max-width: $sw-md-max) {
			width: 46%;
		}
		@media (max-width: $sw-sm-max) {
			width: 47%;
			margin-right: 3rem;
		}
		@media (max-width: $sw-xs-max) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 4rem;
		}
		&:nth-child(even) {
			margin-right: 0;
		}
		&-img {
			height: 27.3rem;
			width: 100%;
			&-inner {
				height: 100%;
			}
		}
		&-link {
			padding-left: 0.5rem;
			padding-top: 1.7rem;
			display: inline-block;
			a {
				display: inline-block;
				letter-spacing: -0.1px;
				.icon-arrow {
					margin-top: 3px;
				}
			}
		}
		&-desc {
			margin-top: 2rem;
    	margin-left: 4px;

			span {
				font-size: 1.6rem;
			}
		}
	}
	&__report {
		.projects-body__report-question {
			span {
				color: #009BD7;
			}
		}
		.news-inner__aside-form .form-box_submit button {
				background-color: #009BD7;
			}
		}
		/* .projects-body__report-list {
			padding-bottom: 13rem;
		} */
}