html {
	height: 100%;
	font-size: 10px;
}

body {
	min-height: 100%;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
}

img{
	height: auto;
	max-width: 100%;
}