.modal{
	&.fade{
		.modal-dialog{
			opacity: 0;
			transform: translate(50%, 0);
		}
	}
	&.show{
		.modal-dialog{
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.modal-backdrop{
	&.show{
		opacity: .9;
	}
}

.modal-dialog{
	margin: 0 auto;
	max-width: 68.4rem;
	@media ( max-width: $sw-xs-max) {
		margin: 0 auto;
		max-width: 100%;
	}
	&_sm{
		max-width: 52.9rem;
		@media ( max-width: $sw-xs-max) {
			margin: 0 auto;
			max-width: 100%;
		}
	}
}

.modal-content{
	border: none;
	border-radius: 0;
	min-height: 100vh;
	padding-top: 4rem;
	padding-bottom: 4rem;
	background-color: $white;
	@media ( max-width: $sw-xs-max) {
		min-height: 100vh;
	}
}

.modal-close{
	z-index: 1;
	top: 2.8rem;
	right: 2.8rem;
	position: absolute;
	@media ( max-width: $sw-xx-max) {
		top: 1.6rem;
		right: 1.6rem;
	}
}

.modal-header{
	padding: 0;
	border: none;
	border-radius: 0;
	padding-top: 2.2rem;
	padding-left: 8.5rem;
	padding-right: 8.5rem;
	flex-direction: column;
	padding-bottom: 3.7rem;
	@media ( max-width: $sw-xs-max) {
		padding-top: .8rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		padding-bottom: 4.4rem;
	}
	.modal-title{
		h3{
			@media ( max-width: $sw-xs-max) {
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
		}
	}
}

.modal-caption{
	font-size: 1.6rem;
	padding-right: 32%;
	margin-top: 1.4rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-xs-max) {
		padding-right: 0;
		margin-top: 2rem;
	}
}

.modal-body{
	padding: 0;
	padding-left: 8.5rem;
	padding-right: 8.5rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.modal-footer{
	padding: 0;
	padding-left: 8.5rem;
	padding-right: 8.5rem;
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.modal-description-box__header{
	display: flex;
	align-items: flex-start;
	@media ( max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.modal-description-box__view{
	width: 15.4rem;
	flex-shrink: 0;
	height: 15.4rem;
	margin-right: 1.6rem;
	@media ( max-width: $sw-xs-max) {
		margin-left: auto;
		margin-right: auto;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
		overflow: hidden;
		border-radius: 50%;
	}
}

.modal-description-box__detail{
	padding-top: 3.3rem;
	@media ( max-width: $sw-xs-max) {
		width: 100%;
		display: flex;
		padding-top: .5rem;
		flex-direction: column;
	}
}

.modal-description-box__name{
	font-weight: 900;
	font-size: 3.2rem;
	line-height: 3.4rem;
	margin-bottom: .5rem;
	@media ( max-width: $sw-xs-max) {
		order: 1;
		margin-bottom: 0;
		font-size: 2.4rem;
		margin-top: 1.1rem;
		line-height: 2.8rem;
	}
}

.modal-description-box__position{
	font-size: 1.6rem;
	line-height: 2.2rem;
	@media ( max-width: $sw-xs-max) {
		order: 2;
		margin-top: 1.2rem;
	}
}

.modal-description-box__social{
	margin-top: 1.1rem;
	@media ( max-width: $sw-xs-max) {
		order: 0;
	}
	ul{
		display: flex;
		align-items: center;
		li{
			&:not(:last-child){
				margin-right: .8rem;
			}
			a{
				display: block;
				svg{
					font-size: 2.4rem;
					@media ( max-width: $sw-xs-max) {
						font-size: 4rem;
					}
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						transform: translateY(-0.4rem);
					}
				}
				&.blue{
					svg{
						fill: $cerule;
					}
				}
			}
		}
	}
}

.modal-description-box__content{
	margin-top: 3.9rem;
	@media ( max-width: $sw-xs-max) {
		margin-top: 3.7rem;
	}
}

// new
.modal-action, .modal-action-sm {
	.modal-close {
		right: 1.5rem;
		top: 1.5rem;
		.btn_close {
			&:before, &:after {
				background-color: rgba(255,255,255,.2);
			}
		}
		&.background--light {
			.btn_close {
				&:before, &:after {
					background-color: rgba(0,0,0,.2);
				}
			}
		}
	}
	.modal-body {
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
	.modal-content {
		min-height: auto;
		height: auto;
		padding-top: 0;
		padding-bottom: 0;
		p {
			line-height: 1.26;	
			font-weight: 500;
			color: #000000;
		}
		&__inner {
			.btn {
				min-width: 280px;
				font-size: 20px;
				text-transform: uppercase;
				@media (max-width: $sw-md-max) {
					font-size: 18px;
				}
				@media (max-width: $sw-xs-max) {
					width: 100%;
					max-width: 280px;
					font-size: 16px;
					min-width: auto;
				}
			}
		}
	}
	
}

.modal-action {
	.modal-content {
		width: 100%;
		max-width: 600px;
		margin: 0 auto;
		@media (max-width: $sw-md-max) {
			max-width: 450px;
		}
		@media (max-width: $sw-xs-max) {
			max-width: 400px;
		}
		p {
			font-size: 20px;
			@media (max-width: $sw-md-max) {
				font-size: 18px;
			}
			@media (max-width: $sw-xs-max) {
				font-size: 15px;
			}
		}
		&__img {
			width: 100%;
			height: 370px;
			@media (max-width: $sw-md-max) {
				height: 280px;
			}
			@media (max-width: $sw-xs-max) {
				height: 140px;
			}
		}
		&__inner {
			padding: 40px 75px 50px 75px;
			background-color: #fff;
			@media (max-width: $sw-md-max) {
				padding: 30px 55px 40px 55px;
			}
			@media (max-width: $sw-xs-max) {
				padding: 20px;
			}
		}
	}
}
.modal-action-sm {
	.modal-dialog {
		max-width: 100%;
	}
	.modal-content {
		position: absolute;
		right: 106px;
		bottom: 68px;
		width: 400px;
		@media (max-width: $sw-md-max) {
			right: 70px;
			bottom: 30px;
			width: 350px;
		}
		@media (max-width: $sw-xs-max) {
			position: relative;
			right: auto;
			bottom: auto;
			margin: 0 auto;
			width: 100%;
			max-width: 350px;
		}
		&__img {
			width: 100%;
			height: 140px;
		}
		&__inner {
			padding: 24px 30px 30px 30px;
			background-color: #fff;
			@media (max-width: $sw-md-max) {
				padding: 20px;
			}
			p {
				font-size: 16px;
				margin-bottom: 15px;
				@media (max-width: $sw-md-max) {
					font-size: 15px;
				}
			}
		}
	}
}