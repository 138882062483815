.container{
	@media ( max-width: $sw-xs-max) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.p-wrapp{
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	flex-direction: column;
}

.nav-list-box{
	top: 0;
	width: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
	left: 22.8rem;
	position: fixed;
	overflow: hidden;
	padding-top: 10rem;
	padding-bottom: 10rem;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {
		left: 29.2rem;
		padding-top: 8.4rem;
		padding-bottom: 8.4rem;
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {
		left: 0;
		width: 0;
		z-index: 6;
		opacity: 0;
		top: 7.2rem;
		visibility: hidden;
		padding-top: 3.1rem;
		.nav-submenu-active & {
			opacity: 1;
			visibility: visible;
		}
		.nav-submenu-deactive & {
			opacity: 1;
			visibility: visible;
		}
	}
	.nav-submenu-active & {
		overflow-y: auto;
	}
}

.nav-box{
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 5;
	width: 22.8rem;
	position: fixed;
	overflow: hidden;
	background-color: $white;
	@media ( max-width: $sw-lg-max) {
		bottom: auto;
		width: 29.2rem;
	}
	@media ( max-width: $sw-xs-max) {
		width: 100%;
	}

}

.content-box{
	padding-left: 22.8rem;
	@media ( max-width: $sw-lg-max) {
		padding-top: 8.4rem;
	}
	@media ( max-width: $sw-md-max) {}
	@media ( max-width: $sw-sm-max) {}
	@media ( max-width: $sw-xs-max) {}
	@media ( max-width: $sw-lg-max) {
		padding: 0;
	}
}

.tab-pane{
	display: none;
	&.active{
		display: block;
	}
}

main{
	flex-grow: 1;
	@media ( max-width: $sw-xs-max) {
		padding-top: 7.2rem;
	}
	&.conference{
		@media ( max-width: $sw-xs-max) {
			padding-top: 5.6rem;
		}	
	}
}

img {
	max-width: 100%;
	height: auto;
	width: auto;
}

hr {
	margin-top: 2.7rem;
	margin-bottom: 2.7rem;
	border-top: 1px solid #f3f3f3;
}

a {
	outline: none;
	color: #eb50a3;
	transition: all $transition_duration ease;

	&.no-decoration{
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
		&:active {
			text-decoration: none;
		}
	}



	

	// tags

	&.tag{
		display: block;
		color: $boulder;
		font-size: 1.6rem;
		padding-top: .7rem;
		padding-left: 1rem;
		line-height: 1.6rem;
		padding-right: 1rem;
		padding-bottom: .9rem;
		border-radius: 2.7rem;
		background-color: $mercury;
		@media ( min-width: $sw-md-min) {
			&:hover{
				color: $black;
			}
		}
	}

	&:hover {
		text-decoration: underline;
		outline: none;
	}
	&:focus {
		text-decoration: underline;
		outline: none;
	}
	&:active {
		text-decoration: underline;
		outline: none;
	}
}

a, span, button {
	&.link{
		//size
		&_black{
			font-weight: 900;
		}
		&_medium{
			font-weight: 500;
		}



		// color
		&_blue{
			color: $cerulean;
			svg{
				fill: $cerulean;
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: .8;
					color: $cerulean;
				}
			}
		}
		&_white{
			color: $white;
			svg{
				fill: $white;
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: .8;
					color: $white;
				}
			}
		}
		&_dark{
			color: $black;
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: .8;
					color: $black;
				}
			}
		}
		&_orange{
			color: $orange;
			svg{
				fill: $orange;
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: .8;
					color: $orange;
				}
			}
		}
		&_green {
			color: #98CB4E;
			svg{
				fill: #98CB4E;
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: .8;
					color: #98CB4E;
				}
			}
		}

		//line

		&_line{
			overflow: hidden;
			position: relative;
			display: inline-flex;
			&:after{
				left: 0;
				right: 0;
				bottom: 0;
				height: 1px;
				content: '';
				display: block;
				position: absolute;
				background-color: $white;
				transition: all 0.3s ease;
				transform: translateX(-110%);
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
					&:after{
						transform: translateX(0);
					}
				}
			}
		}

		//arrow

		&_arrow{
			font-weight: 900;
			font-size: 1.6rem;
			align-items: center;
			line-height: 1.6rem;
			display: inline-flex;
			span{
				& + svg{
					margin-left: .8rem;
				}
			}
			svg{
				flex-shrink: 0;
				font-size: 1.8rem;
				transition: all 0.3s ease;
				& + span{
					margin-left: .8rem;
				}
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
					span{
						& + svg{
							transform: translateX(.6rem);
						}
					}
					svg{
						& + span{
							transform: translateX(.6rem);
						}
					}
				}
			}
			&.small{
				font-weight: 400;
				font-size: 1.4rem;
				span{
					& + svg{
						margin-left: 1.1rem;
					}
				}
				svg{
					font-size: 1.1rem;
				}
				@media (min-width: $sw-md-min) {
					&:hover{
						span{
							& + svg{
								transform: translateX(.3rem);
							}
						}
						svg{
							& + span{
								transform: translateX(.3rem);
							}
						}
					}
				}
			}
		}

		// inverted arrow
		&_arrow-back {
			font-weight: 900;
			font-size: 1.6rem;
			align-items: center;
			line-height: 1.6rem;
			display: inline-flex;
			svg {
				transition: all 0.3s ease;
				transform: translateX(0) rotate(180deg);
				& + span{
					margin-left: .8rem;
				}
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
					svg{
						transform: translateX(-0.6rem) rotate(180deg);
					}
				}
			}
		}

		&_arrow-down{
			font-weight: 900;
			font-size: 1.6rem;
			align-items: center;
			line-height: 1.6rem;
			display: inline-flex;
			span{
				& + svg{
					margin-left: .8rem;
				}
			}
			svg{
				flex-shrink: 0;
				font-size: 1.8rem;
				margin-top: -.2rem;
				transform: rotateZ(90deg);
				transition: all 0.3s ease;
				& + span{
					margin-left: .8rem;
				}
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
				}
			}
		}

		// video

		&_icon-descript{
			color: $black;
			font-weight: 900;
			font-size: 1.6rem;
			line-height: 1.6rem;
			align-items: center;
			display: inline-flex;
			text-transform: uppercase;
			i{
				width: 3.4rem;
				height: 3.4rem;
				display: block;
				position: relative;
				margin-right: .8rem;
				transition: all 0.3s ease;
				svg{
					top: 50%;
					left: 50%;
					fill: $black;
					stroke: $black;
					font-size: 3.4rem;
					position: absolute;
					transition: all 0.3s ease;
					transform: translate(-50%, -50%);
				}
			}
			@media ( min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
					i{
						svg{
							font-size: 3.8rem;
						}
					}
				}
			}
			&.link{
				&_blue{
					color: $cerule;
					i{
						svg{
							fill: $cerule;
							stroke: $cerule;
						}
					}
				}
			}
			&-big{
				color: $white;
				i{
					width: 4.8rem;
					height: 4.8rem;
					margin-right: 1.6rem;
					svg{
						stroke: none;
						font-size: 4.8rem;
					}
				}
				@media ( min-width: $sw-md-min) {
					&:hover{
						i{
							svg{
								font-size: 5.2rem;
							}
						}
					}
				}
			}
		}

		&_arrow-filed{
			font-weight: 900;
			font-size: 1.6rem;
			align-items: center;
			line-height: 1.6rem;
			display: inline-flex;
			span{
				white-space: nowrap;
				& + i{
					margin-left: 1.8rem;
				}
			}
			i{
				width: 5.2rem;
				height: 5.2rem;
				flex-shrink: 0;
				position: relative;
				margin-left: 1.8rem;
				&:after{
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					content: '';
					display: block;
					position: absolute;
					border-radius: 50%;
					background-color: $white;
				}
				svg{
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					margin: auto;
					fill: $cerulean;
					font-size: 1.8rem;
					position: absolute;
				}
				& + span{
					margin-left: 1.8rem;
				}
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					opacity: 1;
					// span{
					// 	& + i{
					// 		transform: translateX(.6rem);
					// 	}
					// }
					// i{
					// 	& + span{
					// 		transform: translateX(.6rem);
					// 	}
					// }
				}
			}
		}

		&_add{
			color: $black;
			font-weight: 900;
			font-size: 1.6rem;
			position: relative;
			line-height: 1.6rem;
			padding-right: 2.2rem;
			text-transform: uppercase;
			&:after{
				top: .1rem;
				content: '';
				right: .7rem;
				width: .2rem;
				height: 1.6rem;
				display: block;
				position: absolute;
				background-color: $black;
			}
			&:before{
				right: 0;
				top: .8rem;
				content: '';
				width: 1.6rem;
				height: .2rem;
				display: block;
				position: absolute;
				background-color: $black;
			}
		}

	}
}

body {
	font-variant-ligatures: none;
	&.scroll-disabled {
		max-height: 100vh;
		overflow: hidden;
		position: fixed;
		width: 100vw;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}
}

.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: top;
    display: inline-block;
}

@mixin paragraphStyle(){
	font-size: 2rem;
	line-height: 2.8rem;
}

ul:not(.h-reset-list):not(.slick-dots):not(.conference-box__list):not(.filters__list):not(.dropdown-side__list):not(.select-options):not(.reg-price__aside-list):not(.price__block-list):not(.news__sublist):not(.news__sublist-list):not(.text-list__item) {
	margin: 0;
	padding: 0;
	list-style: none;
	    line-height: 1.45rem;
	        font-size: 2rem;
    line-height: 2.8rem;
	/* margin-top: 2.2rem;
	margin-bottom: 2.8rem; */
	li{
		position: relative;
		padding-left: 4.2rem;
		margin-bottom: .6rem;
		padding-bottom: 2rem;
		//@include paragraphStyle();
		&:after{
			/* top: .6rem;
			left: 2rem;
			width: 1rem;
			content: '';
			height: 1rem;
			display: block;
			position: absolute;
			transform: rotateZ(-45deg);
			border-right: 1px solid #ee6db2;
			border-bottom: 1px solid #ee6db2; */
			    content: '';
			    display: block;
			    width: 10px;
			    height: 10px;
			    border-radius: 50%;
			    background-color: #47D597;
			    position: absolute;
			    top: 9px;
			    left: 0;
		}
	}
}

ol:not(.h-reset-list):not(.slick-dots){
	margin: 0;
	padding: 0;
	list-style: none;
	/* margin-top: 2.2rem;
	margin-bottom: 2.8rem; */
	li{
		position: relative;
		padding-left: 4.2rem;
		margin-bottom: .6rem;
		@include paragraphStyle();
		counter-increment: step-counter;
		&:after{
			top: 0;
			left: 2rem;
			display: block;
			color: #ee6db2;
			position: absolute;
			content: counter(step-counter) '.';
		}
	}
}

h1,h2,h3,h4,h5,h6{
	font-weight: 900;
}
h1{
	margin: 0;
	font-size: 6.4rem;
	line-height: 6.8rem;
	margin-bottom: 1.6rem;
}
h2{
	font-size: 4.8rem;
	margin-top: .9rem;
	line-height: 5.2rem;
	margin-bottom: .9rem;
}
h3{
	margin-top: 1rem;
	font-size: 3.2rem;
	line-height: 3.4rem;
	margin-bottom: 2rem;
}
h4{
	font-size: 2.4rem;
	margin-top: 1.1rem;
	line-height: 2.8rem;
	margin-bottom: 1.1rem;
}
h5{
	font-size: 2rem;
	margin-top: 1.2rem;
	line-height: 2.2rem;
	margin-bottom: 1.2rem;
}
h6{
	font-size: 1.6rem;
	margin-top: 1.3rem;
	line-height: 1.8rem;
	margin-bottom: 1.3rem;
}

p{
	margin-bottom: 2.8rem;
	@include paragraphStyle();
	&.small{
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	&.big{
		font-size: 2.4rem;
		line-height: 3rem;
	}
	&.text-main {
		font-weight: 500;
	}
}



blockquote{
	position: relative;
	&:after{
		top: 0;
		left: 0;
		right: 0;
		content: '';
		height: .2rem;
		display: block;
		position: absolute;
		background-color: #000;
	}
	p{
		font-size: 2rem;
		font-weight: 700;
		line-height: 2.6rem;
	}
	span{
		display: block;
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	&.ru{

	}
}

.slick-track{
	display: flex;
	align-items: stretch;
	.slick-slide{
		float: none;
		height: auto;
		outline: none !important;
	}
}

.text-hint {
	margin-left: 10px;
	cursor: pointer;
	.icon-info {
		font-size: 2.4rem;
	}
}
.popover {
	font-size: 1.2rem;
}

.about-ui__content {
	@media (max-width: $sw-lg-max) {
		padding-top: 7rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 0;
	}
	.conf-visit__section, .cta__section, .cta-update__section {
		margin-top: 5rem;
		@media (max-width: $sw-xs-max) {
			margin-top: 3.5rem;
		}
	}
}
// section-about-info start
.section-about-info {
	padding: 6.7rem 0 4.4rem 0;
	background-color: #F5F5F5;
	h3 {
		margin-right: 6rem;
		text-transform: uppercase;
		@media (max-width: $sw-sm-max) {
			margin-right: 2rem;
		}
		@media (max-width: $sw-xs-max) {
			margin-right: 0;
		}
	}
}
.about-info {
	&__top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 5rem;
		@media (max-width: $sw-xs-max) {
			flex-wrap: wrap;
		}
	}
	&__image {
		width: 15.4rem;
		height: 15.4rem;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		@media (max-width: $sw-sm-max) {
			width: 13rem;
			height: 13rem;
		}
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			border-radius: 50%;
		}
	}
	&__inner {
		display: flex;
		align-items: flex-start;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		flex-wrap: wrap;
		@media (max-width: $sw-sm-max) {
			margin-left: -0.8rem;
			margin-right: -0.8rem;
		}
		@media (max-width: $sw-xs-max) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	&__block {
		background-color: #fff;
		padding: 4.7rem 2.5rem;
		min-height: 39.2rem;
		@media (max-width: $sw-sm-max) {
			padding: 2rem;
			min-height: 30rem;
			@media (max-width: $sw-xs-max) {
				min-height: auto;
				width: 100%;
				margin: 0 auto;
			}
		}
		&-wrap {
			width: calc(100% / 3);
			padding: 0 1.5rem;
			margin-bottom: 3rem;
			@media (max-width: $sw-sm-max) {
				padding: 0 0.8rem;
				margin-bottom: 1.6rem;
			}
			@media (max-width: $sw-xs-max) {
				padding: 0;
				width: 100%;
			}
		}
		&__title {
			color: #FF0000;
			font-size: 4.8rem;
			margin-bottom: 3.4rem;
			font-weight: 700;
			@media (max-width: $sw-sm-max) {
				font-size: 3.7rem;
				margin-bottom: 1.5rem;
			}
		}
		p {
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 1.8;
			color: #000000;
			@media (max-width: $sw-sm-max) {
				font-size: 1.5rem;
				line-height: 1.6;
			}
		}
	}
}
// section-about-info end

// section-about-different start
.section-about-different {
	padding: 6.7rem 0 6.7rem 0;
	@media (max-width: $sw-lg-max) {
		padding: 6.7rem 0 6.7rem 0;
	}
	@media (max-width: $sw-md-max) {
		padding: 6.7rem 0 6.7rem 0;
	}
	@media (max-width: $sw-sm-max) {
		padding: 6.7rem 0 6.7rem 0;
	}
	.section-title__main {
		margin-bottom: 7rem;
		@media (max-width: $sw-sm-max) {
			margin-bottom: 5rem;
		}
		h2 {
			color: #98CB4F;
			text-transform: uppercase;
		}
	}
}
.about-different {
	&__inner {
		display: flex;
		align-items: flex-start;
		margin-left: -2rem;
		margin-right: -2rem;
		flex-wrap: wrap;
		@media (max-width: $sw-sm-max) {
			margin-left: -1rem;
			margin-right: -1rem;
		}
		@media (max-width: $sw-xs-max) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	&__block {
		background-color: #fff;
		padding-right: 0.35rem;
		@media (max-width: $sw-xs-max) {
			padding-right: 0;
			width: 100%;
			margin: 0 auto;
		}
		&-wrap {
			width: calc(100% / 3);
			padding: 0 2rem;
			/* margin-bottom: 3rem; */
			@media (max-width: $sw-sm-max) {
				padding: 0 1rem;
				/* margin-bottom: 1.6rem; */
			}
			@media (max-width: $sw-xs-max) {
				padding: 0;
				width: 100%;
			}
		}
		&__icon {
			margin-bottom: 1rem;
			min-height: 9.7rem;
			display: flex;
			align-items: center;
			@media (max-width: $sw-sm-max) {
				min-height: 8rem;
			}
			@media (max-width: $sw-xs-max) {
				min-height: auto;
				width: 100%;
				text-align: center;
			}
			.icon {
				font-size: 8.4rem;
				color: #000000;
				@media (max-width: $sw-sm-max) {
					font-size: 7.1rem;
				}
				&-diamond {
					font-size: 9.7rem;
					@media (max-width: $sw-sm-max) {
						font-size: 8rem;
					}
				}
			}
		}
		p {
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 1.8;
			color: #000000;
			@media (max-width: $sw-sm-max) {
				font-size: 1.5rem;
				line-height: 1.6;
			}
		}
	}
}
// section-about-different end