.reg-success {
    &__section {
        background-color: $wildSand;
        padding-bottom: 7.6rem;
        @media ( max-width: $sw-sm-max) {
            padding-top: 13rem;
        }
        @media ( max-width: $sw-xs-max) {
            padding-top: 3rem;
            padding-bottom: 4rem;
        }
        @media ( max-width: $sw-xx-max) {
            padding-bottom: 0;
        }
        .section-conference-header {
            @media ( max-width: $sw-sm-max) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    &__icon {
        margin-bottom: 2.3rem;
        .icon-success {
            font-size: 7.2rem;
            color: #000;
        }
    }
    &__title {
        text-transform: uppercase;
        margin-top: 0;
        @media ( max-width: $sw-md-max) {
			font-size: 4.3rem;
			line-height: 4.8rem;
		}
		@media ( max-width: $sw-sm-max) {
			font-size: 3.8rem;
			line-height: 4.4rem;
		}
		@media ( max-width: $sw-xs-max) {
			font-size: 3.2rem;
			line-height: 3.4rem;
		}
    }
    &__subtitle {
        font-weight: 500;
        margin-bottom: 4.8rem;
        font-size: 2.4rem;
        line-height: 3rem;
            white-space: pre-line;
    }
    &__boxes-wrap {
        margin-right: -1.6rem;
        display: flex;
        justify-content: center;
        width: 100%;
        @media ( max-width: $sw-sm-max) {
            // display: block;
            // margin-right: 0;
            flex-wrap: wrap;
        }
        @media ( max-width: $sw-xx-max) {
            margin-right: 0;
        }
    }
    &__box {
        width: 32.5rem;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        vertical-align: top;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        @media ( max-width: $sw-xx-max) {
            margin-right: 0;
        }
        &-content {
            padding: 2.4rem 1.5rem 2rem 2.4rem;
            min-height: 24.5rem;
            width: 100%;
            background-color: #fff;
            @media (max-width: $sw-xx-max) {
                min-height: 22.5rem;
                padding: 2rem 1.5rem 1rem 1.7rem;
            }
            p {
                margin-bottom: 0;
            }
        }
        &-link {
            height: 6.3rem;
            padding: 0 2.4rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #fff;
            width: 100%;
            @media (max-width: $sw-xx-max) {
                padding: 0 1.7rem;
            }
        }
        &-title {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.6rem;
            margin-top: 0;
            margin-bottom: 1.6rem;
        }
    }
}