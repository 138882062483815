.projects-hero {
	padding: 40px 0 0 0;
	@media (max-width: $sw-lg-max) {
		padding-top: 10rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 2rem;
	}
	&__links {
		position: absolute;
		top: 17px;
		right: 29px;
		z-index: 2;
		@media (max-width: $sw-sm-max) {
			display: none;
		}
		.btn_green {
			padding:  0 6.2rem;
		}
		.btn_black {
			padding: 0 2.9rem;
		}
	}
	&__img {
		height: 400px;
		@media (max-width: $sw-sm-max) {
			height: 350px;
		}
		@media (max-width: $sw-xs-max) {
			height: 300px;
		}
		&-inner {
			height: 100%;
		}
	}
	&__title {
		display: flex;
		justify-content: space-between;
		padding-top: 78px;
		@media (max-width: $sw-md-max) {
			padding-top: 40px;
			display: flex;
			flex-direction: column;
		}
		@media (max-width: $sw-xs-max) {
			padding-top: 20px;
			display: flex;
			flex-direction: column;
		}
		&-txt {
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			max-width: 630px;
			@media (max-width: $sw-md-max) {
				max-width: 100%;
			}
			@media (max-width: $sw-xs-max) {
				font-size: 20px;
				line-height: 25px;
			}
			@media (max-width: $sw-xx-max) {
				font-size: 1.4rem;
			}
			a {
				text-decoration: none;
				color: $cerule;
			}
		}
		&-txt-logo {
			display: flex;
			flex-direction: column;
			padding-right: 5px;
			@media (max-width: $sw-sm-max) {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
			@media (max-width: $sw-md-max) {
				margin-top: 20px;
			}
			@media (max-width: $sw-xx-max) {
				flex-direction: column;
			}
			span {
				font-size: 16px;
				font-weight: 800;
				text-transform: uppercase;
				@media (max-width: $sw-xx-max) {
					font-size: 1.4rem;
				}
			}
			img {
				margin-top: 18px;
				width: 313px;
				@media (max-width: $sw-sm-max) {
					height: 50px;
					width: 250px;
					margin-top: 0;
				}
			}
		}
	}
	&__subtitle {
		padding-top: 32px;
		max-width: 70rem;
		p {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 2.5rem;
			margin-bottom: 0;
			@media (max-width: $sw-xx-max) {
				font-size: 1.4rem;
				line-height: 2rem;
			}
			&:first-child {
				margin-bottom: 1.6rem;
			}
		}
	}
}




