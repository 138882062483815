// Font variables

	$font_TTNorms: 'TTNorms', sans-serif;

// Color variables

	$white: #ffffff;
	$black: #000000;
	$boulder: #787878;
	$cerule: #009BDF;
	$mercury: #E2E2E2;
	$thunder: #231F20;
	$cerulean: #009BD7;
	$alto: #D8D8D8;
	$silver: #cbcaca;
	$boulderSolid: #7A7A7A;
	$orange: #FC6621;
	$gray: #929292;
	$webOrange: #F2A900;
	$shamrock: #48D597;
	$wildSand: #F5F5F5;
	$alizarinCrimson: #EE2737;
	$silverApprox: #BABABA;
	$vistaBlue: #73D19C;



	$xxxx: #8A9297;
	$xxxx: #8BD3E6;
	$xxxx: #97D700;
	$xxxx: #FBF619;



	// $gray: #808080;
	// $alto: #dfdfdf;
	// $gallery: #efefef;
	// $altoSolid: #dbdbdb;
	// $cornflowerBlue: #5da6d0;

// Bootstrap variables

	$body-bg:      #f3f3f3;
	$body-color:   $black;
	
	$transition_duration: 0.3s;
	
	$link-decoration:         underline;
	$link-hover-decoration:   none;

	$headings-font-family: $font_TTNorms;

	$font-size-base: 1.4rem;

	$line-height-base: 1.4;

	$sizes: (
		25: 25%,
		50: 50%,
		75: 75%,
		100: 100%
	) !default;


	$link-color:            #eb50a3;
	$link-decoration:       underline;
	// $link-hover-color:      darken($link-color, 15%) !default;
	/*$link-hover-color:      darken($link-color, 15%);*/
	$link-hover-color:      #1c1c1c;
	$link-hover-decoration: none ;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$sw-xx-min: 0;//0
$sw-xs-min: 480px;//0//576
// $sw-sm-min:768px;//576//768
$sw-sm-min: 768px;
$sw-md-min: 992px;
$sw-lg-min: 1200px;
$sw-xl-min: 1440px;

$sw-xx-max:$sw-xs-min - 1px;//479px
$sw-xs-max:$sw-sm-min - 1px;
$sw-sm-max:$sw-md-min - 1px;
$sw-md-max:$sw-lg-min - 1px;
$sw-lg-max:$sw-xl-min - 1px;

$grid-breakpoints: (
	xs: $sw-xs-min,
	sm: $sw-sm-min,
	md: $sw-md-min,
	lg: $sw-lg-min,
	xl: $sw-xl-min,
);


// Grid columns

$grid-columns: 24;
$grid-gutter-width: 3rem;
$grid-gutter-width-base: $grid-gutter-width;
$grid-gutter-widths: (
	xs: $grid-gutter-width-base,
	sm: $grid-gutter-width-base,
	md: $grid-gutter-width-base,
	lg: $grid-gutter-width-base,
	xl: $grid-gutter-width-base
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	xs: 100%,
	sm: 73.8rem,
	md: 96.2rem,
	lg: 103.8rem,
	xl: 103.8rem
);

// Fonts

$font-family-sans-serif: $font_TTNorms;
$font-family-serif:      $font_TTNorms;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;